import React, { FC, useContext, useRef, memo } from 'react';

import { AuthContext } from 'routes/auth.context';
import { SubmitHandler, FormHandles } from '@unform/core';
import PageSigninComponent from './component';

import * as yup from 'yup';
import { YupUtilsTry, YupUtilsCatch } from 'libs/yup/ShapeValidation';

const PageSignin: FC = memo(() => {
	const { cacheEmail, loading, signed, signIn } = useContext(AuthContext);
	const formRef: any = useRef<FormHandles>(null);
	const handleSubmit: SubmitHandler<IFormLogin> = async data => {
		const { setErrors } = formRef.current;
		try {
			await YupUtilsTry(data, setErrors, {
				email: yup
					.string()
					.min(3, 'Email inválido')
					.max(60)
					.email('Email inválido')
					.required('Email não informado'),
				senha: yup
					.string()
					.min(3, 'Senha inválido')
					.max(30)
					.required('Senha não informada'),
			});
			await signIn(data);
		} catch (err) {
			YupUtilsCatch(err, setErrors);
		}
	};
	const props: any = {
		loading,
		signed,
		formRef,
		cacheEmail,
		handleSubmit,
	};
	return <PageSigninComponent {...props} />;
});
export default PageSignin;
