import { gql } from '@apollo/client';

export const GRAPHQL_RECUPERAR_PROSPECTO: any = gql`
    query prospectoContainer {
        recuperarProspecto {
            data {
                id
                razaoSocial
                cnpj
                enderecoLogradouro
                enderecoNumero
                enderecoBairro
                enderecoComplemento
                enderecoCep
                email
                nomeResponsavel
                telefone
                cidade {
                    nome
                    uf
                }
                respostasEnquete {
                    pergunta
                    resposta
                }
            }
            hasNextPage
        }
        perguntas {
            id
        }
    }
`;
