import React, { createContext, FC, createRef, RefObject } from 'react';
import { FormHandles } from '@unform/core';
import { useSubmit } from './hooks/useSubmit';
import { useProspecto } from './hooks/useProspecto';
import { useSteps } from './hooks/useSteps';

interface ISubmit {
	atual?: string;
	proximo?: string;
}
interface ISignupContext {
	data?: any;
	steps?: any;
	loading?: boolean;
	enqueteProspecto: any;
	switchComponent: string;
	stepSelected: number;
	submit(data: ISubmit): void;
	initialDataForm: any;
}
export const SignupContext = createContext<ISignupContext>(
	{} as ISignupContext
);

const staticSteps = {
	personal: {
		nome: 'Dados pessoais',
		ref: createRef<FormHandles>(),
		formularioSucesso: false,
	},
	business: {
		nome: 'Seu negócio',
		ref: createRef<FormHandles>(),
		formularioSucesso: false,
	},
	company: {
		nome: 'Sua empresa',
		ref: createRef<FormHandles>(),
		formularioSucesso: false,
	},
};

export const SignupProvider: FC = ({ children }) => {
	// Controla navegação entre abas
	const {
		initialDataForm,
		switchComponent,
		submit,
		steps,
		setSteps,
	} = useSteps(staticSteps);

	// Busca as enquetes e trata as informações
	const { perguntas, id, enqueteProspecto } = useProspecto();

	// Trata as informações e salva noservidor
	const { loading, names } = useSubmit({
		steps,
		initialDataForm,
		perguntas,
		id,
		setSteps,
	});

	return (
		<SignupContext.Provider
			value={{
				steps,
				enqueteProspecto,
				stepSelected: names.indexOf(switchComponent),
				switchComponent,
				submit,
				loading,
				initialDataForm,
			}}
		>
			{children}
		</SignupContext.Provider>
	);
};
