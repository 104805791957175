// REPOST
import { format } from 'date-fns';
import { useMemo } from 'react';

import { useTransformScale } from '../useTransformScale';
import { IActions, IEntregador, IPeriodo } from './index.d';
import { useToggleActiveRecieveCollect } from '../useToggleActiveRecieveCollect';
//turno={props.variables.filter.turno}
export const useTransformPlayer = (
    entregadores: IEntregador[] = [],
    actions: IActions,
    periodos?: IPeriodo[],
    turno?: 'Café' | 'Almoço' | 'Janta' // props.variables.filter.turno
) => {
    const { handleToogleRecieveCollect } = useToggleActiveRecieveCollect();
    const { handleTransformScale } = useTransformScale(periodos, turno);
    const data = useMemo(() => {
        return entregadores?.map((entregador) => {
            let lineClassName = '';
            let ativo = false;

            switch (entregador.status) {
                case 'inativo':
                    lineClassName = 'bgRed';
                    break;
                case 'pendente':
                    lineClassName = 'bgOrange';
                    break;
                default:
                    ativo = true;
            }

            const dataCadastro = format(
                new Date(entregador.dataCadastro),
                'dd/MM/yyyy'
            );

            return {
                lineClassName,
                colunas: [
                    {
                        status: entregador.status,
                        tipo: 'Texto',
                        width: 'minmax(25rem, 35rem)',
                        marginRight: '0',
                        titulo: entregador.nomeCompleto,
                        subtitulo: entregador.telefone,
                        descricao: entregador.email,
                        flexDirection: 'row',
                    },
                    {
                        tipo: 'Texto',
                        temaBotao: '2',
                        width: '13rem',
                        marginRight: '0',
                        titulo: dataCadastro,
                    },
                    {
                        tipo: 'Texto',
                        temaBotao: '2',
                        width: '8rem',
                        marginRight: '0',
                        titulo: entregador?.appVersion || '',
                    },
                    {
                        tipo: 'Texto',
                        temaBotao: '2',
                        width: '8rem',
                        marginRight: '0',
                        titulo: entregador?.sdkVersion || '',
                    },
                    {
                        tipo: 'Texto',
                        temaBotao: '2',
                        width: 'minmax(26rem, 1fr)',
                        marginRight: '0',
                        titulo: '',
                        subtitulo: '',
                        botoesInline: handleTransformScale(entregador),
                    },
                    {
                        tipo: 'Texto',
                        width: '25rem',
                        titulo: entregador.machineCode ? 'Possui maquina JR' : '',
                        flexDirection: 'row',
                        botoesInline: [
                            {
                                titulo: entregador.recebeColeta
                                    ? 'Recebe coleta'
                                    : 'Não recebe coleta',
                                icone: !entregador.recebeColeta ? 'icon-deslike' : 'icon-like',
                                color: !entregador.recebeColeta ? 'red' : 'green',
                                click: () => {
                                    handleToogleRecieveCollect({
                                        entregadorId: entregador.id,
                                        recebeColeta: entregador.recebeColeta ? false : true,
                                    });
                                },
                            },
                        ],
                    },
                    {
                        tipo: 'Texto',
                        titulo: '',
                        subtitulo: '',
                        width: '17rem',
                        marginRight: '0',
                        botoes: [
                            {
                                titulo: `Seu modal é "${
                                    entregador.modalTransporteNome || 'Moto'
                                }". Clique para trocar de modal de transporte`,
                                click: actions.handleToogleModalTransporte,
                                data: {
                                    entregadorId: entregador.id,
                                    modalTransporteId:
                                        entregador.modalTransporteId,
                                },
                                icone:
                                    entregador.modalTransporteId === 2
                                        ? 'icon-carro'
                                        : 'icon-moto',
                            },
                            {
                                titulo: 'Inserir ou alterar os dados bancários',
                                click: actions.handleDataBackPlayer,
                                data: { entregadorId: entregador.id },
                                icone: 'icon-casa-moeda',
                            },
                            {
                                titulo: 'Relatório financeiro',
                                click: actions.handleReportPlayer,
                                data: { entregadorId: entregador.id },
                                icone: 'icon-ajustar',
                            },
                            {
                                titulo: ativo
                                    ? 'Entregador ativo'
                                    : 'Entregador inativo',
                                click: actions.handleToogleActive,
                                data: {
                                    entregadorId: entregador.id,
                                    ativo,
                                },
                                icone: ativo
                                    ? 'icon-visualizar'
                                    : 'icon-naovisualizar',
                            },
                            {
                                titulo: 'Editar dados do entregador',
                                click: actions.handleEditPlayer,
                                data: { entregadorId: entregador.id },
                                icone: 'icon-editar',
                            },
                            {
                                titulo: 'Validar Documentos',
                                click: actions.handleValidateDocument,
                                data: { entregadorId: entregador.id },
                                icone: 'icon-ajuste',
                            },
                        ],
                    },
                ],
            };
        });
    }, [entregadores]);

    return data;
};
