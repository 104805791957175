import { useQuery } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { GRAPHQL_BUSCAR_UNIDADE_FATURA } from 'gql/unidadeFatura';
import { useEffect, useMemo } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IQueryUnidadeFatura } from './index.d';
export const useQueryFilterFatura = () => {
    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);

    const props = usePaginationAndFilter({
        dataKeyName: null,
        keysQueryVariavel: [
            'razaoSocial',
            'nomeFantasia',
            'identificador',
            'nomeResponsavel',
        ],
    });

    const { loading } = useQuery<IQueryUnidadeFatura>(
        GRAPHQL_BUSCAR_UNIDADE_FATURA,
        {
            context: props.context,
            variables: props.variables,
            onCompleted: ({ unidadeFatura }) =>
                props.onCompleted(unidadeFatura),
            onError: props.onError,
        }
    );

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, dataSelectHeader };
};
