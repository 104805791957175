// eslint-disable-next-line no-use-before-define
import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import { dataToBr } from 'libs/DataUtils';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentClienteEditar: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Editar cliente`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            titleModal={'Editar cliente'}
            message={null}
        >
            <FormGenerator
                rForm={oProps.rForm}
                childs={[
                    {
                        type: 'text',
                        label: 'Nome',
                        placeholder: 'Nome',
                        name: 'nomeCompleto',
                        defaultValue: oProps.values.nomeCompleto,
                    },

                    {
                        type: 'text',
                        label: 'Email',
                        placeholder: 'Email',
                        name: 'email',
                        defaultValue: oProps.values.email,
                    },
                    {
                        gridFirstWidth: 1,
                        items: [
                            {
                                type: 'mask',
                                label: 'Telefone Fixo',
                                mask: '(99) 9999.9999',
                                name: 'telefoneFixo',
                                defaultValue: oProps.values.telefoneFixo,
                            },
                            {
                                type: 'mask',
                                label: 'Telefone Celular',
                                mask: '(99) 9 9999.9999',
                                name: 'telefoneCelular',
                                defaultValue: oProps.values.telefoneCelular,
                            },
                        ],
                    },
                ]}
            />
        </SimplesForm>
    );
});
export default ComponentClienteEditar;
