import Icone from 'components/Icone';
import { shake } from 'routes/styl';
import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'input' })<any>`
    justify-content: start;
    align-items: center;
    padding-top: 1rem;
    cursor: pointer;
    width: 100%;
    > div {
        background-color: ${({ erro }) =>
            erro ? 'var(--error-cor-br)' : 'var(--bg-white)'};
        padding: 0 1rem;
        border-radius: var(--def-radius);
        animation: ${({ erro }) => (erro ? shake : '')} 0.5s 1 ease-in-out;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    label {
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        min-height: calc(2*1rem + 20px);
        cursor: pointer;
    }
    input {
        display: none;
    }
    position: relative;
    span.customLabel {
        padding-left: 30px;
        font-size: 14px;
    }
    span.inputClick {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: var(-bg-white);
        border: 1px solid var(--bg-hover-light);
        border-radius: 4px;
    }
    .font-ic {
        display: none;
    }
    input:checked ~ span.inputClick {
        border: none;
        background-color: var(--btn-admin-icone-hover);
    }
    input:checked ~ span.customLabel {
        padding-left: 10px;
    }
    input:checked ~ span.inputClick ~ .font-ic {
        display: block;
        z-index: 2;
    }
`;
export const CheckBoxDisable = styled(Icone)<any>``;
export const Input = styled.input<any>``;
export const LabelError: any = styled.span<any>`
    color: var(--error-cor);
    padding: 0.5rem 1rem 0;
    text-align: right;
    opacity: ${({ erro }) => (erro ? 1 : 0)};
    transition: all 0.4s linear;
    z-index: 3;
`;
