import { useField } from '@unform/core';
import { useCallback, useEffect, useState } from 'react';

export const usePrice = (name) => {
    const { fieldName, defaultValue, registerField, clearError } =
        useField(name);
    const [value, setValue] = useState(defaultValue || '0,00');
    const onChangeEvent = useCallback((_e, maskedvalue) => {
        setValue(maskedvalue);
    }, []);
    useEffect(() => {
        registerField({
            name: fieldName,
            clearValue: (ref) => setValue(''),
            getValue: (ref) => value,
            setValue: (ref: any, Value: string) => setValue(Value),
        });
    }, [fieldName, registerField, value]);
    return {
        value: Number.isNaN(Number(value.toString().replace('.','').replace(',','.'))) ? 0 : Number(value.toString().replace('.','').replace(',','.')),
        autoFocus: true,
        onChangeEvent,
    };
};
