import { format } from 'date-fns';
export const prepareDados = (data) => {
    let variables: any = {
        ...(data.unidadeId ? { unidadeId: data.unidadeId } : {}),
        ...(data.entregadorId ? { entregadorId: data.entregadorId } : {}),
    };
    variables.dataInicio = format(data.dataInicio, 'yyyy-MM-dd');
    variables.dataFim = format(data.dataFim, 'yyyy-MM-dd');
    return variables;
};
