import React, { FC, useCallback, useState } from 'react';
import Input from './';
import Icone from 'components/Icone';
import { ContainerToogle } from './styl';
const ButtonToogle: FC<any> = ({ hide, onPress }) => {
    return (
        <ContainerToogle onClick={onPress}>
            <Icone icone={hide ? 'icon-visualizar' : 'icon-naovisualizar'} />
        </ContainerToogle>
    );
};
const InputPassword: FC<IInput> = (rest) => {
    const [hide, setHide] = useState(true);
    const handlerToogle = useCallback(
        (e) => {
            e.preventDefault();
            setHide(!hide);
        },
        [hide]
    );
    return (
        <Input
            {...rest}
            type={hide ? 'password' : 'text'}
            icon={<ButtonToogle hide={hide} onPress={handlerToogle} />}
        />
    );
};
export default InputPassword;
