import { useState, useCallback } from 'react';
const celular = '(99) 9 9999.9999';
const fixo = '(99) 9999.9999';
export const useTelefone = () => {
	const [mascara, setMascara] = useState(fixo);
	const onChange = useCallback(
		(e: InputEvent) => {
			const target: HTMLInputElement = e.target as HTMLInputElement;
			const total = target.value.length;
			if (total > 4) {
				const isMobile = Number(target.value.charAt(5));
				if (isMobile > 6 && mascara === fixo) {
					target.setSelectionRange(6, 6);
					setMascara(celular);
				} else if (isMobile < 7 && mascara === celular) {
					setMascara(fixo);
				}
			}
		},
		[mascara]
	);
	return {
		onChange,
		mask: mascara,
	};
};
