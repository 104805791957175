// eslint-disable-next-line no-use-before-define
import ToolTipLabel from 'components/Form/template/partial/ToolTipLabel';
import Icone from 'components/Icone';
import { format } from 'date-fns';
import React, { FC, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { IColetaByStatus } from 'types/queryColeta';
import { Container } from './styl';

interface IColetaCard {
    coleta: IColetaByStatus;
    lineClassName: string;
    key: string;
    linePropsClassName: string;
}
interface IMiniCard {
    card: IColetaCard;
}
const MiniCard: FC<any> = memo(({ card }: IMiniCard) => {
    const dataAlteracao = new Date(card.coleta.dataAlteracao);
    return (
        <Container className={card.lineClassName}>
            <div className="header">
                <p>
                    <strong>{card.coleta.numeroInterno}:</strong>{' '}
                    {card.coleta.unidade}
                </p>
                <button
                    className="icone"
                    data-for="tooltipDetalhes"
                    data-tip={`Clique para visualizar os detalhes da corrida. Esta coleta deve ser realizada com moto.`}
                >
                    <ReactTooltip
                        html={true}
                        multiline={true}
                        textColor="white"
                        backgroundColor="var(--btn-admin-icone)"
                        id="tooltipDetalhes"
                        className="tool-tip"
                        place="bottom"
                        data-type="info"
                        effect="solid"
                    />
                    <Icone
                        size="2rem"
                        icone="icon-moto"
                        color="var(--btn-admin-icone)"
                    />
                </button>
            </div>
            <div className="pessoas">
                <div className="cliente">
                    <p className="label">
                        <strong>Cliente:</strong>
                        <Icone
                            size="1.4rem"
                            icone="icon-ajuda-solid"
                            color="var(--btn-admin-icone)"
                        />
                    </p>
                    <p className="value">{card.coleta.cliente}</p>
                </div>
                <div className="entregador">
                    <p className="label">
                        <strong>Entregador:</strong>
                        <Icone
                            size="1.4rem"
                            icone="icon-ajuda-solid"
                            color="var(--btn-admin-icone)"
                        />
                    </p>
                    <p className="value">{card.coleta.entregador}</p>
                </div>
            </div>
            <div className="status_acoes">
                <div className="status">
                    <p className="aceito">{card.coleta.status}</p>
                    <p className="tempo">
                        {format(dataAlteracao, 'HH:mm')} (8m)
                    </p>
                </div>
                <div className="acoes">
                    <ul>
                        <li>
                            <button>
                                <Icone
                                    size="1.5rem"
                                    icone="icon-ajuda-solid"
                                    color="var(--btn-admin-icone)"
                                />
                            </button>
                        </li>
                        <li>
                            <button>
                                <Icone
                                    size="1.5rem"
                                    icone="icon-relogio3"
                                    color="var(--btn-admin-icone)"
                                />
                                {/* Visualizado*/}
                            </button>
                        </li>
                        <li>
                            <button>
                                <Icone
                                    icone="icon-fechar"
                                    color="var(--btn-admin-icone)"
                                    size="1.5rem"
                                />
                            </button>
                        </li>
                        <li>
                            <button>
                                <Icone
                                    icone="icon-adicionar2"
                                    color="var(--btn-admin-icone)"
                                    size="1.5rem"
                                />
                            </button>
                        </li>
                        <li>
                            <button>
                                <Icone
                                    icone="icon-agrupar"
                                    color="var(--btn-admin-icone)"
                                    size="1.5rem"
                                />
                            </button>
                        </li>
                        <li>
                            <button>
                                <Icone
                                    icone="icon-gps"
                                    color="var(--btn-admin-icone)"
                                    size="1.5rem"
                                />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
    );
});
export default MiniCard;
