import { botaoCirculo, botaoCirculoHover } from 'routes/styl';
import styled from 'styled-components';
export const Container = styled.div<any>`
    background-color: white;
    margin: 1rem 0.5rem;
    border-radius: var(--def-radius);
    overflow: hidden;

    .header {
        padding: 1rem;
        border-bottom: 1px solid var(--bg-color);
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            font-size: 1.4rem;
            strong {
                font-size: 1.4rem;
                color: black;
                font-weight: var(--bold);
            }
        }
    }

    .pessoas {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-bottom: 1px solid var(--bg-color);
        > div {
            padding: 1rem;
            text-align: left;
            .label {
                strong {
                    color: black;
                    font-size: 1.4rem;
                    margin-right: .5rem;
                }
                > * {
                    display: inline-block;
                }
            }
            .value {
                font-size: 1.4rem;
            }
        }
        > div + div {
            border-left: 1px solid var(--bg-color);
        }
    }

    .status_acoes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        .status p {
            text-align: left;
            color: var(--text-cor);
            &.aceito {
                color: #00c0ef;
            }
        }

        .acoes ul {
            display: flex;
            list-style: none;
        }
    }

    button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }

    position: relative;
    &.group-start {
        .header {
            padding-top: 2rem;
        }
    }
    &.group-start,
    &.group,
    &.group-end {
        .header {
            box-shadow: 0 0 15px rgba(94,69,10,0.2);
        }
        .pessoas {
            border-color: #e7e1d2;
            > div + div {
                border-color: #e7e1d2;
            }
        }
    }
    &.group-start {
        background-color: var(--btn-bg-small);
        border-top: 4px solid var(--btn-admin-icone-hover);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
        border-bottom: 2px solid var(--btn-admin-icone-hover);
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.group-start::after {
        content: 'AGRUPAMENTO';
        color: var(--btn-admin-icone);
        font-size: 1rem;
        position: absolute;
        font-weight: bold;
        background-color: var(--btn-admin-icone-hover);
        padding: 0rem 1rem 0.4rem 1rem;
        border-bottom-right-radius: 0.4rem;
        text-align: center;
        left: 0;
        top: -1px;
    }
    &.group {
        background-color: var(--btn-bg-small);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
        border-bottom: 2px solid var(--btn-admin-icone-hover);
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    &.group-end {
        background-color: var(--btn-bg-small);
        margin-bottom: 10px;
        border-bottom: 1px solid var(--btn-admin-icone-hover);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
    &.attention {
        background-color: var(--error-bg-error);
        border-bottom-color: var(--error-cor);
    }
    &.attention.group-start::after {
        background-color: var(--error-cor);
        color: white;
    }
    &.attention.group-end,
    &.attention.group-start {
        border-left: 1px solid var(--error-cor);
        border-right: 1px solid var(--error-cor);
    }
    &.attention.group-end {
        border-bottom-color: var(--error-cor);
    }
    &.attention.group-start {
        border-top-color: var(--error-cor);
    }

`;

