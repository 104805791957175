import { useMutation } from '@apollo/client';
import { GRAPHQL_ATUALIZAR_CONFIGURACAO } from 'gql/configuracao';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useMutationConfigDefault = () => {
    const history = useHistory();

    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConta, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_CONFIGURACAO
    );

    const atualizaConfiguracaoSistema = useCallback(async (data) => {
        try {
            await handleAtualizaConta({
                variables: {
                    data,
                },
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    }, []);
    return {
        atualizaConfiguracaoSistema,
        loading,
    };
};
