import { cssButtonCircular } from 'components/Table/Columns/styl';
import {
    botaoCirculo,
    botaoCirculoHover,
    boxShadow,
    transitionEaseBack,
} from 'routes/styl';
import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 70rem;
    max-width: calc(100vw - 3rem);
    ${boxShadow};
    padding: 2rem;
    > p {
        text-align: center;
        padding: 2rem 0;
        color: var(--btn-admin-icone);
        font-size: 1.8rem;
    }
    > span {
        text-align: center;
        margin-bottom: 2rem;
        display: block;
    }
    .space-button {
        margin-top: 2rem;
    }
    .select > div > div {
        background-color: var(--bg-color);
    }
    label.title-title {
        display: block;
    }
`;
export const Warp = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90px;
`;
export const Header = styled.header`
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    h1 {
        margin-right: 1rem;
    }
    button {
        margin-left: auto;
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    button:first-child {
        display: flex;
        align-items: center;
        span {
            ${transitionEaseBack};
            margin-left: 0.5rem;
        }
        .font-ic {
            ${botaoCirculo}
        }
        &:hover {
            span {
                transform: translateX(1rem);
            }
            .font-ic::after {
                ${botaoCirculoHover}
            }
        }
    }
    button:last-child {
        padding: 0 1rem;

        ${botaoCirculo}
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    svg {
        width: 2rem;
        height: 2rem;
        path {
            color: var(--btn-admin-icone);
        }
    }
`;
export const Button = styled.button<any>`
    background: var(--btn-bg-small-ativo);
    color: var(--success-cor);
    margin-left: auto;

    font-size: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.4rem;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    cursor: pointer;
`;

export const Grid = styled.div<any>`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto auto 150px;
`;

export const Table = styled.table`
    border-collapse: collapse;
    border: none;
    thead th {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: left;
        color: var(--text-cor);
        background-color: var(--bg-color);
        padding: 1rem;
    }
    thead th.column-check {
        width: 20px;
    }
    tr,
    td {
        padding: 1rem;
        font-size: 1.5rem;
        .input {
            margin-bottom: 0;
            padding-bottom: 0;
            height: auto;
        }
        color: var(--text-cor);
        &.input-checkbox > div {
            padding-top: 0;
            > div {
                background-color: transparent;
            }
        }
        &.input-text div > div {
            margin-top: 5px;
        }
    }

    tr:nth-child(even) {
        border-radius: var(--def-radius);
        background-color: #f2f2f2;
    }
    .inativo {
        td {
            color: var(--placeholder);
        }
        button {
            background: var(--btn-bg-small-inativo);
            color: var(--placeholder);
        }
    }
    width: 100%;
`;
