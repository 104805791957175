import styled from 'styled-components';
import Icone from 'components/Icone';
import { cssButtonCircular } from 'components/Table/Columns/styl';

export const Container = styled.div`
    padding: 2rem 0;
    display: flex;
    p {
        font-weight: var(--bold);
        color: black;
    }
    div {
        margin-left: 1rem;
        button {
            ${cssButtonCircular}
        }
    }
`;
export const IconFilter = styled(Icone)<any>``;
