import { cssButtonCircular } from 'components/Table/Columns/styl';
import styled from 'styled-components';

export const Group = styled.div`
    input {
        display: none;
    }
    input:checked ~ .warpEndereco {
        .radio {
            .font-ic {
                display: block;
                position: absolute;
                z-index: 2;
                left: 1px;
                top: 0px;
            }
            &::after {
                background-color: var(--btn-admin-icone-hover);
                border: none;
            }
        }
        h1 {
            color: var(--btn-admin-icone);
        }
    }
`;
export const WarpEndereco = styled.div.attrs({
    className: 'warpEndereco',
})<any>`
    display: grid;
    grid-template-columns: 2rem 1fr 6rem;
    grid-gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
    position: relative;
    label {
        padding: 0;
        p {
            margin: 0;
        }
    }
    > label,
    > div {
        display: block !important;
    }
    .radio {
        cursor: pointer;
        position: relative;
        width: 2rem;
        height: 2rem;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 2rem;
            background-color: var(--bg-white);
            border: 1px solid var(--bg-hover-light);
            border-radius: ${({ type }) =>
                type === 'checkbox' ? '2px' : '50%'};
            content: '';
        }
        .font-ic {
            display: none;
        }
    }

    .endereco {
        cursor: pointer;
        h1 {
            display: block;
            font-size: var(--size-h4);
            color: var(--text-cor);
        }
    }
    .distancia {
        span {
            display: block;
            text-align: center;
            color: var(--text-cor);
            b {
                font-size: var(--size-p);
                font-weight: var(--bold);
            }
        }
    }
`;
export const ButtonAction = styled.button<any>`
    margin-left: 1px;
    ${cssButtonCircular}
`;
