import { useEffect } from 'react';
import { useCallback, useContext, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { format, parseISO } from "date-fns";
import locale from 'date-fns/locale/pt-BR';
import { GRAPHQL_LISTAR_ENTREGADORES_ESCALADOS, GRAPHQL_TOGGLE_ENTREGADOR_ESCALA_STATUS } from "gql/entregador";
import { useParams } from "react-router-dom";
import { IEntregador } from './../../../../../types/modules/entregador/index';
import { IPeriodo } from './../../../../../types/modules/periodo/index';
import { AuthContext } from 'routes/auth.context';

export interface IEntregadorEscala {
    entregadorId: string;
    periodoId: number;
    data: string;
    dataCancelamento?: string;
    periodo: IPeriodo;
    entregador: IEntregador;
}

export const useQueryData = () => {

    const [handleToggleEntregadorEscalaStatus, { loading: loadingEntregadorEscalaStatus }] = useMutation(
        GRAPHQL_TOGGLE_ENTREGADOR_ESCALA_STATUS
    );

    const { dataEscala, turnoId } = useParams<any>();
    const { alertConfirm } = useContext(AuthContext);
    const [data, setData] = useState<{entregadorEscala: IEntregadorEscala[]}>(null);

    const { data: dataEscalas } = useQuery<{entregadorEscala: IEntregadorEscala[]}>(GRAPHQL_LISTAR_ENTREGADORES_ESCALADOS, {
        fetchPolicy: 'no-cache',
        variables: {
            periodoId: parseInt(turnoId),
            data: dataEscala
        },
    });

    useEffect(() => {
        setData(dataEscalas);
    }, [setData, dataEscalas])

    const dataEscalaFormated = format(
        parseISO(dataEscala),
        `eeee, dd 'de' MMMM`,
        {
            locale,
        },
    );

    let periodo = null;
    let entregadoresEscala = null;
    if (data && data.entregadorEscala && data.entregadorEscala.length > 0) {
        periodo = data.entregadorEscala[0].periodo;
        entregadoresEscala = data.entregadorEscala;
    }

    const toggleEntregadorEscalaStatus = useCallback(async (item: IEntregadorEscala, indice: number) => {
        try {
            await handleToggleEntregadorEscalaStatus({
                variables: {
                    entregadorId: item.entregadorId,
                    periodoId: parseInt(item.periodo.id),
                    dataEscala
                }
            });

            const items = data.entregadorEscala;
            const dtCancelamento = items[indice].dataCancelamento;
            items[indice].dataCancelamento = dtCancelamento ? null : format(new Date(), 'yyyy-MM-dd');
            setData({ entregadorEscala: items});

        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    }, [handleToggleEntregadorEscalaStatus, dataEscala, setData, data]);

    return {
        titulo: `Entregadores escalados: ${dataEscalaFormated} - ${periodo ? periodo.nome : ''}`,
        entregadoresEscala,
        loading: false,
        toggleEntregadorEscalaStatus
    };
};
