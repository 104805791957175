import React, { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';
import { Props as InputProps } from 'react-input-mask';
import { Container, LabelError, InputMask } from './styl';

interface IMask extends IInput {
    mask: string;
}
const FormInputMask: React.FC<IMask> = memo(
    ({ mask, name, label, icon, className, ...rest }) => {
        const inputRef = useRef(null);
        const { fieldName, defaultValue, registerField, clearError, error } =
            useField(name);
        useEffect(() => {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value',
                setValue(ref: any, value: string) {
                    ref.setInputValue(value || '');
                },
                clearValue(ref: any) {
                    ref.setInputValue('');
                },
            });
        }, [fieldName, registerField]);

        return (
            <Container erro={error}>
                <div className={className}>
                    <InputMask
                        name={name}
                        mask={mask || null}
                        onFocus={clearError}
                        shake={error ? 1 : undefined}
                        ref={inputRef}
                        defaultValue={defaultValue}
                        {...rest}
                    />
                    {icon}
                </div>
                <LabelError erro={error}>{error}</LabelError>
            </Container>
        );
    }
);
export default FormInputMask;
