import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { GRAPHQL_SOLICITAR_CODIGO } from 'gql/recoversignin';
import { AuthContext } from 'routes/auth.context';

export const useRequestCode = ({ setSwitchComponent }) => {
    const { alertConfirm, setCacheEmail } = useContext(AuthContext);
    const [handleSendMailCode, { loading }] = useMutation(
        GRAPHQL_SOLICITAR_CODIGO
    );
    const requestCode = useCallback(
        async (data) => {
            try {
                await handleSendMailCode({
                    variables: {
                        basic: {
                            ...data,
                            senha: 'Sem senha',
                        },
                    },
                });
                setSwitchComponent('enter-code-and-authorize-new-password');
                setCacheEmail(data.email);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        },
        [alertConfirm, handleSendMailCode, setCacheEmail, setSwitchComponent]
    );
    return {
        loading,
        requestCode,
    };
};
