import styled, { css } from 'styled-components';

const inputStyl = css`
    border: 1px solid var(--bg-hover-light);
    border-radius: var(--def-radius);
`;

export const WarpMap = styled.div<any>`
    position: relative;
    width: 40rem;
    margin-left: 2rem;
    height: ${({ height }) => height || '60rem'};
    margin-top: 3rem;
    position: relative;
    background-color: var(--bg-color);
`;
export const CartWarp = styled.div`
    width: 100%;
    height: var(--h-input);
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 1rem;
    p {
        flex: 1;
        font-family: var(--font);
        font-size: 1.6rem;
        color: black;

        font-weight: var(--regular);
    }
    margin-bottom: 2rem;
    border-radius: var(--def-radius);
    border: 1px solid var(--bg-hover-light);
`;

export const ContainerForm = styled.div<any>`
    flex: 1;
    display: flex;

    justify-content: space-between;
    > label {
        display: flex;
    }
    > form {
        > p {
            margin: 3rem auto;
            font-size: var(--size-p);
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        .space-button {
            width: 100%;
            display: flex;
            margin-top: 1.5rem;
        }
        .select > div {
            ${inputStyl}
        }
    }
`;
