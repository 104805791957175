import { abreviarNome } from 'libs/StringUteis';
import { useCallback, useMemo, useState } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { formulario } from '../formulario.json';
import CartaoCliente from '../partial/cartao';

export const useSelectClient = (props) => {
    const [loading, setLoading] = useState(false);
    const history = useModalHistory();
    const handleEdit = useCallback(
        async ({ fieldName }) => {
            history.push(
                `editar~cliente/${props.itemSelecionado.id}/${fieldName}`
            );
        },
        [props]
    );

    const response = useMemo(() => {
        let dataForm: any = [
            {
                type: 'hidden',
                name: 'clienteId',
            },
            {
                type: 'hidden',
                name: 'endereco',
            },
        ];
        if (!props.loadingInitial && !props.defaultValue.novo) {
            dataForm.push({
                type: 'select-async',
                // label: 'Nome, email ou telefone',
                // toolTipLegend:
                //     'Para criar um novo cliente é necessário no mínimo 5 caracteres. É obrigatório informar o nome completo.',
                placeholder: 'Digite ou selecione o nome do cliente',
                name: 'nomeCompleto',
                onChange: props.handleSetDefaultValue,
                loadOptions: props.loadOptions,
                defaultValue: props.defaultValue,
            });
        } else if(props.defaultValue.novo){
            dataForm.push({
                type: 'text',
                label: 'Nome',
                toolTipLegend:
                    'Para criar um novo cliente é necessário no mínimo 5 caracteres. É obrigatório informar o nome completo.',
                placeholder: 'Digite o nome completo do cliente',
                name: 'nomeCompleto',
                defaultValue: props.defaultValue.value,
                stylWarp: {
                    marginTop: 30,
                }
            });
        }
        if (props.itemSelecionado) {
            dataForm.push({
                type: 'custom-component',
                component: CartaoCliente,
                handleClick: handleEdit,
                nomeCompleto: abreviarNome(props.itemSelecionado?.nomeCompleto),
                telefoneFixo: props.itemSelecionado?.telefoneFixo,
                telefoneCelular: props.itemSelecionado?.telefoneCelular,
                email: props.itemSelecionado?.email,
                id: props.itemSelecionado?.id,
            });
        }
        if (props.defaultValue.novo) {
            dataForm = dataForm.concat(formulario);
        }
        if (props.itemSelecionado || props.defaultValue.novo) {
            dataForm.push({
                type: 'button',
                value: props.defaultValue.novo
                    ? 'Salvar novo cliente'
                    : 'Ir para endereço',
                loading,
            });
        }
        return dataForm;
    }, [props]);

    return { formsChilds: response, setLoading };
};
