import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Header, WarpMap } from './styl';

const PagComprovantePagamento: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [type, setType] = useState('img');
    const handleError = useCallback(() => {
        setType('text');
    }, []);
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Comprovante de pagamento</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <WarpMap>
                        {type === 'img' ? (
                            <img
                                onError={handleError}
                                src={`https://res.cloudinary.com/jogor-pido/image/upload/v1643544949/maquina-jogorapido/${id}.jpg`}
                                alt="comprovante de pagamento"
                            />
                        ) : (
                            <p>
                                Está coleta não possui comprovante de pagamento.
                                <br />
                                <span>
                                    ( O comprovante passou a ser obrigatório a
                                    partir de 31/01/2022 )
                                </span>
                            </p>
                        )}
                    </WarpMap>
                </Container>
            )}
        />
    );
};
export default PagComprovantePagamento;
