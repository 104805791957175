import Calendario from 'components/calendario';
import MapView from 'components/mapView';
import NotificacaoPush from 'components/NotificacaoPush';
import Banco from 'pages/banco/formulario';
import ClienteEditar from 'pages/cliente/editar';
import { PageNavegarCadastrarColeta } from 'pages/coleta/cadastrar';
import Coleta from 'pages/coleta/listar';
import PageColetaDetalhes from 'pages/coleta/modal/detalhes';
import ListarColetasModoMapa from 'pages/coleta/listar-em-mapa';
import ListarColetasMiniCard from 'pages/coleta/listar-mini-card';
import ColetaAgrupar from 'pages/coleta/modal/agrupar';
import PagePagamentoColeta from 'pages/coleta/modal/editar-pagamento';
import PageClienteAtualizarLocalizacao from 'pages/coleta/modal/cliente-atualizar-localizacao';
import PageListarStatusColeta from 'pages/coleta/modal/listar-status';
import PageEditarStatusColeta from 'pages/coleta/modal/editar-status';
import PageColetaPlayerLocation from 'pages/coleta/modal/mapa';
import Endereco from 'pages/endereco/editar';
import EntregadorCadastrar from 'pages/entregador/cadastrar';
import EntregadorEditar from 'pages/entregador/editar';
import ValidarDocumento from 'pages/entregador-documento/validate-document';
import EntregadorEditarStatus from 'pages/entregador/editarstatus';
import Escala from 'pages/escala/listar';
import ComponentConfiguracaoEscala from 'pages/escala/modal/configuracao';
import ComponentEscalaCriar from 'pages/escala/modal/criar';
import Perfil from 'pages/perfil-usuario/listar';
import PageListProspectus from 'pages/prospectus/list';
import PageListMaquinasEntregador from 'pages/maquinas-entregador/list';
import PageListUnit from 'pages/unidades/list';
import PageListFatura from 'pages/unidades/listFatura';
import PageAdicionarPlayer from 'pages/unidades/modal/adicionar-administrador';
import PageUnidadeConfiguracao from 'pages/unidades/modal/configuracao';
import PageConfiguracaoPadrao from 'pages/unidades/modal/configuracao-padrao';
import PageCreateUnit from 'pages/unidades/modal/create';
import PageUnidadeContaBancaria from 'pages/unidades/modal/dadosBancarios';
import EditarUnidade from 'pages/unidades/modal/editar';
import PageNotificarFatura from 'pages/unidades/modal/notificarFatura';
import PageRelatorioUnidade from 'pages/unidades/modal/relatorio';
import PageRelatorioPagamento from 'pages/unidades/modal/relatorioPagamento';
import PageEntregadoresEscala from 'pages/escala/modal/entregadores';
import UnidadesSelecionar from 'pages/unidades/modal/selecionar';
import PageCreateUser from 'pages/user/create';
import PageListUser from 'pages/user/list';
import PageUpdateUser from 'pages/user/update';
import PageListWhatsapp from 'pages/whatsapp/listar';
import ModalConfiguracaoWhatsapp from 'pages/whatsapp/modal/session';
import React, { FC, memo, useContext } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import PrivateRoute from '../private.route';
import TemplateAdmin from './partial/template';
import PageEditarClienteColeta from 'pages/coleta/modal/editar-cliente';
import PagComprovantePagamento from 'pages/coleta/modal/comprovante';
import PageEditarTipoPagamento from 'pages/unidades/modal/tipoPagamento';
import PageInserirSaldo from 'pages/unidades/modal/inserirSaldo';
import PageUnidadeconfigValueAppAndKeyIFood from 'pages/unidades/modal/configurar-value-app-key-ifood';
import { PageNavegarCadastrarColetaIfood } from 'pages/coleta/ifood';
import { PageNavegarCadastrarColetaAnotaAi } from 'pages/coleta/anotaai';
import EscalaVagas from 'pages/escala/vagas';

const AdminRoute: FC = () => {
    const { path, params } = useRouteMatch();
    const pathModal = `${path.replace('*', '')}${params['0'].split('/')[0]}`;
    const { loadingAutoSignin } = useContext(AuthContext);
    if (loadingAutoSignin && !pathModal) return null;

    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path={`${pathModal}/coleta~cadastrar/:clienteId?/:enderecoId?`}
                    component={PageNavegarCadastrarColeta}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/coleta~ifood`}
                    component={PageNavegarCadastrarColetaIfood}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/coleta~anotaai`}
                    component={PageNavegarCadastrarColetaAnotaAi}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/editar~cliente/:clienteId?/:fieldName?`}
                    component={PageEditarClienteColeta}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/cliente/:id?`}
                    component={ClienteEditar}
                />
                <PrivateRoute
                    exact
                    path={`/admin/wpp/configuracao`}
                    component={ModalConfiguracaoWhatsapp}
                />
                <PrivateRoute
                    exact
                    path={`/admin/player-escala/configuracao`}
                    component={ComponentConfiguracaoEscala}
                />
                <PrivateRoute
                    exact
                    path={`/admin/vagas-escala/criar`}
                    component={ComponentEscalaCriar}
                />
                <PrivateRoute
                    path={`/admin/vagas-escala/entregadores/:dataEscala/:turnoId`}
                    component={PageEntregadoresEscala}
                />
                <PrivateRoute
                    path={`${pathModal}/notificar/:faturaId/:tipoNotificacao`}
                    component={PageNotificarFatura}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/entregador/:id?`}
                    component={EntregadorEditar}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/entregador-documento/:id`}
                    component={ValidarDocumento}
                />

                <PrivateRoute
                    exact
                    path={`/admin/empresa/configuracao/:unidadeId?`}
                    component={PageUnidadeConfiguracao}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/configurar-value-app-key-ifood/:unidadeId?`}
                    component={PageUnidadeconfigValueAppAndKeyIFood}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/banco/:unidadeId?`}
                    component={PageUnidadeContaBancaria}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/editar-cadastro/:unidadeId?`}
                    component={EditarUnidade}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/configuracao~padrao`}
                    component={PageConfiguracaoPadrao}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/adicionar~usuario`}
                    component={PageCreateUser}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/editar~usuario/:id?`}
                    component={PageUpdateUser}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/comprovante/:id?`}
                    component={PagComprovantePagamento}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/tipo-pagamento/:unidadeId?`}
                    component={PageEditarTipoPagamento}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/inserir-saldo/:unidadeId?`}
                    component={PageInserirSaldo}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/coleta/adicionar-status-manual/:coletaId/:entregadorId`}
                    component={PageEditarStatusColeta}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/entregador~cadastrar`}
                    component={EntregadorCadastrar}
                />
                <PrivateRoute
                    exact
                    path={`/admin/player-escala/banco/:idEntregador?`}
                    component={Banco}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/endereco/:idCliente?/:idColeta?/:enderecoIdSelecionado?/:unidadeId?`}
                    component={Endereco}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/coleta~agrupar/:coletaId/:numeroInterno?`}
                    component={ColetaAgrupar}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/entregador~status/:id_entregador/:status?`}
                    component={EntregadorEditarStatus}
                />
                <PrivateRoute
                    exact
                    path={`/admin/coleta/pagamento/:coletaId`}
                    component={PagePagamentoColeta}
                />
                <PrivateRoute
                    exact
                    path={`/admin/coleta/detalhes/:coletaId`}
                    component={PageColetaDetalhes}
                />
                <PrivateRoute
                    exact
                    path={`/admin/coleta/status/listar/:coletaId`}
                    component={PageListarStatusColeta}
                />
                <PrivateRoute
                    exact
                    path={`/admin/coleta/status/editar/:coletaId`}
                    component={PageEditarStatusColeta}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/unidade~listar`}
                    component={UnidadesSelecionar}
                />
                <PrivateRoute
                    exact
                    path={`/admin/player-escala/relatorio/:entregadorId?`}
                    component={PageRelatorioUnidade}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/relatorio/:unidadeId?`}
                    component={PageRelatorioUnidade}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/relatorio-pagamento/:unidadeId?`}
                    component={PageRelatorioPagamento}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/permissoes/:unidadeId?`}
                    component={PageAdicionarPlayer}
                />
                <PrivateRoute
                    exact
                    path={`/admin/empresa/cadastrar`}
                    component={PageCreateUnit}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/mapa/:latitude/:longitude`}
                    component={MapView}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/localizacao/:unidadeLat/:unidadeLng/:clienteLat/:clienteLng/:entregadorId/:statusId/:coletaId?`}
                    component={PageColetaPlayerLocation}
                />
                <PrivateRoute
                    exact
                    path={`/admin/coleta/cliente-atualizar-localizacao/:clienteEnderecoId/:latitude/:longitude/:coletaId`}
                    component={PageClienteAtualizarLocalizacao}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/calendario`}
                    component={Calendario}
                />
                <PrivateRoute
                    exact
                    path={`${pathModal}/notificacao~push/:idEntregador?`}
                    component={NotificacaoPush}
                />
            </Switch>
            <TemplateAdmin>
                <Switch>
                    <PrivateRoute
                        path="/admin/coleta"
                        component={Coleta}
                    />
                    <PrivateRoute
                        path="/admin/player-escala"
                        component={Escala}
                    />
                    <PrivateRoute
                        path="/admin/coleta-mapa"
                        component={ListarColetasModoMapa}
                    />
                    <PrivateRoute
                        path="/admin/mini-card"
                        component={ListarColetasMiniCard}
                    />
                    <PrivateRoute
                        path={`/admin/vagas-escala`}
                        component={EscalaVagas}
                    />
                    <PrivateRoute
                        path="/admin/empresa"
                        component={PageListUnit}
                    />
                    <PrivateRoute
                        path="/admin/fatura"
                        component={PageListFatura}
                    />
                    <PrivateRoute
                        path="/admin/wpp"
                        component={PageListWhatsapp}
                    />

                    <PrivateRoute
                        path="/admin/prospectos"
                        component={PageListProspectus}
                    />
                    <PrivateRoute
                        path="/admin/maquinas-entregador"
                        component={PageListMaquinasEntregador}
                    />
                    <PrivateRoute
                        path="/admin/usuario/unidade/:unidadeId"
                        component={PageListUser}
                    />
                    <PrivateRoute
                        path="/admin/usuario"
                        component={PageListUser}
                    />

                    <PrivateRoute
                        path="/admin/perfil"
                        component={Perfil}
                    />
                </Switch>
            </TemplateAdmin>
        </>
    );
};

export default memo(AdminRoute);
