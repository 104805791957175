import { boxShadow } from 'routes/styl';
import styled from 'styled-components';

export const WarpLogoMenu = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-basis: var(--sidebar-width);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    @media (max-width: 1439.8px) {
        flex-basis: 80px;
        min-width: 80px;
        max-width: 80px;
    }
    margin-right: 1.5rem;
    z-index: 8;
    img:last-child {
        margin: 10px 0 0 10px;
    }
`;

export const WarpLogo = styled.aside.attrs({ className: 'logo' })`
    display: flex;
    .logo-text {
        margin: 10px 0 0 10px;
    }
    @media (max-width: 1439.8px) {
        .logo-text {
            display: none;
        }
    }
`;
export const WrapHeader = styled.header.attrs({ className: 'header' })`
    display: flex;
    height: 8rem;
`;
export const Logo = styled.img``;

export const ContainerCard = styled.aside.attrs({ className: 'card' })`
    position: absolute;
    max-width: 200px;
    top: 4rem;
    width: 100%;
    background: white;
    overflow: hidden;
    border-radius: var(--def-radius);
    ${boxShadow};
`;
export const ToggleMenu = styled.button`
    display: none;
    cursor: pointer;
    margin-left: 0.2rem;
    position: relative;
    top: 8px;

    .icon-menu-sanduiche {
        color: rgb(255,255,255);
    }

    @media (max-width: 1439.8px) {
        display: inline-block;
    }
`;
