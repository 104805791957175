import { gql } from '@apollo/client';

export const GRAPHQL_SOLICITAR_CODIGO: any = gql`
    mutation usuarioRecuperarSenha {
        usuarioRecuperarSenha
    }
`;
export const GRAPHQL_ENVIAR_CODIGO: any = gql`
    mutation usuarioCriarNovaSenhaContainer($data: NovaSenhaInput) {
        usuarioCriarNovaSenha(data: $data)
    }
`;
