import * as Yup from 'yup';
export const YupUtilsTry = async (
    data,
    handleErrors = null,
    objSchema,
    abortEarly = false
) => {
    if (handleErrors) handleErrors({});
    await Yup.object().shape(objSchema).validate(data, {
        abortEarly,
    });
};
export const YupUtilsCatch = (err, handleErrors) => {
    const validationErrors: any = {};
    if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => {
            if (Array.isArray(error.value)) {
                validationErrors[error.path] = error.value.map(
                    () => error.message
                );
            } else {
                validationErrors[error.path] = error.message;
            }
        });
        if (handleErrors) handleErrors(validationErrors);

        return true;
    }
    return false;
};
