import styled from 'styled-components';
import { txtCenterMobile } from 'routes/styl';
export const ContainerPageSignin = styled.section`
	${txtCenterMobile}
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	p {
		padding: 3rem 0;
	}
	label {
		display: flex;
	}
	form {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		.space-button {
			width: 100%;
			display: flex;
			margin-top: 1.5rem;
		}
	}
`;
export const WarpLink: any = styled.div`
	text-align: right;
	padding: 2rem 1rem 0;
`;
