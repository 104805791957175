import { useGetCnpj } from 'hooks/useGetCnpj';
import { useTabs } from 'hooks/useTabs';
import React, { createContext, FC } from 'react';

export const NavigateContext: any = createContext({});

export const NavigateProvider: FC<any> = ({ dataTabs, children }) => {
    // Controlando as janelas e validando os erros
    const tabs = useTabs({ abas: dataTabs });

    // Validando e buscando CNPJ
    const cnpj = useGetCnpj();

    // Render
    return (
        <NavigateContext.Provider
            value={{
                cnpj,
                tabs,
            }}
        >
            {children}
        </NavigateContext.Provider>
    );
};
