// eslint-disable-next-line no-use-before-define
import { defTitle } from 'assets/strings';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, memo, useEffect } from 'react';

const ComponentEscalaCriar: FC<any> = memo(oProps => {
    useEffect(() => {
        document.title = `${defTitle} - Escala da semana`;
    }, []);
    return (
        <SimplesForm
            widthModal="85rem"
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            loadingData={oProps.loadingData}
            titleSubmit={'Salvar'}
            titleModal={'Escala da semana'}
            message={null}>
            <FormGenerator
                rForm={oProps.rForm}
                childs={oProps.inputs}
            />
        </SimplesForm>
    );
});
export default ComponentEscalaCriar;
