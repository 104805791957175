import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IUsuario } from './useQueryFilter/index.d';
import { useMutationToggleActive } from './useMutationToggleActive';
import { GRAPHQL_ALTERAR_SENHA } from 'gql/usuario';
import { useMutation } from '@apollo/client';
import { AuthContext } from 'routes/auth.context';

export const useTransformUser = (usuarios: IUsuario[] = []) => {
    const { handleToogleActive } = useMutationToggleActive();
    const history = useHistory();
    const [handleAlterarSenha, { loading }] = useMutation(
        GRAPHQL_ALTERAR_SENHA
    );
    const { alertConfirm, user } = useContext(AuthContext);

    const handleEditarUsuario = useCallback((data: IUsuario) => {
        history.push(`usuario/editar~usuario/${data.id}`);
    }, []);
    const handleUpdatePassword = useCallback(
        async (data) => {
            try {
                const isUpdate = await alertConfirm({
                    tipo: 'erro',
                    textOk: 'Sim',
                    titulo: 'Alterar senha',
                    conteudo: `Se você confirmar a alteração de senha deste usuário, será enviado um e-mail para ${data.email} com uma nova senha. Tem certeza que deseja alterar a senha deste usuário?`,
                });
                if (isUpdate) {
                    await handleAlterarSenha({
                        variables: { usuarioId: data.id },
                    });
                    await alertConfirm({
                        tipo: 'sucesso',
                        titulo: 'Alerta',
                        conteudo: 'Senha alterada com sucesso!',
                    });
                }
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [history]
    );
    const data = useMemo(() => {
        return usuarios?.map((usuario) => ({
            lineClassName: usuario?.ativo ? '' : 'bgRed',
            colunas: [
                {
                    tipo: 'Texto',
                    width: 'minmax(20rem,25rem)',
                    marginRight: '0',
                    titulo: `${usuario?.perfil?.nome}${
                        usuario?.perfil?.administrador
                            ? ' [ super administrador ] '
                            : ''
                    }`,
                },
                {
                    tipo: 'Texto',
                    width: '1fr',
                    marginRight: '0',
                    titulo: usuario?.nomeCompleto,
                    subtitulo: usuario?.telefoneCelular,
                    flexDirection: 'row',
                },
                {
                    tipo: 'Texto',
                    width: '1fr',
                    marginRight: '0',
                    titulo: usuario?.email,
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                },

                {
                    tipo: 'Texto',
                    titulo: '',
                    subtitulo: '',
                    width: '10rem',
                    marginRight: '0',
                    botoes: [
                        {
                            titulo: 'Alterar senha',
                            click: handleUpdatePassword,
                            data: usuario,
                            icone: 'icon-cadeadofechado',
                        },
                        {
                            titulo: 'Editar usuário',
                            icone: 'icon-editar',
                            data: usuario,
                            click: handleEditarUsuario,
                        },
                        ...(usuario.id !== user.id
                            ? [
                                  {
                                      titulo: usuario?.ativo
                                          ? 'Usuário não poderá administrar o sistema'
                                          : 'Usuário ativado',
                                      click: handleToogleActive,
                                      data: usuario,

                                      icone: usuario?.ativo
                                          ? 'icon-visualizar'
                                          : 'icon-naovisualizar',
                                  },
                              ]
                            : []),
                    ],
                },
            ],
        }));
    }, [usuarios]);

    return data;
};
