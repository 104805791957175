// eslint-disable-next-line no-use-before-define
import React, {
    FC,
    useEffect,
    useState,
    useCallback,
    memo,
    RefObject,
} from 'react';
import { FormHandles, useField } from '@unform/core';
import CurrencyInput from 'react-currency-input';
import { Container } from './styl';
import { usePrice } from './hooks/usePrice';
import { calculaValorFreteComTaxa } from '../../../../../libs/NumberUtils';
import { IModalTransporte } from '../../../../../types/modules/modalTransporte';
import { IConfiguracao } from 'types/modules/configuracao';

interface IInputPreco {
    name: string;
    frete?: {
        distancia: number;
        frete: number;
        id: number;
        nome: string;
        precisao: number;
        retorno: boolean;
        txRetorno: number;
    };
    descricao?: string;
    prefix?: string;
    decimalSeparator?: string;
    thousandSeparator?: string;
    somarFrete?: boolean;
    formRef?: RefObject<FormHandles>;
    exigeTaxaRetorno?: boolean;
    porcentagemNegociacao?: number;
    pagamentoId?: number;
    modalMoto?: IModalTransporte;
    distanciaMinima?: IConfiguracao;
    mostrarValorTotal?: boolean;
    valorAdicionalCorrida?: number;
    valorAdicionalCorridaJogoRapido?: number;
    defaultValue?: number;
}

const InputPreco: FC<IInputPreco> = memo(
    ({
        name,
        descricao,
        thousandSeparator,
        modalMoto,
        distanciaMinima,
        frete,
        somarFrete,
        prefix = '',
        decimalSeparator = ',',
        pagamentoId,
        formRef,
        exigeTaxaRetorno,
        porcentagemNegociacao,
        mostrarValorTotal,
        valorAdicionalCorrida,
        valorAdicionalCorridaJogoRapido,
        defaultValue,
    }) => {
        const props = usePrice(name);
        const freteTaxaRetronoPorcentagem = useCallback(() => {
            if (frete) {
                const valorComRetornoESem = calculaValorFreteComTaxa({
                    frete: frete.frete,
                    porcentagemNegociacao: porcentagemNegociacao,
                    distancia: frete.distancia + frete.precisao,
                    distanciaMinima: distanciaMinima
                        ? Number(distanciaMinima.valor)
                        : 0,
                    adcionalRetorno: modalMoto.valorKmRetorno,
                    txMinRetorno: modalMoto.txMinRetorno,
                    mostrarValorTotal: mostrarValorTotal,
                    valorAdicionalCorridaJogoRapido: valorAdicionalCorridaJogoRapido,
                });
                if (exigeTaxaRetorno)
                    return valorComRetornoESem.valorFreteComRetorno;
                return valorComRetornoESem.valorFreteSemRetorno;
            }

            return 0;
        }, [frete, somarFrete, exigeTaxaRetorno]);
        return (
            <Container>
                <div>
                    {frete && pagamentoId >= 1 && (
                        <>
                            <p className="frete">
                                JOGO RAPIDO R${' '}
                                {Number(freteTaxaRetronoPorcentagem())
                                    .toFixed(2)
                                    .replace('.', ',')}{' '}
                            </p>
                            {/* <p className="cobrar-cliente">
                                COBRAR DO CLIENTE R${' '}
                                {(somarFrete
                                    ? props.value
                                    : Number(frete.frete) + props.value
                                )
                                    .toFixed(2)
                                    .replace('.', ',')}
                            </p> */}
                        </>
                    )}
                    <p>Digite o valor para cobrar do cliente</p>
                    <div>
                        <CurrencyInput
                            {...props}
                            thousandSeparator={thousandSeparator}
                            prefix={prefix}
                            decimalSeparator={decimalSeparator}
                            allowEmpty={true}
                        />
                    </div>

                    {descricao && <p className="frete">{descricao}</p>}
                    {/* {frete && frete.retorno && frete.txRetorno && (
                        <span className="txRetorno">
                            A forma de pagamento selecionada possuí
                            <br />
                            taxa de retorno no valor de R$
                            {frete.txRetorno.toFixed(2).replace('.', ',')}
                        </span>
                    )} */}
                </div>
            </Container>
        );
    },
);
export default InputPreco;
