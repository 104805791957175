import styled from 'styled-components';
export const Container = styled.button`
	display: flex;
	cursor: pointer;
	align-items: center;
	p {
		color: black;
		font-size: var(--size-p);
	}
	img {
		margin-right: 1rem;
		width: 6rem;
		height: 6rem;
		object-fit: contain;
		padding: 0.8rem;
		background: var(--bg-color);
		border-radius: var(--def-radius);
	}
`;
export const Ativo = styled.span<any>`
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	&::before {
		content: '';
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;
		background-color: var(
			${({ status }) => {
				switch (status) {
					case 'ativo':
						return '--success-cor';
					case 'inativo':
						return '--error-cor';
					default:
						return '--btn-admin-icone-hover';
				}
			}}
		);
	}
	&::after {
		content: 'Ativo';
		margin-left: 0.5rem;
		font-size: var(--size-small);
	}
`;
