import { gql } from '@apollo/client';

export const GRAPHQL_WPP_CHECK_SESSION = gql`
    query wppCheckSession {
        wppCheckSession {
            status
            qrCode
        }
    }
`;
export const GRAPHQL_WPP_STATUS_SUBSCRIPTION: any = gql`
    subscription {
        whatsappStatus {
            status
            qrCode
        }
    }
`;
export const GRAPHQL_WPP_RECEIVER_SUBSCRIPTION: any = gql`
    subscription {
        whatsappReceiver {
            from
            body
        }
    }
`;
export const GRAPHQL_WPP_LOGOUT_SUBSCRIPTION: any = gql`
    mutation wppLogout {
        wppLogout
    }
`;
