import React, { FC, useMemo } from 'react';

interface IProps {
    dataTip: { nomeCompleto: string }[];
    className: string;
    name: string;
    onChange?: (name: string) => void;
}
const Button: FC<Pick<IProps, 'onChange' | 'name' | 'className'>> = ({
    children,
    onChange,
    className,
    name,
}) => {
    if (onChange) {
        return (
            <button className={className} onClick={() => onChange(name)}>
                {children}
            </button>
        );
    }
    return <dl className={className}>{children}</dl>;
};
export const Item: FC<IProps> = ({
    className,
    dataTip,
    name,
    onChange,
    children,
}) => {
    const tip = useMemo(
        () =>
            dataTip
                .map(({ nomeCompleto }) => nomeCompleto)
                .join(`<br/>`),
        [dataTip]
    );
    return (
        <Button onChange={onChange} name={name} className={className}>
            <dl className="escalado" data-for="online-garantido" data-tip={tip}>
                <dt>{children}</dt>
                <dd>{dataTip.length}</dd>
            </dl>
        </Button>
    );
};
