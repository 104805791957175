import { addMinutes } from 'date-fns';
import { dateTimeline, getMinDiff } from 'libs/DataUtils';
import { useCallback, useMemo } from 'react';
import { StatusID } from './../../../listar/constants/status-name-2-id';
import { IColetaByStatus } from './index.d';
interface IInput {
    coletas: IColetaByStatus[];
    permissoes;
    totalEntregadorEscalado: number;
    timeAdicionalCollectt: number;
    timeEsperaEstabelecimento: number;
}
export const useTransformColeta = ({
    coletas,
    permissoes,
    totalEntregadorEscalado,
    timeAdicionalCollectt,
    timeEsperaEstabelecimento,
}: IInput) => {

    const coletasPai = useMemo(
        () =>
            coletas
                ?.map(({ coletaPaiId }) => coletaPaiId)
                .filter((value) => !!value),
        [coletas]
    );
    const coletasFilho = useMemo(() => {
        const filhos = {};
        coletas
            ?.filter(
                ({ coletaPaiId }) => coletasPai.indexOf(coletaPaiId) !== -1
            )
            ?.forEach(({ id, coletaPaiId, status }) => {
                // const statusId =
                //     status.length > 0 ? status[0].id : StatusID.EXPEDINDO;
                filhos[coletaPaiId] = filhos[coletaPaiId] || [];
                // if (
                //     [
                //         StatusID.PENDENTE,
                //         StatusID.RECEBIDO,
                //         StatusID.VISUALIZADO,
                //         StatusID.ACEITO,
                //         StatusID.CHECKIN_UNIDADE,
                //         StatusID.CHECKOUT_UNIDADE,
                //         StatusID.CHECKIN_CLIENTE,
                //     ].indexOf(statusId) !== -1
                // ) {
                //     filhos[coletaPaiId].push(id);
                // }
            });
        return filhos;
    }, [coletas, coletasPai]);

    const onlyUnique = useCallback(
        (value, index, self) => {
            const indice = self.findIndex(({ id }, index2) => {
                return index > index2 && id === value.id;
            });
            return indice === -1;
        },
        [coletas, coletasPai]
    );
    const handleSortGroupFirst = useCallback(
        (prev: IColetaByStatus, next: IColetaByStatus) => {
            if (prev.reactPaiIdSort > next.reactPaiIdSort) {
                return 1;
            }
            if (prev.reactPaiIdSort < next.reactPaiIdSort) {
                return -1;
            }
            return 0;
        },
        [coletas, coletasPai]
    );

    const handleSortPaiFirst = useCallback(
        (prev: IColetaByStatus, next: IColetaByStatus) => {
            if (prev.reactIsPai > next.reactIsPai) {
                return -1;
            }
            if (prev.reactIsPai < next.reactIsPai) {
                return 1;
            }
            return 0;
        },
        [coletas, coletasPai]
    );

    const groupUnit = useCallback(
        (coleta, unidadesAgrupaveis) => {
            coleta.reactPaiIdSort = coleta.coletaPaiId || coleta.id;
            coleta.reactIsPai = coletasPai.indexOf(coleta.id) !== -1;
            coleta.reactGrouped =
                coletasPai.indexOf(coleta.id) !== -1 || !!coleta.coletaPaiId;
            if (
                [
                    StatusID.PENDENTE,
                    StatusID.ACEITO,
                    StatusID.CHECKIN_UNIDADE,
                    StatusID.EXPEDINDO,
                    StatusID.VISUALIZADO,
                    StatusID.RECEBIDO,
                ].indexOf(
                    (coleta?.status[0] || { id: StatusID.EXPEDINDO })?.id
                ) !== -1 &&
                !coleta.coletaPaiId &&
                coletasPai.indexOf(coleta.id) === -1
            ) {
                unidadesAgrupaveis[coleta?.unidade.id] =
                    (unidadesAgrupaveis[coleta?.unidade.id] || 0) + 1;
            }
            return coleta;
        },
        [coletas, coletasPai]
    );
    const filterFinish = useCallback(
        ({ id: coletaId, coletaPaiId, status }) => {
            const statusId =
                status.length > 0 ? status[0].id : StatusID.EXPEDINDO;
            return (
                statusId === StatusID.CONCLUIDO &&
                ((!coletasFilho[coletaId] && !coletasFilho[coletaPaiId]) ||
                    (!coletasFilho[coletaId] && coletasFilho[coletaPaiId]) ||
                    (coletasFilho[coletaId] &&
                        coletasFilho[coletaId].length === 0) ||
                    (coletasFilho[coletaPaiId] &&
                        coletasFilho[coletaPaiId].length === 0))
            );
        },
        [coletas, coletasPai]
    );
    const filterOthers = useCallback(
        ({ id: coletaId, coletaPaiId, status }) => {
            const statusId =
                status.length > 0 ? status[0].id : StatusID.EXPEDINDO;

            return (
                ([StatusID.CANCELADO, StatusID.CONCLUIDO].indexOf(statusId) ===
                    -1 &&
                    (!coletasFilho[coletaId] || !coletasFilho[coletaPaiId])) ||
                ([StatusID.CANCELADO].indexOf(statusId) === -1 &&
                    coletasFilho[coletaId] &&
                    coletasFilho[coletaId].length > 0)
            );
        },
        [coletas, coletasFilho, coletasPai]
    );
    const getClassName = useCallback(
        (coleta, startChildGroup, key, total, coletaGroup) => {
            const lineClassName = [];

            if (coletasPai.indexOf(coleta.id) !== -1) {
                if (coleta.reactPaiIdSort === coleta.id) {
                    lineClassName.push('group-start');
                    startChildGroup = true;
                }
            } else if (coletasPai.indexOf(coleta.coletaPaiId) !== -1) {
                if (key < total) {
                    if (
                        (coletaGroup[key + 1] || {}).coletaPaiId ||
                        (!startChildGroup &&
                            (coletaGroup[key + 1] || {}).coletaPaiId ===
                                coleta.id)
                    ) {
                        lineClassName.push('group');
                    } else {
                        lineClassName.push('group-end');
                    }
                } else {
                    lineClassName.push('group');
                }
            } else {
                if (coleta.coletaPaiId && startChildGroup) {
                    lineClassName.push('group-end');
                }
            }
            return { lineClassName, startChildGroup };
        },
        [coletas, coletasFilho, coletasPai]
    );
    const alertAceiteAndCheckInCliente = useCallback(
        (coleta, lineClassName, linePropsClassName) => {
            if (
                permissoes.administrador &&
                coleta.status &&
                coleta.status.length > 0 &&
                [StatusID.ACEITO, StatusID.CHECKOUT_UNIDADE].includes(
                    coleta.status[0].id
                )
            ) {
                const statusAtual = coleta.status[0];
                const maxTime =
                    statusAtual.tempoPercurso + timeAdicionalCollectt;

                const dataInicio = new Date(statusAtual.dataAlteracao);
                const dataAgora = new Date();
                const dataFim = addMinutes(dataInicio, maxTime);
                const diffTime = getMinDiff(dataInicio, dataAgora);

                if (diffTime > maxTime) {
                    lineClassName.push('bgRed', 'time-collectt');

                    linePropsClassName.content = `PLAYER (${dateTimeline(
                        dataAgora,
                        dataFim
                    )} DE ATRASO )`.toUpperCase();
                }
            }
        },
        [permissoes, coletasPai, coletasFilho, coletas, timeAdicionalCollectt]
    );
    const alertEsperaNoEstabelecimento = useCallback(
        (coleta, lineClassName, linePropsClassName) => {
            if (
                coleta.status &&
                coleta.status.length > 0 &&
                [StatusID.CHECKIN_UNIDADE].includes(coleta.status[0].id)
            ) {
                const statusAtual = coleta.status[0];

                const dataInicio = new Date(statusAtual.dataAlteracao);
                const dataAgora = new Date();
                const dataFim = addMinutes(
                    dataInicio,
                    timeEsperaEstabelecimento
                );
                const diffTime = getMinDiff(dataInicio, dataAgora);

                if (diffTime > timeEsperaEstabelecimento) {
                    lineClassName.push('bgRed', 'time-collectt');

                    linePropsClassName.content =
                        `ESTABELECIMENTO (${dateTimeline(
                            dataAgora,
                            dataFim
                        )} DE ATRASO )`.toUpperCase();
                }
            }
        },
        [timeEsperaEstabelecimento, coletas, coletasFilho, coletasPai]
    );
    const data = useMemo(() => {
        if (!coletas || coletas.length === 0) return [];
        const unidadesAgrupaveis = {};
        // Adicionando variaveis de controle para ordenação
        const coletaSortGroupFirst = coletas
            ?.filter(onlyUnique) // remove coletas duplicadas
            ?.map((coleta) => groupUnit(coleta, unidadesAgrupaveis))
            .sort(handleSortPaiFirst) // coloca as coletas PAI em primeiro lugar
            .sort(handleSortGroupFirst); // ordena as coletas filhas abaixo de seu respectivo pai;

        const cancelados = coletaSortGroupFirst.filter(({ status }) => {
            const id = status.length > 0 ? status[0].id : StatusID.EXPEDINDO;
            return id === StatusID.CANCELADO;
        });

        const concluidos = coletaSortGroupFirst.filter(filterFinish);

        const demais = coletaSortGroupFirst.filter(filterOthers);
        let startChildGroup = false;
        const coletaGroup = [...demais, ...concluidos, ...cancelados];
        const total = coletaGroup.length - 1;

        return coletaGroup.map((coleta, key) => {
            const responseClassName = getClassName(
                coleta,
                startChildGroup,
                key,
                total,
                coletaGroup
            );
            let lineClassName = responseClassName.lineClassName;
            startChildGroup = responseClassName.startChildGroup;

            startChildGroup = false;
            const endereco = coleta?.cliente?.endereco;
            const hasUpdateAddress = !!endereco?.novoEnderecoNaoAprovado;

            let linePropsClassName = { content: '' };
            alertAceiteAndCheckInCliente(
                coleta,
                lineClassName,
                linePropsClassName
            );
            alertEsperaNoEstabelecimento(
                coleta,
                lineClassName,
                linePropsClassName
            );
            const lineClass = lineClassName.concat(
                hasUpdateAddress ? ['attention'] : []
            );

            return {
                lineClassName: lineClass.join(' '),
                linePropsClassName,
                key: `${coleta.id}${coleta.cliente.id}${coleta.entregador?.id}${
                    coleta.coletaPaiId
                }${coleta.status[0]?.id}${lineClass.join('-')}`,
                coleta,
            };
        });
    }, [
        filterFinish,
        filterOthers,
        groupUnit,
        onlyUnique,
        alertAceiteAndCheckInCliente,
        alertEsperaNoEstabelecimento,
        getClassName,
        timeEsperaEstabelecimento,
        handleSortGroupFirst,
        timeAdicionalCollectt,
        handleSortPaiFirst,
        coletas,
        coletasPai,
        permissoes,
        coletasFilho
    ]);

    return data;
};
