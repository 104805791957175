import React, { FC, createRef, useContext } from 'react';
import { IReceiver } from '../../coleta/cadastrar/cliente';
import PageNavigateSelecionarEndereco from '../../coleta/cadastrar/endereco/';
import { useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import ComponentEnderecoEditar from './Component';
import { GRAPHQL_ATUALIZAR_ENDERECO_COLETA } from 'gql/cliente';
import { useMutation } from '@apollo/client';
import { AuthContext } from 'routes/auth.context';
const PageEditarEndereco: FC<IReceiver> = () => {
    const { idCliente, enderecoIdSelecionado, unidadeId, idColeta } =
        useParams<{
            idCliente: string;
            idColeta: string;
            enderecoIdSelecionado: string;
            unidadeId: string;
        }>();
    const formRef = createRef<FormHandles>();
    const [pAtualizarEndereco] = useMutation(GRAPHQL_ATUALIZAR_ENDERECO_COLETA);
    const { alertConfirm } = useContext(AuthContext);
    const history = useHistory();

    const handleSubmit = async (clienteEnderecoId: number) => {
        try {
            await pAtualizarEndereco({
                variables: {
                    coletaId: idColeta,
                    clienteEnderecoId,
                },
            });
            history.goBack();
            await alertConfirm({
                tipo: 'sucesso',
                titulo: 'Atualizar endereço',
                conteudo: 'Endereço atualizado com sucesso',
            });
        } catch ({ message }: any) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    };
    return (
        <ComponentEnderecoEditar>
            <PageNavigateSelecionarEndereco
                formRef={formRef}
                endereco={enderecoIdSelecionado}
                clienteId={idCliente}
                nomeCompleto={idCliente}
                unidadeId={unidadeId}
                setMinWidthModal={value => {}}
                handleNavigateForms={() => {}}
                handleSubmit={handleSubmit}
            />
        </ComponentEnderecoEditar>
    );
};
export default PageEditarEndereco;
