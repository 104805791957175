import { useQuery } from '@apollo/client';
import { GRAPHQL_CONFIGURACAO } from 'gql/configuracao';
import { useQueryPermissions } from 'pages/coleta/listar/hooks/useQueryPermissions';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IConfiguracao } from '../../configuracao-padrao';
import { useUploadProcUnidade } from './useUploadProcUnidade';
export const useFormTipoRelatorio = () => {
    const { administrador, perfilUnidadeAdministrador } = useQueryPermissions();
    const location = useLocation();
    const { onChange: handleVerificarUnidadeExiste } = useUploadProcUnidade();
    const [tipo, setTipo] = useState(0);
    const [faturaVenc, setFaturaVenc] = useState(7);

    const { data, error, loading } = useQuery<{
        recuperarConfiguracao?: IConfiguracao[];
    }>(GRAPHQL_CONFIGURACAO, {
        onCompleted: ({ recuperarConfiguracao }) => {
            setFaturaVenc(
                Number(
                    (
                        recuperarConfiguracao.find(
                            ({ chave }) =>
                                chave === 'Fatura::QtdDia::Vencimento'
                        ) || { valor: 7 }
                    ).valor
                )
            );
        },
    });

    const handleChange = useCallback(({ value }) => {
        setTipo(value);
    }, []);

    const select = useMemo(() => {
        const opts = [
            {
                value: 0,
                label: 'Exportar CSV',
            },
            {
                value: 1,
                label: 'Gerar fatura',
            },
        ];
        return {
            type: 'select-simple',
            onChange: handleChange,
            label: 'Ação',
            toolTipLegend:
                'Escolha se deseja gerar fatura ou apenas visualizar o relatório',
            placeholder: 'Digite ou selecione',
            name: 'acao',
            defaultValue: opts[tipo],
            options: [
                {
                    value: '',
                    label: 'Selecione',
                },
                ...opts,
            ],
        };
    }, [tipo, handleChange]);

    const dataVencimento = useMemo(() => {
        return {
            type: 'date',
            zIndex: 9994,
            label: 'Data vencimento boleto',
            toolTipLegend:
                'Informe a data que o boleto vence. Lembre-se que no menu configurações informa quantos dias após o vencimento o sistema será bloqueado.',

            placeholder: 'Data vencimento',
            defaultValue: new Date(),
            name: 'dataVencimentoBoleto',
        };
    }, [faturaVenc]);

    const response = useMemo(() => {
        if (tipo === 0) {
            return [select];
        }
        return [
            {
                gridFirstWidth: 1,
                items: [select, dataVencimento],
            },
            // {
            //     type: 'file',
            //     onChange: handleVerificarUnidadeExiste,
            //     label: 'Notas fiscais',
            //     toolTipLegend:
            //         'Anexe aqui as notas fiscais. Lembre-se que o nome do arquivo tem que ser o cpf/cnpj do cliente para que possamos idêntifica-lo.',
            //     placeholder: 'Anexar nota fiscal',
            //     name: 'notasFiscais',
            // },
            {
                type: 'file',
                onChange: handleVerificarUnidadeExiste,
                label: 'Boletos',
                toolTipLegend:
                    'Anexe aqui os boletos. Lembre-se que o nome do arquivo tem que ser o cpf/cnpj do cliente para que possamos idêntifica-lo.',
                placeholder: 'Anexar boletos',
                name: 'boletos',
            },
        ];
    }, [tipo, select, handleVerificarUnidadeExiste, dataVencimento]);
    const inputTipo =
        !loading &&
        administrador &&
        location.pathname.indexOf('/empresa/') !== -1
            ? response
            : [];

    return { inputTipo, perfilUnidadeAdministrador };
};
