import { useQuery } from '@apollo/client';
import { IRecuperarEntregadoresEscaladosHoje } from 'components/infoPlayer';
import { abreviarNome } from 'libs/StringUteis';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    COLETA_ENTREGADOR_ESCALADO,
} from './../../../../gql/coleta';

interface IEntregador extends IRecuperarEntregadoresEscaladosHoje {
    indiceColeta: number;
    filtered?: boolean;
}

interface IColetasNaoConcluidas {
    indiceEntregador: number;
    unidadeNomeCompleto: string;
    unidadeLatitude: number;
    unidadeLongitude: number;
    clienteLatitude: number;
    clienteLongitude: number;
    clienteNomeCompleto: string;
    numeroInterno: string;
    coletaId: string;
    entregadorId: string;
    statusId: number;
}
interface IProps {
    inputEl: MutableRefObject<any>;
    refAutoCenter: MutableRefObject<boolean>;
    execFilter: (e: any) => void;
    loading: boolean;
    recuperarColetasNaoConcluidas?: IColetasNaoConcluidas[];
    recuperarEntregadoresPeriodoVigente?: IEntregador[];
    execFilterPlayers: (list: string) => void;
    entregadores: IEntregador[];
    totalPlayerBusy?: IEntregador[];
    totalPlayerOffline?: IEntregador[];
    totalPlayersOnline?: IEntregador[];
    totalPlayerOfflineOcupado?: IEntregador[];
    totalPlayerOfflineDesocupado?: IEntregador[];
    totalPlayersOnlineDesocupado?: IEntregador[];
    collectFiltered?: boolean;
}
const generateRandomString = (num) => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result1 += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result1;
};
export const useQueryColetasEntregadores = (): IProps => {
    const inputEl = useRef(null);
    const refAutoCenter = useRef(true);
    const hasStartData = useRef(true);
    const hasSelectFilter = useRef(false);
    const [entregadores, setEntregadores] = useState<IEntregador[]>([]);
    const [coletas, setColetas] = useState<IColetasNaoConcluidas[]>([]);
    const [filter, setFilter] = useState<string>('');
    const execFilter = useCallback((e) => {
        if (e && e.preventDefault) e.preventDefault();
        setFilter(inputEl.current.value.trim());
    }, []);

    const { loading, data } = useQuery<{
        recuperarColetasNaoConcluidas?: IColetasNaoConcluidas[];
        recuperarEntregadoresPeriodoVigente?: IEntregador[];
    }>(COLETA_ENTREGADOR_ESCALADO, {
        fetchPolicy: 'no-cache',
        pollInterval: 5000,
    });

    useEffect(() => {
        if (data) {
            
            const recuperarEntregadoresPeriodoVigente = data.recuperarEntregadoresPeriodoVigente.map(
                (entregador) => ({
                    ...entregador,
                    indiceColeta: -1,
                })
            );
            setEntregadores(recuperarEntregadoresPeriodoVigente);
            const recuperarColetasNaoConcluidas = data.recuperarColetasNaoConcluidas.map(
                (coleta, indiceColeta) => {
                    const indiceEntregador = coleta.entregadorId
                        ? recuperarEntregadoresPeriodoVigente?.findIndex(
                              (entregador) =>
                                  entregador.entregadorId === coleta.entregadorId
                          )
                        : -1;
                    if (indiceEntregador !== -1) {
                        recuperarEntregadoresPeriodoVigente[
                            indiceEntregador
                        ].indiceColeta = indiceColeta;
                    }
    
                    return { ...coleta, indiceEntregador };
                }
            );
            setColetas(recuperarColetasNaoConcluidas);
            setTimeout(() => {
                hasStartData.current = false;
            }, 1000);
        }
    }, [data]);

    const coletasEEntregadoresMapa = useMemo(() => {
        const recuperarEntregadoresPeriodoVigente = entregadores.map(
            (entregador) => ({
                ...entregador,
                indiceColeta: -1,
            })
        );
        const recuperarColetasNaoConcluidas = coletas.map(
            (coleta, indiceColeta) => {
                const indiceEntregador = coleta.entregadorId
                    ? recuperarEntregadoresPeriodoVigente?.findIndex(
                          (entregador) =>
                              entregador.entregadorId === coleta.entregadorId
                      )
                    : -1;
                if (indiceEntregador !== -1) {
                    recuperarEntregadoresPeriodoVigente[
                        indiceEntregador
                    ].indiceColeta = indiceColeta;
                }

                return { ...coleta, indiceEntregador };
            }
        );
        return {
            recuperarColetasNaoConcluidas,
            recuperarEntregadoresPeriodoVigente,
        };
    }, [entregadores, coletas]);

    const totalPlayerBusy = useMemo(() => {
        return coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
            (entregador) => entregador.indiceColeta !== -1
        );
    }, [coletasEEntregadoresMapa]);

    const totalPlayerOffline = useMemo(() => {
        return coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
            (entregador) => !entregador.entregadorOnline
        );
    }, [coletasEEntregadoresMapa]);

    const totalPlayersOnline = useMemo(() => {
        return coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
            (entregador) => entregador.entregadorOnline
        );
    }, [coletasEEntregadoresMapa]);

    const totalPlayerOfflineOcupado = useMemo(() => {
        return coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
            (entregador) =>
                !entregador.entregadorOnline && entregador.indiceColeta !== -1
        );
    }, [coletasEEntregadoresMapa]);

    const totalPlayerOfflineDesocupado = useMemo(() => {
        return coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
            (entregador) =>
                !entregador.entregadorOnline && entregador.indiceColeta === -1
        );
    }, [coletasEEntregadoresMapa]);

    const totalPlayersOnlineDesocupado = useMemo(() => {
        return [];
    }, []);

    const markersFiltered = useMemo(() => {
        const partsSearch =
            filter.length === 0 ? null : filter.toLowerCase().split(', ');
        let entregadorIds: string[] = [];

        let recuperarEntregadoresPeriodoVigente: IEntregador[] =
            coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
                (entregador) => {
                    if (hasSelectFilter.current) {
                        const visible = entregador.filtered === true;
                        if (visible) {
                            entregadorIds.push(entregador.entregadorId);
                        }
                        return visible;
                    }
                    const nome =
                    entregador && entregador.entregadorNomeCompleto ? entregador.entregadorNomeCompleto.toLowerCase() : '';
                    const result =
                        partsSearch === null ||
                        partsSearch.filter(
                            (part) => nome.indexOf(part.trim()) !== -1
                        ).length > 0;
                    if (result) {
                        entregadorIds.push(entregador.entregadorId);
                    }
                    return result;
                }
            );

        const recuperarColetasNaoConcluidas =
            coletasEEntregadoresMapa.recuperarColetasNaoConcluidas.filter(
                (data) => {
                    const cliente = data.clienteNomeCompleto.toLowerCase();
                    const unidade = data.unidadeNomeCompleto.toLowerCase();
                    if (hasSelectFilter.current) {
                        const visible =
                            entregadorIds.indexOf(data.entregadorId) !== -1;
                        if (visible) {
                            entregadorIds.push(data.entregadorId);
                        }
                        return visible;
                    }
                    const result =
                        entregadorIds.indexOf(data.entregadorId) !== -1 ||
                        partsSearch === null ||
                        partsSearch.filter(
                            (value) =>
                                cliente.indexOf(value) !== -1 ||
                                unidade.indexOf(value) !== -1
                        ).length > 0;
                    if (result) {
                        entregadorIds.push(data.entregadorId);
                    }
                    return result;
                }
            );

        recuperarEntregadoresPeriodoVigente =
            coletasEEntregadoresMapa.recuperarEntregadoresPeriodoVigente.filter(
                (entregador) => {
                    return (
                        entregadorIds.indexOf(entregador.entregadorId) !== -1
                    );
                }
            );
        return {
            recuperarEntregadoresPeriodoVigente,
            recuperarColetasNaoConcluidas,
        };
    }, [filter, loading, coletasEEntregadoresMapa]);
    const response = {
        refAutoCenter,
        inputEl,
        execFilter,
        entregadores,
        
        loading,
        totalPlayersOnlineDesocupado,
        totalPlayerOfflineDesocupado,
        totalPlayerOfflineOcupado,
        ...markersFiltered,
        recuperarColetasNaoConcluidas: coletas,
        totalPlayerBusy,
        totalPlayerOffline,
        totalPlayersOnline,
    };
    const execFilterPlayers = useCallback(
        (name: string) => {
            const listagem = response[name].map(
                ({ entregadorNomeCompleto }) => entregadorNomeCompleto
            );
            inputEl.current.value = '';
            refAutoCenter.current = true;
            setFilter([...listagem, generateRandomString(5)].join(', '));
        },
        [response]
    );
    return { ...response, execFilterPlayers };
};
