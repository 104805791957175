import { useQuery } from '@apollo/client';
import { GRAPHQL_TOTAL_ESCALADO_ENTREGADOR } from 'gql/entregador';

export const useTotalEntregadorEscalado = () => {
    const { data } = useQuery<{ totalEntregadorEscalado?: number }>(
        GRAPHQL_TOTAL_ESCALADO_ENTREGADOR,
        {
            fetchPolicy: 'no-cache',
        }
    );

    return data?.totalEntregadorEscalado || 0;
};
