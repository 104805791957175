import { useCallback } from 'react';

export const useCreateCsv = () => {
    const ConvertToCSV = (objArray: any) => {
        let rows = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let  header = "";
        rows[0].map(pr => (header += pr + ";"));
        rows.splice(0, 1);

        let str = "";
        rows.forEach(row => {
            let line = "";
            let columns =
                typeof row !== "object" ? JSON.parse(row) : Object.values(row);
            columns.forEach(column => {
                if (line !== "") {
                    line += ";";
                }
                if (typeof column === "object") {
                    line += JSON.stringify(column);
                }  else {
                    line += column;
                }
            });
            str += line + "\r\n";
        });
        return header + "\r\n" + str;
    }
    const handleGenerateCsv = useCallback(({ fileName, content }) => {
        const csv = ConvertToCSV(content);
        const nameDownload = `${fileName}.csv`;

        const valueAttr = encodeURI(csv);

        var pom = document.createElement('a');
        pom.setAttribute(
            'href',
            `data:text/csv;charset=utf-8,%EF%BB%BF${valueAttr}`
        );
        pom.setAttribute('download', nameDownload);
        pom.click();
    }, []);
    return { handleGenerateCsv };
};
