import React, { FC, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { Container, Ativo } from './styl';
import { IUnidade } from 'types/modules/unidade';
interface IItemListaEmpresa {
	item: IUnidade;
}
export const ItemListaEmpresa: FC<IItemListaEmpresa> = ({ item }) => {
	const { setUnidadeSelecionada } = useContext(AuthContext);
	const history = useModalHistory();
	return (
		<Container
			onClick={() => {
				// seleciona a unidade
				setUnidadeSelecionada(item);
				// remove o popup
				history.popTo();
			}}
		>
			{item.logotipo && (
				<img src={item.logotipo} alt={item.razaoSocial} />
			)}
			<div>
				<p>{item.nomeFantasia}</p>
				<Ativo status="ativo" />
			</div>
		</Container>
	);
};
