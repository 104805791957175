import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_AUTO_LOGIN } from 'gql/signin';
import { useEffect, useCallback } from 'react';

interface IAutoLogin {
    usuarioAutoConectar?: IUser;
}
export const useAutoSignin = (setUser, loadDefaultData, handleUpdateBearer) => {
    const [handleAutoSignin, { loading: loadingAutoSignin, data }] =
        useLazyQuery<IAutoLogin>(GRAPHQL_AUTO_LOGIN);

    useEffect(() => {
        if (data && data?.usuarioAutoConectar?.token) {
            const { token, ...rest } = data?.usuarioAutoConectar;
            handleUpdateBearer(token);
            setUser({ ...rest });
        }
    }, [data, setUser]);

    const autosignin = useCallback(async () => {
        try {
            await handleAutoSignin();
            await loadDefaultData();
        } catch ({ message }) {
            console.error(message);
        }
    }, [handleAutoSignin, loadDefaultData]);

    useEffect(() => {
        const token = localStorage.getItem('@auth/token');
        if (token && token.length > 10) {
            autosignin();
        }
    }, [autosignin]);

    return {
        loadingAutoSignin,
    };
};
