import { gql } from '@apollo/client';

export const GRAPHQL_DELLA_TV: any = gql`
    query tvDellaFotos {
        tvDellaFotos {
            type
            src
            name
        }
    }
`;
