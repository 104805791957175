import React, { FC, useContext, memo } from 'react';
import Modal from 'components/modal/base';
import { Container, Header } from './styl';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ComponentStepByStep from 'components/StepByStep';
import { NavigateContext } from './context';
import Icone from 'components/Icone';
const ComponentUnidadesCadastrar: FC<any> = memo(({ title, closeModal }) => {
    const {
        tabs: { selectTab, width, component, tabName, stepSelected, steps },
    } = useContext(NavigateContext);
    return (
        <Container width={width}>
            <Header>
                <h1>{title}</h1>
                <button onClick={closeModal}>
                    <Icone
                        icone="icon-fechar"
                        size="2rem"
                        color="var(--btn-admin-icone)"
                    />
                </button>
            </Header>
            <ComponentStepByStep
                stepSelected={stepSelected}
                fases={steps}
                submit={selectTab}
                colorSeparator="var(--bg-white)"
            />
            <SwitchTransition>
                <CSSTransition
                    key={tabName}
                    timeout={400}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    classNames="switch-component"
                >
                    {component}
                </CSSTransition>
            </SwitchTransition>
        </Container>
    );
});
export default ComponentUnidadesCadastrar;
