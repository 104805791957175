import CircularLoading from 'components/atom/Loading/CircularLoading';
import React, { FC } from 'react';
import {
    Map,
    TileLayer,
    Marker,
    // PropTypes as MapPropTypes
  } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { stylMap, WarpLoad } from './styl';
import L from 'leaflet';

interface ILL {
    lat: number;
    lng: number;
}
interface IAtomMarkerMyLocationMove {
    keyMaps: string;
    onLoadMap?(map: any): void;
    onUnloadMap?(): void;
    onDragEndMarker(location: ILL): void;
    bounds?: ILL[];
    marker?: ILL;
    loading: boolean;
    boundsMapsLimit?: google.maps.LatLngBounds;
    setViewFields?: React.Dispatch<React.SetStateAction<boolean>>;
}
const AtomMarkerMyLocationMove: FC<IAtomMarkerMyLocationMove> = props => {
    if (!props.marker) {
        if (props.loading) {
            return (
                <WarpLoad>
                    <CircularLoading
                        show={true}
                        size={3}
                        primaryColor="var(--bg-hover-light)"
                        secondaryColor="var(--btn-underline-cor)"
                    />
                </WarpLoad>
            );
        }
        return null;
    }
    return (
        <>
            {props.loading && (
                <WarpLoad>
                    <CircularLoading
                        show={true}
                        size={3}
                        primaryColor="var(--bg-hover-light)"
                        secondaryColor="var(--btn-underline-cor)"
                    />
                </WarpLoad>
            )}
            {props.marker && props.marker.lat !== 0 && !props.loading && (
            <Map center={props.marker} zoomControl={false} style={stylMap} zoom={16}>
                <TileLayer
                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                
                    <Marker 
                    onDragEnd={(e) => {
                        const latLng = e.target.getLatLng();
                        if(props.setViewFields) props.setViewFields(true);
                        if (props.onDragEndMarker) {
                            props.onDragEndMarker({
                                lat: latLng.lat,
                                lng: latLng.lng,
                            });
                        }
                    }}
                    draggable={true} 
                    icon={
                        new L.Icon({
                            iconUrl: require('leaflet/dist/images/marker-icon.png'),
                            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
                            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            popupAnchor: [1, -34],
                            tooltipAnchor: [16, -28],
                            shadowSize: [41, 41]
                        })
                    } position={props.marker} />
                
            </Map>
            )}
        </>
    );
};
export default AtomMarkerMyLocationMove;
