import React, { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';
import {
    Input as InputField,
    InputHidden,
    Container,
    LabelError,
} from './styl';

const Input: React.FC<IInput> = memo(
    ({ name, label, icon, className, autoFocus, type, ...rest }) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const { fieldName, defaultValue, registerField, clearError, error } =
            useField(name);
        useEffect(() => {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value',
            });
            if (autoFocus && inputRef.current && inputRef.current.focus) {
                inputRef.current.focus();
            }
        }, [fieldName, registerField]);

        if (type === 'hidden') {
            return (
                <InputHidden
                    type={'text'}
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...rest}
                />
            );
        }
        return (
            <Container erro={error}>
                <div className={`${className} ${rest.disabled}`}>
                    <InputField
                        name={name}
                        type={type || 'text'}
                        autoComplete="on"
                        onFocus={clearError}
                        shake={error ? 1 : undefined}
                        ref={inputRef}
                        defaultValue={defaultValue}
                        {...rest}
                    />
                    {icon}
                </div>
                <LabelError erro={error}>{error}</LabelError>
            </Container>
        );
    }
);
export default Input;
