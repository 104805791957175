import { useQuery } from '@apollo/client';
import { format, roundToNearestMinutes } from 'date-fns';
import { GRAPHQL_LISTAR_PERIODO } from 'gql/periodo';
import { useCallback, useMemo } from 'react';
import { IPeriodo, IQuery, IuseQueryPeriod } from './index.d';

export const useQueryPeriod = (props: IuseQueryPeriod): IPeriodo[] => {
    const { data } = useQuery<{ periodo?: IQuery[] }>(GRAPHQL_LISTAR_PERIODO);
    const transformHour = useCallback((hour: string): string => {
        const [h, m, s] = hour.split(':').map((v) => Number(v));
        var date = new Date();
        date.setHours(h);
        date.setMinutes(m);
        date.setSeconds(s);
        return format(
            roundToNearestMinutes(date, { nearestTo: 15 }),
            `HH:mm'h'`
        );
    }, []);
    const periodos = useMemo(() => {
        return data?.periodo?.map(({ id, nome, horaInicio, horaFim }) => ({
            id,
            icone: 'icon-click-like',
            status: '',
            titulo: nome,
            placeholder: `${nome} começa ás ${transformHour(
                horaInicio
            )} e termina ás ${transformHour(horaFim)}`,
            click: props.handleToogleEscale,
        }));
    }, [data, props]);
    return periodos || [];
};
