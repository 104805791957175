import { useField } from '@unform/core';
import Icone from 'components/Icone';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ContainerFile, LabelError, WarpInputFile, WarpFiles } from './styl';

export interface ICallbackProps {
    file: File;
    id?: string;
    name: string;
}
interface InputRefProps extends HTMLInputElement {
    acceptedFiles: ICallbackProps[];
}
export interface IChange {
    acceptedFiles: File[];
    callback(response: ICallbackProps[]): void;
}
const InputFile: React.FC<any> = memo(
    ({ name, icon, className, placeholder, onChange }) => {
        const inputRef = useRef<InputRefProps>(null);

        const {
            fieldName,
            registerField,
            defaultValue = [],
            clearError,
            error,
        } = useField(name);

        const [filesSelected, setFilesSelected] =
            useState<ICallbackProps[]>(defaultValue);

        const onTransformed = useCallback((data: ICallbackProps[]) => {
            inputRef.current.acceptedFiles = data;
            setFilesSelected(data);
            clearError();
        }, []);

        const { getRootProps, getInputProps } = useDropzone({
            accept: 'application/pdf',
            onDrop: (acceptedFiles) => {
                if (inputRef.current) {
                    onChange({
                        acceptedFiles,
                        callback: onTransformed,
                    });
                }
            },
        });

        const handleRemoveItem = useCallback((item: ICallbackProps) => {
            setFilesSelected((files) => {
                const result = files.filter(({ file }) => item.file !== file);
                inputRef.current.acceptedFiles = result;
                return result;
            });
            clearError();
        }, []);

        useEffect(() => {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                getValue: (ref: InputRefProps) => {
                    return ref.acceptedFiles || [];
                },
                clearValue: (ref: InputRefProps) => {
                    ref.acceptedFiles = [];
                    setFilesSelected([]);
                },
                setValue: (ref: InputRefProps, value) => {
                    ref.acceptedFiles = value;
                    setFilesSelected(value);
                },
            });
        }, [fieldName, registerField]);

        return (
            <ContainerFile erro={error}>
                <div className={className}>
                    <WarpInputFile
                        {...getRootProps()}
                        onClick={() => inputRef.current?.click()}
                    >
                        <div className="info">
                            <input
                                {...getInputProps()}
                                accept="application/pdf"
                                ref={inputRef}
                            />
                            <p>{placeholder}</p>
                            <Icone
                                icone="icon-anexo"
                                size="3rem"
                                color="var(--bg-hover-light)"
                            />
                        </div>
                    </WarpInputFile>
                    {filesSelected && filesSelected.length > 0 && (
                        <WarpFiles>
                            {filesSelected.map((item) => (
                                <li
                                    key={item.id || item.file.name}
                                    className={item.id ? 'success' : 'failed'}
                                >
                                    <p>{item.file.name}</p>
                                    <button
                                        type="button"
                                        onClick={(e) => handleRemoveItem(item)}
                                    >
                                        <span className="legenda">
                                            {item.name}
                                        </span>
                                        <Icone
                                            icone="icon-remover3"
                                            size="2rem"
                                            color="var(--error-cor)"
                                        />
                                    </button>
                                </li>
                            ))}
                        </WarpFiles>
                    )}
                    {icon}
                </div>
                <LabelError erro={error}>{error}</LabelError>
            </ContainerFile>
        );
    }
);
export default InputFile;
