import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_PROCURAR_DADOS_CNPJ } from 'gql/procurarDadosCnpj';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import * as yup from 'yup';
import { matchCnpj } from '../libs/yup/matches';
interface IRepresentantesEmpresa {
    value?: String;
    label?: String;
}
interface IDataReceitaWs {
    cep?: string;
    cnpj?: string;
    numero?: string;
    uf?: string;
    bairro?: string;
    email?: string;
    complemento?: string;
    fantasia?: string;
    logradouro?: string;
    municipio?: string;
    nome?: string;
    telefoneCelular?: string;
    telefoneFixo?: string;
    representantes?: IRepresentantesEmpresa[];
}
export const useGetCnpj = () => {
    const { alertConfirm } = useContext(AuthContext);
    const [handleLoadCnpj, { loading, data }] = useLazyQuery<{
        procurarDadosCnpj?: IDataReceitaWs;
    }>(GRAPHQL_PROCURAR_DADOS_CNPJ, {
        onError: ({ message }) => {
            alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message,
            });
        },
    });

    const loadBusiness = useCallback(async (formRef) => {
        const { setErrors } = formRef.current;
        const data = {
            identificador: formRef.current.getFieldValue('identificador'),
        };

        try {
            await YupUtilsTry(data, setErrors, {
                identificador: yup
                    .string()
                    .matches(matchCnpj, 'CNPJ Inválido')
                    .required('Informe o CNPJ'),
            });

            handleLoadCnpj({
                variables: {
                    cnpj: data.identificador.replace(/\D/g, ''),
                },
            });
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    }, []);

    return { loading, loadBusiness, data: data?.procurarDadosCnpj };
};
