import React, { FC, useRef, memo } from 'react';

import { SubmitHandler, FormHandles } from '@unform/core';
import ComponentNewPasswordView from './Component';
import * as yup from 'yup';
import { YupUtilsTry, YupUtilsCatch } from 'libs/yup/ShapeValidation';
import { useSendCodeNewPassword } from '../hooks/useSendCodeNewPassword';
const initialData = {
	senha: '',
};
const PageSubmitCode: FC<any> = memo(({ setSwitchComponent, code }) => {
	const { sendCode } = useSendCodeNewPassword();
	const formRef: any = useRef<FormHandles>(null);

	const handleSubmit: SubmitHandler<any> = async data => {
		const { setErrors } = formRef.current;
		try {
			await YupUtilsTry(data, setErrors, {
				senha: yup
					.string()
					.min(3, 'Senha inválido')
					.max(30)
					.required('Informe sua senha'),
			});
			await sendCode({ senha: data.senha, codigo: code });
		} catch (err) {
			YupUtilsCatch(err, setErrors);
		}
	};
	const props: any = {
		initialData,
		formRef,
		setSwitchComponent,
		handleSubmit,
	};
	return <ComponentNewPasswordView {...props} />;
});
export default PageSubmitCode;
