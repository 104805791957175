import styled, { css, keyframes, createGlobalStyle } from 'styled-components';
export const hideMobile = css`
    @media (max-width: 992px) {
        display: none;
    }
`;
export const txtCenterMobile = css`
    @media (max-width: 992px) {
        text-align: center;
    }
`;
export const flexCenterMobile = css`
    @media (max-width: 992px) {
        justify-content: center;
    }
`;

export const boxShadow = css`
    box-shadow: 2px 0px 8px 1px rgba(0, 0, 0, 0.1);
`;
export const textShadow = css`
    text-shadow: 2px 0px 8px 1px rgba(0, 0, 0, 0.1);
`;
export const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;
export const moveIn = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
`;
export const moveOut = keyframes`
  from{
    opacity:1;
    /* transform:scale(1) translateY(0%); */
  }
  to{
    opacity:0;
    /* transform:scale(.8); */
  }
`;
export const fadeIn = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
`;
export const fadeOut = keyframes`
  from{
    opacity:1;
  }
  to{
    opacity:0;
  }
`;
export const transitionEaseBack = css`
    transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
`;
export const botaoCirculo = css`
    cursor: pointer;
    position: relative;
    &::after {
        content: '';
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        opacity: 0;
        transform: scale(0.5);
        ${transitionEaseBack};
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        position: absolute;
        background-color: var(--btn-admin-icone-hover);
        z-index: 1;
    }
`;
export const botaoCirculoHover = css`
    opacity: 1;
    transform: scale(1);
`;
export default createGlobalStyle`
  *, button, input, select {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: black;
    border: 0;
    background: none;
    font-size: 10px;
    font-family: var(--font);
    font-weight:var(--regular);
    @media (max-width: 568px) {
      font-size: 8px;
    }
  }
  form > .input:nth-of-type(2n) div{
    animation-direction: alternate;
    animation-delay:50ms;
  }
  *:focus{
    outline: none;
    text-decoration: none;
  }
  #root{
    width:100%;
    height:auto;
    min-height:100vh;
    background-color:var(--bg-color);
  }
  body{
    direction: ltr;
    -webkit-font-smoothing:antialiased;
    text-rendering: optimizeLegibility;
  }


  input::placeholder{
    color:var(--placeholder);
  }
  img {
    max-width: 100%;
    display: block;
  }
  h3, input{
    font-size:1.6rem;
  }
  i, b, a, p, label, button, h6{
    font-size:1.4rem;
  }
  label{
    font-size:1.30rem;
  }
  span{
    font-size:1.2rem;
  }
  h1{
    letter-spacing: .011em;
    font-size:2.5rem;
  }
  h1, h2{
    font-weight:var(--regular);
  }
  b, h6{
    font-weight:var(--bold);
  }
  i{
    font-style:italic;
  }
  b{
    color:var(--btn-underline-cor);
  }
  a{
    font-style:italic;
    color:var(--btn-underline-cor);
  }
  label, span, p, i{
    color:var(--text-cor);

  }
  label{
    padding:0 1rem .8rem 1rem;
  }
  .switch-page-wrapper-auth {
    position: relative;
    width:100%;
  }

  .switch-page-wrapper-auth > div {
    position: absolute;
    transform:translateY(-50%);
    width:100%;
  }
  .switch-component-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  .switch-component-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  .switch-component-exit {
    opacity: 1;
  }
  .switch-component-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }


  :root {
    --bold:500;
    --regular:initial;
    --size-h1:3.5rem;
    --size-h2:3rem;
    --size-h3:2.5rem;
    --size-h4:2rem;
    --size-p:1.6rem;
    --size-span:1.4rem;
    --size-small:1.2rem;
    --font:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto;
    --h-input:4.5rem;
    --def-radius:1rem;
    --btn-gradient-active-cor: linear-gradient(
      90deg,
      #486ec8 0%,
      #6d4ea9 50%,
      #893691 100%,
      #486ec8 150%
    );
    --btn-gradient-reject-cor: linear-gradient(
      90deg,
      #ef5451 0%,
      #ef4451 50%,
      #ef6451 100%,
      #ef5451 150%
    );
    --btn-gradient-deactive-cor: linear-gradient(
      90deg,
      #E0E0E0 0%,
      #D0D0D0 100%
    );
    --bg-modal:rgba(32, 33, 36, 0.3);
    --placeholder:#ACACAC;
    --bg-loading:blue;
    --bg-white:#F5F6F8;
    --bg-color:#EFEFEF;
    --bg-hover-light:#D9D9D9;
    --btn-underline-cor: #772fc3;
    --btn-admin-icone: #45246E;
    --btn-admin-icone-hover: #FFBD24;

    --btn-bg-small-inativo: #FBF1E7;
    --btn-bg-small-ativo: #FEFFE3;
    --btn-bg-small-interest: #e3f2ff;
    --btn-bg-small: #fff9e9;
    --btn-bg-small-vazio: #EAEAEA;
    --btn-underline-hover-cor: #1647c3;
    --text-cor: #627081;
    --error-cor: #ef5451;
    --error-bg-error: #ef545121;
    --error-cor-br: #fff9e9;
    --success-cor: #8dc63f;
    --info-cor: #2f84cc;
    // TV Della
    --bg: #eeeded;
    --gold: #c29d4c;
    --brown: #2f1c00;
    --green: #809b34;
    --red: #a21337;
    --gray: #8e8e8e;
    --white: #ffffff;
    --sidebar-width: 20rem;
  }
`;
export const MoveBottom = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
`;
