import ListTemplate from 'components/template/ListTemplate';

import React, { FC } from 'react';
import { useMutationScaledTogglePlayer } from './hooks/useMutationScaledTogglePlayer';
import { useQuerySubscriptionFilter } from './hooks/useQuerySubscriptionFilter';
import { useQueryPeriod } from './hooks/useQueryPeriod';
import { useHeader } from './hooks/useHeader';
import { useHandleOpenModal } from './hooks/useHandleOpenModal';
import { useTransformPlayer } from './hooks/useTransformPlayer';
import { useMutationScale } from './hooks/useMutationScale';
import { useMutationModalTransport } from './hooks/useMutationModalTransport';
import { useMutationMaquininhaJr } from './hooks/useMutationMaquininhaJr';

const UnidadeGrid: FC<any> = () => {
    const propsScale = useMutationScale();
    const periodos = useQueryPeriod(propsScale);
    const handles = useHandleOpenModal();
    const { handleToogleActive } = useMutationScaledTogglePlayer();
    const props = useQuerySubscriptionFilter();

    const { handleToogleModalTransporte, entregadores: e1 } =
        useMutationModalTransport(props);
    const { handleToogleMaquininhaJr, entregadores } = useMutationMaquininhaJr({
        ...props,
        response: e1,
    });

    const colunas = useHeader();

    const data = useTransformPlayer(
        entregadores,
        {
            handleToogleActive,
            handleToogleModalTransporte,
            handleToogleMaquininhaJr,
            ...handles,
        },
        periodos,
        props.variables.filter.turno
    );

    return (
        <ListTemplate
            addFilterDate={true}
            browserTitle="Players e escalas"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default UnidadeGrid;
