import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { ENTREGADOR_ALTERAR_RECEBE_COLETA } from 'gql/entregador';

export const useToggleActiveRecieveCollect = () => {
    const [toggleActive, { loading: loadingToogleReciveCollect }] = useMutation(
        ENTREGADOR_ALTERAR_RECEBE_COLETA
    );
    const handleToogleRecieveCollect = useCallback(
        ({ entregadorId, recebeColeta } : {
            entregadorId: string;
            recebeColeta: boolean;
        }) => {
            console.log('id', entregadorId, recebeColeta);
            toggleActive({
                variables: { entregadorId, recebeColeta },
            });
        },
        [toggleActive]
    );
    return {
        handleToogleRecieveCollect,
        loadingToogleReciveCollect,
    };
};
