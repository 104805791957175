import { flexCenterMobile, hideMobile } from 'routes/styl';
import styled from 'styled-components';
export const Hr = styled.div`
    background: var(--btn-gradient-active-cor);
    height: 0.5rem;
`;
export const ContainerTemplateAuth = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 0.5rem);
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    & header {
        display: flex;
        justify-content: flex-end;
        padding: 2.5rem 2.5rem 0;
        ${flexCenterMobile}
    }
`;
export const WarpTemplateAuth = styled.section.attrs({
    className: 'warpTemplateAuth',
})`
    margin: 0 auto;
    flex: 1;
    display: flex;
    & figure {
        ${hideMobile}
        img {
            height: 100%;
            object-fit: cover;
            object-position: 20% 20%;
            border-radius: 1rem;
        }
    }
    width: 100%;
    max-width: 550px;
    @media (min-width: 991px) {
        margin: 0 auto;
        flex: none;
        max-width: none;
    }
    .centerVertical {
        display: flex;
        align-items: center;
        margin: 0 1.5rem;
        width: 100%;
        @media (min-width: 991px) {
            width: auto;
            display: grid;
            grid-gap: 3rem;
            grid-template-columns: 1fr max(32rem, 48rem);
        }
    }
`;
