import React, { FC, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { ContainerPageSignin, WarpLink } from '../../signin/styl';
import InputPassword from 'components/Form/Input/InputPassword';
import { defTitle } from 'assets/strings';
import ButtonLoading from 'components/atom/ButtonLoading';
const ComponentNewPasswordView: FC<any> = memo(
	({ initialData, formRef, handleSubmit }) => {
		useEffect(() => {
			document.title = `${defTitle} - Nova senha`;
		});
		return (
			<ContainerPageSignin>
				<h1>Pronto! Agora crie uma senha</h1>
				<p>
					Digite sua <b>senha</b> e clique em concluir e pronto.
					<br />
					<i>
						A senha deve ser composta por no mínimo 6 carácteres,
						entre eles números e letras
					</i>
				</p>
				<Form
					initialData={initialData}
					ref={formRef}
					onSubmit={handleSubmit}
				>
					<label htmlFor="email">Nova senha</label>
					<InputPassword name="senha" />
					<div className="space-button">
						<ButtonLoading value="Continuar" loading={false} />
					</div>
					<WarpLink>
						<Link to="/">Lembrei minha senha</Link>
					</WarpLink>
				</Form>
			</ContainerPageSignin>
		);
	}
);
export default ComponentNewPasswordView;
