export enum StatusID {
    PENDENTE = 1,
    ACEITO = 2,
    CHECKIN_UNIDADE = 3,
    CHECKOUT_UNIDADE = 4,
    CHECKIN_CLIENTE = 5,
    CONCLUIDO = 6,
    CANCELADO = 7,
    EXPEDINDO = 8,
    RECEBIDO = 11,
    VISUALIZADO = 10,
    RETORNO_ESTABELECIMENTO = 12,
}
export enum StatusName {
    _2 = 'Aceito',
    _3 = 'Checkin Unidade',
    _4 = 'Checkout Unidade',
    _5 = 'Checkin Cliente',
    _6 = 'Concluido',
    _7 = 'Cancelado',
    _11 = 'Recebido',
    _10 = 'Visualizado',
    _9 = 'Tempo Esgotado',
    _8 = 'Salvo',
    _1 = 'Procurando Player',
    _12 = 'Retorno Estabelecimento',
}
