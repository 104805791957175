import styled from 'styled-components';
import Icone from 'components/Icone';
export const Container = styled.div`
	height: 7rem;
	overflow: hidden;
`;
export const WarpAction = styled.div.attrs({ className: 'warpAction' })`
	> div {
		margin-right: 1rem;
	}
`;
export const Button = styled.button<any>`
	width: 3.5rem;
	height: 4.3rem;
	cursor: pointer;
`;
export const IcSearch = styled(Icone)<any>``;
