import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_ATUALIZAR_USUARIO } from 'gql/usuario';
import { createRef, useContext, useMemo } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import { useLoadUser } from '../useLoadUser';
import { useValidadeSubmit } from '../useValidadeSubmit';
import { GRAPHQL_ADICIONAR_USUARIO } from 'gql/usuario';
import { IPerfilSelected } from './index.d';

export const useMutationSaveAndEdit = () => {
    const history = useModalHistory();
    const { alertConfirm, user, unidadeSelecionada } = useContext(AuthContext);
    const { usuario, perfil, hasId, id } = useLoadUser();
    const [handleAtualizaUsuario, { loading }] = useMutation(
        hasId ? GRAPHQL_ATUALIZAR_USUARIO : GRAPHQL_ADICIONAR_USUARIO
    );

    const rForm = createRef<FormHandles>();
    const handleDataValidate = useValidadeSubmit();
    const handleSubmit = async (data) => {
        if (user?.perfil?.id === 2) {
            if (usuario?.perfil?.id === 2) {
                data.perfil = 2;
            } else {
                data.unidadeId = unidadeSelecionada.id;
                data.perfil = 3;
            }
        }
        const post = await handleDataValidate({ data, rForm: rForm.current });

        if (post) {
            const variables = hasId
                ? {
                      usuarioId: id,
                      data,
                  }
                : { data };

            try {
                await handleAtualizaUsuario({
                    variables,
                });
                history.goBack();
                await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Usuário',
                    conteudo: `Usuário ${
                        hasId ? 'atualizado' : 'criado'
                    } com sucesso`,
                });
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        }
    };

    const perfils: IPerfilSelected[] = useMemo(() => {
        return perfil?.map(({ id, nome, administrador }) => ({
            value: id,
            label: `${nome}${administrador ? ' [ super administrador ] ' : ''}`,
        }));
    }, [perfil]);

    const perfilSelected = useMemo(() => {
        return perfils?.find((ele) => ele.value === usuario?.perfil?.id);
    }, [perfils]);

    return {
        rForm,
        title: `${hasId ? 'Editar' : 'Adicionar'} usuário`,
        titleSubmit: `${hasId ? 'Editar' : 'Adicionar'} usuário`,
        values: usuario,
        perfilSelected,
        handleSubmit,
        isEstabelecimentoAdministrador: user?.perfil?.id === 2,
        perfils,
        loading,
    };
};
