import styled, { css } from 'styled-components';
import { boxShadow, botaoCirculo, botaoCirculoHover } from 'routes/styl';

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 500px;
    transition: 0.3s width ease-in-out;
    ${boxShadow};
    padding: 2rem 2rem 3rem 2rem;
    .react-calendar {
        width: 100%;
        border: 0px;
        background: transparent;
        margin-bottom: 15px;
    }
    .react-calendar__tile--active {
        background: var(--btn-admin-icone);
        border-radius: 10px;
    }
    .react-calendar__tile abbr {
        color: #000;
        font-size: 14px;
    }
    .react-calendar__tile--active abbr {
        color: #fff;
        font-size: 14px;
    }
    .react-calendar__tile--active {
        background: var(--btn-admin-icone);
    }
    .react-calendar__navigation button span {
        color: var(--btn-admin-icone);
        font-size: 14px;
    }
    .full-width {
        width: 100%;
    }
`;
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h1 {
        color: var(--btn-admin-icone);
    }
    button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
`;
