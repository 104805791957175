import styled from 'styled-components';
import { boxShadow } from 'routes/styl';
export const stylMap = {
    width: '100%',
    height: '100%',
};
export const WarpLoad = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--bg-modal);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const WarpFreteAndKm = styled.div`
    position: absolute;
    width: 15rem;
    height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: var(--def-radius);
    top: 1rem;
    left: 1rem;
    background: var(--bg-white);
    z-index: 999;
    ${boxShadow};
    > span {
        font-weight: var(--bold);
        color: var(--info-cor);
        .erro {
            color: var(--error-cor);
        }
        .normal {
            color: var(--text-cor);
        }
    }
    h1 {
        font-weight: var(--bold);
        color: var(--btn-admin-icone);
        text-align: center;
        line-height: 1;
    }
    .retorno{
        padding-top: 2rem;
        margin-top: 1.5rem;
        border-top: 1px solid var(--bg-hover-light);
        width: 100%;
        text-align: center;
    }
    h1 span {
        display: block;
        font-size: 1.5rem;
    }
`;
