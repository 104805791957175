import styled from 'styled-components';
export const Message = styled.h2`
    color: var(--text-color);
    font-size: 3rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
`;
export const BlcMessage = styled.h2`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100vh;
`;
