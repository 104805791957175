import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import Modal from 'components/modal/base';
import { GRAPHQL_LISTAR_UNIDADE } from 'gql/unidade';
import { IQueryUnidade } from 'pages/unidades/list/hooks/useQueryFilterUnit/index.d';
import React, { createRef, useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';
import ComponentUnidadesCadastrar from '../create/Component';
import { NavigateProvider } from '../create/context';
import PageEmpresa from '../create/empresa';
import PageEndereco from '../create/endereco';
import { useFetchSubmit } from './hooks/useFetchSubmit';
const UnidadesCadastrar: FC = () => {
    const { unidadeId } = useParams<any>();

    const { data, error, loading } = useQuery<IQueryUnidade>(
        GRAPHQL_LISTAR_UNIDADE,
        {
            variables: { filter: { id: unidadeId } },
        }
    );

    const prosSubmit = useFetchSubmit();
    const formularios = useMemo(() => {
        if (loading) return null;
        const {
            unidade: {
                data: [u],
            },
        } = data;

        const email = (
            (u.unidadeContatos || []).find(({ tipo }) => tipo === 'email') || {}
        ).contato;
        const contatoTipo = (u.unidadeContatos || [])
            .filter(({ tipo }) => tipo !== 'email')
            .map(({ tipo }) => tipo);
        const contatoValor = (u.unidadeContatos || [])
            .filter(({ tipo }) => tipo !== 'email')
            .map(({ contato }) => contato);
        return {
            dadosEmpresa: {
                component: PageEmpresa,
                nome: 'Empresa',
                width: '60rem',
                initialData: {
                    editar: true,
                    email,
                    contatoTipo,
                    contatoValor,
                    identificador: u.identificador,
                    nomeFantasia: u.nomeFantasia,
                    nomeResponsavel: u.nomeResponsavel,
                    razaoSocial: u.razaoSocial,
                    tipo:
                        u.identificador.length > 15
                            ? 'Pessoa jurídica'
                            : 'Pessoa física',
                },
                formRef: createRef<FormHandles>(),
            },
            endereco: {
                props: prosSubmit,
                component: PageEndereco,
                nome: 'Endereço',
                width: '80rem',
                initialData: {
                    editar: true,
                    enderecoBairro: u.enderecoBairro,
                    enderecoCep: u.enderecoCep,
                    enderecoComplemento: u.enderecoComplemento,
                    enderecoLogradouro: u.enderecoLogradouro,
                    enderecoNumero: u.enderecoNumero,
                    enderecoPontoReferencia: u.enderecoPontoReferencia,
                    enderecoQuadra: u.enderecoQuadra,
                    enderecoLote: u.enderecoLote,
                    geoLatitude: u.geoLatitude,
                    geoLongitude: u.geoLongitude,
                },
                formRef: createRef<FormHandles>(),
            },
        };
    }, [data, loading]);
    return (
        <Modal
            render={({ closeModal }) =>
                formularios && (
                    <NavigateProvider dataTabs={formularios}>
                        <ComponentUnidadesCadastrar
                            title="Editar estabelecimento"
                            closeModal={closeModal}
                        />
                    </NavigateProvider>
                )
            }
        />
    );
};
export default UnidadesCadastrar;
