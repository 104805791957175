import { appendPrependString } from 'libs/StringUteis';
import { useCallback, useMemo } from 'react';
interface IUsuarioPermissoes {
    resourceId?: string;
    incluir?: boolean;
    editar?: boolean;
    excluir?: boolean;
    ler?: boolean;
}
interface IPerfil {
    id?: string;
    nome?: string;
    administrador?: boolean;
    permissoes?: IUsuarioPermissoes[];
}

interface IResource {
    id?: string;
    titulo?: string;
    resourceFilhos?: IResource[];
}
interface IQueryPerfil {
    perfil?: IPerfil[];
    resources?: IResource[];
}
export const useTransformData = (dataServer: IQueryPerfil) => {
    const normalizePermissao = useCallback(
        (permissoes: IUsuarioPermissoes[]): IUsuarioPermissoes[] => {
            let ids = [];
            let data = dataServer.resources.map((resource) => {
                ids.push(resource.id);
                return {
                    id: resource.id,
                    resourceId: resource.titulo,
                    incluir: false,
                    editar: false,
                    excluir: false,
                    ler: false,
                };
            });
            permissoes.forEach((permissao) => {
                const indice = ids.indexOf(permissao.resourceId);
                if (indice >= 0) {
                    data[indice] = {
                        ...data[indice],
                        incluir: permissao.incluir,
                        editar: permissao.editar,
                        excluir: permissao.excluir,
                        ler: permissao.ler,
                    };
                }
            });
            return data;
        },
        [dataServer]
    );
    const data = useMemo(() => {
        return (dataServer?.perfil || []).map((perfil) => [
            {
                tipo: 'Texto',
                width: '1fr',
                marginRight: '0',
                titulo: perfil?.nome,
            },
            ...normalizePermissao(perfil.permissoes).map((permissao) => ({
                tipo: 'Texto',
                width: 'minmax(10rem,20rem)',
                marginRight: '0',
                temaBotao: '2',
                titulo: permissao?.resourceId,
                botoes: [
                    {
                        titulo: 'Editar',
                        placeholder:
                            'Permitir que o usuário possa editar informações',
                        icone: 'icon-deslike',
                    },
                    {
                        titulo: 'Excluir',
                        placeholder:
                            'Permitir que o usuário possa excluir informações',
                        icone: 'icon-like',
                    },
                    {
                        titulo: 'Cadastrar',
                        placeholder:
                            'Permitir que o usuário possa cadastrar informações',
                        icone: 'icon-like',
                    },
                    {
                        titulo: 'Ler',
                        placeholder:
                            'Permitir que o usuário possa ler informações',
                        icone: 'icon-like',
                    },
                ],
            })),
        ]);
    }, [dataServer]);

    return data;
};
