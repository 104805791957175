import { useQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_PERFIL } from 'gql/perfil';
import { GRAPHQL_LISTAR_USUARIO_E_PERFIL } from 'gql/usuario';

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IRetriever, IQueryUserPerfil } from './index.d';
export const useLoadUser = (): IRetriever => {
    const { id } = useParams<any>();
    const hasId = id && id.length > 10;
    const variables = useMemo(() => {
        if (hasId) {
            return {
                filter: {
                    id,
                },
            };
        }
        return {};
    }, [id]);

    const query = hasId
        ? GRAPHQL_LISTAR_USUARIO_E_PERFIL
        : GRAPHQL_LISTAR_PERFIL;

    const { data: dataUser } = useQuery<IQueryUserPerfil>(query, {
        fetchPolicy: 'no-cache',
        variables,
    });

    return {
        hasId,
        id,
        perfil: dataUser?.perfil,
        usuario: dataUser?.usuario?.data?.pop(),
    };
};
