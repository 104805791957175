import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_UNIDADE_SINTETICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';

export const useRelatorioMestreUnidadeSin = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_UNIDADE_SINTETICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'CNPJ/CPF',
                        'Nome fantasia',
                        '% negociação',
                        'Qtd. coletas',
                        'Total de vendas',
                        'Total tx. retorno',
                        'Total frete',
                        'Pagar player',
                        'Modal',
                        'Taxa JogoRápido',
                        'Adiconal de frete',
                        'Receber do estabelecimento',
                        'Whatsapp financeiro',
                        'Email(s) financeiro',
                    ],
                ];

                response.relatorioUnidadesSintetico.forEach((props) => {
                    content.push([
                        props.identificador.replace(/[^0-9]/gi, ''),
                        `${props.nomeFantasia}`,
                        `${props.porcentagemNegociacao * 100}%`,
                        props.qtdeColetas,
                        formatarMoeda(props.valorProduto),
                        formatarMoeda(props.valorRetorno),
                        formatarMoeda(props.valorFrete),
                        formatarMoeda(props.totalEntregador),
                        props.modalTransporte,
                        formatarMoeda(props.taxaJogoRapido),
                        formatarMoeda(props.valorAdicionalCorrida),
                        props.valorAdicionalCorrida ? formatarMoeda(props.totalAReceberUnidade + (props.valorAdicionalCorrida || 0)) : formatarMoeda(props.valorFrete + (props.taxaJogoRapido || 0) + (props.valorRetorno || 0)),
                        props.whatsappFinanceiro || '',
                        props.emailFinanceiro || '',
                    ]);
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO ESTABELECIMENTO SINTÉTICO ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`
                    )} ( Este relatório não leva em consideração o garantido)`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );

    const handleClick = useCallback(
        (variables) => {
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return {
        handleClick,
        loading,
    };
};
