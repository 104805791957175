import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_ADICIONAR_CLIENTE } from 'gql/cliente';
import { RefObject, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IPropsNavigateForms } from '../..';
import dot from 'dot-object';
import { ICollectData } from '../../hooks/useContainerDataForm';
import { yupValidate } from '../commands/yupValidate';

interface IReturn {
    loading: boolean;
    handleSubmit: SubmitHandler<ICollectData>;
}
interface IReceiver extends ICollectData {
    formRef: RefObject<FormHandles>;
    handleNavigateForms(props: IPropsNavigateForms): void;
}
interface IMutationSaveCliente {
    adicionarCliente?: {
        id?: string;
        telefoneCelular?: string;
    };
}

export const useSubmit = (props: IReceiver, select, setLoading): IReturn => {
    const { alertConfirm, unidadeSelecionada } = useContext(AuthContext);
    const [handleAdicionarCliente, { loading }] =
        useMutation<IMutationSaveCliente>(GRAPHQL_ADICIONAR_CLIENTE);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    const handleSubmit: SubmitHandler<ICollectData> = useCallback(
        async (dataForm) => {
            try {
                if (!unidadeSelecionada || !unidadeSelecionada.id) {
                    throw new Error(
                        'Selecione o estabelecimento que deseja criar seu pedido [code 2]'
                    );
                }
                if (!dataForm.clienteId && !dataForm.nomeCompleto) {
                    throw new Error('Informe o nome do cliente');
                }

                if (
                    select &&
                    select.itemSelecionado &&
                    'telefoneCelular' in select.itemSelecionado
                ) {
                    props.handleNavigateForms({
                        next: 1,
                        current: 0,
                        success: true,
                        data: {
                            clienteId: dataForm.nomeCompleto,
                            nomeCompleto: select.defaultValue.label,
                            somarFrete: select.somarFrete,
                            ...dataForm,
                        },
                    });
                } else {
                    await yupValidate(
                        dataForm,
                        props.formRef.current.setErrors
                    );

                    const data = dot.transform(
                        {
                            unidadeId: 'unidadeId',
                            nomeCompleto: 'nomeCompleto',
                            email: 'email',
                            telefoneFixo: 'telefoneFixo',
                            telefoneCelular: 'telefoneCelular',
                        },
                        {
                            ...props,
                            ...dataForm,
                            unidadeId: unidadeSelecionada.id,
                        }
                    );

                    const cliente = await handleAdicionarCliente({
                        variables: {
                            data,
                        },
                    });
                    select.handlePushDataListCliente(
                        cliente?.data?.adicionarCliente
                    );
                }
            } catch ({ message, errors, ...rest }) {
                if (message && !errors) {
                    await alertConfirm({
                        tipo: 'erro',
                        titulo: 'Erro',
                        conteudo: message as string,
                    });
                }
            }
        },
        [select]
    );
    return { handleSubmit, loading };
};
