import { useQuery } from '@apollo/client';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios, { AxiosRequestConfig } from 'axios';
import styleMaps from 'components/atom/map/styleMaps.json';
import { COLETA_ENTREGADOR_ESCALADO } from 'gql/coleta';
import { GRAPHQL_ENTREGADOR_UPDATE_LAT_LNG } from 'gql/entregador';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, SelectMultiple, stylMap } from './styl';
type IMarker = Marker & {
    animateTo: (newPosition: google.maps.LatLng, options: any) => void;
};
interface IMap {
    fitBounds?: any;
}
interface IEntregador {
    entregadorId: string;
    entregadorLatitude?: number;
    entregadorLongitude?: number;
    destinoLatitude?: number;
    destinoLongitude?: number;
    renderMaps?: google.maps.DirectionsRenderer;
    locationName?: 'destino' | 'entregador';
    color?: 'a' | 'b';
    indice?: number;
    listPosition?: { lat: number; lng: number }[];
    entregadorNomeCompleto: string;
    entregadorOnline: boolean;
}
(google.maps.Marker as any).prototype.animateTo = function (
    newPosition,
    options,
) {
    const defaultOptions = {
        duration: 1000,
        complete: null,
        pan: null,
    };
    options = options || {};

    // complete missing options
    for (const key in defaultOptions) {
        options[key] = options[key] || defaultOptions[key];
    }

    // make sure the pan option is valid
    if (options.pan !== null) {
        if (options.pan !== 'center' && options.pan !== 'inbounds') {
            return;
        }
    }

    window.requestAnimationFrame = window.requestAnimationFrame;
    window.cancelAnimationFrame = window.cancelAnimationFrame;

    // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    this.AT_startPosition_lat = this.getPosition().lat();
    this.AT_startPosition_lng = this.getPosition().lng();
    var newPosition_lat = newPosition.lat();
    var newPosition_lng = newPosition.lng();
    var newPoint = new google.maps.LatLng(newPosition.lat(), newPosition.lng());

    if (options.pan === 'center') {
        this.map.setCenter(newPoint);
    }

    if (options.pan === 'inbounds') {
        if (!this.map.getBounds().contains(newPoint)) {
            var mapbounds = this.map.getBounds();
            mapbounds.extend(newPoint);
            this.map.fitBounds(mapbounds);
        }
    }

    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPosition_lng - this.AT_startPosition_lng) > 180) {
        if (newPosition_lng > this.AT_startPosition_lng) {
            newPosition_lng -= 360;
        } else {
            newPosition_lng += 360;
        }
    }
    const easeInOutSine = (x, t, b, c, d) => {
        return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
    };
    var animateStep = (marker, startTime) => {
        var ellapsedTime = new Date().getTime() - startTime;
        var durationRatio = ellapsedTime / options.duration; // 0 - 1
        var easingDurationRatio = durationRatio;

        easingDurationRatio = easeInOutSine(
            durationRatio,
            ellapsedTime,
            0,
            1,
            options.duration,
        );

        if (durationRatio < 1) {
            var deltaPosition = new google.maps.LatLng(
                marker.AT_startPosition_lat +
                    (newPosition_lat - marker.AT_startPosition_lat) *
                        easingDurationRatio,
                marker.AT_startPosition_lng +
                    (newPosition_lng - marker.AT_startPosition_lng) *
                        easingDurationRatio,
            );
            marker.setPosition(deltaPosition);

            // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
            if (window.requestAnimationFrame) {
                marker.AT_animationHandler = window.requestAnimationFrame(
                    function () {
                        animateStep(marker, startTime);
                    },
                );
            } else {
                marker.AT_animationHandler = setTimeout(function () {
                    animateStep(marker, startTime);
                }, 17);
            }
        } else {
            marker.setPosition(newPosition);

            if (typeof options.complete === 'function') {
                options.complete();
            }
        }
    };

    // stop possibly running animation
    if (window.cancelAnimationFrame) {
        window.cancelAnimationFrame(this.AT_animationHandler);
    } else {
        clearTimeout(this.AT_animationHandler);
    }

    animateStep(this, new Date().getTime());
};
const directions = new google.maps.DirectionsService();
export const GpsFake: FC = () => {
    const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
    const [boy, setBoy] = useState<IEntregador[]>([]);
    const [variables, setVariables] = useState<{
        googleDirections: google.maps.DirectionsRequest;
        indice: number;
    } | null>(null);
    const selectedRef = useRef(-1);
    const markersRef = useRef({} as { [key: string]: IMarker });
    const centerIsLoadedSetup = useRef(false);
    const handleSelect = useCallback(
        e => {
            let values = Array.from(
                e.target.selectedOptions,
                (option: any) => option.value,
            );
            if (values.length > 0) {
                const id = values[0];
                setBoy(prev => {
                    const clone = [...prev];

                    selectedRef.current = clone.findIndex(
                        v => v.entregadorId === id,
                    );

                    if (
                        map &&
                        selectedRef.current > -1 &&
                        clone[selectedRef.current].entregadorLatitude
                    ) {
                        const ll = new google.maps.LatLng(
                            clone[selectedRef.current].entregadorLatitude,
                            clone[selectedRef.current].entregadorLongitude,
                        );
                        map.setZoom(15);
                        map.setCenter(ll);
                    }
                    return clone;
                });
            }
        },
        [map],
    );
    const { loading } = useQuery<{
        recuperarEntregadoresPeriodoVigente?: IEntregador[];
    }>(COLETA_ENTREGADOR_ESCALADO, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data) {
                centerIsLoadedSetup.current = true;
                setBoy(
                    data.recuperarEntregadoresPeriodoVigente.map(v => {
                        v.renderMaps = new google.maps.DirectionsRenderer({
                            routeIndex: 5,
                            polylineOptions: {
                                strokeColor: 'black',
                                strokeWeight: 2,
                            },
                        });
                        v.indice = 0;
                        v.listPosition = [];
                        v.color = v.destinoLatitude ? 'a' : 'b';
                        v.locationName = v.destinoLatitude
                            ? 'destino'
                            : 'entregador';
                        return { ...v };
                    }),
                );
            }
        },
    });

    const setRoute = useCallback((clone: IEntregador[]) => {
        (async () => {
            const request: google.maps.DirectionsRequest = {
                origin: `${clone[selectedRef.current].entregadorLatitude},${
                    clone[selectedRef.current].entregadorLongitude
                }`,
                destination: `${clone[selectedRef.current].destinoLatitude},${
                    clone[selectedRef.current].destinoLongitude
                }`,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            await new Promise((resolve, reject) => {
                directions.route(request, (result, status) => {
                    if (status == 'OK') {
                        clone[selectedRef.current].indice = 0;
                        clone[selectedRef.current].listPosition = result.routes
                            .map(v =>
                                v.overview_path.map(v => ({
                                    lat: v.lat(),
                                    lng: v.lng(),
                                })),
                            )
                            .flat();

                        clone[selectedRef.current].renderMaps.setDirections(
                            result,
                        );
                        clone[selectedRef.current].renderMaps.setMap(map);
                    }
                    resolve(true);
                });
            });
        })();
    }, []);

    useEffect(() => {
        boy.forEach(b => {
            if (b.destinoLatitude < 0) {
                const lat = b.entregadorLatitude;
                const lng = b.entregadorLongitude;
                const id = b.entregadorId;

                axios({
                    url: 'http://localhost:4000/graphql',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        query: `query queryAppEntregadorUpdLatLng(
                            $entregadorId: ID!
                            $lat: Float!
                            $lng: Float!
                            $perfilId: Int!
                            $connection: String
                        ) {
                            appEntregadorUpdLatLng(
                                entregadorId: $entregadorId
                                lat: $lat
                                lng: $lng
                                perfilId: $perfilId
                                connection: $connection
                            )
                        }`,
                        variables: {
                            lat,
                            lng,
                            entregadorId: id,
                            perfilId: 4,
                            connection: 'online',
                        },
                    },
                }).catch(error => {
                    console.error(error);
                });
            }
        });
    }, [boy]);

    useEffect(() => {
        let clearTimer: (NodeJS.Timeout | null)[] = boy.map((bo, index) => {
            const timer = Math.round(20 * (Math.random() * 10));

            if (bo.listPosition && bo.listPosition.length > 0) {
                return setInterval(() => {
                    bo.entregadorLatitude = bo.listPosition[bo.indice].lat;
                    bo.entregadorLongitude = bo.listPosition[bo.indice].lng;

                    bo.indice++;
                    if (bo.listPosition.length - 1 <= bo.indice) {
                        bo.indice = 0;
                    }
                }, timer);
            }

            return null;
        });
        return () => {
            if (clearTimer && clearTimer.length)
                clearTimer.map(item => item && clearInterval(item));
        };
    }, [boy]);

    useLocation();

    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAEOaJwrZTSQ3ufTKeSXpcmpTJoyKv2Ps0',
    });

    useEffect(() => {
        if (variables && variables.indice > -1) {
            directions.route(variables.googleDirections, (result, status) => {
                if (status == 'OK') {
                    setBoy(prev => {
                        const clone = [...prev];
                        clone[variables.indice].indice = 0;
                        clone[variables.indice].listPosition = result.routes
                            .map(v =>
                                v.overview_path.map(v => ({
                                    lat: v.lat(),
                                    lng: v.lng(),
                                })),
                            )
                            .flat();

                        clone[variables.indice].renderMaps.setDirections(
                            result,
                        );
                        clone[variables.indice].renderMaps.setMap(map);

                        return clone;
                    });
                }
            });
        }
    }, [variables]);

    useEffect(() => {
        let evt: google.maps.MapsEventListener | undefined;
        if (map) {
            evt = google.maps.event.addListener(
                map,
                'click',
                function (event: any) {
                    if (selectedRef.current > -1) {
                        setBoy(prev => {
                            const clone = [...prev];
                            const { locationName: locationNamePrev } =
                                clone[selectedRef.current];
                            const locationNameNew =
                                locationNamePrev === 'entregador'
                                    ? 'destino'
                                    : 'entregador';

                            clone[selectedRef.current] = {
                                ...clone[selectedRef.current],
                                locationName: locationNameNew,
                                [`${locationNamePrev}Latitude`]:
                                    event.latLng.lat() as number,
                                [`${locationNamePrev}Longitude`]:
                                    event.latLng.lng() as number,
                            };
                            if (
                                clone[selectedRef.current].entregadorLatitude &&
                                clone[selectedRef.current].destinoLatitude
                            ) {
                                setVariables({
                                    googleDirections: {
                                        origin: `${
                                            clone[selectedRef.current]
                                                .entregadorLatitude
                                        },${
                                            clone[selectedRef.current]
                                                .entregadorLongitude
                                        }`,
                                        destination: `${
                                            clone[selectedRef.current]
                                                .destinoLatitude
                                        },${
                                            clone[selectedRef.current]
                                                .destinoLongitude
                                        }`,
                                        travelMode:
                                            google.maps.TravelMode.DRIVING,
                                    },
                                    indice: selectedRef.current,
                                });
                                // setRoute(clone);
                            }
                            return clone;
                        });
                    }
                },
            );
        }
        return () => evt && evt.remove();
    }, [map]);

    const getIcon = useCallback(({ text = 1, color = 'a' }) => {
        return `https://mt.google.com/vt/icon/text=${text}&psize=16&font=fonts/arialuni_t.ttf&color=ff330000&name=icons/spotlight/spotlight-waypoint-${color}.png&ax=44&ay=48&scale=1`;
    }, []);

    useEffect(() => {
        if (centerIsLoadedSetup.current) {
            const api: any = window?.google?.maps;

            if (map && map.fitBounds && api && api?.LatLngBounds) {
                const bounds = new api.LatLngBounds();

                boy.forEach(item => {
                    if (item.entregadorLatitude) {
                        bounds.extend(
                            new api.LatLng(
                                item.entregadorLatitude,
                                item.entregadorLongitude,
                            ),
                        );
                    }
                });
                map.fitBounds(bounds);
            }
        }
        centerIsLoadedSetup.current = false;
    }, [map, boy]);

    const onUnmount = useCallback(() => {
        setMap(undefined);
    }, []);

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    return (
        <Container>
            {boy.length > 0 && (
                <SelectMultiple
                    name="player[]"
                    multiple
                    onChange={handleSelect}>
                    {boy.map((item, index) => (
                        <option
                            key={item.entregadorId}
                            value={item.entregadorId}>
                            {index} - {item.entregadorNomeCompleto}
                        </option>
                    ))}
                </SelectMultiple>
            )}
            <GoogleMap
                mapContainerStyle={stylMap}
                options={{
                    styles: styleMaps as google.maps.MapTypeStyle[],
                }}
                center={{
                    lat: -16.6861519,
                    lng: -49.2648365,
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}>
                {boy.map((entregador, indice) =>
                    entregador && entregador.entregadorLatitude ? (
                        <Marker
                            ref={ref => {
                                if (ref && ref.marker) {
                                    markersRef.current[
                                        entregador.entregadorId
                                    ] = ref.marker as any as IMarker;
                                }
                            }}
                            animation={google.maps.Animation.DROP}
                            key={entregador.entregadorId}
                            position={{
                                lat: entregador.entregadorLatitude,
                                lng: entregador.entregadorLongitude,
                            }}
                            icon={getIcon({
                                text: `E${indice}`,
                                color: 'a',
                            })}
                            title={entregador.entregadorNomeCompleto}
                        />
                    ) : null,
                )}
            </GoogleMap>
        </Container>
    );
};
