import React, { FC, memo, useEffect } from 'react';
import { ContainerPageSignup, Grid } from '../styl';
import { defTitle } from 'assets/strings';
import { Form } from '@unform/web';
import Input from 'components/Form/Input';
import ButtonLoading from 'components/atom/ButtonLoading';
import { Link } from 'react-router-dom';
import { WarpLink } from 'pages/signin/styl';
import InputSearch from 'components/Form/InputSearch';
const PageSignupComponent: FC<any> = memo(
	({
		formRef,
		loading,
		handleSubmit,
		loadingSave,
		handleLoadBusiness,
		initialData,
	}) => {
		useEffect(() => {
			document.title = `${defTitle} - Dados pessoais`;
		});
		return (
			<ContainerPageSignup>
				<Form
					ref={formRef}
					initialData={initialData}
					onSubmit={handleSubmit}
				>
					<p className="pFatura">
						Para faturamento, precisamos de mais algumas informações
					</p>
					<Grid flexStart={0.8}>
						<div className="coluna">
							<label htmlFor="cnpj">CNPJ</label>
							<InputSearch
								loading={loading}
								action={handleLoadBusiness}
							/>
						</div>
						<div className="coluna">
							<label htmlFor="razaoSocial">Nome Fantasia</label>
							<Input
								name="razaoSocial"
								placeholder="Nome Fantasia"
							/>
						</div>
					</Grid>
					<Grid flexStart={2.6}>
						<div className="coluna">
							<label htmlFor="enderecoLogradouro">Endereço</label>
							<Input
								name="enderecoLogradouro"
								placeholder="Ex. Av. T-63"
							/>
						</div>
						<div className="coluna">
							<label htmlFor="enderecoCep">CEP</label>
							<Input
								name="enderecoCep"
								placeholder="Ex. xx.xxx-xx"
							/>
						</div>
					</Grid>
					<Grid flexStart={0.3}>
						<div className="coluna">
							<label htmlFor="enderecoNumero">Número</label>
							<Input
								name="enderecoNumero"
								placeholder="Ex. 1296"
							/>
						</div>
						<div className="coluna">
							<label htmlFor="enderecoComplemento">
								Complemento
							</label>
							<Input
								name="enderecoComplemento"
								placeholder="Ex. Sala 1"
							/>
						</div>
					</Grid>

					<div className="space-button">
						<Input name="enderecoBairro" type="hidden" />
						<Input name="cidade" type="hidden" />
						<Input name="uf" type="hidden" />
						<ButtonLoading
							loading={loadingSave}
							value="Finalizar"
						/>
					</div>
					<WarpLink>
						<Link to="/"> Já sou cadastrado</Link>
					</WarpLink>
				</Form>
			</ContainerPageSignup>
		);
	}
);
export default PageSignupComponent;
