import { FormHandles } from '@unform/core';
import { useCliente, useClienteAlterar } from 'hooks/useCliente';
import { matchTelefone } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import ComponentClienteEditar from './Component';

interface IParams {
    id: string;
}
const PageEditarCliente: FC = () => {
    // pega o id cliente da url
    const { id } = useParams<IParams>();
    const history = useHistory();
    const { data: cliente } = useCliente({ clienteId: id });
    const values: any =
        cliente && cliente.cliente.length ? cliente.cliente[0] : {};
    const { atualizaCliente, loading } = useClienteAlterar();
    const rForm = createRef<FormHandles>();
    const handleSubmit = async (data) => {
        const { setErrors } = rForm.current as FormHandles;
        try {
            await YupUtilsTry(data, setErrors, {
                nomeCompleto: yup
                    .string()
                    .min(5, 'Nome inválido')
                    .required('Nome inválido'),

                email: yup.string().email('Email inválido'),
                telefoneFixo: yup
                    .string()
                    .matches(matchTelefone, 'Formato de telefone inválido'),
                telefoneCelular: yup
                    .string()
                    .matches(matchTelefone, 'Formato de telefone inválido')
                    .required('Informe seu telefone'),
            });

            const result = await atualizaCliente({
                data,
                clienteId: id,
            });
            if (result) history.goBack();
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };
    const oProps = {
        rForm,
        values,
        handleSubmit,
        cliente,
        loading,
    };
    return <ComponentClienteEditar {...oProps} />;
};
export default PageEditarCliente;
