import { useQuery } from '@apollo/client';
import { IColeta, IQueryColeta } from 'pages/coleta/listar/hooks/useTransformColeta/index.d';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_LISTAR_COLETA } from './../../../../../gql/coleta';
import { GRAPHQL_USUARIO_PERMISSOES } from 'gql/usuario';
import { useLocalObservable } from 'mobx-react-lite';

export const useQueryData = () => {
    const { coletaId } = useParams<any>();
    const store = useLocalObservable(() => ({
        frete: 0,
        setFrete(frete) {
            this.frete = frete;
        },
        coleta: {} as IColeta,
        setColeta(coleta: IColeta) {
            this.coleta = coleta;
        },
        configuracao: [],
        setConfiguracao(configuracao) {
            this.configuracao = configuracao;
        },
        loading: true,
    }))

    useQuery<IQueryColeta & {
        recuperarConfiguracao: {
            chave: string;
            valor: string;
        }[];
    }>(
        GRAPHQL_LISTAR_COLETA,
        {
            variables: {
                filter: { id: coletaId },
            },
            onCompleted: (data) => {
                if (data && data.recuperarColeta.data.length > 0) {
                    store.setColeta(data.recuperarColeta.data[0]);
                    store.setConfiguracao(data.recuperarConfiguracao);
                    store.loading = false;
                }
            }
        }
    );

    return {
        store,
    };
};
