import { FormHandles, SubmitHandler } from '@unform/core';
import { format, startOfWeek } from 'date-fns';
import { YupUtilsCatch } from 'libs/yup/ShapeValidation';
import { createRef, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { ValidationError } from 'yup';
import { prepareDados } from './../uteis/prepareDados';
import { validacao } from './../uteis/validacao';
import { apiv2Axios } from '../../../../../services/apiv2';
import { formatarMoeda } from '../../../../../libs/NumberUtils';

interface IPropsSubmit {
    dataInicio: Date;
    dataFim: Date;
}

export const useQueryRelatorio = () => {
    const [loading, setLoading] = useState(false);
    const { handleGenerateCsv } = useCreateCsv();
    const { unidadeId, entregadorId } = useParams<any>();
    const formRef = createRef<FormHandles>();
    const { alertConfirm } = useContext(AuthContext);
    const dataStart = useMemo(() => startOfWeek(new Date()), []);
    const dataEnd = useMemo(() => new Date(), []);

    const handleSubmit: SubmitHandler<IPropsSubmit> = async (data) => {
        const { setErrors } = (formRef.current || {}) as FormHandles;

        try {
            await validacao({ data, setErrors });

            const variables = prepareDados({
                ...data,
                unidadeId,
                entregadorId,
            });
            setLoading(true);
            const resultApi = await apiv2Axios.post(`api/panel/report-machine-jogo-rapido`, variables).finally(() => {
                setLoading(false);
            });
            if(resultApi.data.status === false){
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: resultApi.data.message,
                });
                return;
            }
            const result = resultApi.data.data;
            let totalProdutos = 0;
            const content: any = [
                [
                    'Unidade',
                    'Nome entregador',
                    'Número interno',
                    'Data',
                    'Método de pagamento',
                    'Bandeira do cartão',
                    'Total',
                ],
            ];
            result.forEach((coleta) => {
                content.push([
                    coleta.unit,
                    coleta.deliverymanName,
                    coleta.codeCollect,
                    format(new Date(coleta.data), 'dd/MM/yyyy'),
                    coleta.methodPayment,
                    coleta.cardBrand,
                    formatarMoeda(coleta.total),
                ]);
                totalProdutos += parseFloat(coleta.total);
            });
            handleGenerateCsv({
                fileName: `RELATÓRIO MÁQUINA JOGO RÁPIDO - ${format(
                    new Date(),
                    `dd-MM-yyyy HH'h e 'mm'm'`
                )}`,
                content,
            });
            
        } catch (err: any) {
            if (err instanceof ValidationError) {
                YupUtilsCatch(err, setErrors);
            } else {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: err.message,
                });
            }
        }
    };

    return {
        loading,
        formRef,
        dataStart,
        dataEnd,
        maxDate: dataEnd,
        handleSubmit,
    };
};
