import { appendPrependString } from 'libs/StringUteis';
import { useCallback, useMemo } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { IProspecto } from './useQueryFilterUnit/index.d';
import { useToggleActiveUnit } from './useToggleActiveUnit';

export const useTransformProspect = (prospectos: IProspecto[]) => {
    return useMemo(
        () =>
            prospectos.map((prospecto) => {
                const enderecoLogradouro = appendPrependString(
                    prospecto.enderecoLogradouro,
                    ''
                );
                const enderecoNumero = appendPrependString(
                    prospecto?.enderecoNumero,
                    ', nº '
                );
                const enderecoComplemento = appendPrependString(
                    prospecto?.enderecoComplemento,
                    ''
                );
                const enderecoCep = appendPrependString(
                    prospecto?.enderecoCep,
                    ' '
                );
                return {
                    colunas: [
                        {
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: 'minmax(17rem, 1fr)',
                            marginRight: '0',
                            titulo: prospecto.razaoSocial,
                            subtitulo: prospecto.cnpj,
                        },
                        {
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: 'minmax(17rem, 1fr)',
                            marginRight: '0',
                            titulo: prospecto.nomeResponsavel,
                            subtitulo: prospecto.telefone,
                            descricao: prospecto.email,
                        },
                        {
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: 'minmax(22rem, 1fr)',
                            marginRight: '0',
                            titulo: `${prospecto?.enderecoBairro} - ${prospecto?.cidade.nome}`,
                            subtitulo: `${enderecoLogradouro}${enderecoNumero}`,
                            descricao: `${enderecoComplemento}${enderecoCep}`,
                        },
                        ...prospecto.respostasEnquete.map((enquete) => ({
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: '13rem',
                            marginRight: '0',
                            titulo: enquete.pergunta,
                            subtitulo: enquete.resposta,
                        })),
                        {
                            tipo: 'Texto',
                            titulo: '',
                            subtitulo: '',
                            width: '6rem',
                            marginRight: '0',
                            botoes: [
                                {
                                    titulo: 'Transformar prospecto em cliente',
                                    click: () => {},
                                    data: {},
                                    disabled: true,
                                    icone: 'icon-subir',
                                },
                                {
                                    disabled: true,
                                    titulo: true
                                        ? 'Prospecto pendente'
                                        : 'Prospecto desativado',
                                    click: () => {},
                                    data: {},

                                    icone: true
                                        ? 'icon-visualizar'
                                        : 'icon-naovisualizar',
                                },
                            ],
                        },
                    ],
                };
            }),
        [prospectos]
    );
};
