import styled from 'styled-components';
export const stylMap = {
    width: '100%',
    height: '100%',
};
export const WarpLoad = styled.div`
    position: absolute;
    width: calc(100% - 200px);
    height: 100%;
    top: 0;
    left: 150px;
    background: var(--bg-modal);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SelectMultiple = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 190px;
    background-color: white;
    z-index: 99999;
    option {
        font-size: 12px;
        font-family: var(--font);
        font-weight: var(--regular);
        background-color: #f1f1f1;
        margin-top: 2px;
        padding: 10px 0 10px 10px;
    }
`;
export const Container = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
