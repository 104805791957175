import { botaoCirculo, botaoCirculoHover } from 'routes/styl';
import styled, { css } from 'styled-components';
import {
    transitionEaseBack,
    moveOut,
    moveIn,
    fadeIn,
    fadeOut,
} from 'routes/styl';
const fillBrowser = css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
`;
export const ContainerAll = styled.div.attrs({
    className: 'containerAll',
})<any>`
    z-index: 9999999 !important;
    position: absolute;
    ${fillBrowser}
    +main {
        overflow: hidden;
        position: fixed;
        height: 100vh;
        width: 100vw;
    }
`;
export const Background = styled.div.attrs({ id: 'bg-root-modal' })<any>`
    animation: ${fadeIn} 150ms;
    &.close {
        animation: ${fadeOut} 100ms forwards;
    }
    position: fixed;
    ${fillBrowser}
    background: var(--bg-modal);
`;
export const ContainerSticky = styled.div.attrs({
    id: 'container-sticky',
})<any>`
    position: sticky;
    top: 2rem;
    z-index: 13;
    max-width: 95%;
`;
export const Container = styled.div<any>`
    position: absolute;
    ${fillBrowser}
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    padding: 40px 0px;
`;
export const Warp = styled.div`
    animation: 500ms ${moveIn} cubic-bezier(0.165, 0.84, 0.44, 1);
    &.close {
        animation: ${moveOut} 100ms forwards;
    }
    background-color: var(--bg-color);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-width: 52rem;
    margin: auto 2rem;
    width: 100%;
    &.info h1 {
        color: var(--info-cor);
    }
    &.erro h1 {
        color: var(--error-cor);
    }
    &.sucesso h1 {
        color: var(--success-cor);
    }
    &.info footer button.bg:hover {
        background-color: var(--info-cor);
    }
    /* &.info footer button.nobg:hover,
    &.erro footer button.nobg:hover,
    &.sucesso footer button.nobg:hover {
        background-color: red;
    } */
    &.erro footer button.bg:hover {
        background-color: var(--error-cor);
    }
    &.sucesso footer button.bg:hover {
        background-color: var(--success-cor);
    }
    header {
        align-items: center;
        background: none;
        box-shadow: none;
        display: flex;
        margin: 0;
        padding: 2.4rem 2.4rem 1.6rem;
        h1 {
            flex: 1;
        }
        button {
            position: relative;
            ${botaoCirculo}
            .font-ic {
                position: relative;
                z-index: 2;
            }
            &:hover::after {
                ${botaoCirculoHover}
            }
        }
    }
    section {
        padding: 0 2.4rem;
        div {
            margin-top: 1.6rem;
            input {
                border-radius: var(--def-radius);

                border: 1px solid var(--bg-hover-light);
                background-color: var() (--bg-color);
                padding: 0 1rem;
                display: flex;
                width: 100%;
                height: var(--h-input);
            }
        }
    }
    footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        margin: 0;
        padding: 2.4rem;
        button {
            font-size: var(--size-span);
            align-items: center;
            border: none;
            position: relative;
            border-radius: 0.4rem;
            cursor: pointer;
            height: 3.6rem;
            min-width: 8rem;
            outline: none;
            padding: 0 1.6rem;
            margin: 0 0 0 0.8rem;
            &.bg {
                color: var(--bg-white);
                background-color: var(--btn-underline-cor);
                transition: background-color 0.3s ease-in-out;
            }

            &.nobg {
                position: relative;
                color: var(--text-cor);
            }

            &.nobg:before {
                /* content: 'Cancelar'; */
                z-index: 3;
            }
            &.nobg:after {
                content: '';
                opacity: 0;
                transform: scale(0.5);
                background: var(--bg-color);
            }

            &.nobg:hover::after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
`;
