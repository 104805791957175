import React, { FC, memo, useCallback, useState } from 'react'; // , useContext
import 'react-calendar/dist/Calendar.css';
import Header from '../Header';
import MenuAdmin from '../MenuAdmin';
import { Container, Content, WrapContent } from './styl';

const useMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleOpenMenuMobile = useCallback(
        (open: boolean) => {
            setMenuOpen(open);
        },
        [menuOpen, setMenuOpen],
    );

    return {
        handleOpenMenuMobile,
        menuOpen,
    };
};
const TemplatesAdmin: FC<IFCProps> = ({ children }) => {
    const { handleOpenMenuMobile, menuOpen } = useMenu();

    return (
        <Container>
            <Header handleOpenMenuMobile={handleOpenMenuMobile} />
            <WrapContent>
                <MenuAdmin
                    menuOpen={menuOpen}
                    handleOpenMenuMobile={handleOpenMenuMobile}
                />
                <Content>{children}</Content>
            </WrapContent>
        </Container>
    );
};
export default memo(TemplatesAdmin);
