import { gql } from '@apollo/client';
export const GRAPHQL_LISTAR_PERIODO: any = gql`
    query periodo {
        periodo {
            id
            nome
            horaInicio
            horaFim
            ativo
            valorGarantido
        }
    }
`;
export const GRAPHQL_MEDIA_VAGAS_NOVA_ESCALA: any = gql`
    query mediaVagasNovaEscala($dataStart: String, $maxInterval: Int) {
        mediaVagasNovaEscala(dataStart: $dataStart, maxInterval: $maxInterval) {
            mediaCorridasEntregador
            vagas
            mediaEscalada
            sugestaoEscala
            mediaCorridas
            periodoId
            periodo
            data
        }
    }
`;

export const GRAPHQL_RECUPERAR_VAGAS_ESCALA: any = gql`
    query recuperarVagasEscala($filter: VagasEscalaFilter) {
        recuperarVagasEscala(filter: $filter) {
            data {
                data
                escala {
                    vagas
                    periodoId
                    escalaNome
                    vagasPreenchidas
                }
            }
            hasNextPage
        }
    }
`;
export const GRAPHQL_ALTERAR_PERIODO: any = gql`
    mutation alterarPeriodo($periodos: [InputPeriodo]) {
        alterarPeriodo(periodos: $periodos)
    }
`;
export const GRAPHQL_ADICIONAR_VAGAS_ESCALA: any = gql`
    mutation adicionarVagasEscala($data: [VagasEscalaInput!]!) {
        adicionarVagasEscala(data: $data)
    }
`;
