// eslint-disable-next-line no-use-before-define
import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentNotificacaoPush: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Notificação push`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Enviar'}
            titleModal={'Notificação'}
            message={
                'Digite uma mensagem para enviar a um player por notificação push.'
            }
        >
            <FormGenerator
                childs={[
                    {
                        type: 'radio',
                        name: 'mensagem',
                        options: [
                            {
                                id: 'mensagem1',
                                value:
                                    'Sua atenção está sendo requisitada no app JogoRápido.',
                                label:
                                    'Sua atenção está sendo requisitada no app JogoRápido.',
                            },
                            {
                                id: 'mensagem2',
                                value:
                                    'Seu comprovante de endereço foi rejeitado',
                                label:
                                    'Seu comprovante de endereço foi rejeitado',
                            },
                            {
                                id: 'mensagem3',
                                value: 'Seus documentos foi rejeitado',
                                label: 'Seus documentos foi rejeitado',
                            },
                        ],
                    },
                ]}
            />
        </SimplesForm>
    );
});
export default ComponentNotificacaoPush;
