import React, { FC, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { ContainerPageSignin, WarpLink } from '../../signin/styl';
import Input from 'components/Form/Input';
import { defTitle } from 'assets/strings';
import ButtonLoading from 'components/atom/ButtonLoading';
const ComponentInformEmailView: FC<any> = ({
	initialData,
	formRef,
	cacheEmail,
	loading,
	handleSubmit,
	handlePageCode,
}) => {
	useEffect(() => {
		document.title = `${defTitle} - Nova senha`;
	});
	return (
		<ContainerPageSignin>
			<h1>Recuperar senha?</h1>
			<p>
				Não se preocupe, para recuperar sua senha basta informar seu{' '}
				<b>email ou celular</b> e siga as instruções que receber via{' '}
				<b>email ou celular.</b>
			</p>
			<Form
				initialData={initialData}
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<label htmlFor="email">E-mail ou celular</label>
				<Input name="email" defaultValue={cacheEmail} />
				<WarpLink>
					<a href="/" onClick={handlePageCode}>
						Já possuo código
					</a>
				</WarpLink>
				<div className="space-button">
					<ButtonLoading value="Continuar" loading={loading} />
				</div>
				<WarpLink>
					<Link to="/">Lembrei minha senha</Link>
				</WarpLink>
			</Form>
		</ContainerPageSignin>
	);
};
export default memo(ComponentInformEmailView);
