import React, {
	FC,
	useState,
	useEffect,
	useCallback,
	useMemo,
	createRef,
} from 'react';
import { Container, Input, WarpInputs, LabelError } from './styl';
const tmpValues = new Array(5).fill('');
const tmpTotal = tmpValues.length - 1;

const FormCodeInputs: FC<any> = ({ updateInputCode, clearError, error }) => {
	const inputsRef = useMemo(
		() => tmpValues.map(() => createRef<HTMLInputElement>()),
		[]
	);
	const [values, setValues] = useState<string[]>(tmpValues);
	const [index, setIndex] = useState(0);
	useEffect(() => {
		inputsRef[index].current.focus();
		updateInputCode(values.join(''));
	}, [index, inputsRef, updateInputCode, values]);
	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			e.preventDefault();
			const { form } = e.target as any;
			const indice = Array.prototype.indexOf.call(form, e.target) - 1;
			let copy = [...values];
			switch (e.keyCode) {
				case 8:
				case 46:
					copy[indice] = '';
					setIndex(Math.max(0, indice - 1));
					setValues(copy);
					break;
				default:
					if (e.key.match(/[0-9{1}]/gi)) {
						copy[indice] = e.key;
						setIndex(Math.min(tmpTotal, indice + 1));
						setValues(copy);
					}
					break;
			}
		},
		[values]
	);
	const handleFocus = useCallback(
		(_e: FocusEvent) => {
			if (!!error) {
				clearError();
			}
		},
		[clearError, error]
	);
	return (
		<Container>
			<WarpInputs erro={error}>
				{values.map((_v, key) => (
					<div key={`${_v || '@'}-${key}`}>
						<Input
							index={key}
							onFocus={handleFocus}
							clearError={clearError}
							onKeyDown={handleKeyDown}
							ref={inputsRef[key]}
							defaultValue={_v}
							maxLength="1"
							autoCorrect="off"
							autoComplete="off"
							autoCapitalize="off"
							type="tel"
							erro={!!error}
						/>
					</div>
				))}
			</WarpInputs>
			<LabelError erro={error}>{error}</LabelError>
		</Container>
	);
};
export default FormCodeInputs;
