import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_CONFIGURACAO } from 'gql/configuracao';

import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import ComponentClienteEditar from './Component';
import { useMutationConfigDefault } from './hooks/useMutationConfigDefault';
import { yupValidationProps } from './validation';
import { useConfiguracaoContext } from 'contexts/configuracao.context/hook.context';
import { AuthContext } from 'routes/auth.context';
export interface IConfiguracao {
    chave: string;
    valor: string;
}
export interface IModalTransporte {
    id: number;
    nome: string;
    valorKm: number;
    valorKmRetorno: number;
    ativo: boolean;
    txMin: number;
    txMinRetorno: number;
    txRetorno: number;
}
const PageConfiguracaoPadrao: FC = () => {
    const { configuracao: defaultValues, loadingData } =
        useConfiguracaoContext();
    const { atualizaConfiguracaoSistema, loading } = useMutationConfigDefault();
    const rForm = createRef<FormHandles>();

    const handleSubmit = async (data: any) => {
        const { setErrors } = rForm.current as FormHandles;

        const listTransformNumber = [
            'Unidade_TaxaRetorno_Valor_Moto',
            // 'Unidade_TaxaRetorno_Valor_Carro',
            'Frete_Adicional_Km_Moto',
            'Frete_Adicional_Km_Moto_Retorno',
            'Valor_Adicional_Corrida',
            'Valor_Adicional_Corrida_Entregador',
            // 'Frete_Adicional_Km_Carro',
            'Frete_TaxaMinima_Valor_Moto',
            'Frete_TaxaMinima_Valor_Moto_Retorno',
            'Bloquear_Sair_Escala',
            'Mostrar_Aviso_Alto_Fluxo',
            // 'Frete_TaxaMinima_Valor_Carro',
            'App_Distancia_CheckInOut_Cliente_Metros',
            'App_Distancia_CheckInOut_Unidade_Metros',
            'DistanciaMaxima_Chamar_Player_km',
            'DistanciaMaxima_Chamar_Player_km_Maximo',
            'Fatura_Percentagem_Negociacao',
            'Fatura_QtdDia_Vencimento',
            'Frete_Adicional_Km',
            'Entregador_Qtd_Chamadas_Nao_Atendidas',
            'Frete_DistanciaMinima_Km',
            'Frete_DistanciaMaxima_Km',
            'Coleta_MargemErro_Checkin',
            'Entregador_Espera_Estabelecimento_Minutos',
        ];
        listTransformNumber.forEach(key => {
            if (data[key] && typeof data[key] === 'string') {
                data[key] = Number(data[key].replace(/[^0-9|\.]/gi, ''));
            }
        });

        try {
            await YupUtilsTry(data, setErrors, yupValidationProps);

            atualizaConfiguracaoSistema(data);
        } catch (err) {
            console.error(err);
            YupUtilsCatch(err, setErrors);
        }
    };

    const oProps = {
        rForm,
        defaultValues,
        loading,
        loadingData: !(
            !loadingData &&
            defaultValues['Frete_TaxaMinima_Valor'] &&
            Object.keys(defaultValues).length > 0
        ),
        handleSubmit,
    };
    return <ComponentClienteEditar {...oProps} />;
};
export default PageConfiguracaoPadrao;
