import styled from 'styled-components';

export const Icon = styled.div<any>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    font-size: ${({ size }) => size};
    color: ${({ color }) => color};
    ${({ marginLeft }) => (marginLeft ? `margin-left:${marginLeft}px;` : '')}
    ${({ marginRight }) =>
        marginRight ? `margin-right:${marginRight}px;` : ''}
    text-align: center;
    &::before {
        position: relative;
        z-index: 2;
    }
`;
