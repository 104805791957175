import ListTemplate from 'components/template/ListTemplate';

import React, { FC } from 'react';
import { useQueryFilterUnit } from './hooks/useQueryFilterUnit';
import { useHeaderUnit } from './hooks/useHeaderUnit';
import { useTransformUnit } from './hooks/useTransformUnit';
import { useQueryPermissions } from 'pages/coleta/listar/hooks/useQueryPermissions';

const PageListUnit: FC<any> = () => {
    const props = useQueryFilterUnit();

    const permissions = useQueryPermissions();
    const colunas = useHeaderUnit(permissions);
    const data = useTransformUnit(props.response, permissions);

    return (
        <ListTemplate
            browserTitle="Empresa"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default PageListUnit;
