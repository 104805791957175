import CircularLoading from 'components/atom/Loading/CircularLoading';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC } from 'react';
import { useQueryShippingAnotaAi } from './hooks/useQueryShippingIAnotaAi';
import { Container, Header, WarpMap } from './styl';
export interface IPropsNavigateForms {
    next: number;
    current: number;
    success: boolean;
    data?: any;
}
export const PageNavegarCadastrarColetaAnotaAi: FC = () => {
    const { pedidos, handleSubmit, loading } = useQueryShippingAnotaAi();
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Anota Ai</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {loading ? (
                        <div className="warp-loading">
                            <CircularLoading
                                size={3}
                                show={true}
                                primaryColor="var(--bg-hover-light)"
                                secondaryColor="var(--btn-underline-cor)"
                            />
                        </div>
                    ) : (
                        (pedidos || []).map(item => (
                            <WarpMap
                                key={`${item.pedido_delivery_id}-${item.cep}-${item.latitude}-${item.longitude}`}>
                                <h1>
                                    <span>
                                        <span>
                                            {item.cliente} ({' '}
                                            {item.pedido_delivery_id} )
                                        </span>
                                        <p>{item.telefone_contato}</p>
                                    </span>
                                </h1>
                                <div className="hstack">
                                    <button
                                        className="add"
                                        onClick={() =>
                                            handleSubmit({
                                                action: 'capture',
                                                data: item,
                                            })
                                        }>
                                        CAPTURAR CORRIDA
                                    </button>
                                    <button
                                        className="remove"
                                        onClick={() =>
                                            handleSubmit({
                                                action: 'cancel',
                                                data: item,
                                            })
                                        }>
                                        REMOVER
                                    </button>
                                </div>
                            </WarpMap>
                        ))
                    )}
                </Container>
            )}
        />
    );
};
