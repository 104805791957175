import { useCallback, useEffect, useMemo, useState } from 'react';
interface IReceiver {
    id?: String;
    nomeCompleto?: String;
    telefoneCelular?: String;
}
export const useSelectAddItem = (
    loadOptions,
    initialData: IReceiver[] | null
) => {
    const [selectLine, setSelectLine] = useState([]);
    const handleAddItem = useCallback(({ key }) => {
        setSelectLine((prevData) => [...prevData, key]);
    }, []);
    const handleRemoveItem = useCallback(({ key }) => {
        setSelectLine((data) => {
            const rest = [...data];
            rest.splice(key, 1);
            return rest;
        });
    }, []);

    useEffect(() => {
        if (initialData !== null) {
            if (initialData.length > 0) {
                const result = initialData?.map(
                    ({ id, nomeCompleto: nome, telefoneCelular }, key) => ({
                        value: id,
                        key: key,
                        label: `${nome} ${
                            telefoneCelular ? ' - ' + telefoneCelular : ''
                        }`,
                    })
                );
                setSelectLine(result);
            } else setSelectLine([null]);
        }
    }, [initialData]);

    const selects = useMemo(() => {
        const total = selectLine.length - 1;

        return selectLine.map((defaultValue, key) => {
            const icone = key === total ? 'icon-adicionar' : 'icon-lixeira2';

            return {
                gridFirstWidth: 15,
                items: [
                    {
                        label: 'Usuário',
                        type: 'select-async',
                        placeholder: 'Pesquisar por usuário...',
                        name: `usuarioId[${key}]`,
                        loadOptions,
                        defaultValue,
                    },
                    {
                        type: 'button-icon',
                        icone,
                        dataClicked: { key },
                        handleClick:
                            key === total ? handleAddItem : handleRemoveItem,
                    },
                ],
            };
        });
    }, [selectLine, handleAddItem]);
    return selects;
};
