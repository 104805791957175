import { gql } from '@apollo/client';
const propsUnit = `
    id
    geoLatitude
    geoLongitude
    razaoSocial
    nomeFantasia
    identificador
    prefixoPedido
    nomeResponsavel
    enderecoCep
    enderecoNumero
    enderecoBairro
    enderecoLogradouro
    enderecoComplemento
    enderecoPontoReferencia
    enderecoQuadra
    enderecoLote
    ativo
    porcentagemNegociacao
    tipoPagamento
    saldo{
        total
    }
    unidadeFatura {
        adimplente
    }
    unidadeContatos {
        tipo
        contato
    }
    unidadeParametro{
        chave
        valor
    }
`;
export const GRAPHQL_ADICIONAR_UNIDADE = gql`
    mutation adicionarUnidade($data: UnidadeInput) {
        adicionarUnidade(data: $data)
    }
`;
export const GRAPHQL_EDITAR_UNIDADE = gql`
    mutation editarUnidade($data: UnidadeEditInput) {
        editarUnidade(data: $data)
    }
`;

export const GRAPHQL_LISTAR_UNIDADE: any = gql`
    query unidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            hasNextPage
            data {
                ${propsUnit}
            }
        }
    }
`;
export const GRAPHQL_UNIDADE_PARAMETROS: any = gql`
    query recuperarUnidadeParametros($unidadeId: ID) {
        recuperarUnidadeParametros(unidadeId: $unidadeId) {
            chave
            valor
        }
    }
`;
export const GRAPHQL_LISTAR_UNIDADE_SEARCH: any = gql`
    query unidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            hasNextPage
            data {
                id
                nomeFantasia
                identificador
                unidadeParametro{
                    chave
                    valor
                }
            }
        }
    }
`;

export const GRAPHQL_UNIDADE_SUBSCRIPTION: any = gql`
    subscription unidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            data{
                ${propsUnit}
            }
            action
        }
    }
`;

export const GRAPHQL_BUSCAR_UNIDADE: any = gql`
    query unidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            id
            razaoSocial
            nomeFantasia
            logotipo
            geoLatitude
            geoLongitude
            unidadeParametro{
                chave
                valor
            }
        }
    }
`;
export const GRAPHQL_UNIDADE: any = gql`
    query unidadeFilter($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            hasNextPage
            data {
                contaBancaria {
                    id
                    titularNome
                    titularCpf
                    contaBanco {
                        id
                        nome
                    }
                    contaAgencia
                    contaAgenciaDv
                    contaNumero
                    contaNumeroDv
                    contaTipo
                    pixTipo
                    pixConta
                }
                unidadeParametro{
                    chave
                    valor
                }
            }
        }
    }
`;
export const GRAPHQL_TIPO_PAGAMENTO: any = gql`
    query unidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            hasNextPage
            data {
                tipoPagamento
            }
        }
    }
`;
export const GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE: any = gql`
    mutation alternanciaAtivoUnidade($unidadeId: ID, $ativo: Boolean) {
        alternanciaAtivoUnidade(unidadeId: $unidadeId, ativo: $ativo)
    }
`;
export const GRAPHQL_ENVIO_NOTIFICACAO_UNIDADE_FATURA: any = gql`
    mutation enviarNotificacaoUnidadeFatura(
        $faturaId: ID!
        $mensagem: String!
        $tipoNotificacao: Float!
        $tipoEnvioNotificacao: Float!
    ) {
        enviarNotificacaoUnidadeFatura(
            faturaId: $faturaId
            mensagem: $mensagem
            tipoNotificacao: $tipoNotificacao
            tipoEnvioNotificacao: $tipoEnvioNotificacao
        )
    }
`;
export const GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE_FATURA: any = gql`
    mutation alternanciaAdimplenteUnidadeFatura(
        $faturaId: ID!
        $adimplente: Boolean!
    ) {
        alternanciaAdimplenteUnidadeFatura(
            faturaId: $faturaId
            adimplente: $adimplente
        )
    }
`;
export const GRAPHQL_CONFIGURACOES_UNIDADE: any = gql`
    mutation configuracaoUnidade(
        $unidadeId: ID!
        $data: UnidadeConfiguracaoInput!
    ) {
        configuracaoUnidade(unidadeId: $unidadeId, data: $data)
    }
`;
export const GRAPHQL_CONFIGURACOES_UNIDADE_PARAMETROS: any = gql`
    mutation salvarUnidadeParametros(
        $unidadeId: ID
        $data: [UnidadeParametrosInput]
    ) {
        salvarUnidadeParametros(unidadeId: $unidadeId, data: $data)
    }
`;
export const GRAPHQL_ATUALIZAR_UNIDADE_CONTA: any = gql`
    mutation bancoUnidade($unidadeId: ID!, $data: UnidadeBancoInput!) {
        bancoUnidade(unidadeId: $unidadeId, data: $data)
    }
`;
export const GRAPHQL_UNIDADE_INSERIR_SALDO: any = gql`
    mutation alterarTipoPagamentoUnidade(
        $unidadeId: ID
        $card: String
        $valor: Float
    ) {
        inserirSaldoUnidade(unidadeId: $unidadeId, card: $card, valor: $valor)
    }
`;

export const GRAPHQL_ATUALIZAR_UNIDADE_TIPO_PAGAMENTO: any = gql`
    mutation alterarTipoPagamentoUnidade(
        $unidadeId: ID!
        $tipoPagamento: TipoPagamento!
    ) {
        alterarTipoPagamentoUnidade(
            unidadeId: $unidadeId
            tipoPagamento: $tipoPagamento
        )
    }
`;
export const GRAPHQL_LISTAR_UNIDADE_IDENTIFICADORES: any = gql`
    query unidadeFilter($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            hasNextPage
            data {
                id
                identificador
                nomeFantasia
            }
        }
    }
`;
