import { useQuery } from '@apollo/client';
import { GRAPHQL_COLETA_AGRUPAVEL } from 'gql/coleta';
import { useParams } from 'react-router-dom';
interface IColetaAgrupavel {
    value: string;
    label: string;
}
export const useQuerySubscriptionData = () => {
    const { coletaId } = useParams<any>();
    const { data, loading } = useQuery<{
        agrupavelColeta?: IColetaAgrupavel[];
    }>(GRAPHQL_COLETA_AGRUPAVEL, {
        fetchPolicy: 'no-cache',
        variables: {
            coletaId,
        },
    });

    return { loading, data: data?.agrupavelColeta || [] };
};
