import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import SuperFiltro from 'components/SuperFiltro';
import TableHeader from 'components/Table/Header';
import { TableWrapper } from 'components/Table/Header/styl';
import TableTd from 'components/Table/Td';
import React, { FC, memo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import { WarpHeaderFixo } from 'routes/admin/partial/template/styl';
import { ContentPage, WarpLoading, Spacer } from './styl';
interface IListTemplate {
    handleSearch: any;
    addFilterDate?: boolean;
    hasMore: boolean;
    loading: boolean;
    dataSelectHeader: any;
    onPagination: any;
    colunas: any;
    data: any;
    browserTitle: string;
    disableFilter?: boolean;
    total?: number;
}
const ListTemplate: FC<IListTemplate> = memo(
    ({
        handleSearch,
        addFilterDate = false,
        browserTitle = '',
        hasMore,
        loading,
        dataSelectHeader,
        onPagination,
        total,
        colunas,
        data,
        disableFilter,
    }) => {
        useEffect(() => {
            document.title = `${defTitle} - ${browserTitle}`;
        }, []);

        useEffect(() => {
            ReactTooltip.rebuild();
        }, [colunas, data]);

        const tableClasses = ['fix-container'];

        if (!disableFilter) {
            tableClasses.push('has-filter');
        }

        if (addFilterDate) {
            tableClasses.push('has-filter-date');
        }

        return (
            <>
                <WarpHeaderFixo height={!disableFilter ? 11 : 7}>
                    {!disableFilter && (
                        <SuperFiltro
                            loadingSearch={loading}
                            onSearch={handleSearch}
                            addFilterDate={addFilterDate}
                            dataSelect={dataSelectHeader}
                            disableFutureDates={true}
                        />
                    )}
                </WarpHeaderFixo>
                <ContentPage>
                    <TableWrapper>
                        <TableHeader
                            className={tableClasses.join(' ')}
                            colunas={colunas}
                        />
                        <InfiniteScroll
                            style={{
                                minHeight: 'calc(100vh - 30rem)',
                            }}
                            dataLength={data.length}
                            next={onPagination}
                            loader={
                                <WarpLoading className={loading ? '' : 'close'}>
                                    <CircularLoading
                                        show={loading}
                                        size={2}
                                        sizeBorder={3}
                                        secondaryColor={'var(--text-cor)'}
                                        primaryColor={'var(--bg-hover-light)'}
                                    />
                                </WarpLoading>
                            }
                            hasMore={hasMore}>
                            {data.map(
                                (
                                    {
                                        key,
                                        colunas,
                                        lineClassName,
                                        linePropsClassName = {},
                                    },
                                    indice,
                                ) => (
                                    <TableTd
                                        className={`fix-container ${lineClassName}`}
                                        propsClassName={linePropsClassName}
                                        colunas={colunas}
                                        key={`${key}-${indice}`}
                                    />
                                ),
                            )}
                        </InfiniteScroll>
                    </TableWrapper>
                </ContentPage>

                <ReactTooltip
                    html={true}
                    backgroundColor="var(--btn-admin-icone)"
                    id="tooltipBotoesAcao"
                />
                <Spacer />
            </>
        );
    },
);
export default ListTemplate;
