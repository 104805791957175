import { StatusID } from 'pages/coleta/listar/constants/status-name-2-id';
import {
    RECUPERAR_STATUS_COLETA,
    STATUS_COLETA_TOGGLE_ATIVO,
} from './../../../../../gql/coleta';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
interface IStatus {
    ativo: boolean;
    corBackground: string;
    corForeground: string;
    dataAlteracao: string;
    id: number;
    statusId: number;
    nome: string;
    nomeCompleto: string | null;
    qtdxchamouplayer: number;
}
interface IQuery {
    statusColeta?: IStatus[];
}
export const useClientColeta = () => {
    const { coletaId } = useParams<any>();
    const { alertConfirm } = useContext(AuthContext);
    const [handleToggleColetaStatus, { loading: loadingUpdate }] = useMutation(
        STATUS_COLETA_TOGGLE_ATIVO,
        {
            refetchQueries: [
                { query: RECUPERAR_STATUS_COLETA, variables: { coletaId } },
            ],
        }
    );
    const handleToggleStatus = useCallback(
        async ({ statusId, dataAlteracao, ativo }) => {
            try {
                await handleToggleColetaStatus({
                    variables: {
                        coletaId,
                        statusId,
                        dataAlteracao,
                        ativo: !ativo,
                    },
                });
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        []
    );
    const { data, loading, error } = useQuery<IQuery>(RECUPERAR_STATUS_COLETA, {
        variables: { coletaId },
    });

    return {
        handleToggleStatus,
        statusColeta: (data?.statusColeta || []).map((result) => {
            const hora = result.dataAlteracao.substring(19, 10);
            const nomeCompleto = result.nomeCompleto || 'player não localizado';
            return { ...result, nomeCompleto, hora };
        }),
        isLastedCollectCanceled:
            (data?.statusColeta || []).findIndex(({ id }) =>
                [StatusID.CANCELADO, StatusID.CONCLUIDO].includes(+id)
            ) === -1,
        loadingUpdate,
        loading,
        error,
    };
};
