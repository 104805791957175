import { useMutation } from '@apollo/client';
import { GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE } from 'gql/unidade';
import { useCallback } from 'react';

export const useToggleActiveUnit = () => {
    const [toggleActive, { loading: loadingToogleActive }] = useMutation(
        GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE
    );
    const handleToogleActive = useCallback(
        ({ id, ativo }) => {
            toggleActive({
                variables: { unidadeId: id, ativo: !ativo },
            });
        },
        [toggleActive]
    );
    return {
        handleToogleActive,
        loadingToogleActive,
    };
};
