import React, { FC, useCallback, useEffect, useState } from 'react';
import { Context } from './context';
import { useQuery, useSubscription } from '@apollo/client';
import { IConfiguracao } from 'types/modules/configuracao';
import {
    CONFIGURACAO_SUBSCRIPTION,
    GRAPHQL_CONFIGURACAO,
} from 'gql/configuracao';
import { IModalTransporte } from 'pages/unidades/modal/configuracao-padrao';
import { useClientApollo } from 'routes/hooks/useClientApollo';
export const ProviderConfiguracao: FC<any> = ({ children }) => {
    const [configuracao, setConfiguracao] = useState<{
        [key: string]: string;
    }>(null);
    const { data, loading: loadingData } = useQuery<{
        recuperarConfiguracao?: IConfiguracao[];
        recuperarModalTransporte?: IModalTransporte[];
    }>(GRAPHQL_CONFIGURACAO, {
        fetchPolicy: 'no-cache',
    });
    const { client } = useClientApollo();
    useEffect(() => {
        if (data?.recuperarConfiguracao?.length > 0) {
            const values: {
                [key: number]: string;
            } = {};
            data?.recuperarConfiguracao?.forEach(({ valor, chave }) => {
                values[chave.replace(/::/gi, '_')] = valor;
            });
            const moto = data?.recuperarModalTransporte.find(v => v.id === 1);
            values['Frete_TaxaMinima_Valor_Moto'] = moto?.txMin;
            values['Frete_TaxaMinima_Valor_Moto_Retorno'] = moto?.txMinRetorno;
            values['Unidade_TaxaRetorno_Valor_Moto'] = moto?.txRetorno;
            values['Frete_Adicional_Km_Moto'] = moto?.valorKm;
            values['Frete_Adicional_Km_Moto_Retorno'] = moto?.valorKmRetorno;

            setConfiguracao(values);
        }
    }, [data]);
    const actionSetConfiguracao = useCallback(
        async (configuracao: { [key: string]: string }[]) => {
            const values: {
                [key: number]: string;
            } = {};
            const result: {
                data: {
                    recuperarModalTransporte: IModalTransporte[];
                    recuperarConfiguracao: IConfiguracao[];
                };
            } = await client.query({
                query: GRAPHQL_CONFIGURACAO,
                fetchPolicy: 'no-cache',
            });
            if (
                result?.data?.recuperarModalTransporte?.length > 0 &&
                result?.data?.recuperarConfiguracao?.length > 0
            ) {
                result?.data?.recuperarConfiguracao?.forEach(
                    ({ valor, chave }) => {
                        values[chave.replace(/::/gi, '_')] = valor;
                    },
                );
                const moto = result?.data?.recuperarModalTransporte.find(
                    v => v.id === 1,
                );
                values['Frete_TaxaMinima_Valor_Moto'] = moto?.txMin;
                values['Frete_TaxaMinima_Valor_Moto_Retorno'] =
                    moto?.txMinRetorno;
                values['Unidade_TaxaRetorno_Valor_Moto'] = moto?.txRetorno;
                values['Frete_Adicional_Km_Moto'] = moto?.valorKm;
                values['Frete_Adicional_Km_Moto_Retorno'] =
                    moto?.valorKmRetorno;
            }
            setConfiguracao(values);
        },
        [setConfiguracao],
    );
    useSubscription<any>(CONFIGURACAO_SUBSCRIPTION, {
        variables: {},
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData.data.configuracao) {
                actionSetConfiguracao(subscriptionData.data.configuracao);
            }
        },
    });
    return (
        <Context.Provider
            value={{
                actionSetConfiguracao,
                configuracao,
                loadingData,
            }}>
            {children}
        </Context.Provider>
    );
};
