
import JSON_DATA_FILTER from '../../data/filter.json';
import { apiv2Axios } from 'services/apiv2';
import { useEffect, useMemo, useState } from 'react';
import { usePaginationAndFilterV2 } from 'components/template/ListTemplate/hooks/usePaginationAndFilterV2';
export const useQueryFilter = () => {
    const [filter, setFilter] = useState({} as any);
    const [page, setPage] = useState(1);
    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);
    const props = usePaginationAndFilterV2({
        dataKeyName: null,
        keysQueryVariavel: [
            'fullName',
        ],
        onSearchComplete: (data) => {
            setFilter(data);
        },
        onScrollInfinite: (page) => {
            setPage(page);
        }
    });

    useEffect(() => {
        props.setLoading(true);
        apiv2Axios.get(`api/panel/deliveryman-list?fullName=${filter.fullName ?? ''}&page=${page}&perPage=${props.meta.perPage}`).then((response) => {
            props.onCompleted(response.data);
        }).catch((error) => {
            props.onError(error);
        }).finally(() => {
            props.setLoading(false);
        });
    }, [filter, page]);

    return { ...props, dataSelectHeader };
};
