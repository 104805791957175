import React, {
    FC,
    useContext,
    useState,
    useCallback,
    useRef,
    useEffect,
} from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { Warp, GroupRoot } from './styl';
import { Background, ContainerSticky } from '../AlertConfirm/styl';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
const Modal: FC<IModal> = ({ render }) => {
    const warpRef = useRef(null);
    const { signed } = useContext(AuthContext);
    const [animationClose, setAnimationClose] = useState(false);
    const history = useModalHistory();
    const closeModal = useCallback((_e = null) => {
        if (_e && _e.preventDefault) {
            _e.preventDefault();
        }
        setAnimationClose(true);
    }, []);
    const _animationend = useCallback(
        (_e) => {
            if (animationClose) {
                history.push('');
            }
        },
        [animationClose, history]
    );
    useEffect(() => {
        if (warpRef.current && warpRef.current.addEventListener) {
            warpRef.current.addEventListener('animationend', _animationend);
        }
        return () => {
            if (warpRef.current && warpRef.current.addEventListener) {
                warpRef.current.removeEventListener(
                    'animationend',
                    _animationend
                );
            }
        };
    });
    const handlerClick = useCallback((event) => {
        if (
            event.target &&
            event.target.parentNode &&
            event.target.parentNode.id === 'group-root-modal'
        ) {
            // closeModal();
        }
    }, []);
    if (signed) {
        return (
            <GroupRoot>
                <Background className={animationClose ? 'close' : ''} />
                <ContainerSticky onClick={handlerClick}>
                    <Warp
                        ref={warpRef}
                        className={animationClose ? 'close' : ''}
                    >
                        {render({ closeModal })}
                    </Warp>
                </ContainerSticky>
            </GroupRoot>
        );
    }
    return <Redirect to={'/'} push={false} />;
};
export default Modal;
