import { cssButtonCircular } from 'components/Table/Columns/styl';
import styled from 'styled-components';
import Icone from 'components/Icone';

export const Container = styled.div`
    cursor: pointer;
    margin-right: 2rem;
    padding: 2.4rem 0 2rem;
    button {
        margin-left: 1rem;
        ${cssButtonCircular}
    }
`;
export const IconMenu = styled(Icone)<any>``;
