import { useMutation } from '@apollo/client';
import { GRAPHQL_ALTERAR_PERIODO } from 'gql/periodo';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useMutationPeriodo = () => {
    const history = useHistory();

    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaPeriodo, { loading }] = useMutation(
        GRAPHQL_ALTERAR_PERIODO
    );
    const atualizaPeriodo = useCallback(async (response) => {
        const periodos = [];
        response.horaInicio.forEach((horaInicio, indice) => {
            const horaFim = response.horaFim[indice];
            const valorGarantido = Number(
                response.valorGarantido[indice].replace(/[^0-9\.]/gi, '')
            );
            periodos.push({ valorGarantido, horaInicio, horaFim, id: indice });
        });

        try {
            await handleAtualizaPeriodo({
                variables: {
                    periodos,
                },
            });
            alertConfirm({
                tipo: 'info',
                titulo: 'Sucesso',
                conteudo:
                    'Informações de configurações atualizado com sucesso!',
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message,
            });
        }
    }, []);
    return {
        atualizaPeriodo,
        loading,
    };
};
