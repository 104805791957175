import { addColumn } from './../../uteis/addColumn';
import { useLazyQuery } from '@apollo/client';
import { format, parse } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_SINTETICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';

export const useRelatorioEstabelecimentoSintetico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_SINTETICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Data',
                        'Período',
                        'Modal',
                        'Quant. entregas',
                        'Produto',
                        // 'Frete',
                        // 'Taxa Jr ( % )',
                        // 'Taxa Jr ( valor )',
                        '',
                        'Coluna',
                        'Valor',
                    ],
                ];
                let totalCorridas = 0;
                let totalFrete = 0;
                let totalTaxaJr = 0;
                let totalProduto = 0;

                response.relatorioEntregasAnaliticoPeriodoDiaSintetico.forEach(
                    (props) => {
                        content.push([
                            format(
                                parse(props.data, 'yyyy-MM-dd', new Date()),
                                `dd/MM/yyyy`
                            ),
                            props.periodoNome,
                            props.modalTransporte,
                            props.qtdeColetas,
                            formatarMoeda(props.valorProduto),
                            // formatarMoeda(props.totalEntregador),
                            // `${props.porcentagemNegociacao}%`,
                            // formatarMoeda(props.taxaJogoRapido),
                            '',
                        ]);

                        totalCorridas += props.qtdeColetas;
                        totalFrete += props.totalEntregador;
                        totalTaxaJr += props.taxaJogoRapido;
                        totalProduto += props.valorProduto;
                    }
                );
                addColumn({
                    content,
                    columnStart: 9,
                    itens: [
                        {
                            label: 'Frete Jogo Rápido',
                            value: formatarMoeda(totalFrete + totalTaxaJr),
                        },
                        {
                            label: 'Quant. entregas',
                            value: totalCorridas,
                        },
                        // {
                        //     label: 'Entregadores',
                        //     value: formatarMoeda(totalFrete),
                        // },
                        // {
                        //     label: 'Taxa Jr',
                        //     value: formatarMoeda(totalTaxaJr),
                        // },
                        {
                            label: 'Vendas totais',
                            value: formatarMoeda(totalProduto),
                        },
                    ],
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO SINTÉTICO ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`
                    )}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );

    const handleClick = useCallback(
        (variables) => {
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return {
        handleClick,
        loading,
    };
};
