import styled from 'styled-components';
export const ContainerScroll = styled.div`
    overflow-x: scroll;
    max-width: calc(100vw - 3rem);
`;
export const Container = styled.div`
    width: calc(100% - 1.5rem);
    height: calc(100vh - 18rem);
    max-height: calc(100vh - 18rem);
    position: sticky;
    top: 1.5rem;
    border-radius: var(--def-radius);
    text-align: center;
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: var(--bg-white);
    border-radius: var(--def-radius);
    z-index: 10;
    padding: 1rem;
     .colunas {
        display: grid;
        grid-template-columns: repeat(5, minmax(32rem,1fr));
        padding: 0.5rem;
        margin: 0 -0.5rem;
    }
`;
