import { useMemo } from 'react';
import JSON_DATA_HEADER from '../data/header.json';
export const useHeaderFatura = ({ administrador }) => {
    return useMemo(() => {
        JSON_DATA_HEADER[JSON_DATA_HEADER.length - 1].width = administrador
            ? '27rem'
            : '13rem';
        return JSON_DATA_HEADER;
    }, []);
};
