import React, { FC, memo } from 'react';
import Modal from 'components/modal/base';
import {
    Container,
    WarpInput,
    Header,
    Content,
    InputSearchChange,
} from './styl';
import { Form } from '@unform/web';
import { ItemListaEmpresa } from './partial/item';
import Icone from 'components/Icone';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { IUnidade } from 'types/modules/unidade';
const PageUnidadeComponent: FC<any> = ({
    unidades,
    handleSearchUnidade,
    loadingSearch,
}) => {
    const history = useModalHistory();
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <button
                            onClick={() =>
                                history.replace('unidade~cadastrar')
                            }>
                            <div>
                                <Icone
                                    icone="icon-adicionar2"
                                    size="2.1rem"
                                    color="var(--btn-admin-icone)"
                                />
                            </div>
                            <span>Adicionar empresa</span>
                        </button>
                        <Form
                            initialData={null}
                            ref={null}
                            onSubmit={null}>
                            <WarpInput>
                                <InputSearchChange
                                    name="search"
                                    placeholder="pesquisar..."
                                    loading={loadingSearch}
                                    action={handleSearchUnidade}
                                />
                            </WarpInput>
                        </Form>
                        <button
                            className="closeButton"
                            onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Content>
                        <ItemListaEmpresa
                            item={{
                                nomeFantasia: 'TODAS AS UNIDADES',
                                logotipo: '',
                                id: '',
                                razaoSocial: 'TODAS AS UNIDADES',
                                geoLatitude: 0,
                                geoLongitude: 0,
                            }}
                            key={-1}
                        />
                        {unidades &&
                            unidades.map((item: IUnidade) => (
                                <ItemListaEmpresa
                                    item={item}
                                    key={item.id}
                                />
                            ))}
                    </Content>
                </Container>
            )}
        />
    );
};
export default memo(PageUnidadeComponent);
