import { format, formatDistance, formatDistanceStrict, parse } from 'date-fns';
import { empty } from './StringUteis';
export const timestamp2HM = (timestamp) => {
    if (empty(timestamp)) return '';
    const date = new Date(Number(timestamp));
    const h = date.getHours().toString().padStart(2, '0');
    const m = date.getMinutes().toString().padStart(2, '0');
    return h + ':' + m;
};
export const dataToBr = (dataEn) => {
    if (empty(dataEn)) return '';
    return dataEn.substr(0, 10).split('-').reverse().join('/');
};
export const dataToEn = (dataBr) => {
    if (empty(dataBr)) return '';
    return dataBr.substr(0, 10).split('/').reverse().join('-');
};

export const strHour2Date = (hhmmss: string): Date => {
    const agora = new Date();
    const ano = agora.getFullYear();
    const mes = (agora.getMonth() + 1).toString().padStart(2, '0');
    const dia = agora.getDate().toString().padStart(2, '0');
    return parse(
        `${ano}-${mes}-${dia} ${hhmmss}`,
        'yyyy-MM-dd k:mm:ss',
        new Date()
    );
};
export const isBetween = (current: Date, start: Date, end: Date): boolean => {
    return (
        current.getTime() >= start.getTime() &&
        current.getTime() <= end.getTime()
    );
};

export const getMinDiff = (startDate: Date, endDate: Date) => {
    const msInMinute = 60 * 1000;

    const tsStartDate = startDate.getTime();
    const tsEndDate = endDate.getTime();
    const response = Math.round(Math.abs(tsEndDate - tsStartDate) / msInMinute);

    return response;
};

export const dateTimeline = (startDate: Date, endDate: Date) => {
    const tsStartDate = startDate.getTime();
    const tsEndDate = endDate.getTime();

    // get total seconds between the times
    let delta = Math.abs(tsEndDate - tsStartDate) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let str = '';

    if (days > 0) {
        str += `${days}d`;
    }

    if (hours > 0) {
        str += `${hours}h`;
    }

    if (minutes > 0) {
        str += `${minutes}m`;
    }
    if (str.length === 0) {
        return '1m';
    }
    return str;
};
