import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC } from 'react';
import { Container, Header } from '../adicionar-administrador/styl';
import { useQuerySend } from './hooks/useQuerySend';
const PageAdicionarStatus: FC = () => {
    const props = useQuerySend();
    return (
        <Modal
            render={({ closeModal }) => (
                <Container width="50">
                    <Header>
                        <h1>{props.titulo}</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Form ref={props.formRef} onSubmit={props.handleSubmit}>
                        <FormGenerator
                            childs={[
                                {
                                    type: 'select-simple',
                                    label: 'Tipo de notificação',
                                    toolTipLegend:
                                        'Escolha como deseja enviar a notificação ao cliente.',
                                    placeholder: 'Digite ou selecione',
                                    name: 'tipoEnvioNotificacao',
                                    defaultValue: {
                                        value: 0,
                                        label: 'Notificar por e-mail',
                                    },
                                    options: [
                                        {
                                            value: 1,
                                            label: 'Notificar por e-mail',
                                        },
                                        {
                                            value: 2,
                                            label: 'Notificar por whatsapp',
                                        },
                                    ],
                                },
                                {
                                    type: 'textarea',
                                    label: 'Mensagem',
                                    toolTipLegend:
                                        'Mensagem que será enviada para o cliente.',
                                    placeholder: 'Digite ou selecione',
                                    name: 'mensagem',
                                    defaultValue: props.mensagem,
                                },
                                {
                                    type: 'button',
                                    value: 'Enviar notificação',
                                    loading: props.loading,
                                },
                            ]}
                        />
                    </Form>
                </Container>
            )}
        />
    );
};
export default PageAdicionarStatus;
