import { Form } from '@unform/web';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuerySubscriptionData } from './hooks/useQuerySubscriptionData';
import { useSubmitAgrupar } from './hooks/useSubmitAgrupar';
import { Container, Header } from './styl';
const PageAgruparColeta: FC<any> = () => {
    const { numeroInterno } = useParams<any>();
    const { loading, data: options } = useQuerySubscriptionData();
    const unformProps = useSubmitAgrupar();

    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Agrupar</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {<CircularLoading show={loading} />}
                    {!loading && options.length === 0 && (
                        <p>Não é possível agrupar esta coleta</p>
                    )}
                    {!loading && options.length > 0 && (
                        <Form {...unformProps}>
                            <FormGenerator
                                childs={[
                                    {
                                        type: 'checkbox',
                                        name: 'coletaIds',
                                        options,
                                    },
                                    {
                                        type: 'button',
                                        value: `Salvar e agrupar com ${numeroInterno.toUpperCase()}`,
                                        loading: false,
                                    },
                                ]}
                            />
                        </Form>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PageAgruparColeta);
