import React, { FC, memo } from 'react';

import { SignupProvider } from './context';
import SignupComponent from './Component';

const PageSignup: FC = memo(() => {
	return (
		<SignupProvider>
			<SignupComponent />
		</SignupProvider>
	);
});
export default PageSignup;
