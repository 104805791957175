import React, { FC, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { HourOpening } from './components/hoursOpening';
import { TransitionImage } from './components/TransitionImage';
import { useGuiche } from './hooks/useGuiche';
import { useSlide } from './hooks/useSlide';
import { Container } from './styl';
import Iframe from 'react-iframe';
import { useCopa2022 } from './hooks/useCopa2022';
const TvDella: FC = () => {
    const { loading, slide } = useSlide();
    const { caixa, play } = useGuiche();
    const { iframeUrl, handleTypeAds } = useCopa2022({ play });

    useEffect(() => {
        document.documentElement.style.setProperty('--chamar', caixa.color);
    }, [caixa]);

    return (
        <Container>
            <section className="carrossel" onClick={handleTypeAds}>
                {!loading && (
                    <>
                        {iframeUrl.indexOf('https://') !== -1 ? (
                            <Iframe
                                url={iframeUrl}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                width="100%"
                                height="100%"
                                display="block"
                                position="relative"
                            />
                        ) : (
                            <TransitionImage name={slide.name}>
                                {slide.type === 'jpg' ? (
                                    <img src={slide.src} alt={slide.name} />
                                ) : (
                                    <video width="300" height="200">
                                        <source
                                            src={slide.src}
                                            type="video/mp4"
                                        />
                                    </video>
                                )}
                            </TransitionImage>
                        )}
                    </>
                )}
            </section>
            <section className="guiche">
                <header>
                    <h2>CAIXA LIVRE</h2>
                    {caixa && caixa.code && (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={`title-${caixa.code}-${caixa.typeId}`}
                                addEndListener={
                                    ((node, done) =>
                                        node.addEventListener(
                                            'transitionend',
                                            done,
                                            false
                                        )) as any
                                }
                                classNames="zoom"
                            >
                                <h4>{caixa.typeName}</h4>
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                </header>
                <div className="warp-guiche">
                    {caixa && caixa.code && (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={`content-${caixa.code}-${caixa.typeId}`}
                                addEndListener={
                                    ((node, done) =>
                                        node.addEventListener(
                                            'transitionend',
                                            done,
                                            false
                                        )) as any
                                }
                                classNames="zoom"
                            >
                                <h1>{caixa.code}</h1>
                            </CSSTransition>
                        </SwitchTransition>
                    )}
                </div>
                <HourOpening />
            </section>
        </Container>
    );
};
export default TvDella;
