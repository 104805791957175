import { abreviarNome } from 'libs/StringUteis';
import { useCallback } from 'react';
import { IColeta } from '../index.d';
import { StatusID } from 'pages/coleta/listar/constants/status-name-2-id';

export const useNumeroInterno = (permissoes: {
    administrador: boolean;
}) => {
    const viewButtonWhatsapp = useCallback((coleta: IColeta) => {
        let viewButtonWhatsapp = false;
        // cliente
        if(!permissoes.administrador){
            viewButtonWhatsapp = false;
        } else if (permissoes.administrador) {
            // admin
            viewButtonWhatsapp = true;
        }
        return viewButtonWhatsapp;
    }, [permissoes]);
    const handleTransformNumeroInterno = useCallback((coleta: IColeta) => {
        let blockColeta = coleta.unidade.unidadeParametro?.find(
            ({ chave }) => chave === 'BLOCK::COLETA'
        )?.valor;
        const boolBlockColeta = blockColeta === 'NO' || !blockColeta ? false : true;
        const whatsappSuporte = (coleta?.unidade?.unidadeContatos ?? []).find(
            ({ tipo }) => tipo === 'whatsapp-suporte-entrega'
        )?.contato.replace(/\D/g, "");
        return {
            tipo: 'Texto',
            width: '25rem',
            marginRight: '0',
            titulo: coleta.numeroInterno,
            subtitulo: abreviarNome(coleta?.unidade?.nomeFantasia) + (boolBlockColeta && permissoes.administrador ? ' - Não recebe coleta' : ''),
            flexDirection: 'row',
            styleSubtitulo: {
                color: boolBlockColeta && permissoes.administrador ? 'red' : 'black',
                fontWeight: boolBlockColeta && permissoes.administrador ? 'bold' : 'normal',
            },
            botoesInline: viewButtonWhatsapp(coleta) ? [
                {
                    titulo: `Whatsapp estabelecimento`,
                    icone: '/whatsapp.png',
                    click: () => {
                        window.open(`https://wa.me/+55${whatsappSuporte}`, '_blank');
                    },
                },
            ] : [],
        };
    }, [permissoes]);
    return { handleTransformNumeroInterno };
};
