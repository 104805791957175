import { useQuery } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { GRAPHQL_LISTAR_COLETA_STATUS } from 'gql/coleta';
import { useEffect, useRef } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IQueryColeta } from './index.d';

export const useQuerySubscriptionFilter = ({status}: {status: number[]}) => {
    const timeAdicionalCollectt = useRef(0);
    const timeAwaitUnitCollectt = useRef(10);
    const props = usePaginationAndFilter({
        dataKeyName: 'dataPedido',
        keysQueryVariavel: [
            'dataPedido',
            'id',
            'nomeEstabelecimento',
            'entregadorId',
            'entregadorNome',
            'razaoSocial',
            'numeroInterno',
            'clienteNome',
            'clienteTelefoneCelular',
            'statusPedido',
            'dataPedido',
            'turno',
        ],
    });

    const { loading } = useQuery<IQueryColeta>(GRAPHQL_LISTAR_COLETA_STATUS, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables: {...props.variables, status},
        onCompleted: ({ recuperarColetaByStatus }) =>
            props.onCompleted(recuperarColetaByStatus),
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return {
        ...props,
        timeAdicionalCollectt: timeAdicionalCollectt.current,
        timeEsperaEstabelecimento: timeAwaitUnitCollectt.current,
        dataSelectHeader: JSON_DATA_FILTER,
    };
};
