import { useMutation } from '@apollo/client';
import { IReceiver } from 'components/template/ListTemplate/hooks/usePaginationAndFilter/index.d';
import { ENTREGADOR_ALTERAR_MODAL } from 'gql/entregador';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IRetriever } from './index.d';
export const useMutationModalTransport = (
    pagination: IReceiver | any
): IRetriever => {
    const { alertConfirm, cacheFiltroData } = useContext(AuthContext);
    const [toggleModal, { loading: loadingModalTransporte }] = useMutation(
        ENTREGADOR_ALTERAR_MODAL
    );

    const handleToogleModalTransporte = useCallback(
        async ({ entregadorId, modalTransporteId: mtId }) => {
            const modalTransporteId = mtId === 1 ? 2 : 1;
            const variables = {
                input: {
                    entregadorId,
                    modalTransporteId,
                },
            };
            try {
                const indice = pagination.response.findIndex(
                    ({ id }) => id === entregadorId
                );
                if (indice === -1) {
                    console.error({ indice, entregadorId });
                    console.error(pagination.response);
                    throw new Error('Entregador não encontrado');
                }
                await toggleModal({
                    variables,
                });

                pagination.response[indice] = {
                    ...pagination.response[indice],
                    modalTransporteId,
                    modalTransporteNome:
                        modalTransporteId === 1 ? 'Moto' : 'Carro',
                };

                pagination.handleSetResponse([...pagination.response]);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [toggleModal, cacheFiltroData, pagination]
    );
    return {
        entregadores: pagination.response,
        handleToogleModalTransporte,
        loadingModalTransporte,
    };
};
