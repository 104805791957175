// eslint-disable-next-line no-use-before-define
import { defTitle } from 'assets/strings';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, memo, useEffect } from 'react';

const ComponentConfiguracaoEscala: FC<any> = memo((oProps) => {
    useEffect(() => {
        document.title = `${defTitle} - Configuração da escala`;
    }, []);
    return (
        <SimplesForm
            widthModal="70rem"
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            loadingData={oProps.loadingData}
            titleSubmit={'Atualizar'}
            titleModal={'Configuração da escala'}
            message={null}
        >
            <FormGenerator rForm={oProps.rForm} childs={oProps.inputs} />
        </SimplesForm>
    );
});
export default ComponentConfiguracaoEscala;
