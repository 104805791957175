import { useQuery } from '@apollo/client';
import { GRAPHQL_TIPO_PAGAMENTO } from 'gql/unidade';
import { useParams } from 'react-router-dom';
export interface IPlayer {
    id?: string;
    nomeCompleto?: string;
    sexo?: string;
    email?: string;
    telefone?: string;
}
interface IParams {
    unidadeId: string;
}
const optionsTipoPagamento = [
    { label: 'Pré-pago', value: 'PRE_PAGO' },
    { label: 'Pós-pago', value: 'POS_PAGO' },
    { label: 'Ambos', value: 'AMBOS' },
];
export const useTypePayment = () => {
    const { unidadeId } = useParams<IParams>();

    const { data: responseData, loading } = useQuery<{
        unidade?: {
            data?: { tipoPagamento: string }[];
        };
    }>(GRAPHQL_TIPO_PAGAMENTO, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id: unidadeId,
            },
        },
    });
    if (loading) return [];
    const tipoPagamento = responseData.unidade.data[0].tipoPagamento;
    const defaultValue = optionsTipoPagamento.find(
        ({ value }) => value === tipoPagamento
    );
    return [
        {
            type: 'select-simple',
            label: 'Tipo de pagamento',
            placeholder: 'Tipo de pagamento',
            name: 'tipoPagamento',
            defaultValue,
            options: optionsTipoPagamento,
        },
    ];
};
