import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';
import { ContainerForm } from 'components/modal/template/simplesForm/styl';
import React, { FC, memo, RefObject, useContext, useEffect } from 'react';
import { IPropsNavigateForms } from '..';
import { ICollectData } from '../hooks/useContainerDataForm';
import { useManagerInputs } from './hooks/useManagerInputs';
import { useSubmit } from './hooks/useSubmit';
import InputPreco from './partial/inputPreco';
import { AuthContext } from '../../../../routes/auth.context';

interface IReceiver extends ICollectData {
    formRef: RefObject<FormHandles>;
    somarFrete?: boolean;
    handleNavigateForms(props: IPropsNavigateForms): void;
    setMinWidthModal?: React.Dispatch<React.SetStateAction<string>>;
}

const PageNavigateSelecionarPagamento: FC<IReceiver> = memo(props => {
    const { unidadeSelecionada, modalTransporte, configuracao } =
        useContext(AuthContext);
    const form = useSubmit(props);
    const [exigeTaxaRetorno, setExigeTaxaRetorno] = React.useState(true);
    const [pagamentoId, setPagamentoId] = React.useState(0);
    const { childsForm, frete } = useManagerInputs({ ...props, ...form });
    useEffect(() => {
        props.setMinWidthModal('50rem');
    }, []);
    useEffect(() => {
        const pagamentoId =
            props.formRef.current?.getFieldValue('formaPagamentoId');
        props.formRef.current?.getFieldRef('formaPagamentoId').focus();
        setTimeout(() => {
            setExigeTaxaRetorno(pagamentoId.exigeTaxaRetorno);
            setPagamentoId(pagamentoId.id);
        }, 500);
    }, [props.formRef.current?.getFieldRef('formaPagamentoId').state]);
    const modalMoto = modalTransporte.find(({ id }) => id === 1);
    const distanciaMinima = configuracao.find(
        ({ chave }) => chave === 'Frete::DistanciaMinima::Km',
    );
    const valorAdicionalCorrida = configuracao.find(
        ({ chave }) => chave === 'Valor::Adicional::Corrida::Entregador',
    )?.valor;
    const valorAdicionalCorridaJogoRapido = configuracao.find(
        ({ chave }) => chave === 'Valor::Adicional::Corrida',
    )?.valor;
    const viewValorTotalFrete =
        unidadeSelecionada &&
        unidadeSelecionada?.unidadeParametro &&
        unidadeSelecionada?.unidadeParametro.find(
            ({ chave }) => chave === 'VIEW::VALOR::TOTAL::FRETE',
        )?.valor === 'YES';
    childsForm.splice(2, 0, {
        type: 'custom-component',
        component: () => (
            <InputPreco
                name="valorTotalProdutos"
                distanciaMinima={distanciaMinima}
                modalMoto={modalMoto}
                frete={frete}
                thousandSeparator="."
                prefix=""
                somarFrete={props.somarFrete}
                formRef={props.formRef}
                exigeTaxaRetorno={exigeTaxaRetorno}
                pagamentoId={pagamentoId}
                mostrarValorTotal={viewValorTotalFrete}
                porcentagemNegociacao={
                    unidadeSelecionada?.porcentagemNegociacao
                }
                valorAdicionalCorrida={
                    valorAdicionalCorrida ? Number(valorAdicionalCorrida) : 0
                }
                valorAdicionalCorridaJogoRapido={
                    valorAdicionalCorridaJogoRapido
                        ? Number(valorAdicionalCorridaJogoRapido)
                        : 0
                }
            />
        ),
    });
    return (
        <ContainerForm>
            <Form
                ref={props.formRef}
                onSubmit={form.submit}>
                <FormGenerator childs={childsForm} />
            </Form>
        </ContainerForm>
    );
});
export default PageNavigateSelecionarPagamento;
