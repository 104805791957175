import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import {
    BlcImage,
    BlcTitleAndButton,
    Container,
    ContainerBody,
    ContainerTitleAndButton,
    ImageRound,
} from './styl';
import ButtonLoading from 'components/atom/ButtonLoading';

const ComponentValidateDocument: FC<any> = memo(oProps => {
    useEffect(() => {
        document.title = `${defTitle} - ${oProps.title}`;
    });
    const refsById = useMemo(() => {
        const refs = {};
        oProps?.values?.documentos &&
            oProps?.values?.documentos.forEach((item, key) => {
                refs[key] = React.createRef();
            });
        return refs;
    }, [oProps.values.documentos]);
    const refsByIdHref = useMemo(() => {
        const refs = {};
        oProps?.values?.documentos &&
            oProps?.values?.documentos.forEach((item, key) => {
                refs[key] = React.createRef();
            });
        return refs;
    }, [oProps.values.documentos]);
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={''}
            titleModal={oProps.title}
            message={null}>
            <ContainerBody>
                {oProps.values &&
                    oProps.values.documentos?.map(
                        (
                            item: {
                                approved?: boolean | null;
                                documento: string;
                                pathArquivo: string;
                            },
                            key,
                        ) => {
                            let nameDocumento = '';
                            let status = 'Aguardando aprovação';
                            if (item.approved) {
                                status = 'Aprovado';
                            } else if (item.approved === false) {
                                status = 'Reprovado';
                            }
                            if (item.documento === 'cnh_frente') {
                                nameDocumento = 'CNH Frente';
                            } else if (item.documento === 'cnh_verso') {
                                nameDocumento = 'CNH Verso';
                            } else if (item.documento === 'crlv') {
                                nameDocumento = 'CRLV';
                            }

                            return (
                                <Container key={key}>
                                    <ImageRound
                                        target="_blank"
                                        ref={refsByIdHref[key]}>
                                        <BlcImage
                                            ref={refsById[key]}
                                            src={
                                                process.env
                                                    .REACT_APP_HOST_EXPRESS +
                                                '/imagem?url=' +
                                                item.pathArquivo
                                            }
                                            onError={() => {
                                                try {
                                                    fetch(
                                                        process.env
                                                            .REACT_APP_HOST_EXPRESS +
                                                            '/imagem?url=' +
                                                            item.pathArquivo,
                                                        {
                                                            headers: {
                                                                authorization:
                                                                    'Basic ' +
                                                                    btoa(
                                                                        'jogorapidoadm:jogorapidoadm',
                                                                    ),
                                                            },
                                                        },
                                                    )
                                                        .then(r => r.blob())
                                                        .then(d => {
                                                            if (
                                                                refsById[key] &&
                                                                refsById[key]
                                                                    .current
                                                            ) {
                                                                refsById[
                                                                    key
                                                                ].current.src =
                                                                    URL.createObjectURL(
                                                                        d,
                                                                    );
                                                                refsByIdHref[
                                                                    key
                                                                ].current.href =
                                                                    URL.createObjectURL(
                                                                        d,
                                                                    );
                                                            }
                                                        });
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }}
                                        />
                                    </ImageRound>
                                    <BlcTitleAndButton>
                                        <h2>{nameDocumento}</h2>
                                        <p>Status: {status}</p>
                                        <ContainerTitleAndButton>
                                            {item.approved !== true && (
                                                <ButtonLoading
                                                    onClick={() => {
                                                        oProps.handleSubmit({
                                                            documento:
                                                                item.documento,
                                                            approved: true,
                                                            key,
                                                        });
                                                    }}
                                                    type="button"
                                                    value={'Aprovar'}
                                                    loading={false}
                                                    stylWarp={{
                                                        width: '100%',
                                                        height: 30,
                                                        fontSize: 14,
                                                    }}
                                                />
                                            )}
                                            {item.approved !== false && (
                                                <ButtonLoading
                                                    type="button"
                                                    value={'Reprovar'}
                                                    loading={false}
                                                    id={`reprovar${item.documento}`}
                                                    stylWarp={{
                                                        width: '100%',
                                                        height: 30,
                                                        fontSize: 14,
                                                    }}
                                                    onClick={() => {
                                                        oProps.handleSubmit({
                                                            documento:
                                                                item.documento,
                                                            approved: false,
                                                            key,
                                                        });
                                                    }}
                                                />
                                            )}
                                        </ContainerTitleAndButton>
                                    </BlcTitleAndButton>
                                </Container>
                            );
                        },
                    )}
            </ContainerBody>
        </SimplesForm>
    );
});
export default ComponentValidateDocument;
