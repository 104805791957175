import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO_DIA } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext, useRef } from 'react';
import { AuthContext } from 'routes/auth.context';
export const useEntregadorRelatorioPgtoSinteticoDia = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const currentDate = useRef({ dataInicio: '', dataFim: '' });

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO_DIA,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Data',
                        'Player',
                        'Cpf',
                        'Modal',
                        'Turno',
                        'Qtd. coletas',
                        'Km rodado',
                        'Tx. retorno',
                        'Adicional Entregador',
                        'Garantido',
                        'Frete',
                        'Valor a pagar',
                    ],
                ];

                response.relatorioPlayerPgtoSinteticoDia.forEach((props) => {
                    content.push([
                        props.data,
                        props.nome,
                        props.cpf,
                        props.modal,
                        props.turno,
                        props.qtdeColetas,
                        `${(props.distanciaPercorrida || 0).toFixed(3).replace('.', ',')}`,
                        formatarMoeda(props.valorTotalTaxaRetorno),
                        formatarMoeda((props.valorTotalAdicionalCorridaEntregador || 0)),
                        formatarMoeda(props.valorGarantidoPgto),
                        formatarMoeda(props.valorTotalFrete),
                        formatarMoeda(
                            props.valorTotalTaxaRetorno +
                                props.valorTotalFrete +
                                props.valorGarantidoPgto + (props.valorTotalAdicionalCorridaEntregador || 0)
                        ),
                    ]);
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO PLAYER SINTÉTICO DIA ${currentDate.current.dataInicio} à ${currentDate.current.dataFim}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );
    const handleClick = useCallback(
        (variables) => {
            currentDate.current = variables;
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return { handleClick, loading };
};
