import { gql } from '@apollo/client';

export const GRAPHQL_BUSCAR_BANCO = gql`
    mutation bancoContainer($filter: BancoFilter) {
        banco(filter: $filter) {
            id
            nome
        }
    }
`;
