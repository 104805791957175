import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo } from 'react';
import { useLoadUser } from './hooks/useLoadUser';
import { useLoadUserUnit } from './hooks/useLoadUserUnit';
import { useSaveCompanyAdministrator } from './hooks/useSaveCompanyAdministrator';
import { useSelectAddItem } from './hooks/useSelectAddItem';
import { Container, Header } from './styl';
const PageAdicionarPlayer: FC<any> = () => {
    const currentUsers = useLoadUserUnit();
    const options = useLoadUser();

    const propsSelect = useSelectAddItem(options, currentUsers);

    const unformProps = useSaveCompanyAdministrator();

    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Adicionar administradores</h1>

                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <p>Selecione o usuário para conceder acesso</p>
                    <span>
                        Para adicionar um novo usuário basta clicar no botão [ +
                        ]<br />
                        para remover clique na lixeira
                    </span>

                    {currentUsers && (
                        <Form {...unformProps}>
                            <FormGenerator
                                childs={[
                                    ...propsSelect,
                                    {
                                        type: 'button',
                                        value: 'Salvar e conceder permissão',
                                        loading: false,
                                    },
                                ]}
                            />
                        </Form>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PageAdicionarPlayer);
