import { useCallback, useMemo, useState } from 'react';
import options from '../data/options-tipo-de-contato.json';
import typeList from '../data/inputs-contato-types.json';
export const useContatosFinanceiro = (initialData) => {
    const [itens, setItens] = useState(() => {
        if (initialData.contatoTipo && initialData.contatoTipo.length > 0) {
            return initialData.contatoTipo.map((type) => {
                if (type === 'celular') type = 'celular-financeiro';
                if (type === 'fixo') type = 'fixo-financeiro';
                if (type === 'email') type = 'email-financeiro';
                return {
                    type,
                };
            });
        }

        return [
            {
                type: options[0].value,
            },
        ];
    });

    const handleChange = useCallback(({ value, index }) => {
        if (value && index >= 0)
            setItens((prev) => {
                const copy = [...prev];
                copy[index].type = value;
                return copy;
            });
    }, []);
    const handleAddItem = useCallback(({ index }) => {
        setItens((prevData) => [
            ...prevData,
            {
                type: options[0].value,
            },
        ]);
    }, []);
    const handleRemoveItem = useCallback(({ index }) => {
        setItens((data) => {
            const rest = [...data];
            rest.splice(index, 1);
            return rest;
        });
    }, []);
    const result = useMemo(() => {
        const total = itens.length - 1;

        return itens
            .map(({ type }, index) => {
                const icone =
                    index === total ? 'icon-adicionar' : 'icon-lixeira2';
                const handleClick =
                    index === total ? handleAddItem : handleRemoveItem;
                if (!typeList[type]) return null;
                const defaultValue = options.find(
                    (option) => option.value === type
                );

                return {
                    gridColumn: '1fr 1fr 25px',
                    items: [
                        {
                            zIndex: 99999,
                            type: 'select-simple',
                            onChange: (value) =>
                                handleChange({ ...value, index }),
                            label: 'Tipo de contato',
                            toolTipLegend: 'Tipo de contato do financeiro',
                            placeholder: 'Digite ou selecione',
                            name: `contatoTipo[${index}]`,
                            defaultValue,
                            options,
                        },
                        { ...typeList[type], name: `contatoValor[${index}]` },
                        {
                            type: 'button-icon',
                            icone,
                            dataClicked: { index },
                            handleClick,
                        },
                    ],
                };
            })
            .filter((value) => !!value);
    }, [itens]);

    return result;
};
