import React, { FC, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ItemEndereco from './partial/endereco';
import { Conteiner } from './styl';

export const ComponentSelecionarEndereco: FC<any> = ({
    filterData,
    enderecos,
    clienteId,
    defaultValue,
    checkEndereco,
}) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const { fieldName, registerField } = useField('endereco');
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue: (refs: HTMLInputElement[]) => {
                return refs
                    .filter(ref => ref && ref.checked)
                    .map(ref => ref.value);
            },
        });
    }, [fieldName, registerField]);
    return (
        <Conteiner>
            {enderecos.map((data, index) => {
                data.clienteId = clienteId;
                const { id, ...propsItemEndereco } = filterData(data);
                const key = `checkbox-${id}`;
                const props = {
                    refs: inputRefs,
                    index,
                    htmlKey: key,
                    id,
                    defaultValue,
                    clienteId,
                    checkEndereco,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    ...propsItemEndereco,
                };
                return (
                    <ItemEndereco
                        {...props}
                        key={key}
                    />
                );
            })}
        </Conteiner>
    );
};
export default ComponentSelecionarEndereco;
