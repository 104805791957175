import { useCallback, useMemo, useState } from 'react';
import { IPlayer } from '..';
interface ITipoConta {
    pixTipo?: string;
    mask?: string;
    tipo?: string;
    placeholder?: string;
}
export const usePix = (entregador: IPlayer) => {
    const [pixTipo, setpixTipo] = useState<ITipoConta>({} as ITipoConta);
    const options = useMemo(
        () => [
            {
                value: {
                    pixTipo: 'telefone',
                    mask: '(99) 9 9999.9999',
                    tipo: 'mask',
                    placeholder: '(xx) x xxxx.xxxx',
                },
                label: 'telefone',
            },
            {
                value: {
                    pixTipo: 'cpf',
                    mask: '999.999.999-99',
                    tipo: 'mask',
                    placeholder: 'xxx.xxx.xxx-xx',
                },
                label: 'CPF',
            },
            {
                value: {
                    pixTipo: 'email',
                    tipo: 'text',
                    placeholder: 'E-mail',
                },
                label: 'E-mail',
            },
            {
                value: {
                    pixTipo: 'aleatorio',
                    tipo: 'text',
                    placeholder: 'Chave aleatória',
                },
                label: 'Chave aleatória',
            },
        ],
        []
    );

    const pixTipoSelected = useMemo(() => {
        const findResponse = options.find(
            (o) => {
                if(pixTipo && pixTipo.pixTipo) {
                    return `${o.value.pixTipo}` === `${pixTipo.pixTipo}`
                }
                return `${o.value.pixTipo}` ===
                `${entregador?.contaBancaria?.pixTipo}`
            }
        );

        return findResponse;
    }, [entregador, pixTipo]);

    const handleChange = useCallback(({ value }) => {
        setpixTipo(value);
    }, []);

    const selectPix = useMemo(
        () => ({
            type: 'select-simple',
            label: 'Tipo de chave pix',
            placeholder: 'Selecione',
            name: 'pixTipo',
            onChange: handleChange,
            options,
            defaultValue: pixTipoSelected,
        }),
        [pixTipoSelected, handleChange]
    );

    if (!pixTipo?.pixTipo) {
        return selectPix;
    }

    return {
        gridFirstWidth: 0.7,
        items: [
            selectPix,
            {
                type: pixTipo?.tipo,
                label: 'Conta pix',
                ...(pixTipo?.mask ? { mask: pixTipo?.mask } : {}),
                placeholder: pixTipo?.placeholder,
                name: 'pixConta',
                defaultValue: entregador?.contaBancaria?.pixConta,
            },
        ],
    };
};
