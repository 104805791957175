import { FormHandles } from '@unform/core';
import React, { createRef, FC } from 'react';
import { useParams } from 'react-router-dom';
import ComponentTipoPagamentoEditar from './Component';
import { useTipoPagamentoAlterar } from './hooks/useTipoPagamentoAlterar';
import { useTypePayment } from './hooks/useTypePayment';
interface IParams {
    unidadeId: string;
}
const PageEditarTipoPagamento: FC = () => {
    const inputs = useTypePayment();
    const { unidadeId } = useParams<IParams>();
    const { atualizaTipoPagamento, loading } = useTipoPagamentoAlterar();
    const rForm = createRef<FormHandles>();

    const handleSubmit = async (data) => {
        await atualizaTipoPagamento({
            unidadeId,
            ...data,
        });
    };
    const oProps = {
        inputs,
        loading,
        rForm,
        handleSubmit,
    };
    return <ComponentTipoPagamentoEditar {...oProps} />;
};
export default PageEditarTipoPagamento;
