import styled from 'styled-components';

export const Container = styled.div`
    background: var(--bg-hover-light);
    margin: 2rem -2rem;
    margin-top: 0;
    display: flex;
    justify-content: center;
    > div {
        padding: 2rem 0;

        > p {
            margin: 0;
        }
        .frete {
            color: var(--error-cor);
            font-size: 1.8rem;
        }
        .cobrar-cliente {
            color: var(--text-cor);
            font-weight: bold;
        }
        width: 35rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: var(--bg-white);

        input {
            font-size: 6rem;
            color: var(--btn-admin-icone);
            text-align: center;
        }
        .txRetorno {
            text-align: center;
            font-size: 1.2rem;
        }
    }
`;
