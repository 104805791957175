import { gql } from '@apollo/client';
export const GRAPHQL_PROCURAR_DADOS_CNPJ: any = gql`
    query procurarDadosCnpj($cnpj: String) {
        procurarDadosCnpj(cnpj: $cnpj) {
            cep
            cnpj
            numero
            uf
            bairro
            complemento
            fantasia
            logradouro
            municipio
            email
            nome
            telefoneCelular
            telefoneFixo
            representantes {
                label
                value
            }
        }
    }
`;
