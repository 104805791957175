import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useTitle = () => {
    const location = useLocation();
    const titulo = useMemo(() => {
        if (location.pathname.indexOf('/empresa/') !== -1) {
            return 'Relatório empresas';
        } else {
            return 'Relatório player';
        }
    }, []);
    return titulo;
};
