import { useQueryPermissions } from './../../../../coleta/listar/hooks/useQueryPermissions';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSelectOptions = () => {
    const location = useLocation();
    const { administrador } = useQueryPermissions();

    const acessoRelatorio = useMemo(() => {
        if (location.pathname.indexOf('/empresa/') !== -1) {
            if (administrador) {
                return [
                    {
                        // novo
                        value: 'mestreAnaPeriodoSin',
                        label: 'Relatório analítico',
                    },
                    {
                        // novo
                        value: 'mestreUnidadeSin',
                        label: 'Sintético',
                    },
                    {
                        // novo
                        value: 'mestreAna',
                        label: 'Analítico',
                    },
                ];
            } else {
                return [
                    {
                        value: 'estabelecimentoSin',
                        label: 'Sintético',
                    },
                    {
                        value: 'estabelecimentoAna',
                        label: 'Analítico',
                    },
                ];
            }
        } else {
            if (administrador) {
                return [
                    {
                        value: 'playerPgtoAna',
                        label: 'Relatório análitico',
                    },
                    {
                        value: 'playerPgtoSin',
                        label: 'Relatório financeiro',
                    },
                    {
                        value: 'playerPgtoSinNotaFiscal',
                        label: 'Relatório nota fiscal',
                    },
                    {
                        value: 'playerPgtoSinDay',
                        label: 'Relatório sintético',
                    },
                    {
                        value: 'playerScaled',
                        label: 'Relatório de escala',
                    },
                    {
                        value: 'playerInterest',
                        label: 'Relatório de Interesse',
                    },
                ];
            } else {
                return [];
            }
        }
    }, [administrador, location.pathname]);
    return acessoRelatorio;
};
