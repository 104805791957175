import React, { FC, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import Icone from 'components/Icone';
import { WarpLabel } from '../../styl';

interface IToolTipLabel {
    htmlFor: string;
    htmlKey: string;
    label: string;
    toolTipColor?: string;
}
const ToolTipLabel: FC<IToolTipLabel> = memo(
    ({
        htmlFor,
        htmlKey,
        label,
        toolTipColor = 'var(--error-cor)',
        children,
    }) => {
        if (!children)
            return (
                <label className={'label_label'} dangerouslySetInnerHTML={{
                    __html: label
                }} htmlFor={htmlFor}>

                </label>
            );
        return (
            <WarpLabel>
                <label className={'label_label'} dangerouslySetInnerHTML={{
                    __html: label
                }} htmlFor={htmlFor}>

                </label>
                <button type="button" data-tip data-for={htmlKey}>
                    <Icone
                        data-tip
                        data-for={htmlKey}
                        icone="icon-ajuda-light"
                        color={toolTipColor}
                        size="2rem"
                    />
                </button>
                <ReactTooltip
                    multiline
                    textColor="white"
                    backgroundColor="var(--btn-admin-icone)"
                    id={htmlKey}
                    className="tool-tip"
                    place="bottom"
                    data-type="info"
                    effect="solid"
                >
                    {children}
                </ReactTooltip>
            </WarpLabel>
        );
    }
);
export default ToolTipLabel;
