const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});
export const formatarMoeda = (valor) => {
    const nValue = valor && Number(valor) >= 0 ? valor : 0;
    return formatter.format(nValue).replace('R$', '').trim();
};

export const calculaValorFreteComTaxa = ({
    frete,
    porcentagemNegociacao,
    distanciaMinima,
    distancia,
    adcionalRetorno,
    txMinRetorno,
    valorAdicionalCorrida,
    valorAdicionalCorridaJogoRapido,
}:{
    frete: number;
    porcentagemNegociacao: number;
    distanciaMinima?: number;
    distancia?: number;
    adcionalRetorno?: number; // valorKmRetorno
    txMinRetorno?: number;
    mostrarValorTotal?: boolean;
    valorAdicionalCorrida?: number;
    valorAdicionalCorridaJogoRapido?: number;
}): { valorFreteComRetorno: number; valorFreteSemRetorno: number; } => {
    let tmp = {
        valorFreteComRetorno: 0,
        valorFreteSemRetorno: 0,
    };
    if (frete) {
        const valorRetorno = ((((distancia - distanciaMinima) * adcionalRetorno + txMinRetorno)));
        if((distancia <= distanciaMinima) || (distancia === undefined) || (distanciaMinima === undefined)){
            const valorTotalComTaxaRetorno =
                frete +
                txMinRetorno +
                (frete + txMinRetorno) *
                    porcentagemNegociacao;
            const valorTotalSemTaxaRetorno =
                frete +
                frete * porcentagemNegociacao;
            tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
            tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
            if(valorAdicionalCorrida){
                tmp.valorFreteComRetorno += valorAdicionalCorrida;
                tmp.valorFreteSemRetorno += valorAdicionalCorrida;
            }
            if(valorAdicionalCorridaJogoRapido){
                tmp.valorFreteComRetorno += valorAdicionalCorridaJogoRapido;
                tmp.valorFreteSemRetorno += valorAdicionalCorridaJogoRapido;
            }
        } else {
            const valorTotalComTaxaRetorno =
                frete +
                valorRetorno +
                (frete + valorRetorno) *
                    porcentagemNegociacao;
            const valorTotalSemTaxaRetorno =
                frete +
                frete * porcentagemNegociacao;
            tmp.valorFreteSemRetorno = valorTotalSemTaxaRetorno;
            tmp.valorFreteComRetorno = valorTotalComTaxaRetorno;
            if(valorAdicionalCorrida){
                tmp.valorFreteComRetorno += valorAdicionalCorrida;
                tmp.valorFreteSemRetorno += valorAdicionalCorrida;
            }
            if(valorAdicionalCorridaJogoRapido){
                tmp.valorFreteComRetorno += valorAdicionalCorridaJogoRapido;
                tmp.valorFreteSemRetorno += valorAdicionalCorridaJogoRapido;
            }
        }
    }
    return tmp;
}

export const calculaValorFreteComTaxaDetalhes = ({
    frete,
    porcentagemNegociacao,
    distanciaMinima,
    distancia,
    adcionalRetorno,
    txMinRetorno,
    mostrarValorTotal,
    valorAdicionalCorrida,
    valorAdicionalCorridaJogoRapido,
}:{
    frete: number;
    porcentagemNegociacao: number;
    distanciaMinima?: number;
    distancia?: number;
    adcionalRetorno?: number; // valorKmRetorno
    txMinRetorno?: number;
    mostrarValorTotal?: boolean;
    valorAdicionalCorrida?: number;
    valorAdicionalCorridaJogoRapido?: number;
}): { valorFreteComRetorno: number; valorFreteSemRetorno: number; } => {
    return calculaValorFreteComTaxa({
        frete,
        porcentagemNegociacao,
        distanciaMinima,
        distancia,
        adcionalRetorno,
        txMinRetorno,
        mostrarValorTotal,
        valorAdicionalCorrida,
        valorAdicionalCorridaJogoRapido,
    });
}
