import Icone from 'components/Icone';
import { CheckBoxDisable } from 'components/Table/Columns/CheckBox/styl';
import React, { FC, useEffect } from 'react';
import { IEnderecoQueryCliente } from 'types/queryCliente';
import { ButtonAction, Group, WarpEndereco } from './styl';
interface IActions {
    classIc?: string;
    action?: any;
}
interface IItemEndereco {
    titulo?: string;
    subtitulo?: string;
    distancia?: string;
    latitude?: number;
    longitude?: number;
    frete?: string;
    acoes?: IActions[];
    htmlKey?: string;
    clienteId?: string;
    checkEndereco: (data: {
        latitude: number;
        longitude: number;
        id?: number;
    }) => void;
    index?: number;
    defaultValue?: number;
    id?: number;
    refs?: any;
}
export const ItemEndereco: FC<IItemEndereco> = ({
    titulo,
    subtitulo,
    distancia,
    frete,
    acoes,
    checkEndereco,
    defaultValue,
    htmlKey,
    clienteId,
    index,
    id,
    refs,
    latitude,
    longitude,
}) => {
    return (
        <Group>
            <input
                type="radio"
                id={htmlKey}
                defaultChecked={defaultValue === id}
                ref={ref => (refs.current[index] = ref as HTMLInputElement)}
                value={`${id}`}
                onChange={() => {
                    if (latitude && longitude) {
                        checkEndereco({
                            id,
                            latitude,
                            longitude,
                        });
                    } else {
                        checkEndereco({
                            id: 0,
                            latitude: 0,
                            longitude: 0,
                        });
                    }
                }}
                name="endereco"
            />
            <WarpEndereco>
                <label htmlFor={htmlKey}>
                    <div className="radio">
                        <CheckBoxDisable
                            icone="icon-certo"
                            size="2rem"
                            color="var(--btn-admin-icone)"
                        />
                    </div>
                </label>
                <label
                    className="endereco"
                    htmlFor={htmlKey}>
                    <h1>
                        {titulo}
                        {/* {distancia !== undefined && (
                            <span> ( {distancia} )</span>
                        )} */}
                    </h1>
                    <p>{subtitulo}</p>
                </label>

                <div>
                    {distancia !== undefined &&
                        acoes.map(({ classIc, action }, index) => (
                            <ButtonAction
                                onClick={action}
                                key={`action-${index}`}
                                data-tip
                                data-for="excluirTop"
                                data-param={clienteId}>
                                {classIc && <Icone icone={classIc} />}
                            </ButtonAction>
                        ))}
                </div>
            </WarpEndereco>
        </Group>
    );
};
export default ItemEndereco;
