import { useMutation, useQuery } from '@apollo/client';
import {
    GRAPHQL_CAPTURE_ORDER_ANOTAAI,
    GRAPHQL_QUERY_SHIPPING_ANOTAAI,
    anotaAiOrders,
    IPedidos,
} from 'gql/anotaAi';
import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';

export const useQueryShippingAnotaAi = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [dataIfood, setDataIFood] = useState<IPedidos[]>([]);
    const { loading } = useQuery<anotaAiOrders>(GRAPHQL_QUERY_SHIPPING_ANOTAAI, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const result = data.anotaAiOrders?.pedidos || [];
            if (result.length > 0) {
                setDataIFood(result);
            } else {
                history.goBack();
                alertConfirm({
                    tipo: 'info',
                    titulo: 'Informação',
                    conteudo: 'Não há pedidos para serem capturados',
                });
            }
        },
        onError: (error) => {
            history.goBack();
            alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo:
                    error.message ||
                    'Falha ao buscar pedidos no IFood. Favor vefirique se está dentro do horáro de funcionamento do IFood e tente novamente.',
            });
        },
    });

    const [handleCapturePedido, { loading: loadingUpdate }] = useMutation(
        GRAPHQL_CAPTURE_ORDER_ANOTAAI
    );

    const handleSubmit = useCallback(
        async ({ action, data: { pedido_ifood_id, pedido_delivery_id } }) => {
            try {
                if (action === 'cancel') {
                    const responseQuestion = await alertConfirm({
                        tipo: 'erro',
                        titulo: 'JogoRápido',
                        textCancelar: 'cancelar',
                        conteudo: `Tem certeza que deseja remover o pedido "${pedido_delivery_id}" da tela de captura? Esta ação não pode ser desfeita.`,
                    });

                    if (!responseQuestion) {
                        throw new Error('ignore');
                    }
                }
                const pedidos = dataIfood.filter(
                    (item) => item.pedido_delivery_id !== pedido_delivery_id
                );
                await handleCapturePedido({
                    variables: { action, order: { pedido_ifood_id } },
                });
                if (pedidos.length > 0) {
                    setDataIFood(pedidos);
                } else {
                    history.goBack();
                    alertConfirm({
                        tipo: 'sucesso',
                        titulo: 'JogoRápido',
                        conteudo: `Pedido "${pedido_delivery_id}" capturada com sucesso.`,
                    });
                }
            } catch ({ message }) {
                if (message !== 'ignore') {
                    await alertConfirm({
                        tipo: 'erro',
                        titulo: 'Alerta',
                        conteudo: message as string,
                    });
                }
            }
        },
        [dataIfood]
    );
    return {
        handleSubmit,
        pedidos: dataIfood,
        loading: loading || loadingUpdate,
    };
};
