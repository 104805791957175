import { ButtonAction } from 'components/Form/template/styl';
import Icone from 'components/Icone';
import React, { FC, memo } from 'react';
import { Container, Grid, H1Value, PValue, Warp } from './styl';

const CartaoCliente: FC<any> = memo(({ handleClick, ...props }) => {
    return (
        <Container>
            <Grid>
                <Warp>
                    <PValue>Nome</PValue>
                    <H1Value>{props.nomeCompleto}</H1Value>
                </Warp>
                <ButtonAction
                    onClick={e => {
                        e.preventDefault();
                        handleClick({ fieldName: 'nomeCompleto' });
                    }}>
                    <Icone
                        icone="icon-editar"
                        color="var(--btn-admin-icone)"
                    />
                </ButtonAction>
                <Warp>
                    <PValue>Email</PValue>
                    <H1Value>{props.email || 'Não informado'}</H1Value>
                </Warp>
                <ButtonAction
                    onClick={e => {
                        e.preventDefault();
                        handleClick({ fieldName: 'email' });
                    }}>
                    <Icone
                        icone="icon-editar"
                        color="var(--btn-admin-icone)"
                    />
                </ButtonAction>
            </Grid>

            <Grid>
                <Warp>
                    <PValue>Telefone fixo</PValue>
                    <H1Value>{props.telefoneFixo || 'Não informado'}</H1Value>
                </Warp>
                <ButtonAction
                    onClick={e => {
                        e.preventDefault();
                        handleClick({ fieldName: 'telefoneFixo' });
                    }}>
                    <Icone
                        icone="icon-editar"
                        color="var(--btn-admin-icone)"
                    />
                </ButtonAction>
                <Warp>
                    <PValue>Telefone celular</PValue>
                    <H1Value>
                        {props.telefoneCelular || 'Não informado'}
                    </H1Value>
                </Warp>
                <ButtonAction
                    onClick={e => {
                        e.preventDefault();
                        handleClick({ fieldName: 'telefoneCelular' });
                    }}>
                    <Icone
                        icone="icon-editar"
                        color="var(--btn-admin-icone)"
                    />
                </ButtonAction>
            </Grid>
        </Container>
    );
});
export default CartaoCliente;
