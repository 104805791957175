import React, { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';
import {
    Textarea as TextareaField,
    ContainerTextarea,
    LabelError,
} from './styl';

const TextArea: React.FC<IInput> = memo(
    ({ name, label, icon, className, type, ...rest }) => {
        const inputRef = useRef<HTMLTextAreaElement>(null);
        const { fieldName, defaultValue, registerField, clearError, error } =
            useField(name);
        useEffect(() => {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value',
            });
        }, [fieldName, registerField]);
        return (
            <ContainerTextarea erro={error}>
                <div className={className}>
                    <TextareaField
                        rows="10"
                        onFocus={clearError}
                        shake={error ? 1 : undefined}
                        ref={inputRef}
                        defaultValue={defaultValue}
                        {...rest}
                    />
                    {icon}
                </div>
                <LabelError erro={error}>{error}</LabelError>
            </ContainerTextarea>
        );
    }
);
export default TextArea;
