import { useContext, useCallback } from 'react';
import { AuthContext } from 'routes/auth.context';
import { useMutation } from '@apollo/client';
import { GRAPHQL_ENVIAR_CODIGO } from 'gql/recoversignin';
import { useHistory } from 'react-router-dom';

export const useSendCodeNewPassword = () => {
	const { alertConfirm, cacheEmail: email } = useContext(AuthContext);
	const history = useHistory();
	const [handleSendCode, { loading }] = useMutation(GRAPHQL_ENVIAR_CODIGO);
	const sendCode = useCallback(
		async ({ codigo, senha }) => {
			try {
				const {
					data: { usuarioCriarNovaSenha },
				} = await handleSendCode({
					variables: {
						data: {
							tokenRecSenha: codigo,
						},
						basic: {
							email,
							senha,
						},
					},
				});
				history.replace('/');
				await alertConfirm({
					conteudo: usuarioCriarNovaSenha,
				});
			} catch ({ message }) {
				await alertConfirm({
					tipo: 'erro',
					titulo: 'Erro',
					conteudo: message,
				});
			}
		},
		[alertConfirm, email, handleSendCode, history]
	);
	return {
		loading,
		sendCode,
	};
};
