import { GRAPHQL_USUARIO_SUBSCRIPTION } from 'gql/usuario';
import { useQuery, useSubscription } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { GRAPHQL_LISTAR_USUARIO } from 'gql/usuario';
import { useEffect, useMemo } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IQueryUsuario, ISubscriptionUsuario } from './index.d';
import { useParams } from 'react-router-dom';

export const useQueryFilter = () => {
    const { unidadeId } = useParams<any>();

    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);

    const props = usePaginationAndFilter({
        dataKeyName: null,
        keysQueryVariavel: [
            'ativo',
            'nomeCompleto',
            'email',
            'telefoneCelular',
            // 'unidadeId',
        ],
    });

    // props.variables = {
    //     ...props.variables,
    //     filter: {
    //         ...props.variables.filter,
    //         unidadeId: unidadeId ? unidadeId : '',
    //     },
    // };

    const variables = useMemo(() => {
        return {
            ...props.variables,
            filter: {
                ...props.variables.filter,
                ...(props.variables.filter.ativo
                    ? { ativo: props.variables.filter.ativo === 'sim' }
                    : {}),
            },
        };
    }, [props]);

    useSubscription<ISubscriptionUsuario>(GRAPHQL_USUARIO_SUBSCRIPTION, {
        variables,
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    usuario: { data },
                },
            },
        }) => props.onSubscriptionData(data),
    });

    const { loading } = useQuery<IQueryUsuario>(GRAPHQL_LISTAR_USUARIO, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables,
        onCompleted: ({ usuario }) => props.onCompleted(usuario),
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, dataSelectHeader };
};
