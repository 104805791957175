import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_ADICIONAR_USUARIO_UNIDADE } from 'gql/usuarioUnidade';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useSaveCompanyAdministrator = () => {
    const { unidadeId } = useParams<any>();
    const history = useModalHistory();
    const formRef: any = useRef<FormHandles>(null);
    const { alertConfirm } = useContext(AuthContext);
    const [handleSaveUserUnit, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_USUARIO_UNIDADE
    );

    const handleSubmit: SubmitHandler<any> = useCallback(async (data) => {
        try {
            const usuarioIds = data.usuarioId.filter(
                (value) => value.trim().length > 0
            );
            if (unidadeId.trim().length < 10) {
                throw new Error(
                    'É necessário selecionar um estabelecimento válido para poder adicionar usuários para o administrar'
                );
            }
            if (usuarioIds.length > 0) {
                await handleSaveUserUnit({
                    variables: { unidadeId, usuarioIds },
                });
                history.goBack();
                await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Adicionar administradores',
                    conteudo:
                        'Adição de administradores ao estabelecimento realizado com sucesso',
                });
            } else {
                throw new Error(
                    'Selecione pelo menos um usuário para administrar este estabelecimento'
                );
            }
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message,
            });
        }
    }, []);

    return { ref: formRef, onSubmit: handleSubmit };
};
