import { DropdownMenu } from 'components/DropdownMenu';
import Icone from 'components/Icone';
import React, { FC, memo } from 'react';
import { ButtonAction, Container } from './styl';

interface ITableColumnDefault {
    titulo?: string;
    styleTitulo?: {};
    subtitulo?: string;
    descricao?: string;
    descricao2?: string;
    styleSubtitulo?: {};
    temaBotao?: string;
    flexDirection?: string;
    flexWrap?: string;
    width: string;
    marginRight: string;
    botoes?: Array<{
        titulo?: string;
        placeholder?: string;
        hidden?: boolean;
        disabled?: boolean;
        click?: any;
        data?: object;
        icone: string;
        color: string;
        status?: string;
    }>;
    botoesInline?: Array<{
        titulo?: string;
        placeholder?: string;
        hidden?: boolean;
        disabled?: boolean;
        click?: any;
        data?: object;
        icone: string;
        color: string;
        status?: string;
    }>;
    botoesInlineImage?: Array<{
        titulo?: string;
        placeholder?: string;
        hidden?: boolean;
        disabled?: boolean;
        click?: any;
        data?: object;
        image: string;
        color: string;
        status?: string;
    }>;
}
const isStringUrl = (url: string) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}
const TableColumnTexto: FC<ITableColumnDefault> = memo(props => {
    return (
        <Container>
            <div className={props.flexDirection || ''}>
                <div className={'tituloSub'}>
                    <p style={props.styleTitulo}>{props.titulo}</p>
                    {props.subtitulo && (
                        <span style={props.styleSubtitulo}>
                            {props.subtitulo}
                        </span>
                    )}
                    {props.descricao && (
                        <span style={props.styleSubtitulo}>
                            {props.descricao}
                        </span>
                    )}
                    {props.descricao2 && (
                        <span style={props.styleSubtitulo}>
                            {props.descricao2}
                        </span>
                    )}
                </div>
                {props.botoesInline && props.botoesInline.length > 0 && (
                    <div className={`containerBotao ${props.flexWrap || ''}`}>
                        {props.botoesInline?.map((botao, index) => {
                            return (
                                <div className='botoesAcao' key={`${index}botoes`}>
                                    {!botao.hidden && (
                                        <>
                                            {props.temaBotao === '2' ? (
                                                <button
                                                    key={`${index}botoes`}
                                                    onClick={() => {
                                                        botao.click(botao.data);
                                                    }}
                                                    data-tip={
                                                        botao.placeholder ||
                                                        botao.titulo
                                                    }
                                                    data-for="tooltipBotoesAcao"
                                                    className={`${
                                                        botao.status || 'vazio'
                                                    } tema2`}>
                                                    <span>{botao.titulo}</span>
                                                    <Icone
                                                        icone={botao.icone}
                                                        color={botao.color}
                                                    />
                                                </button>
                                            ) : (
                                                <ButtonAction
                                                    key={`${index}botoes`}
                                                    onClick={() => {
                                                        if (
                                                            botao.disabled ===
                                                                undefined ||
                                                            botao.disabled ===
                                                                false
                                                        )
                                                            botao.click(
                                                                botao.data,
                                                            );
                                                    }}
                                                    data-tip={
                                                        botao.placeholder ||
                                                        botao.titulo
                                                    }
                                                    data-for="tooltipBotoesAcao">
                                                    {!isStringUrl(botao.icone) ?
                                                    <Icone
                                                        icone={botao.icone}
                                                        color={
                                                            botao.color ||
                                                            (botao.disabled ===
                                                            true
                                                                ? 'var(--placeholder)'
                                                                : 'var(--btn-admin-icone)')
                                                        }
                                                    />
                                                    : <img height={20} src={botao.icone} alt={botao.titulo} />}
                                                </ButtonAction>
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {props.botoes && props.botoes.length > 0 && (
                    <div className={`containerBotao ${props.flexWrap || ''}`}>
                        <DropdownMenu
                            classe={'menu-mobile'}
                            botoes={props.botoes}
                            alignRight={true}
                        />
                    </div>
                )}
            </div>
        </Container>
    );
});
export default TableColumnTexto;
