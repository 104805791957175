import { useMutation } from '@apollo/client';
import { GRAPHQL_ADICIONAR_VAGAS_ESCALA } from 'gql/periodo';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useMutationPeriodo = () => {
    const history = useHistory();

    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaPeriodo, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_VAGAS_ESCALA,
    );
    const atualizaPeriodo = useCallback(async response => {
        const data = Object.keys(response.vagas)
            .map(data => {
                const item = response.vagas[data];
                const periodoIds = Object.keys(item);
                const vagasQtd = Object.values(item);
                return periodoIds.map((periodoId, index) => ({
                    periodoId: +periodoId,
                    data,
                    vagas: +vagasQtd[index],
                }));
            })
            .flat();

        try {
            await handleAtualizaPeriodo({
                variables: {
                    data,
                },
            });
            alertConfirm({
                tipo: 'info',
                titulo: 'Sucesso',
                conteudo:
                    'Informações de configurações atualizado com sucesso!',
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    }, []);
    return {
        atualizaPeriodo,
        loading,
    };
};
