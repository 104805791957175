import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';
const ComponentEntregadorEditar: FC<any> = memo((oProps) => {
	useEffect(() => {
		document.title = `${defTitle} - Editar status`;
	});
	return (
		<SimplesForm
			rForm={oProps.rForm}
			handleSubmit={oProps.handleSubmit}
			loading={oProps.loading}
			titleSubmit={"Salvar"}
			titleModal={"Editar status"}
			message={'Selecione um status para o Player'}
		>
			<FormGenerator
				childs={[
					{
						type: 'select-simple',
						label: 'Status',
						placeholder: 'Selecione um status',
						name: 'status',
						options: oProps.status
					},
					...oProps.FormGenerator,
				]}
			/>
		</SimplesForm>
	);
});
export default ComponentEntregadorEditar;
