import React, { FC, useRef, useCallback, FormEvent } from 'react';

import { SubmitHandler, FormHandles } from '@unform/core';
import ComponentInformEmailView from './Component';

import * as yup from 'yup';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import { ISwitchComponent } from '../';
import { useRequestCode } from '../hooks/useRequestCode';

const initialData = {
	email: '',
};

const PageInformEmail: FC<ISwitchComponent | any> = ({
	setSwitchComponent,
	cacheEmail,
}) => {
	const { loading, requestCode } = useRequestCode({
		setSwitchComponent,
	});

	const formRef: any = useRef<FormHandles>(null);
	const handleSubmit: SubmitHandler<IFormLogin> = async (data, { reset }) => {
		const { setErrors } = formRef.current;
		try {
			await YupUtilsTry(data, setErrors, {
				email: yup
					.string()
					.email('Email inválido')
					.required('informe o email'),
			});
			requestCode(data);
		} catch (err) {
			YupUtilsCatch(err, setErrors);
		}
	};
	const handlePageCode = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			setSwitchComponent('enter-code-and-authorize-new-password');
		},
		[setSwitchComponent]
	);
	const props: any = {
		initialData,
		formRef,
		loading,
		setSwitchComponent,
		cacheEmail,
		handleSubmit,
		handlePageCode,
	};
	return <ComponentInformEmailView {...props} />;
};
export default PageInformEmail;
