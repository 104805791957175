import { useState } from 'react';

export const useCacheFiltroData = () => {
    // Armazena o filtro de data da listagem de dados
    const [dataCacheFiltroData, setDataCacheFiltroData] = useState(new Date());

    // useEffect(() => {
    // 	const filtroData = localStorage.getItem('@auth/filtroData');
    // 	if (!!filtroData) {
    // 		setCacheFiltroData(filtroData);
    // 	}
    // }, []);

    // Salvando cache do data
    function setCacheFiltroData(data) {
        localStorage.setItem('@auth/filtroData', data);
        setDataCacheFiltroData(data);
    }
    return {
        dataCacheFiltroData,
        setCacheFiltroData,
    };
};
