import React, {
    FC,
    useState,
    useContext,
    useRef,
    useEffect,
    useCallback,
} from 'react';
import { Background, Container, ContainerAll, Warp } from './styl';
import Icone from 'components/Icone';
import { AuthContext } from 'routes/auth.context';
const AlertConfirm: FC = () => {
    const [message, setMessage] = useState('');
    const warpRef = useRef(null);
    const {
        setDataAlertaConfirme,
        dataAlertaConfirme: {
            titulo = 'JogoRápido',
            conteudo,
            textOk = 'Ok',
            animationClose,
            tipo = 'info',
            textCancelar = 'Cancelar',
            reject,
            resolve,
        },
    } = useContext(AuthContext);

    const _animationend = useCallback(
        (_e) => {
            if (animationClose) {
                setDataAlertaConfirme({});
            }
        },
        [animationClose, setDataAlertaConfirme]
    );
    useEffect(() => {
        const current: any = warpRef.current;
        if (current !== null) {
            current.addEventListener('animationend', _animationend);
            return () => {
                current.removeEventListener('animationend', _animationend);
            };
        }
    });
    const handleChange = useCallback((event) => {
        setMessage(event.target.value);
    }, []);
    const handleOk = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            resolve && (await resolve(message));
            e.persist();
        },
        [resolve, message]
    );
    if (!conteudo) return null;

    return (
        <ContainerAll>
            <Background className={animationClose ? 'close' : ''} />
            <Container>
                <Warp
                    ref={warpRef}
                    className={`${tipo} ${animationClose ? 'close' : ''}`}
                >
                    <header>
                        <h1>{titulo}</h1>
                        <button onClick={reject}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </header>
                    <section>
                        <p dangerouslySetInnerHTML={{__html: conteudo}}></p>
                        {tipo === 'input' && (
                            <div>
                                <input
                                    name="embed"
                                    type="text"
                                    onChange={handleChange}
                                    value={message}
                                />
                            </div>
                        )}
                    </section>
                    <footer>
                        <button className="bg" onClick={handleOk}>
                            {textOk}
                        </button>
                        {'sucesso,info'.indexOf(tipo) === -1 && (
                            <button className="nobg" onClick={reject}>
                                {textCancelar}
                            </button>
                        )}
                    </footer>
                </Warp>
            </Container>
        </ContainerAll>
    );
};
export default AlertConfirm;
