import { useQuery } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { GRAPHQL_RECUPERAR_VAGAS_ESCALA } from 'gql/periodo';
import { useMemo, useEffect } from 'react';
export const useQuerySubscriptionFilter = () => {
    const props = usePaginationAndFilter({
        dataKeyName: 'dataEscala',
        keysQueryVariavel: [
            'dataEscala',
            'nomeCompleto',
            'status',
            'telefone',
            'email',
            'turno',
        ],
    });

    const dataSelectHeader = [];
    const variables = useMemo(() => {
        const clone = { ...props.variables.filter } as any;

        return { filter: clone };
    }, [props]);

    const { data, loading } = useQuery<any>(GRAPHQL_RECUPERAR_VAGAS_ESCALA, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables,
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, ...data, dataSelectHeader };
};
