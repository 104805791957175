import styled from 'styled-components';
import { txtCenterMobile } from 'routes/styl';
export const Grid = styled.div<any>`
	display: grid;
	grid-gap: 2rem;
	z-index: ${({ zIndex }) => zIndex || 1};
	grid-template-columns: ${({ flexStart }) => `${flexStart}fr 1fr`};
`;
export const ContainerPageSignup = styled.div<any>`
	${txtCenterMobile}
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.yourCompany {
		text-align: center;
	}
	.yourCompany p,
	.yourCompany > span {
		margin: 0;
		color: var(--text-cor);
	}
	.yourCompany > p:nth-child(1) {
		margin-top: 3rem;
	}
	.yourCompany form > p:nth-child(1n + 2) {
		margin: 1rem auto 1rem auto;
	}
	label {
		display: flex;
	}
	form {
		& > span {
			display: block;
			padding-bottom: 1rem;
		}
		.select:nth-child(3) {
			z-index: 3;
		}
		.select:nth-child(5) {
			z-index: 2;
		}
		.select:nth-child(7) {
			z-index: 1;
		}
		p:first-child {
			margin: 3rem auto 0.5rem auto;
		}
		p.pFatura {
			margin: 3rem auto;
		}
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		.space-button {
			width: 100%;
			display: flex;
			margin-top: 1.5rem;
		}
	}
`;
