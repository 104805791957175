import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { CheckBoxDisable } from 'components/Table/Columns/CheckBox/styl';
import { Container, LabelError, Input as InputField } from './styl';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    typeInput: string;
    options: {
        id: string;
        value: string;
        label: string;
    }[];
}
const CheckboxInput: React.FC<Props> = ({
    name,
    className,
    typeInput,
    options,
    ...rest
}) => {
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const {
        fieldName,
        registerField,
        defaultValue = [],
        clearError,
        error,
    } = useField(name);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue: (refs: HTMLInputElement[]) => {
                return refs
                    .filter((ref) => ref && ref.checked)
                    .map((ref) => (ref ? ref.value : ''));
            },
            clearValue: (refs: HTMLInputElement[]) => {
                refs.forEach((ref) => {
                    ref.checked = false;
                });
            },
            setValue: (refs: HTMLInputElement[], values: string[]) => {
                refs.forEach((ref) => {
                    if (values.includes(`${ref.value}`)) {
                        ref.checked = true;
                    } else {
                        ref.checked = false;
                    }
                });
            },
        });
    }, [defaultValue, fieldName, registerField]);
    return (
        <Container erro={error}>
            <div className={className}>
                {options.map((option, index) => {
                    const id = option.id || option.value;
                    return (
                        <label htmlFor={id} key={id}>
                            <InputField
                                defaultChecked={defaultValue.find(
                                    (dv: string) => dv === id
                                )}
                                ref={(ref) => {
                                    inputRefs.current[index] =
                                        ref as HTMLInputElement;
                                }}
                                name={name}
                                value={option.value}
                                type={typeInput}
                                id={`${id}`}
                                {...rest}
                            />
                            <span className={'inputClick'} />
                            <CheckBoxDisable icone="icon-certo" size="2rem" />
                            <span className={'customLabel'}>
                                {option.label}
                            </span>
                        </label>
                    );
                })}
            </div>
            {error && <LabelError erro={error}>{error} </LabelError>}
        </Container>
    );
};
export default CheckboxInput;
