import { FormHandles } from '@unform/core';
import { defTitle } from 'assets/strings';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC, useEffect } from 'react';
import * as yup from 'yup';

import ComponentNotificacaoPush from './Component';

const PageNotificacaoPush: FC = () => {
    const rForm = createRef<FormHandles>();
    useEffect(() => {
        document.title = `${defTitle} - Notificar`;
    });
    const handleSubmit = async (Data) => {
        const { setErrors } = rForm.current as FormHandles;
        try {
            await YupUtilsTry(Data, setErrors, {
                mensagem: yup.string().required('Selecione uma mensagem.'),
            });

            // if (result) history.goBack();
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };

    const oProps = {
        rForm,
        handleSubmit,
    };
    return <ComponentNotificacaoPush {...oProps} />;
};
export default PageNotificacaoPush;
