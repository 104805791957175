import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Container, LabelError } from '../styl';
import './datePicker.scss';
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt);
interface IProps extends IInput {
    minDate?: Date;
    maxDate?: Date;
}
const InputDate: React.FC<IProps> = ({
    name,
    label,
    icon,
    className,
    defaultValue,
    onChange,
    ...rest
}) => {
    const datepickerRef = useRef(null);
    const { fieldName, registerField, error } = useField(name);
    const [date, setDate] = useState(defaultValue || null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: datepickerRef.current,
            path: 'props.selected',
            clearValue: (ref: any) => {
                ref.clear();
            },
        });
    }, [fieldName, registerField]);

    const handleChange = useCallback(date => {
        setDate(date);
        onChange && onChange(date);
    }, []);
    return (
        <Container erro={error}>
            <div className={`jr-warp-date-picker ${className || ''}`}>
                <DatePicker
                    {...rest}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    shake={error ? 1 : undefined}
                    ref={datepickerRef}
                    selected={date}
                    onChange={handleChange}
                />
                {icon}
            </div>
            <LabelError erro={error}>{error}</LabelError>
        </Container>
    );
};
export default InputDate;
