import styled from 'styled-components';
import { boxShadow } from 'routes/styl';
export const Container = styled.button<{
    [key: string]: any;
}>`
    width: 100%;
    border-radius: 1.5rem;
    height: 5.5rem;
    background: var(--btn-gradient-active-cor);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    ${props => props}
    span {
        color: var(--bg-white);
        font-size: var(--size-h4);
        transition: transform 0.3s ease-out;
    }
    background-size: 150% 5.5rem;
    &:hover {
        background-position: 100%;
        ${boxShadow}
    }
    &:disabled span {
        transform: translateX(-2rem);
    }
    &:disabled {
        background-position: 100%;
        cursor: none;
    }
`;
