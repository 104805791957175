import React, { FC, useEffect } from 'react';
import { IRecuperarEntregadoresEscaladosHoje } from '.';
import { InfoWarp } from './styl';
import ReactTooltip from 'react-tooltip';
import { Item } from './Item';
import { GRAPHQL_RECUPERAR_VIEWPORT } from 'gql/entregador';
// import { useQuery } from '@tanstack/react-query';
import { useClientApollo } from 'routes/hooks/useClientApollo';
import { useQuery } from '@apollo/client';
export interface IPropsViewInfoPlayer {
    totalPlayerEscalados?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayersOnline?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayersOnlineDesocupado?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayerOffline?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayerOfflineOcupado?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayerOfflineDesocupado?: IRecuperarEntregadoresEscaladosHoje[];
    totalPlayerBusy?: IRecuperarEntregadoresEscaladosHoje[];
    onChange?: (name: string) => void;
}
export interface IPropsViewInfoColeta {
    coletasSemPlayer?: Number;
    coletasNaoConcluidas?: Number;
}
export const ViewInfoPlayer: FC<IPropsViewInfoPlayer & IPropsViewInfoColeta> =
    ({
        totalPlayerEscalados = [],
        totalPlayersOnline = [],
        totalPlayerOffline = [],
        totalPlayersOnlineDesocupado = [],
        totalPlayerOfflineOcupado = [],
        totalPlayerOfflineDesocupado = [],
        coletasSemPlayer = 0,
        coletasNaoConcluidas = 0,
        totalPlayerBusy = [],
        onChange,
    }) => {
        const { client } = useClientApollo();
        const [totalColetasSemPlayer, setTotalColetasSemPlayer] = React.useState(0);
        useEffect(() => {
            ReactTooltip.rebuild();
        }, []);
        const { data }: {
            data: {
                recuperarViewPort: {
                    totalEntregadoresEscalados: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresOnline: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresOffline: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresOcupados: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresOcupadosOnline: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresOcupadosOffline: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresDesocupados: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresDesocupadosOnline: {
                        nomeCompleto: string;
                    }[];
                    totalEntregadoresDesocupadosOffline: {
                        nomeCompleto: string;
                    }[];
                    coletasNaoConcluidas: {
                        ultimoDataStatus: String
                        coletaId: String
                        entregadorId: String
                        numeroInterno: String
                        clienteNomeCompleto: String
                        clienteLatitude: String
                        clienteLongitude: String
                        unidadeNomeCompleto: String
                        unidadeLatitude: String
                        unidadeLongitude: String
                    }[]
                    coletasSemPlayer: Number;
                }
            }
        } = useQuery(GRAPHQL_RECUPERAR_VIEWPORT, {
            fetchPolicy: 'no-cache',
            pollInterval: 5000,
        });
        useEffect(() => {
            if (data?.recuperarViewPort) {
                const totalColetasSemPlayer = data?.recuperarViewPort?.coletasNaoConcluidas.filter((coleta) => !coleta.entregadorId).length;
                setTotalColetasSemPlayer(totalColetasSemPlayer);
            }
        } , [data?.recuperarViewPort]);
        return (
            <>
                <InfoWarp>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresEscalados || []}
                        name="entregadores"
                        className="escalado"
                        data-for="online-garantido"
                    >
                        Players
                        <br />
                        Escalados
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresOcupados || []}
                        name="totalPlayerBusy"
                        className="online"
                        data-for="online-garantido"
                    >
                        Players
                        <br />
                        ocupados
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresOcupadosOffline || []}
                        name="totalPlayerOfflineOcupado"
                        className="pendentes"
                        data-for="online-garantido"
                    >
                        Players offline
                        <br />
                        ocupados
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresDesocupadosOffline || []}
                        name="totalPlayerOfflineDesocupado"
                        className="offline"
                        data-for="online-garantido"
                    >
                        Players offline
                        <br />
                        desocupado
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresDesocupadosOnline || []}
                        name="totalPlayersOnlineDesocupado"
                        className="online"
                        data-for="online-garantido"
                    >
                        Players online
                        <br />
                        desocupado
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresOnline || []}
                        name="totalPlayersOnline"
                        className="online"
                        data-for="online-garantido"
                    >
                        Players
                        <br />
                        Online
                    </Item>
                    <Item
                        onChange={onChange}
                        dataTip={data?.recuperarViewPort?.totalEntregadoresOffline || []}
                        name="totalPlayerOffline"
                        className="offline"
                        data-for="online-garantido"
                    >
                        Players
                        <br />
                        Offline
                    </Item>

                    <dl className="sem-player">
                        <dt>
                            Coletas
                            <br />
                            Sem player
                        </dt>
                        <dd>{data?.recuperarViewPort?.coletasSemPlayer}</dd>
                    </dl>
                    <dl className="nao-concluidas">
                        <dt>
                            Coletas
                            <br />
                            Não concluidas
                        </dt>
                        <dd>{data?.recuperarViewPort?.coletasNaoConcluidas.length ?? 0}</dd>
                    </dl>
                </InfoWarp>
                <ReactTooltip
                    html={true}
                    backgroundColor="var(--btn-admin-icone)"
                    className="tool-tip"
                    place="top"
                    data-type="info"
                    effect="solid"
                    id="online-garantido"
                />
            </>
        );
    };
