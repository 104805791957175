import {
    botaoCirculo,
    // transitionEaseBack,
    botaoCirculoHover,
    boxShadow,
} from 'routes/styl';
import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    min-width: 60rem;
    max-width: 100rem;

    transition: 0.3s width ease-in-out;
    ${boxShadow};
    padding: 2rem;
    > div.warp-loading {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export const WarpMap = styled.div`
    background-color: var(--bg-color);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        margin-top: 0px;
        padding-top: 0px;
        line-height: 1.8rem;
        display: flex;
        display: block;
        span {
            font-size: 1.8rem;
        }
        span ~ span {
            font-size: 2.5rem;
        }
    }
    .hstack {
        button {
            cursor: pointer;
            margin-bottom: 2px;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            color: white;
            justify-content: space-between;
            &.add {
                background-color: var(--success-cor);
            }
            &.remove {
                background-color: var(--error-cor);
                margin-left: 1rem;
            }
        }
        button.add:hover {
            background-color: var(--green);
        }
        button.remove:hover {
            background-color: var(--red);
        }
    }
`;
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h1 {
        color: var(--btn-admin-icone);
    }
    button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
`;
