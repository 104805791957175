import styled from 'styled-components';
export const stylMap = {
    width: '100%',
    height: '100%',
};
export const WarpLoad = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--bg-modal);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Container = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
export const Message = styled.h2`
    color: var(--text-color);
    font-size: 3rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
`;
export const BlcMessage = styled.h2`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100vh;
`;
