import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import ComponentStepByStep from 'components/StepByStep';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PageNavigateSelecionarCliente from './cliente';
import PageNavigateSelecionarEndereco from './endereco';
import { useContainerDataForm } from './hooks/useContainerDataForm';
import { useTabs } from './hooks/useTabs';
import PageNavigateSelecionarPagamento from './pagamento';
import { Container, Header } from './styl';
export interface IPropsNavigateForms {
    next: number;
    current: number;
    success: boolean;
    data?: any;
}
export const PageNavegarCadastrarColeta: FC = memo(() => {
    const [minWidthModal, setMinWidthModal] = React.useState('50rem');
    const { formRef, handleSetDataForm, dataForm } = useContainerDataForm();

    const {
        tabs,
        indexSelected,
        itemSelected,
        handleSelectTab,
        handleFormTabSuccess,
    } = useTabs();

    const handleNavigateSteps = useCallback(
        ({ proximo }) => {
            const indexTabNext = Number(proximo);
            handleSetDataForm();
            handleSelectTab(indexTabNext);
        },
        [handleSetDataForm, handleSelectTab],
    );

    const handleNavigateForms = useCallback(
        (props: IPropsNavigateForms) => {
            handleSetDataForm(props?.data);
            handleFormTabSuccess(props);
            handleSelectTab(props.next);
        },
        [handleSetDataForm, handleFormTabSuccess, handleSelectTab],
    );

    const componentAbas = useMemo(() => {
        switch (indexSelected) {
            case 0:
                return (
                    <PageNavigateSelecionarCliente
                        formRef={formRef}
                        setMinWidthModal={setMinWidthModal}
                        handleNavigateForms={handleNavigateForms}
                        {...dataForm}
                    />
                );
            case 1:
                return (
                    <PageNavigateSelecionarEndereco
                        formRef={formRef}
                        setMinWidthModal={setMinWidthModal}
                        handleNavigateForms={handleNavigateForms}
                        {...dataForm}
                    />
                );
            case 2:
                return (
                    <PageNavigateSelecionarPagamento
                        formRef={formRef}
                        setMinWidthModal={setMinWidthModal}
                        handleNavigateForms={handleNavigateForms}
                        {...dataForm}
                    />
                );
        }
    }, [indexSelected, dataForm]);

    return (
        <Modal
            render={({ closeModal }) => (
                <Container style={{ minWidth: minWidthModal }}>
                    <Header>
                        <h1>{itemSelected.titulo}</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <ComponentStepByStep
                        stepSelected={indexSelected}
                        fases={tabs}
                        submit={handleNavigateSteps}
                        colorSeparator="var(--bg-white)"
                    />
                    <SwitchTransition>
                        <CSSTransition
                            key={`css-transition-${indexSelected}`}
                            timeout={400}
                            mountOnEnter={true}
                            unmountOnExit={true}
                            classNames="switch-component">
                            {componentAbas}
                        </CSSTransition>
                    </SwitchTransition>
                </Container>
            )}
        />
    );
});
