import {
    GoogleMap,
    Marker,
    MarkerClusterer,
    useJsApiLoader,
} from '@react-google-maps/api';
import { ClustererOptions } from '@react-google-maps/marker-clusterer';
import clienteComPlayer from 'assets/images/cliente-com-player.png';
import clienteSemPlayer from 'assets/images/cliente-sem-player.png';
import unidadeIcon from 'assets/images/estabelecimento-entregador.png';
import playerOfflineCarregado from 'assets/images/player-offline-carregado.png';
import playerOfflineVazio from 'assets/images/player-offline-vazio.png';
import playerOnlineCarregado from 'assets/images/player-online-carregado.png';
import playerOnlineVazio from 'assets/images/player-online-vazio.png';
import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import styleMaps from 'components/atom/map/styleMaps.json';
import { Input } from 'components/Form/Input/styl';
import {
    Container as WarpSearch,
    IconeSearch,
} from 'components/SuperFiltro/Search/styl';
import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { AuthContext } from 'routes/auth.context';
import { ViewInfoPlayer } from '../../../components/infoPlayer/ViewInfoPlayer';
import { useQueryColetasEntregadores } from './hooks/useQueryColetasEntregadores';
import { Container, ContainerSearch, stylMap, WarpLoading } from './styl';
const statusCluster = {
    online: {
        styles: [
            {
                width: 52,
                height: 52,
                textColor: '#fff',
                textSize: 14,
                url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
            },
        ],
    } as ClustererOptions,
    offline: {
        styles: [
            {
                width: 52,
                height: 52,
                textColor: '#fff',
                textSize: 14,
                url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
            },
        ],
    } as ClustererOptions,
};
const initialLocation = { lat: -16.7333165, lng: -49.2869242 };
const ListarColetasModoMapa: FC<any> = () => {
    const { configuracao } = useContext(AuthContext);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const data = useQueryColetasEntregadores();
    const keyMaps = useMemo(() => {
        return configuracao.find(({ chave }) => chave === 'key_maps').valor;
    }, [configuracao]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: keyMaps,
    });

    useEffect(() => {
        document.title = `${defTitle} - Localização`;
    });
    useEffect(() => {
        if (
            window?.google?.maps?.LatLng &&
            map &&
            data.recuperarColetasNaoConcluidas
        ) {
            if (data.refAutoCenter.current) {
                data.refAutoCenter.current = false;
                const { LatLngBounds, LatLng } = window?.google?.maps;
                const bounds = new LatLngBounds();
                let counterMarkers = 0;
                let startPlayer = null;
                data.recuperarEntregadoresPeriodoVigente.forEach(
                    (entregador) => {
                        counterMarkers++;
                        startPlayer = new LatLng(
                            entregador.entregadorLatitude,
                            entregador.entregadorLongitude
                        );
                        bounds.extend(startPlayer);
                    }
                );
                data.recuperarColetasNaoConcluidas.forEach((coleta) => {
                    counterMarkers++;
                    bounds.extend(
                        new LatLng(
                            coleta.clienteLatitude,
                            coleta.clienteLongitude
                        )
                    );
                });

                if (counterMarkers > 2) {
                    map.fitBounds(bounds);
                } else if (counterMarkers > 0 && startPlayer !== null) {
                    map.setZoom(16);
                    map.setCenter(startPlayer);
                } else {
                    map.setCenter(
                        new LatLng(initialLocation.lat, initialLocation.lng)
                    );
                }
            }
        }
    }, [
        map,
        data.recuperarEntregadoresPeriodoVigente,
        data.recuperarColetasNaoConcluidas,
        data.refAutoCenter,
    ]);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);
    let coletasSemPlayer = 0;
    let listMarkersKey = [];
    let counter = 0;
    return (
        <Container>
            {!data.loading && (
                <ContainerSearch onSubmit={data.execFilter}>
                    <WarpSearch>
                        <Input
                            name="search"
                            type="text"
                            placeholder="Pesquisar. Ex.entregador, cliente, estabelecimento"
                            ref={data.inputEl}
                        />
                        <button type="submit">
                            <IconeSearch
                                loadingSearch
                                icone="icon-search"
                                size="2.5rem"
                            />
                        </button>
                    </WarpSearch>
                </ContainerSearch>
            )}

            {isLoaded && (
                <GoogleMap
                    onLoad={onLoad}
                    center={initialLocation}
                    zoom={12}
                    onUnmount={onUnmount}
                    mapContainerStyle={stylMap}
                    options={{
                        styles: styleMaps as google.maps.MapTypeStyle[],
                        scrollwheel: true,
                        streetViewControl: false,
                        fullscreenControl: true,
                        mapTypeControl: false,
                    }}
                >
                    <MarkerClusterer
                        options={statusCluster.online}
                        gridSize={100}
                    >
                        {(clustererOnline) => {
                            const unidades = data.collectFiltered
                                ? data.recuperarColetasNaoConcluidas.map(
                                      (coleta, index) => {
                                          if (
                                              !coleta.entregadorId &&
                                              [1, 10, 11].indexOf(
                                                  coleta.statusId
                                              ) !== -1
                                          ) {
                                              coletasSemPlayer++;
                                          }
                                          const key = `unidade-${coleta.statusId}-${coleta.coletaId}`;
                                          if (
                                              listMarkersKey.indexOf(key) !== -1
                                          ) {
                                              return null;
                                          }
                                          listMarkersKey[index] = key;
                                          return (
                                              <Marker
                                                  zIndex={counter++}
                                                  key={key}
                                                  position={{
                                                      lat: coleta.unidadeLatitude,
                                                      lng: coleta.unidadeLongitude,
                                                  }}
                                                  title={
                                                      coleta.unidadeNomeCompleto
                                                  }
                                                  icon={unidadeIcon}
                                              />
                                          );
                                      }
                                  )
                                : [];

                            const clientes = data.collectFiltered
                                ? data.recuperarColetasNaoConcluidas.map(
                                      (coleta, index) => {
                                          const key = `cliente-${coleta.statusId}-${coleta.coletaId}`;
                                          if (
                                              listMarkersKey.indexOf(key) !== -1
                                          ) {
                                              return null;
                                          }
                                          listMarkersKey[index] = key;
                                          return (
                                              <Marker
                                                  zIndex={counter++}
                                                  key={key}
                                                  position={{
                                                      lat: coleta.clienteLatitude,
                                                      lng: coleta.clienteLongitude,
                                                  }}
                                                  title={
                                                      coleta.clienteNomeCompleto
                                                  }
                                                  icon={
                                                      coleta.entregadorId
                                                          ? clienteComPlayer
                                                          : clienteSemPlayer
                                                  }
                                              />
                                          );
                                      }
                                  )
                                : [];

                            const players =
                                data.recuperarEntregadoresPeriodoVigente.map(
                                    (entregador) => {
                                        if (!entregador.entregadorOnline) {
                                            return null;
                                        }
                                        return (
                                            <Marker
                                                zIndex={counter++}
                                                key={`entregador-${entregador.entregadorId}`}
                                                position={{
                                                    lat: entregador.entregadorLatitude,
                                                    lng: entregador.entregadorLongitude,
                                                }}
                                                title={`${
                                                    entregador.entregadorNomeCompleto
                                                } - ${
                                                    entregador.entregadorOnline
                                                        ? entregador.indiceColeta ===
                                                          -1
                                                            ? 'online (Vazio)'
                                                            : 'online (Carregado)'
                                                        : entregador.indiceColeta ===
                                                          -1
                                                        ? 'offline (vazio)'
                                                        : 'offline (carregado)'
                                                }`}
                                                clusterer={clustererOnline}
                                                icon={
                                                    entregador.entregadorOnline
                                                        ? entregador.indiceColeta ===
                                                          -1
                                                            ? playerOnlineVazio
                                                            : playerOnlineCarregado
                                                        : entregador.indiceColeta ===
                                                          -1
                                                        ? playerOfflineVazio
                                                        : playerOfflineCarregado
                                                }
                                            />
                                        );
                                    }
                                );
                            return players
                                .concat(
                                    data.recuperarEntregadoresPeriodoVigente
                                        .length === 1
                                        ? unidades
                                        : []
                                )
                                .concat(
                                    data.recuperarEntregadoresPeriodoVigente
                                        .length === 1
                                        ? clientes
                                        : []
                                )
                                .filter(Boolean);
                        }}
                    </MarkerClusterer>
                    <MarkerClusterer
                        options={statusCluster.offline}
                        gridSize={100}
                    >
                        {(clustererOnline) => {
                            const players =
                                data.recuperarEntregadoresPeriodoVigente.map(
                                    (entregador) => {
                                        if (entregador.entregadorOnline) {
                                            return null;
                                        }
                                        return (
                                            <Marker
                                                zIndex={counter++}
                                                key={`entregador-${entregador.entregadorId}`}
                                                position={{
                                                    lat: entregador.entregadorLatitude,
                                                    lng: entregador.entregadorLongitude,
                                                }}
                                                title={`${
                                                    entregador.entregadorNomeCompleto
                                                } - ${
                                                    entregador.entregadorOnline
                                                        ? entregador.indiceColeta ===
                                                          -1
                                                            ? 'online (Vazio)'
                                                            : 'online (Carregado)'
                                                        : entregador.indiceColeta ===
                                                          -1
                                                        ? 'offline (vazio)'
                                                        : 'offline (carregado)'
                                                }`}
                                                clusterer={clustererOnline}
                                                icon={
                                                    entregador.entregadorOnline
                                                        ? entregador.indiceColeta ===
                                                          -1
                                                            ? playerOnlineVazio
                                                            : playerOnlineCarregado
                                                        : entregador.indiceColeta ===
                                                          -1
                                                        ? playerOfflineVazio
                                                        : playerOfflineCarregado
                                                }
                                            />
                                        );
                                    }
                                );
                            return players.filter(Boolean);
                        }}
                    </MarkerClusterer>
                </GoogleMap>
            )}
            {data.loading ? (
                <WarpLoading>
                    <CircularLoading
                        size={3}
                        sizeBorder={2}
                        primaryColor="var(--btn-admin-icone)"
                        show={true}
                    />
                    <p>Procurando player</p>
                </WarpLoading>
            ) : (
                <ViewInfoPlayer
                    onChange={data.execFilterPlayers}
                    totalPlayersOnlineDesocupado={
                        data.totalPlayersOnlineDesocupado
                    }
                    coletasNaoConcluidas={
                        data.recuperarColetasNaoConcluidas.length
                    }
                    coletasSemPlayer={coletasSemPlayer}
                    totalPlayerEscalados={data.entregadores}
                    totalPlayerBusy={data.totalPlayerBusy}
                    totalPlayerOffline={data.totalPlayerOffline}
                    totalPlayersOnline={data.totalPlayersOnline}
                    totalPlayerOfflineOcupado={data.totalPlayerOfflineOcupado}
                    totalPlayerOfflineDesocupado={
                        data.totalPlayerOfflineDesocupado
                    }
                />
            )}
        </Container>
    );
};
export default ListarColetasModoMapa;
