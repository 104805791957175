import ListTemplate from 'components/template/ListTemplate';

import React, { FC } from 'react';
import { useQueryFilterFatura } from './hooks/useQueryFilterFatura';
import { useHeaderFatura } from './hooks/useHeaderFatura';
import { useQueryPermissions } from 'pages/coleta/listar/hooks/useQueryPermissions';
import { useTransformFatura } from './hooks/useTransformFatura';

const PageListFatura: FC<any> = () => {
    const props = useQueryFilterFatura();

    const permissions = useQueryPermissions();
    const colunas = useHeaderFatura(permissions);
    const data = useTransformFatura(props, permissions);

    return (
        <ListTemplate
            browserTitle="Empresa"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default PageListFatura;
