import React, { FC } from 'react';
import ButtonLoading from 'components/atom/ButtonLoading';
import { useMutation } from '@apollo/client';
import { GRAPHQL_WPP_LOGOUT_SUBSCRIPTION } from 'gql/whatsapp';
import { Form } from '@unform/web';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
export const Connected: FC = () => {
    const history = useModalHistory();
    const [handleLogout, { loading }] = useMutation(
        GRAPHQL_WPP_LOGOUT_SUBSCRIPTION,
        {
            onCompleted: () => {
                history.popTo();
            },
        }
    );
    return (
        <Form onSubmit={() => handleLogout()}>
            <p className="mb">
                Seu whatsapp está conectado ao sistema. Caso queira
                desconecta-lo basta clicar no botão abaixo
            </p>
            <ButtonLoading value="Desconectar whatsapp" loading={loading} />
        </Form>
    );
};
