import { useCallback, useContext, useState } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IReceiver } from '../../cliente';

export const useSelectSubmit = (props: IReceiver) => {
    const [hasNewAddress, setHasNewAddress] = useState(!!props.endereco);
    const { alertConfirm } = useContext(AuthContext);
    const handleListarEnderecos = useCallback(() => {
        setHasNewAddress(true);
    }, []);
    const submit = useCallback(
        async ({ endereco: [sEndereco], cartoes, ...rest }) => {
            if (sEndereco === 'novo-endereco') {
                setHasNewAddress(false);
            } else {
                const { latitude, longitude, frete: fretes } = rest;
                const { distancia, precisao } = fretes.find(
                    ({ id }) => id === '1'
                );
                if (precisao > 4) {
                    alertConfirm({
                        tipo: 'erro',
                        titulo: 'Seleção de endereço',
                        conteudo: `O endereço selecionado está com uma margem de erro de ${precisao}km. Favor cadastre novamente o endereço para que possamos recalcular o frete`,
                    });
                } else {
                    props.handleNavigateForms({
                        next: 2,
                        current: 1,
                        success: true,
                        data: {
                            latitude,
                            longitude,
                            endereco: sEndereco,
                            distancia,
                            precisao,
                            cartoes,
                            fretes,
                        },
                    });
                }
            }
        },
        [props]
    );
    return {
        hasNewAddress,
        setHasNewAddress,
        submit,
        submitLoading: false,
        handleListarEnderecos,
    };
};
