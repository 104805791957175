import styled, { css } from 'styled-components';
import { transitionEaseBack } from 'routes/styl';
import { Link } from 'react-router-dom';
const cssContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    & > p {
        text-align: left;
        flex: 1;

    }
    input {
        display: none;
    }
    min-height: 4.5rem;

    &.group {
        padding: 0 1rem 0.8rem 1rem;
        position: relative;
        &:nth-child(1) {
            padding-top: 3rem;
            margin-bottom: 1rem;
        }
        &:nth-child(1n + 2) {
            margin-top: 3rem;
        }
        &:nth-child(1n + 2)::after {
            content: '';
            position: absolute;
            top: -1rem;
            left: 2rem;
            right: 2rem;
            height: 1px;
            background-color: var(--bg-hover-light);
        }
        & .wrap-text > .font-ic {
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1.6rem;
        }
        & > div,
        & > p {
            ${transitionEaseBack}
        }
        & > p {
            display: flex;
            flex: 1;
            color: var(--placeholder);
        }
        & > span,
        & .wrap-text > span {
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
            margin-right: 0.5rem;
            color: var(--btn-admin-icone);
        }
        .card {
            ${transitionEaseBack};
            visibility: hidden;
            pointer-events: none;
            left: calc(100% + 2rem);
            opacity: 0;
            transform: translate3d(-5rem, 0rem, 0rem) rotate(5deg) scale(0.8);

            @media (max-width: 1439.8px) {
                display: none;
                position: relative;
                top: inherit;
                left: inherit;
                transform: scale(0);
            }

        }
        &:hover > span,
        &:hover .wrap-text > span,
        input:checked ~ .wrap-text p ~ span {
            opacity: 1;
        }
        input:checked ~ .card {
            visibility: visible;
            pointer-events: auto;
            opacity: 1;
            transform: translate3d(-5rem, 0rem, 0rem) rotate(0deg) scale(1);

            @media (max-width: 1439.8px) {
                position: relative;
                display: block;
                transform: scale(1);
            }

        }
        &:nth-child(1) input:checked ~ .card {
            transform: translate3d(-5rem, 1rem, 0rem) rotate(0deg) scale(1);

            @media (max-width: 1439.8px) {
                transform: none;
            }
        }
    }
    &.grouper {
        position: relative;
        font-weight: normal;
        &.notfirst {
            margin-top: 3rem;
        }
        &.first {
            margin-top: 1rem;
        }
        margin-left: 1rem;
        &.notfirst::after {
            content: '';
            position: absolute;
            top: -1rem;
            left: 0rem;
            right: 2rem;
            height: 1px;
            background-color: var(--bg-hover-light);
        }
    }
    &.item {
        z-index: 1;
        transform: translateX(-5px);
        ::before {
            content: '';
            width: 5px;
            height: 100%;
            opacity: 0;
            transform: translateX(-5px);
            background-color: var(--btn-admin-icone);
        }
        transition: all 200ms ease-out;
        &:hover {
            transform: translateX(0px);
            background-color: var(--btn-admin-icone-hover);
            p {
                color: var(--btn-admin-icone);
            }
            .font-ic {
                color: var(--btn-admin-icone);
            }
            ::before {
                opacity: 1;
                transform: translateX(0px);
            }
        }
        .font-ic {
            margin: 0 1rem 0 1rem;
            color: var(--btn-admin-icone);
        }
        p {
            color: var(--text-cor);
        }
    }
`;
// .attrs({ className: 'label-item-sd' })
export const WrapText = styled.div.attrs({ className: 'wrap-text', highlights: true })<any>`
    ${cssContainer}
    width: 100%;
    min-height: 4.5rem;
`;
export const WarpLabel = styled.label<any>`
    cursor: pointer;
    ${cssContainer}
    flex-direction: column;
    position: relative;
    ${({ zIndex }) => (zIndex ? `z-index:${zIndex};` : '')}
`;
export const WarpLink = styled(Link)<any>`
    ${cssContainer}

    ${({ to }) => {
        return to && to.state && to.state.zIndex
            ? `z-index:${to.state.zIndex};`
            : '';
    }}
    ${({ highlights }) => (highlights ? `background-color:var(--btn-admin-icone-hover);` : '')}
`;
export const WarpNotLink = styled.div<any>`
    ${cssContainer}
    ${({ zIndex }) => (zIndex ? `z-index:${zIndex};` : '')}
`;
