import React, { FC, useRef, useEffect, useCallback } from 'react';
import Select from 'react-select/creatable';
import { useField } from '@unform/core';
import { colourStyles, Container, LabelError } from './styl';

const selectStyles = colourStyles();
const SelectCreatable: FC<any> = ({ name, ...rest }) => {
    const selectRef = useRef(null);
    const {
        fieldName,
        registerField,
        defaultValue,
        clearError,
        error,
    } = useField(name);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField]);
    const handlerFocus = useCallback(
        (e) => {
            clearError();
        },
        [clearError]
    );
    return (
        <Container erro={error}>
            <Select
                error={error}
                onFocus={handlerFocus}
                styles={selectStyles}
                defaultValue={{
                    label: defaultValue,
                    value: defaultValue,
                }}
                ref={selectRef}
                classNamePrefix="react-select"
                {...rest}
            />
            <LabelError erro={error}>{error}</LabelError>
        </Container>
    );
};
export default SelectCreatable;
