import React, { FC, memo, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { ContainerPageSignin, WarpLink } from './styl';
import Input from 'components/Form/Input';
import InputPassword from 'components/Form/Input/InputPassword';
import { defTitle } from 'assets/strings';
import ButtonLoading from 'components/atom/ButtonLoading';
const PagesSigninView: FC<any> = ({
    signed,
    formRef,
    handleSubmit,
    cacheEmail,
    loading,
}) => {
    useEffect(() => {
        document.title = `${defTitle} - Conectar`;
    });
    if (signed) {
        return (
            <Redirect
                to="/admin/coleta"
                push={false}
            />
        );
    }
    return (
        <ContainerPageSignin>
            <h1>Olá, tudo bem?</h1>
            <p>Entre na sua conta e faça seu pedido em poucos minutos.</p>
            <Form
                ref={formRef}
                onSubmit={handleSubmit}>
                <label htmlFor="email">E-mail</label>
                <Input
                    name="email"
                    defaultValue={cacheEmail}
                />

                <label htmlFor="senha">Senha</label>
                <InputPassword name="senha" />

                <WarpLink>
                    <Link to="/recover-sign-in">Esqueci minha senha</Link>
                </WarpLink>
                <div className="space-button">
                    <ButtonLoading
                        value="Entrar"
                        loading={loading}
                    />
                </div>
                <WarpLink>
                    <span>Não tem uma conta?</span>
                    <Link to="/signup"> Crie minha conta agora</Link>
                </WarpLink>
            </Form>
        </ContainerPageSignin>
    );
};
export default memo(PagesSigninView);
