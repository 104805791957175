import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_ENVIO_NOTIFICACAO_UNIDADE_FATURA } from 'gql/unidade';
import { GRAPHQL_BUSCAR_UNIDADE_FATURA } from 'gql/unidadeFatura';
import { createRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { TIPO_NOTIFICACAO } from '../data/tipoNotificacao';
export const useQuerySend = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const { faturaId, tipoNotificacao } = useParams<any>();
    const formRef = createRef<FormHandles>();
    const [sendNotification, { loading }] = useMutation(
        GRAPHQL_ENVIO_NOTIFICACAO_UNIDADE_FATURA
    );

    const handleSubmit: SubmitHandler<any> = async (data) => {
        try {
            await sendNotification({
                variables: {
                    tipoEnvioNotificacao: Number(data.tipoEnvioNotificacao),
                    tipoNotificacao: Number(tipoNotificacao),
                    faturaId,
                    mensagem: data.mensagem,
                },
                refetchQueries:
                    Number(tipoNotificacao) === 2
                        ? [
                              {
                                  query: GRAPHQL_BUSCAR_UNIDADE_FATURA,
                                  context: {
                                      headers: {
                                          'current-page': '0',
                                      },
                                  },
                                  variables: {
                                      filter: {},
                                  },
                              },
                          ]
                        : [],
            });
            alertConfirm({
                tipo: 'info',
                titulo: 'Notificação',
                conteudo: 'Cliente notificado com sucesso',
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    };

    return {
        formRef,
        loading,
        handleSubmit,
        ...TIPO_NOTIFICACAO[tipoNotificacao],
    };
};
