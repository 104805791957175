import { useMutation } from '@apollo/client';
import { GRAPHQL_ATUALIZAR_ENTREGADOR_CONTA } from 'gql/entregador';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { useHistory } from 'react-router-dom';
export const useEntregadorContaAlterar = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConta, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_ENTREGADOR_CONTA
    );
    const atualizaEntregadorConta = useCallback(
        async ({ entregadorId, data }) => {
            try {
                await handleAtualizaConta({
                    variables: {
                        entregadorId,
                        data,
                    },
                });
                history.goBack();
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        },
        []
    );
    return {
        atualizaEntregadorConta,
        loading,
    };
};
