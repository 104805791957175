import React, { FC, memo } from 'react';
import logo from 'assets/images/logo.svg';
import banner from 'assets/images/banner.jpg';
import FooterSocial from '../footerSocial';
import { ContainerTemplateAuth, Hr, WarpTemplateAuth } from './styl';
const TemplateAuth: FC<any> = ({ children }) => {
	return (
		<>
			<Hr />
			<ContainerTemplateAuth>
				<header>
					<img src={logo} className="img-fluid" alt="" />
				</header>
				<WarpTemplateAuth>
					<div className="centerVertical">
						<figure>
							<img src={banner} alt="" />
						</figure>
						{children}
					</div>
				</WarpTemplateAuth>
				<FooterSocial />
			</ContainerTemplateAuth>
		</>
	);
};
export default memo(TemplateAuth);
