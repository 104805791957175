// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
import { ContainerPage404 } from './styl';

const Page404Component: FC<any> = ({ handlerBack }) => {
    return (
        <ContainerPage404>
            <h1>Page404</h1>
            <button onClick={handlerBack}>Voltar</button>
        </ContainerPage404>
    );
};
export default memo(Page404Component);
