import { parse, format } from 'date-fns';
export const prepareDados = (data) => {
    let variables: any = {
        ...(data.formaPagamento && data.formaPagamento !== ''
            ? { formaPagamento: data.formaPagamento }
            : {}),
        ...(data.periodoId && data.periodoId !== ''
            ? { periodoId: data.periodoId }
            : {}),
        ...(data.unidadeId ? { unidadeId: data.unidadeId } : {}),
        ...(data.entregadorId ? { entregadorId: data.entregadorId } : {}),
    };
    if (data.dataVencimentoBoleto) {
        variables.dataInicio = data.dataInicio;
        variables.dataFim = data.dataFim;

        variables.tipoRelatorio = data.tipoRelatorio;
        variables.dataVencimentoBoleto = data.dataVencimentoBoleto;
        const arquivos = [
            // ...data.notasFiscais.map(({ file }) => file),
            ...data.boletos.map(({ file }) => file),
        ];
        variables.unidadeIds = [
            // ...data.notasFiscais.map(({ id }) => id),
            ...data.boletos.map(({ id }) => id),
        ];
        const formData = new FormData();
        Object.keys(variables).forEach((key) => {
            const v = variables[key];
            if (typeof v === 'object' && v.length > 0) {
                v.forEach((value) => {
                    formData.append(key, value);
                });
            } else {
                formData.append(key, v);
            }
        });
        arquivos.forEach((file) => {
            formData.append('arquivos', file);
        });
        return formData;
    }
    variables.dataInicio = format(data.dataInicio, 'yyyy-MM-dd');
    variables.dataFim = format(data.dataFim, 'yyyy-MM-dd');
    return variables;
};
