import React, { memo, FC, useContext, useCallback } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ComponentStepByStep from 'components/StepByStep';
import PagePersonalData from './personalData';
import PageYourBusiness from './yourBusiness';
import PageYourCompany from './yourCompany';
import { SignupContext } from './context';
const SignupComponent: FC = memo(() => {
    const { switchComponent, steps, stepSelected, submit } = useContext(
        SignupContext
    );
    const renderComponent = useCallback(() => {
        switch (switchComponent) {
            case 'personal':
                return <PagePersonalData />;
            case 'business':
                return <PageYourBusiness />;
            case 'company':
            default:
                return <PageYourCompany />;
        }
    }, [switchComponent]);
    return (
        <div>
            <ComponentStepByStep
                stepSelected={stepSelected}
                fases={steps}
                submit={submit}
            />
            <SwitchTransition>
                <CSSTransition
                    key={switchComponent}
                    timeout={400}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    classNames="switch-component"
                >
                    {renderComponent()}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
});
export default SignupComponent;
