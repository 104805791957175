import React, { FC, memo, useCallback } from 'react';
import { ContainerBtnStep, IconeSucesso } from './styl';
interface IStep {
    nome: string;
    classes: string;
    colorSeparator?: string;
}
const Step: FC<any> = ({
    nome,
    classes,
    colorSeparator,
    indice,
    submit,
    setMinWidthModal,
}) => {
    const click = useCallback(() => {
        submit({ proximo: indice });
    }, [indice, submit]);
    return (
        <ContainerBtnStep
            onClick={click}
            colorSeparator={colorSeparator}
            className={classes}>
            {classes.indexOf(' sucesso') !== -1 && (
                <IconeSucesso
                    size="2rem"
                    icone="icon-sucesso"
                />
            )}
            <span className="legenda">{nome}</span>
        </ContainerBtnStep>
    );
};
export default memo(Step);
