import { matchTelefone } from '../../../../../libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from '../../../../../libs/yup/ShapeValidation';
import * as yup from 'yup';

export const yupValidate = (data, setErrors) =>
    new Promise(async (resolve, reject) => {
        try {
            await YupUtilsTry(data, setErrors, {
                email: yup.string().email('Email inválido'),
                telefoneFixo: yup
                    .string()
                    .matches(matchTelefone, 'Formato de telefone inválido'),
                telefoneCelular: yup
                    .string()
                    .matches(matchTelefone, 'Formato de telefone inválido')
                    .required('Informe seu telefone'),
            });
            resolve(true);
        } catch (err) {
            YupUtilsCatch(err, setErrors);
            reject(err);
        }
    });
