import React, { FC } from 'react';
import ComponentValidateDocument from './Component';
import { useEntregadorDocumento } from './hooks/useEntregadorDocumento';

const PageValidateDocument: FC = () => {
    const oProps = useEntregadorDocumento();

    return <ComponentValidateDocument {...oProps} />;
};
export default PageValidateDocument;
