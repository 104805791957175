// eslint-disable-next-line no-use-before-define
import { defTitle } from 'assets/strings';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, memo, useEffect } from 'react';

const ComponentInserirSaldo: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Inserir saldo`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Inserir saldo'}
            titleModal={'Inserir saldo'}
            message="Informe os dados de seu cartão e o valor que deseja inserir de saldo para fazer entregas."
        >
            <FormGenerator rForm={oProps.rForm} childs={oProps.inputs} />
        </SimplesForm>
    );
});
export default ComponentInserirSaldo;
