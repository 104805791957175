// eslint-disable-next-line no-use-before-define
import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentClienteEditar: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Configur valor corrida cliente e key IFood`;
    });

    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            titleModal={'Configurações'}
            message={null}
        >
            <FormGenerator rForm={oProps.rForm} childs={oProps.data} />
        </SimplesForm>
    );
});
export default ComponentClienteEditar;
