import { useMutation } from '@apollo/client';
import { GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE_FATURA } from 'gql/unidade';
import { GRAPHQL_BUSCAR_UNIDADE_FATURA } from 'gql/unidadeFatura';
import { useCallback } from 'react';

export const useToggleAdimplenteFatura = (props) => {
    const [toggleAdimplente, { loading: loadingToogleAdimplente }] =
        useMutation(GRAPHQL_ALTERNANCIA_ATIVO_UNIDADE_FATURA);
    const handleToogleAdimplente = useCallback(
        ({ id, adimplente }) => {
            toggleAdimplente({
                variables: { faturaId: id, adimplente: !adimplente },
                refetchQueries: [
                    {
                        query: GRAPHQL_BUSCAR_UNIDADE_FATURA,
                        context: props.context,
                        variables: props.variables,
                    },
                ],
            });
        },
        [toggleAdimplente]
    );
    return {
        handleToogleAdimplente,
        loadingToogleAdimplente,
    };
};
