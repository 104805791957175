import { gql } from '@apollo/client';
export const GRAPHQL_RESOURCE_UNIDADE_CONFIGURACAO: any = gql`
    query unidadeConfiguracao {
        unidade(filter: { ativo: true }) {
            data {
                id
                razaoSocial
                nomeFantasia
                logotipo
                geoLatitude
                geoLongitude
                porcentagemNegociacao
                unidadeParametro{
                    chave
                    valor
                }
            }
        }
        recuperarConfiguracao {
            chave
            valor
        }
        recuperarModalTransporte {
            id
            nome
            txMinRetorno
            valorKmRetorno
            ativo
        }
    }
`;

export const GRAPHQL_UNIDADE_FILTER: any = gql`
    query recuperarUnidade($filter: UnidadeFilter) {
        unidade(filter: $filter) {
            data {
                id
                razaoSocial
                nomeFantasia
                logotipo
                geoLatitude
                geoLongitude
                porcentagemNegociacao
                unidadeParametro{
                    chave
                    valor
                }
            }
        }
    }
`;
