import React, { FC, createRef, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GRAPHQL_ATUALIZAR_ENTREGADOR } from 'gql/entregador';
import ComponentEntregadorEditar from './Component';
import { propsComponent } from './commands/propsComponent';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { YupUtilsTry } from 'libs/yup/ShapeValidation';
import { AuthContext } from 'routes/auth.context';
const PageEditarStatusEntregador: FC = () => {
	const rForm = createRef<FormHandles>();
	// pega o id cliente da url
	const { id_entregador, status } = useParams<any>();
	const history = useHistory();
	// Status Pendente  
	const { alertConfirm } = useContext(AuthContext);
	const [handleSalvarStatus, { loading }] = useMutation(GRAPHQL_ATUALIZAR_ENTREGADOR);
	const newStatus = [
		{ value: 'ativo', label: "Ativo" },
		{ value: 'inativo', label: "Inativo" },
		{ value: 'pendente', label: "Pendente" },
	];
	const selectionado = newStatus.find(ele => ele.value + "" === status + "");
	useEffect(() => {
		if (status) {
			rForm.current.setFieldValue('status', selectionado);
		}
	}, [status, newStatus, rForm, selectionado]);
	const handleSubmit = async (data) => {
		const { setErrors } = rForm.current as FormHandles;
		try {
			await YupUtilsTry(data, setErrors, {
				status: yup.string().required('Selecione um status'),
			});
			await handleSalvarStatus(
				{
					variables: {
						entregadorId: id_entregador,
						data: {
							status: data.status
						}
					},
				});
			history.goBack();
		} catch ({ message }) {
			alertConfirm({
				tipo: 'erro',
				titulo: 'Erro',
				conteudo: message,
			});
		}
	};
	const oProps = {
		...propsComponent({ loading }),
		rForm,
		handleSubmit,
		status: newStatus,
		loading
	};
	return <ComponentEntregadorEditar {...oProps} />;
};
export default PageEditarStatusEntregador;
