import { trim } from 'libs/StringUteis';
import { matchTelefone } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import * as yup from 'yup';

export const useValidadeSubmit = () => {
    return async ({ data, rForm }) =>
        new Promise((resolve, reject) => {
            const { setErrors } = rForm;
            YupUtilsTry(data, setErrors, {
                nomeCompleto: yup
                    .string()
                    .min(5, 'Nome inválido')
                    .required('Nome inválido'),
                email: yup
                    .string()
                    .required('Email inválido')
                    .email('Email inválido'),
                telefoneCelular: yup
                    .string()
                    .matches(matchTelefone, 'Formato de telefone inválido')
                    .required('Telefone inválido'),
                perfil: yup.string().required('Perfil inválido'),
            })
                .then(() => {
                    data.nomeCompleto = trim(data.nomeCompleto);
                    resolve(data);
                })
                .catch((err) => {
                    resolve(null);
                    YupUtilsCatch(err, setErrors);
                });
        });
};
