import React, { FC, memo, RefObject } from 'react';
import Modal from '../../../../components/modal/base';
import { Container, ContainerForm, Header, Paragrafo } from './styl';
import Icone from '../../../../components/Icone';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ButtonLoading from '../../../../components/atom/ButtonLoading';
import CircularLoading from '../../../../components/atom/Loading/CircularLoading';
interface IModalSubmitProps {
    reset(): void;
    closeModal(_e?: any): void;
}
// @ts-ignore
interface IModalSimplesForm extends IFCProps {
    rForm: RefObject<FormHandles>;
    handleSubmit(data: any, props: IModalSubmitProps): void;
    loading: boolean;
    titleSubmit: string;
    message: string;
    loadingData?: boolean;
    initialData?: unknown;
    titleModal: string;
    widthModal?: string;
    children?: any;
}
const ModalTemplateSimplesForm: FC<IModalSimplesForm> = ({
    rForm,
    handleSubmit,
    initialData,
    loading,
    titleSubmit,
    message,
    titleModal,
    loadingData = false,
    widthModal = '50rem',
    children,
}) => {
    return (
        <Modal
            render={({ closeModal }) => (
                <Container width={widthModal}>
                    <Header>
                        <h1>{titleModal}</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <ContainerForm>
                        <CircularLoading
                            show={loadingData}
                            size={3}
                            primaryColor="var(--btn-underline-cor)"
                        />
                        {!loadingData && (
                            <Form
                                ref={rForm}
                                // @ts-ignore
                                initialData={initialData}
                                onSubmit={async (data, props) =>
                                    await handleSubmit(data, {
                                        closeModal,
                                        ...props,
                                    })
                                }>
                                {message && <Paragrafo>{message}</Paragrafo>}
                                {children}
                                {titleSubmit && (
                                    <div className="space-button">
                                        <ButtonLoading
                                            value={titleSubmit}
                                            loading={loading}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </ContainerForm>
                </Container>
            )}
        />
    );
};
export default memo(ModalTemplateSimplesForm);
