import ButtonLoading from 'components/atom/ButtonLoading';
import React, { FC } from 'react';

export const Disconnected: FC = () => {
    return (
        <>
            <p className="mb">
                No momento o whatsapp está desconectado, clique no botão abaixo
                para conectar seu whatsapp com o sistema.
            </p>
            <ButtonLoading value="Conectar meu whatsapp" loading={false} />
        </>
    );
};
