// eslint-disable-next-line no-use-before-define
import { defTitle } from 'assets/strings';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, memo, useEffect } from 'react';

const ComponentTipoPagamentoEditar: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Editar tipo de pagamento`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            titleModal={'Tipo de pagamento'}
            message={null}
        >
            <FormGenerator rForm={oProps.rForm} childs={oProps.inputs} />
        </SimplesForm>
    );
});
export default ComponentTipoPagamentoEditar;
