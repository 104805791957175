import React, { FC, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';

const PrivateRoute: FC<any> = ({ component: RouteComponent, ...rest }) => {
    const { signed } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={routeProps =>
                signed ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect
                        to={'/'}
                        push={false}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
