import { useQuery } from '@apollo/client';
import { defTitle } from 'assets/strings';
import { GRAPHQL_COLETA_PUBLIC } from 'gql/coleta';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BlcMessage, Message } from './styl';
export const ValidarRecibo: FC = () => {
    const [statusId, setStatusId] = useState(0);
    useEffect(() => {
        document.title = `${defTitle} - Coleta`;
    }, []);
    const apiLoc = useLocation();
    const [coleta, setColeta] = useState<any>(null);
    const { data, loading } = useQuery(GRAPHQL_COLETA_PUBLIC, {
        variables: {
            coletaId: apiLoc.pathname.replace('/validar-recibo/', ''),
        },
    });
    useEffect(() => {
        if (
            data &&
            data.recuperarColetaByIdPublic &&
            data.recuperarColetaByIdPublic
        ) {
            const coleta = data.recuperarColetaByIdPublic;
            setColeta(coleta);
            setStatusId(coleta.status[0].id);
        }
    }, [data]);
    return (
        <>
            {[7].includes(statusId) && (
                <BlcMessage>
                    <Message>
                        Este recibo foi cancelado pelo estabelecimento.
                    </Message>
                </BlcMessage>
            )}
            {![7].includes(statusId) && (
                <BlcMessage>
                    <Message>Este e um recibo valido.</Message>
                </BlcMessage>
            )}
        </>
    );
};
