import { useMutation, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import {
    GRAPHQL_ATUALIZAR_ENTREGADOR,
    GRAPHQL_BUSCAR_ENTREGADOR,
} from 'gql/entregador';
import { IPlayer } from 'pages/banco/formulario';
import { useValidadeSubmit } from 'pages/entregador/hooks/useValidadeSubmit';
import {
    createRef,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
const aSexo = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
];

export const useEntregadorAlterar = () => {
    const { id } = useParams<any>();
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [entregador, setEntregador] = useState<IPlayer>({} as IPlayer);
    const [handleAtualizaCliente, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_ENTREGADOR
    );

    const atualizaEntregador = useCallback(
        async ({ entregadorId, data }) => {
            await handleAtualizaCliente({
                variables: {
                    entregadorId,
                    data,
                },
            });
        },
        [handleAtualizaCliente]
    );

    const { data: responseData } = useQuery<{
        recuperarEntregador?: {
            data?: IPlayer[];
        };
    }>(GRAPHQL_BUSCAR_ENTREGADOR, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id,
            },
        },
    });

    useEffect(() => {
        const entregadorDto = responseData?.recuperarEntregador?.data[0] || {};
        setEntregador(entregadorDto);
    }, [responseData]);

    const sexoSelected = useMemo(
        () => aSexo.find((ele) => ele.value === entregador?.sexo),
        [entregador]
    );

    const rForm = createRef<FormHandles>();
    const handleDataValidate = useValidadeSubmit();
    const handleSubmit = useCallback(
        async (data) => {
            const post = await handleDataValidate({
                data,
                rForm: rForm.current,
            });
            if (post) {
                try {
                    setEntregador({ ...entregador, ...data });
                    await atualizaEntregador({
                        data,
                        entregadorId: id,
                    });
                    history.goBack();
                } catch ({ message }) {
                    await alertConfirm({
                        tipo: 'erro',
                        titulo: 'Erro',
                        conteudo: message,
                    });
                }
            }
        },
        [entregador, rForm, history, id]
    );

    return {
        rForm,
        title: 'Editar player',
        titleSubmit: 'Editar player',
        values: entregador,
        sexoSelected,
        handleSubmit,
        aSexo,
        loading,
    };
};
