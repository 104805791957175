import { useMutation } from '@apollo/client';
import { GRAPHQL_EDITAR_UNIDADE } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import { transformData } from '../../create/command/transformData';

export const useFetchSubmit = () => {
    const { unidadeId } = useParams<any>();
    // Controle de fluxo de navegação
    const history = useModalHistory();

    // Acesso ao alert para mensagem de erro se necessário
    const { alertConfirm } = useContext(AuthContext);

    // Mutação que é responsável por salvar os dados
    const [handleEditarUnidade, { loading }] = useMutation(
        GRAPHQL_EDITAR_UNIDADE
    );
    const handleFetchSubmit = useCallback(
        async (data) => {
            data = transformData({ unidadeId, ...data });
            data.enderecoLote = data.enderecoLote || '';
            data.enderecoQuadra = data.enderecoQuadra || '';
            data.enderecoNumero = data.enderecoNumero || '';

            try {
                await handleEditarUnidade({
                    variables: {
                        data,
                    },
                });
                history.push('');
                await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Cadastro de empresa',
                    conteudo: `Estabelecimento ${data.nomeFantasia} atualizada com sucesso!`,
                });
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: `Falha ao atualizada a estabelecimento [${message}]`,
                });
            }
        },
        [alertConfirm, handleEditarUnidade, history]
    );
    return { handleFetchSubmit, loading };
};
