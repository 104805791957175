import _ from 'lodash';
import { IColeta } from 'pages/coleta/listar/hooks/useTransformColeta/index.d';
import { StatusID } from "pages/coleta/listar/constants/status-name-2-id";

export const removeElementArray = (array, elem) => {
    const index = array.indexOf(elem);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
};
export const statusVisiblePermiteEdicao = ['1', '2', '3', '4', '8', '9'];
export const formatCnpj = v => {
    if (v)
        v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return v;
};

export const trim = v => {
    if (v === null || v === undefined) {
        return '';
    }
    if (typeof v === 'string') {
        v = v.replace(/^\s+|\s+$/g, ''); // tira espaços do inicio e do fim
        v = v.replace(/\s{2,}/g, ' '); // tira espaços duplicados
        return v;
    }
    if (typeof v === 'number') {
        return `${v}`;
    }
    if (typeof v === 'boolean') {
        return v ? 'true' : 'false';
    }
    if (typeof v === 'object') {
        const _str = JSON.stringify(v);
        return _str === '{}' ? '' : _str;
    }
    return v;
};
export const onlyNumbers = v => {
    if (v === null || v === undefined) {
        return '';
    }
    v = v.replace(/[^\d]+/g, ''); // deia somente numero
    return v;
};
export const limite = (texto, length = 100, padrao = '...') => {
    if (texto === undefined || texto === null || texto === 'undefined') {
        return '';
    }
    padrao = padrao || '...';
    if (texto.length < length) {
        return texto;
    }
    texto = texto.substr(0, length);
    texto = texto.substr(0, texto.lastIndexOf(' '));
    return texto + padrao;
};
export const empty = v => {
    if (v === undefined || v === null) {
        return true;
    }
    switch (typeof v) {
        case 'object':
            if (Array.isArray(v)) {
                return v.length === 0;
            }
            return Object.keys(v).length === 0;
        case 'string':
            return trim(v).length === 0;
        case 'number':
            if (isNaN(v)) {
                return true;
            }
            return Number(v) === 0;
        case 'boolean':
            return v;
        default:
            return false;
    }
};
export const str2slug = v => {
    if (empty(v)) {
        return '';
    }
    if (empty(v)) {
        return '';
    }
    const _s =
        'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž/.,';
    const _t =
        'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz___';
    return trim(
        v
            .split('')
            .map(_v => {
                const _i = _s.indexOf(_v);
                if (_i !== -1) {
                    return _t.charAt(_i);
                }
                return _v;
            })
            .join(''),
    )
        .replace(/[\s|\t|\n]/gi, '_')
        .replace(/[-{2,}]/gi, '_');
    // v = v
    //   .normalize('NFD')
    //   .toLowerCase()
    //   .trim();
};
export const removeCaracterEspecial = v => {
    return trim(v).replace(/[^a-zA-Z\u00C0-\u00FF\s]/gi, '');
};
export const upperCamelCase = v => {
    if (empty(v)) {
        return '';
    }
    const rejeitUppercase =
        'a,e,i,o,u,no,na,da,de,mas,nas,ne,nem,para,tu,ta,te,ti,to,tu,que,tem,por,não,os,há,á,é,í,ó,ú'.split(
            ',',
        );
    return v
        .toLowerCase()
        .split(' ')
        .map(part => {
            if (part.length < 2 || rejeitUppercase.indexOf(part) !== -1)
                return part;
            return (
                part.substring(0, 1).toUpperCase() +
                part.substring(1, part.length).toLowerCase()
            );
        })
        .join(' ');
};
export const embaralharEmail = v => {
    const _parts = v.split('@');
    return `${_parts[0].substring(0, 2) + '*'.repeat(_parts[1].length - 3)}@${
        _parts[1]
    }`;
};
export const embaralharTelefone = v => {
    const _t = v.length;
    return (
        v.substring(0, 3) +
        v.replace(/[0-9]/gi, '*').substring(3, _t - 2) +
        v.substring(_t - 2, _t)
    );
};
export const embaralharCartao = v => {
    return (
        v.substring(0, 14).replace(/[0-9]/gi, '•') + v.substring(14, v.length)
    );
};
export const completarCartao = v => {
    const tmp = v.replace(/[\D]/gi, '');
    return tmp
        .padEnd(16, '*')
        .replace(/^([0-9*]{4})([0-9*]{4})([0-9*]{4})([0-9*])/g, '$1 $2 $3 $4');
};
export const captalize = (v, force = true) => {
    if (empty(v)) {
        return '';
    }
    return (
        v.charAt(0).toUpperCase() +
        (force ? v.slice(1).toLowerCase() : v.slice(1))
    );
};
export const UDID = (modelo = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx') => {
    return modelo.replace(/[xy]/g, () => {
        return `${Math.ceil(Math.random() * 10)}`;
    });
};
export const imageName = v => {
    if (empty(v)) {
        return '';
    }
    const _tmp = v.split('/');
    v = _tmp.pop();
    return v;
};
export const imageExtensao = v => {
    if (empty(v)) {
        return '';
    }
    const _tmp = v.split('/');
    v = _tmp.pop();
    v = v.toLowerCase();
    return v;
};
export const abreviarNome = (s, minCharAbreviar = 10) => {
    if (empty(s)) {
        return '';
    }
    s = upperCamelCase(s);
    if (s.length > minCharAbreviar) {
        const _nome = s.split(' ');
        if (_nome.length > 1) {
            const t = _nome.length - 1;
            _nome.forEach(function (value, key) {
                if (key > 0 && key < t) {
                    if (value.length > 2) {
                        _nome[key] = value.substring(0, 1) + '.';
                    } else {
                        _nome[key] =
                            value.substring(0, 1) +
                            value.substring(1, value.length);
                    }
                }
            });
            _nome[t] =
                _nome[t].substring(0, 1) +
                _nome[t].substring(1, _nome[t].length);
        }
        return _nome.join(' ');
    }
    return s;
};
export const km2String = metros => {
    if (metros < 1000) {
        return `${metros} m`;
    }
    metros /= 1000;
    return `${metros.toFixed(3)} km`;
};
export const appendPrependString = (
    value: string,
    prepend: string = '',
    append: string = '',
    defaultValue: string = '',
): string => {
    if (value) {
        // return `${prepend}${value || defaultValue}${append}`;
        return `${prepend}${value || defaultValue}${append}`
            .replace(/casa\s/gi, 'Cs. ')
            .replace(/setor/gi, 'St. ')
            .replace(/lote/gi, 'Lt. ')
            .replace(/Piso/gi, 'Ps. ')
            .replace(/quadra/gi, 'Qd. ')
            .replace(/Andar/gi, 'And. ')
            .replace(/avenida/gi, 'Av. ')
            .replace(/jardim/gi, 'Jd. ')
            .replace(/chácara/gi, 'Chác. ')
            .replace(/loja/gi, 'Lj. ')
            .replace(/chacara/gi, 'Chác. ')
            .replace(/edif\s/gi, 'Ed. ')
            .replace(/shopping\s/gi, 'Shop. ')
            .replace(/vereador\s/gi, 'Ver. ')
            .replace(/edifício\s/gi, 'Ed. ')
            .replace(/edificio\s/gi, 'Ed. ')
            .replace(/alameda\s/gi, 'Al. ')
            .replace(/doutor/gi, 'Dr. ')
            .replace(/rua\s/gi, 'R. ')
            .replace(/praça/gi, 'Pç. ')
            .replace(/praca/gi, 'Pç. ')
            .replace(/parque/gi, 'Pq. ')
            .replace(/deputado/gi, 'Dep. ')
            .replace(/sala\s/gi, 'Sl. ');
    }
    return '';
};

const onlyUnique = (value, index, self) => {
    const indice = self.findIndex(({ id }, index2) => {
        return index > index2 && id === value.id;
    });
    return indice === -1;
}

export const groupUnit = (coleta: IColeta, unidadesAgrupaveis = {}, coletas: IColeta[]) => {
    let coletasPai = coletas
    ?.filter(onlyUnique) // remove coletas duplicadas
    ?.map(({ coletaPaiId }) => coletaPaiId)
    .filter((value) => !!value)
    if(!coletasPai) coletasPai = [];
    const tmpColeta = { ...coleta };

    tmpColeta.reactPaiIdSort = tmpColeta.coletaPaiId || tmpColeta.id;
    tmpColeta.reactIsPai = coletasPai.indexOf(tmpColeta.id) !== -1;
    tmpColeta.reactGrouped =
        coletasPai.indexOf(tmpColeta.id) !== -1 || !!tmpColeta.coletaPaiId;
    if (
        [
            StatusID.PENDENTE,
            StatusID.ACEITO,
            StatusID.CHECKIN_UNIDADE,
            StatusID.EXPEDINDO,
            StatusID.VISUALIZADO,
            StatusID.RECEBIDO,
        ].indexOf(
            (tmpColeta?.status && tmpColeta?.status[0] || { id: StatusID.EXPEDINDO })?.id
        ) !== -1 &&
        !tmpColeta.coletaPaiId &&
        coletasPai.indexOf(tmpColeta.id) === -1
    ) {
        if(tmpColeta?.unidade){
        unidadesAgrupaveis[tmpColeta?.unidade.id] =
            (unidadesAgrupaveis[tmpColeta?.unidade.id] || 0) + 1;
        }
    }
    return tmpColeta;
}

export const coletaAgrupadas = (coletas: IColeta[]) => {
    if(coletas.length === 0) return [];
    const unidadesAgrupaveis = {};
    let coletaSortGroupFirst = coletas
    ?.filter(onlyUnique) // remove coletas duplicadas
    ?.filter((coleta: any) => {
        return coleta.reactIsPai === false && coleta.coletaPaiId === null;
    });
    coletaSortGroupFirst = _.orderBy(coletaSortGroupFirst, ['ordem'], ['desc']);

    let agrupados = coletas
    ?.filter(onlyUnique) // remove coletas duplicadas
    ?.map((coleta) => groupUnit(coleta, unidadesAgrupaveis, coletas))
    ?.filter((coleta: any) => {
        return coleta.reactIsPai === true || coleta.coletaPaiId !== null ;
    })
    agrupados = _.orderBy(agrupados, [function(resultItem) {
        return resultItem.reactIsPai === true;
    }], ['desc']);
    const agrupadosFilter = agrupados.filter((coleta: any) => {
        return coleta.reactIsPai === true ;
    });
    const aNewAgrupados= [];
    agrupadosFilter.forEach((item) => {
        aNewAgrupados.push(item)
        const childs = agrupados.filter((coleta: any) => {
            return coleta.coletaPaiId === item.id ;
        });
        aNewAgrupados.push(...childs)
    });
    return aNewAgrupados;
};
