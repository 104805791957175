import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Grid = styled.div<any>`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
export const Warp = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 0 2rem 1rem;
`;
export const H1Value = styled.p`
    font-size: var(--size-h4);
    line-height: 1.8rem;
    color: var(--btn-admin-icone);
`;
export const PValue = styled.p``;
