import { useCallback, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import * as yup from 'yup';
const options = [
    {
        id: 2,
        value: 'Pessoa jurídica',
        label: 'Pessoa jurídica',
    },
    {
        id: 1,
        value: 'Pessoa física',
        label: 'Pessoa física',
    },
];
export const useTipoCliente = ({ formRef, initialData }) => {
    const [clienteJuridico, setClienteJuridico] = useState(() => {
        return !initialData.tipo
            ? null
            : initialData.tipo === 'Pessoa jurídica';
    });
    const handleChange = useCallback((data) => {
        if (data) {
            setClienteJuridico(data.id === 2);
        }
    }, []);
    const handleSubmit: SubmitHandler<any> = useCallback(async (data) => {
        const { setErrors } = formRef.current as FormHandles;
        if (data) {
            try {
                await YupUtilsTry(data, setErrors, {
                    tipo: yup.string().required('Campo obrigatório'),
                });
            } catch (err) {
                YupUtilsCatch(err, setErrors);
            }
        }
    }, []);
    return {
        clienteJuridico,
        handleSubmit,
        form: initialData.editar
            ? []
            : {
                  type: 'select-simple',
                  onChange: handleChange,
                  label: 'Tipo de cliente',
                  placeholder: 'Digite ou selecione',
                  name: 'tipo',

                  options,
              },
    };
};
