import React, { FC, memo } from 'react';
import { WarpFreteAndKm } from './styl';
interface IFormEnderecoFrete {
    distanciaKM: string;
    precisaoKM: string;
    precisao: number;
    valorFrete: string;
    valorFreteComRetorno: string;
    valorFreteSemRetorno: string;
}
export const AtomFreteMapa: FC<IFormEnderecoFrete> = memo(
    ({
        distanciaKM,
        precisaoKM,
        precisao,
        valorFrete,
        valorFreteSemRetorno,
        valorFreteComRetorno,
    }) => {
        const precisaoNumber = parseFloat((precisao ? precisao : 0).toFixed(3));
        const distanciaKMNumber = Number(
            distanciaKM ? distanciaKM.replace('km', '').replace('m', '') : 0,
        );
        const freteMaisPrecisao = distanciaKMNumber + precisaoNumber;
        return (
            <>
                <WarpFreteAndKm>
                    <span>
                        {freteMaisPrecisao.toFixed(2)}
                        {freteMaisPrecisao < 1 ? 'm' : 'km'}
                    </span>
                    <h1>
                        <span>Sem Retorno</span>
                        {valorFreteSemRetorno}
                    </h1>
                    <span className="retorno">
                        {(freteMaisPrecisao * 2).toFixed(2)}km
                    </span>
                    <h1>
                        <span>Com Retorno</span>
                        {valorFreteComRetorno}
                    </h1>
                </WarpFreteAndKm>
            </>
        );
    },
);
