import { StatusID } from 'pages/coleta/listar/constants/status-name-2-id';
import { appendPrependString } from 'libs/StringUteis';
import { useCallback } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { IColeta } from '../index.d';

export const useClienteEndereco = () => {
    const history = useModalHistory();
    const handleTransformClienteEndereco = useCallback(
        (coleta?: IColeta) => {
            const endereco = coleta?.cliente?.endereco;
            const hasUpdateAddress = !!endereco?.novoEnderecoNaoAprovado;

            return {
                status: 'ativo',
                tipo: 'Texto',
                width: 'minmax(22rem, 1.5fr)',
                marginRight: '0',

                titulo: hasUpdateAddress ? 'ATENÇÃO' : endereco?.enderecoBairro,
                subtitulo: hasUpdateAddress
                    ? 'Correção de endereço do cliente aguardando sua moderação'
                    : endereco?.enderecoLogradouro
                    ? `${endereco?.enderecoLogradouro}
          ${appendPrependString(endereco?.enderecoNumero, ', nº ')}
          ${appendPrependString(endereco?.enderecoComplemento, ', ')}
          ${appendPrependString(endereco?.enderecoQuadra, ', quadra ')}
          ${appendPrependString(endereco?.enderecoLote, ', lote ')}
          ${appendPrependString(endereco?.enderecoPontoReferencia, ', ')}
          ${appendPrependString(endereco?.enderecoCep, ' - ')}
          `
                    : '',
                flexDirection: 'row',
                flexWrap: 'nowrap',
            };
        },
        []
    );
    return { handleTransformClienteEndereco };
};
