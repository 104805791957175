import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_LISTAR_UNIDADE } from '../../../../gql/unidade';
import { IQueryUnidade } from '../../../../pages/unidades/list/hooks/useQueryFilterUnit/index.d';
import React, { createRef, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../routes/auth.context';
import ComponentClienteEditar from './Component';
import { useEntregadorConfiguracaoAlterar } from './hooks/useEntregadorConfiguracaoAlterar';
interface IParams {
    unidadeId: string;
}

const PageUnidadeConfiguracao: FC = () => {
    // pega o id cliente da url
    const { unidadeId } = useParams<IParams>();
    // @ts-ignore
    const { alertConfirm } = useContext(AuthContext);
    const { data } = useQuery<IQueryUnidade>(GRAPHQL_LISTAR_UNIDADE, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id: unidadeId,
            },
        },
        onError: err => console.error(err),
    });

    const { atualizaUnidadeConfiguracoes, loading } =
        useEntregadorConfiguracaoAlterar();
    const rForm = createRef<FormHandles>();

    const handleSubmit = async data => {
        try {
            const percentJr = Number(
                (Number(data.porcentagemNegociacao) / 100).toFixed(2),
            );

            if (percentJr < 0) {
                throw new Error(
                    'Valor a ser cobrado em porcentagem deve ser superior 0%',
                );
            }

            await atualizaUnidadeConfiguracoes({
                data: { taxaJogoRapido: percentJr },
                unidadeId,
            });
            window.location.reload();
        } catch ({ message }: any) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    };
    // @ts-ignore
    const percent = (data?.unidade?.data[0] || {})?.porcentagemNegociacao || 0;

    const porcentagemNegociacao = Number((percent * 100).toFixed(0));
    const oProps = {
        porcentagemNegociacao,
        loading,
        rForm,
        handleSubmit,
    };
    return <ComponentClienteEditar {...oProps} />;
};
export default PageUnidadeConfiguracao;
