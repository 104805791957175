import { useQuery, useSubscription } from '@apollo/client';
import { defTitle } from 'assets/strings';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import {
    GRAPHQL_WPP_CHECK_SESSION,
    GRAPHQL_WPP_STATUS_SUBSCRIPTION,
} from 'gql/whatsapp';
import React, { FC, memo, useEffect, useState } from 'react';

import * as ComponentsActions from './components';
import { Container, Header, Warp } from './styl';

export type TStatusLogged =
    | 'PENDING'
    | 'CONNECTED'
    | 'FAILED'
    | 'NONE'
    | 'NO_AUTH'
    | 'DESCONECTED';
type IComponentName = 'Loading' | 'Connected' | 'Disconnected' | 'ReadQrCode';
export interface IStatusQr {
    status?: TStatusLogged;
    qrCode?: string;
}
const ModalConfiguracaoWhatsapp: FC = memo(() => {
    const [componentName, setComponentName] =
        useState<IComponentName>('Loading');
    const [propsComponent, setPropsComponent] = useState({});
    useEffect(() => {
        document.title = `${defTitle} - Unidades`;
    });

    useQuery<{
        wppCheckSession: IStatusQr;
    }>(GRAPHQL_WPP_CHECK_SESSION, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ wppCheckSession }) => {
            switch (wppCheckSession.status) {
                case 'CONNECTED':
                    setComponentName('Connected');
                    setPropsComponent({});
                    break;
                case 'NO_AUTH':
                    setComponentName('ReadQrCode');
                    setPropsComponent({ qrCode: wppCheckSession.qrCode });
                    break;
                case 'FAILED':
                case 'DESCONECTED':
                    setPropsComponent({});
                    setComponentName('Disconnected');
                    break;
            }
        },
    });
    useSubscription(GRAPHQL_WPP_STATUS_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: { whatsappStatus },
            },
        }) {
            switch (whatsappStatus.status) {
                case 'CONNECTED':
                    setComponentName('Connected');
                    setPropsComponent({});
                    break;
                case 'NO_AUTH':
                    setComponentName('ReadQrCode');
                    setPropsComponent({ qrCode: whatsappStatus.qrCode });
                    break;
                case 'FAILED':
                case 'DESCONECTED':
                    setPropsComponent({});
                    setComponentName('Disconnected');
                    break;
            }
        },
    });
    const Component = ComponentsActions[componentName];
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Whatsapp</h1>

                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Warp>
                        <Component {...propsComponent} />
                    </Warp>
                </Container>
            )}
        />
    );
});
export default ModalConfiguracaoWhatsapp;
