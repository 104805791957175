import { useQuery, useSubscription } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import {
    GRAPHQL_LISTAR_UNIDADE,
    GRAPHQL_UNIDADE_SUBSCRIPTION,
} from 'gql/unidade';
import { useEffect, useMemo } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { ISubscriptionUnidade, IQueryUnidade } from './index.d';
export const useQueryFilterUnit = () => {
    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);

    const props = usePaginationAndFilter({
        dataKeyName: null,
        keysQueryVariavel: [
            'id',
            'ativo',
            'search',
            'parceiroId',
            'razaoSocial',
            'nomeFantasia',
            'identificador',
            'identificadores',
            'nomeResponsavel',
        ],
    });

    useSubscription<ISubscriptionUnidade>(GRAPHQL_UNIDADE_SUBSCRIPTION, {
        variables: props.variables,
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    unidade: { data },
                },
            },
        }) => {
            props.onSubscriptionData(data);
        },
    });

    const { loading } = useQuery<IQueryUnidade>(GRAPHQL_LISTAR_UNIDADE, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables: props.variables,
        onCompleted: ({ unidade }) => props.onCompleted(unidade),
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, dataSelectHeader };
};
