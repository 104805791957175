import { gql } from '@apollo/client';
export const GRAPHQL_PROCURAR_GEOLOCALIZACAO_ENDERECO: any = gql`
    query procurarGeolocalizacaoEndereco(
        $destino: InputDataGeoEndereco
        $origem: Origem
    ) {
        procurarGeolocalizacaoEndereco(destino: $destino, origem: $origem) {
            cep
            logradouro
            enderecoLote
            enderecoQuadra
            bairro
            municipio
            uf
            numero
            mapa {
                limites {
                    lat
                    lng
                }
                geolocalizacao {
                    lat
                    lng
                }
                distancia
                duracao
                precisao
                taxaRetorno
                frete
            }
        }
    }
`;
