import { useMutation } from '@apollo/client';
import { SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { defTitle } from 'assets/strings';
import Button from 'components/atom/Button';
import ButtonLoading from 'components/atom/ButtonLoading';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import {
    GRAPHQL_ACEITAR_NOVO_ENDERECO_CLIENTE,
    GRAPHQL_REPROVAR_NOVO_ENDERECO_CLIENTE,
} from 'gql/cliente';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { Container, Header, WarpButton, WarpMap } from './styl';

interface IPropsParams {
    clienteEnderecoId: string;
    latitude: string;
    longitude: string;
    coletaId: string;
}
export interface IPropsQuery {
    latitude?: number;
    longitude?: number;
    entregadorId: string;
    connection: string;
    dateGeoUpdated?: string;
}
interface IMap {
    fitBounds?: any;
}

export interface ISubscription {
    entregadorLocation?: {
        data?: IPropsQuery;
    };
}
const PageClienteAtualizarLocalizacao: FC = () => {
    const history = useHistory();
    const { configuracao, alertConfirm } = useContext(AuthContext);
    const [handleAceitarNovoEndereco, { loading: loadingAccept }] = useMutation(
        GRAPHQL_ACEITAR_NOVO_ENDERECO_CLIENTE,
    );
    const [handleReprovarNovoEndereco, { loading: loadingReject }] =
        useMutation(GRAPHQL_REPROVAR_NOVO_ENDERECO_CLIENTE);
    const keyMaps = useMemo(() => {
        return configuracao.find(({ chave }) => chave === 'key_maps').valor;
    }, [configuracao]);

    useEffect(() => {
        document.title = `${defTitle} - Localização`;
    });

    const pQuery = useParams<IPropsParams>();
    const props = useMemo(() => {
        return {
            clienteEnderecoId: +pQuery.clienteEnderecoId,
            latitude: +pQuery.latitude,
            longitude: +pQuery.longitude,
            coletaId: pQuery.coletaId,
        };
    }, [pQuery]);

    const handleRejectSubmit: SubmitHandler<void> = async () => {
        try {
            const resultConfirm = await alertConfirm({
                tipo: 'erro',
                titulo: 'Atualizar endereço',
                textOk: 'Sim',
                textCancelar: 'Não',
                conteudo: `Tem certeza que deseja rejeitar esta correção de endereço?`,
            });
            if (resultConfirm) {
                await handleReprovarNovoEndereco({
                    variables: {
                        coletaId: props.coletaId,
                        clienteEnderecoId: props.clienteEnderecoId,
                    },
                });

                history.goBack();
            }
        } catch ({ message }: any) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    };
    const handleAcceptSubmit: SubmitHandler<void> = async () => {
        try {
            const resultConfirm = await alertConfirm({
                tipo: 'erro',
                titulo: 'Atualizar endereço',
                textOk: 'Sim',
                textCancelar: 'Não',
                conteudo: `Tem certeza que deseja aceitar a atualização do endereço do cliente para um novo endereço com base na localização atual do player?`,
            });
            if (resultConfirm) {
                await handleAceitarNovoEndereco({
                    variables: {
                        coletaId: props.coletaId,
                        clienteEnderecoId: props.clienteEnderecoId,
                    },
                });
                alertConfirm({
                    tipo: 'info',
                    titulo: 'Endereço atualizado',
                    conteudo: `Meus parabéns, o endereço do cliente foi atualizado. Importante: o valor do frete referente a esta coleta não sofrerá reajuste. Mas, na próxima vez que o cliente solicitar uma entrega o valor será calculado com base nesta nova localização.`,
                });
                history.goBack();
            }
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    };
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Atualizar endereço</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <WarpMap>
                        <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${props.latitude},${props.longitude}&zoom=15&size=460x400&maptype=roadmap&markers=color:red%7Clabel:C%7C${props.latitude},${props.longitude}
&key=${keyMaps}`}
                            alt=""
                        />
                    </WarpMap>
                    <WarpButton>
                        <Button
                            onClick={handleAcceptSubmit}
                            value="Aprovar novo endereço"
                            loading={loadingAccept}
                        />
                        <Button
                            className="cancel"
                            onClick={handleRejectSubmit}
                            value="Reprovar novo endereço"
                            loading={loadingReject}
                        />
                    </WarpButton>
                </Container>
            )}
        />
    );
};
export default PageClienteAtualizarLocalizacao;
