import Button from 'components/atom/Button';
import { ButtonAction } from 'components/Form/template/styl';
import Icone from 'components/Icone';
import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import { CardUser, Container, LogoutIcon, SetaSelect, WarpIcon } from './styl';
const SelectUnidadeEUsuario: FC = () => {
    const { signOut, unidadeSelecionada, unidades, user } =
        useContext(AuthContext);
    const history = useModalHistory();
    const handlerOpenUnidades = () => {
        if (unidades.length > 1) history.push('unidade~listar');
    };

    return (
        <Container>
            <div className="selecionar-estabelecimento">
                <button onClick={handlerOpenUnidades}>
                    <h1 className="titulo-unidades">
                        {unidadeSelecionada?.nomeFantasia ||
                            'TODAS AS UNIDADES'}
                    </h1>
                    {unidades && unidades.length > 1 && (
                        <WarpIcon>
                            <SetaSelect
                                icone="icon-selecionar"
                                size="2rem"
                            />
                        </WarpIcon>
                    )}
                </button>
            </div>
            <div className="adicionar-credito">
                {/* <Link to="/admin/empresa/inserir-saldo">
                    <Icone
                        icone="icon-money"
                        color="var(--btn-white)"
                        size="2rem"
                    />
                    <span>Adicionar crédito</span>
                </Link> */}
                {/* <Link to="/admin/empresa/inserir-saldo">
                    <Icone
                        icone="icon-money"
                        color="var(--btn-white)"
                        size="2rem"
                    />
                    <span>Fale conosco</span>
                </Link> */}
            </div>
            <div className="credenciais_usuario">
                <CardUser to={`/admin/usuario/editar~usuario/${user?.id}`}>
                    <div className="nomeEmail">
                        <p>{user?.nomeCompleto}</p>
                        <span>{user?.email}</span>
                    </div>
                    <div className="avatar">
                        {/* <img src={Avatar} alt="seta" /> */}
                        <Icone
                            icone=" icon-homem"
                            color="var(--btn-admin-icone)"
                            size="2rem"
                        />
                    </div>
                    <WarpIcon>
                        <SetaSelect
                            icone="icon-selecionar"
                            size="2rem"
                        />
                    </WarpIcon>
                </CardUser>
            </div>
            <div className="logout">
                <button onClick={signOut}>
                    <WarpIcon>
                        <LogoutIcon
                            icone="icon-sair"
                            size="2rem"
                        />
                    </WarpIcon>
                </button>
            </div>
        </Container>
    );
};
export default SelectUnidadeEUsuario;
