import styled from 'styled-components';

export const InfoWarp = styled.div`
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    background: var(--btn-admin-icone);
    display: inline-grid;
    padding: 1rem;
    border-radius: 2px;
    grid-template-columns: repeat(9, auto);
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    z-index: 99;
    max-width: calc(100% - 3rem);

    @media (max-width: 719.8px) {
        left: 1.5rem;
        overflow-y: scroll;
        justify-content: flex-start;
    }

    button {
        cursor: pointer;
    }
    dl {
        text-align: center;
        font-family: var(--font);
        dt {
            color: var(--bg-white);
            font-size: var(--size-small);
        }
        dd {
            font-weight: var(--bold);
            font-size: var(--size-h4);
        }
    }
    .escalado dd {
        color: var(--success-cor);
    }
    .online dd {
        color: var(--success-cor);
    }
    .offline dd {
        color: var(--error-cor);
    }
    .pendentes dd {
        color: var(--btn-admin-icone-hover);
    }
    .sem-player dd {
        color: var(--error-cor);
    }
    .nao-concluidas dd {
        color: var(--btn-admin-icone-hover);
    }
`;
