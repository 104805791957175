import { ApolloProvider } from '@apollo/client';
import AlertConfirm from 'components/modal/AlertConfirm';
import { setLocalePtUtilsYup } from 'libs/yup/LocalePtUtilsYup';
import { GpsFake } from 'pages/gps-faker';
import PoliticaPage from 'pages/politica';
import { RotaColeta } from 'pages/rota-coleta';
import { AcompanharEntregador } from 'pages/acompanhar-entregador';
import { ValidarRecibo } from 'pages/validar-recibo';
import TvDella from 'pages/tv-della';
import React, { FC, memo, useEffect } from 'react'; //
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AuthProvider } from 'routes/auth.context';
import Providers from 'contexts';
import Page404 from '../pages/404';
import AdminRoute from './admin';
import AuthRoute from './auth';
import { useClientApollo } from './hooks/useClientApollo';
import GlobalStyl from './styl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const GroupRoutes: FC = () => {
    const queryClient = new QueryClient();
    useEffect(setLocalePtUtilsYup, []);
    const { client, clientAxios, handleUpdateBearer, clienteApolloLogout } =
        useClientApollo();
    const location = useLocation();
    const renderRoutes = () => {
        if (location.pathname.indexOf('/rota-coleta') !== -1) {
            return <RotaColeta />;
        }
        if (location.pathname.indexOf('/acompanhar-entregador') !== -1) {
            return <AcompanharEntregador />;
        }
        if (location.pathname.indexOf('/validar-recibo') !== -1) {
            return <ValidarRecibo />;
        }
        if (location.pathname.indexOf('/gps-fake') !== -1) {
            return <GpsFake />;
        }
        if (location.pathname === '/tv-della') {
            return (
                <AuthProvider
                    handleUpdateBearer={handleUpdateBearer}
                    clienteApolloLogout={clienteApolloLogout}
                    axios={clientAxios}>
                    <Route
                        path="*"
                        component={AlertConfirm}
                    />
                    <Switch>
                        <Route
                            exact
                            component={TvDella}
                        />
                    </Switch>
                </AuthProvider>
            );
        }
        if (location.pathname === '/politicas-de-privacidade') {
            return <PoliticaPage />;
        }
        return (
            <AuthProvider
                handleUpdateBearer={handleUpdateBearer}
                clienteApolloLogout={clienteApolloLogout}
                axios={clientAxios}>
                <Providers>
                    <Route
                        path="*"
                        component={AlertConfirm}
                    />
                    <Switch>
                        <Route path={['/admin/*']}>
                            <AdminRoute />
                        </Route>
                        <Route
                            path={[
                                '/',
                                '/signup',
                                '/recover-sign-in',
                                '/tv-della',
                                '/politicas-de-privacidade',
                                '/rota-coleta',
                                '/acompanhar-entregador',
                                '/validar-recibo',
                            ]}>
                            <AuthRoute />
                        </Route>
                        <Redirect
                            exact
                            from="/admin"
                            to="/admin/coleta"
                        />
                        <Route
                            exact
                            component={Page404}
                        />
                    </Switch>
                    <Redirect
                        exact
                        from="/build"
                        to="/"
                    />
                </Providers>
            </AuthProvider>
        );
    };
    return (
        <>
            <GlobalStyl />
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={client}>{renderRoutes()}</ApolloProvider>
            </QueryClientProvider>
        </>
    );
};

export default memo(GroupRoutes);
