import { useMutation } from '@apollo/client';
import { GRAPHQL_UNIDADE_INSERIR_SALDO } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useInserirSaldo = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConta, { loading }] = useMutation(
        GRAPHQL_UNIDADE_INSERIR_SALDO
    );
    const handleInserirSaldo = useCallback(
        async ({
            cartaoCvc,
            cartaoNumero,
            cartaoValidade,
            titularCartao,
            ...rest
        }) => {
            try {
                const card =
                    Math.random().toString(36).substring(0, 4) +
                    Buffer.from(
                        `${cartaoCvc}#,#${cartaoNumero}#,#${cartaoValidade}#,#${titularCartao}`
                    ).toString('base64');
                await handleAtualizaConta({
                    variables: { ...rest, card },
                });
                history.goBack();
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        },
        []
    );
    return {
        handleInserirSaldo,
        loading,
    };
};
