import { useMemo } from 'react';

export const useTransformData = (response) => {
    return useMemo(
        () =>
            response.map((item) => {
                return {
                    colunas: [
                        {
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: 'minmax(17rem, 1fr)',
                            marginRight: '0',
                            titulo: item.fullName,
                            subtitulo: item.phone,
                        },
                        {
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: 'minmax(17rem, 1fr)',
                            marginRight: '0',
                            titulo: item.id,
                        },
                        {
                            tipo: 'Texto',
                            titulo: '',
                            subtitulo: '',
                            width: '6rem',
                            marginRight: '0',
                            botoes: [],
                        },
                    ],
                };
            }),
        [response]
    );
};
