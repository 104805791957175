import { IPeriodo } from 'types/modules/periodo';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GRAPHQL_LISTAR_PERIODO } from 'gql/periodo';
import templateInput from '../data/input.json';
export const useQueryPeriodo = () => {
    const [inputs, setInputs] = useState([]);
    const { data, loading } = useQuery<{
        periodo?: IPeriodo[];
    }>(GRAPHQL_LISTAR_PERIODO, { fetchPolicy: 'no-cache' });
    useEffect(() => {
        if (data?.periodo?.length > 0) {
            const itemInput = data?.periodo.map((value) => {
                const clone = JSON.parse(JSON.stringify(templateInput));

                clone.items[0].label = `Hora início ${value.nome}`;
                clone.items[1].label = `Hora fim ${value.nome}`;
                clone.items[2].label = `Garantido ${value.nome}`;
                clone.items[0].name = `horaInicio[${value.id}]`;
                clone.items[1].name = `horaFim[${value.id}]`;
                clone.items[2].name = `valorGarantido[${value.id}]`;
                clone.items[0].defaultValue = value.horaInicio;
                clone.items[1].defaultValue = value.horaFim;
                clone.items[2].defaultValue = '' + value.valorGarantido;
                return clone;
            });
            setInputs(itemInput);
        }
    }, [data]);
    return { inputs, loading };
};
