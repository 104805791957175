import { useSubscription } from '@apollo/client';
import ListTemplate from 'components/template/ListTemplate';
import { GRAPHQL_WPP_RECEIVER_SUBSCRIPTION } from 'gql/whatsapp';
import React, { FC, useMemo, useState } from 'react';
import { useHeaderWhatsapp } from './hooks/useHeaderWhatsapp';

const PageListWhatsapp: FC<any> = () => {
    const colunas = useHeaderWhatsapp();
    const [data, setData] = useState([]);
    const handleSearch = () => {
        /** */
    };
    const onPagination = () => {
        /** */
    };
    useSubscription(GRAPHQL_WPP_RECEIVER_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: {
                    whatsappReceiver: { from, body },
                },
            },
        }) {
            setData((prev) => {
                const newData = [...prev];
                newData.push({
                    colunas: [
                        {
                            tipo: 'Texto',
                            width: '18rem',
                            marginRight: '1rem',
                            titulo: from,
                        },
                        {
                            tipo: 'Texto',
                            width: '1fr',
                            marginRight: '1rem',
                            titulo: body,
                        },
                        {
                            tipo: 'Texto',
                            width: '3rem',
                            titulo: '',
                            botoes: [
                                {
                                    titulo: 'Responder mensagem',
                                    disabled: true,
                                    icone: 'icon-enviar',
                                },
                            ],
                        },
                    ],
                });
                return newData;
            });
        },
    });

    return (
        <ListTemplate
            onPagination={onPagination}
            dataSelectHeader={[]}
            hasMore={false}
            loading={false}
            handleSearch={handleSearch}
            browserTitle="Whatsapp"
            colunas={colunas}
            data={data}
        />
    );
};
export default PageListWhatsapp;
