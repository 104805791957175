import { useQuery } from '@apollo/client';
import { defTitle } from 'assets/strings';
import { GRAPHQL_LISTAR_UNIDADE } from 'gql/unidade';

import React, { FC, memo, useEffect, useState } from 'react';
import PageUnidadeComponent from './component';
import { IQueryUnidade } from '../../list/hooks/useQueryFilterUnit/index.d';

const UnidadesSelecionar: FC = memo(() => {
    const [filter, setFilter] = useState({});

    const { data, loading } = useQuery<IQueryUnidade>(GRAPHQL_LISTAR_UNIDADE, {
        variables: { filter },
    });

    useEffect(() => {
        document.title = `${defTitle} - Unidades`;
    });

    const handleSearchUnidade = async (e) => {
        e.preventDefault();
        if (!loading) {
            setFilter({
                nomeFantasia: e.target.value,
            });
        }
    };

    const props = {
        loadingSearch: loading,
        unidades: data?.unidade?.data || [],
        handleSearchUnidade,
    };

    return <PageUnidadeComponent {...props} />;
});
export default UnidadesSelecionar;
