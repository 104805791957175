import React, { FC, useCallback, useState, useContext } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PageSubmitCode from './newPassword';
import PageInformEmail from './informMail';
import PageSendCode from './sendCode';
import { AuthContext } from 'routes/auth.context';

type IswitchComponent =
	| 'inform-email-and-receive-code'
	| 'enter-code-and-authorize-new-password'
	| 'new-password';
export interface ISwitchComponent {
	setSwitchComponent(switchComponent: IswitchComponent): void;
}
const PageRecoverSignin: FC = () => {
	const [code, setCode] = useState();
	const [switchComponent, setSwitchComponent] = useState<IswitchComponent>(
		'inform-email-and-receive-code'
	);
	const { cacheEmail, setCacheEmail } = useContext(AuthContext);
	const renderComponent = useCallback(() => {
		const props: any = {
			setSwitchComponent,
			cacheEmail,
			code,
			setCode,
			setCacheEmail,
		};
		switch (switchComponent) {
			case 'new-password':
				return <PageSubmitCode {...props} />;
			case 'enter-code-and-authorize-new-password':
				return <PageSendCode {...props} />;
			case 'inform-email-and-receive-code':
			default:
				return <PageInformEmail {...props} />;
		}
	}, [cacheEmail, code, setCacheEmail, switchComponent]);
	return (
		<SwitchTransition>
			<CSSTransition
				key={switchComponent}
				timeout={400}
				mountOnEnter={true}
				unmountOnExit={true}
				classNames="switch-component"
			>
				{renderComponent()}
			</CSSTransition>
		</SwitchTransition>
	);
};
export default PageRecoverSignin;
