import { useQuery } from '@apollo/client';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import clienteComPlayer from 'assets/images/cliente-com-player.png';
import unidadeIcon from 'assets/images/estabelecimento-entregador.png';
import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import styleMaps from 'components/atom/map/styleMaps.json';
import { GRAPHQL_COLETA_LOCALIZACAO } from 'gql/coleta';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, stylMap, WarpLoad } from './styl';
interface IMap {
    fitBounds?: any;
}
export const RotaColeta: FC = () => {
    const [map, setMap] = useState<IMap>({});
    useEffect(() => {
        document.title = `${defTitle} - Coleta`;
    }, []);
    const apiLoc = useLocation();
    const { data, loading } = useQuery(GRAPHQL_COLETA_LOCALIZACAO, {
        variables: { coletaId: apiLoc.pathname.replace('/rota-coleta/', '') },
    });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAEOaJwrZTSQ3ufTKeSXpcmpTJoyKv2Ps0',
    });

    const onLoad = useCallback(
        (map) => {
            setMap(map);
        },
        [data]
    );

    useEffect(() => {
        const api: any = window?.google?.maps;
        if (
            map &&
            map.fitBounds &&
            api &&
            api?.LatLngBounds &&
            data &&
            data.recuperarColetaById
        ) {
            const bounds = new api.LatLngBounds();
            bounds.extend(
                new api.LatLng(
                    data.recuperarColetaById.clienteLat,
                    data.recuperarColetaById.clienteLng
                )
            );
            bounds.extend(
                new api.LatLng(
                    data.recuperarColetaById.unidadeLat,
                    data.recuperarColetaById.unidadeLng
                )
            );
            data.recuperarColegaLocationById.forEach((item) => {
                bounds.extend(new api.LatLng(item.lat, item.lng));
            });
            map.fitBounds(bounds);
        }
    }, [map, data]);
    const onUnmount = useCallback(() => {
        setMap({} as IMap);
    }, []);
    return (
        <>
            {isLoaded ||
                (loading && (
                    <WarpLoad>
                        <CircularLoading
                            show={true}
                            size={3}
                            primaryColor="var(--bg-hover-light)"
                            secondaryColor="var(--btn-underline-cor)"
                        />
                    </WarpLoad>
                ))}
            {!loading &&
                data &&
                data.recuperarColetaById &&
                data.recuperarColetaById.keyMaps && (
                    <Container>
                        <GoogleMap
                            mapContainerStyle={stylMap}
                            options={{
                                styles: styleMaps as google.maps.MapTypeStyle[],
                            }}
                            center={{
                                lat: -16.702038277190702,
                                lng: -49.26757205109623,
                            }}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            {/* {data.recuperarColegaLocationById && (
                                <Polygon
                                    path={data.recuperarColegaLocationById}
                                    options={{
                                        fillColor: 'transparent',
                                        fillOpacity: 0.4,
                                        strokeColor: '#d35400',
                                        strokeOpacity: 0.8,
                                        strokeWeight: 3,
                                    }}
                                />
                            )} */}
                            {(data.recuperarColegaLocationById || []).map(
                                (item, index) => (
                                    <Marker
                                        zIndex={index}
                                        key={index}
                                        position={item}
                                    />
                                )
                            )}
                            {data.recuperarColetaById &&
                                data.recuperarColetaById.unidadeLat && (
                                    <Marker
                                        zIndex={500}
                                        position={{
                                            lat: data.recuperarColetaById
                                                .unidadeLat,
                                            lng: data.recuperarColetaById
                                                .unidadeLng,
                                        }}
                                        title={
                                            data.recuperarColetaById.razaoSocial
                                        }
                                        icon={unidadeIcon}
                                    />
                                )}
                            {data.recuperarColetaById &&
                                data.recuperarColetaById.clienteLat && (
                                    <Marker
                                        zIndex={501}
                                        position={{
                                            lat: data.recuperarColetaById
                                                .clienteLat,
                                            lng: data.recuperarColetaById
                                                .clienteLng,
                                        }}
                                        title={data.recuperarColetaById.cliente}
                                        icon={clienteComPlayer}
                                    />
                                )}
                        </GoogleMap>
                    </Container>
                )}
        </>
    );
};
