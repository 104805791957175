export const TIPO_NOTIFICACAO = [
    {
        titulo: 'Aviso de vencimento em 24h',
        mensagem: `Olá, tudo bem? \r\n\r\nEssa é uma mensagem automática!\r\n\r\nSua fatura vence amanhã, fique atento! Caso não tenha recebido segue link abaixo para sua conferência:\r\n\r\nBOLETO: #BOLETO#\r\r\nRECIBO: #RECIBO#\r\n\r\nAtt, financeiro Jogorapido Delivery!`,
    },
    {
        titulo: 'Enviar nova fatura',
        mensagem: `Olá, tudo bem? \r\n\r\nEssa é uma mensagem automática!\r\n\r\nInformamos que consta em aberto o boleto abaixo que segue para sua conferência:\r\n\r\nBOLETO: #BOLETO#\r\r\nRECIBO: #RECIBO#\r\n\r\nCaso já tenha sido pago, pedimos a gentileza de desconsiderar essa mensagem.\r\n\r\nAtt, financeiro Jogorapido Delivery!`,
    },
    {
        titulo: 'Bloqueio por inadimplência',
        mensagem: `Olá, tudo bem? \r\n\r\nEssa é uma mensagem automática!\r\n\r\nInformamos que seu aplicativo foi bloqueado por inadimplência. Para regularizar a situação, acesse os links abaixo:\r\n\r\nBOLETO: #BOLETO#\r\r\nRECIBO: #RECIBO#\r\n\r\nPedimos a gentileza de nos enviar o comprovante de pagamento para desbloqueio.\r\n\r\nAtt, financeiro Jogorapido Delivery!`,
    },
    {
        titulo: 'Aviso de inadimplência',
        mensagem: `Olá, tudo bem? \r\n\r\nEssa é uma mensagem automática!\r\n\r\nInformamos que consta em aberto o boleto abaixo que segue para sua conferência:\r\n\r\nBOLETO: #BOLETO#\r\r\nRECIBO: #RECIBO#\r\n\r\nDestacamos que o não pagamento ou negociação do mesmo pode gerar protesto cartorário.\r\n\r\nAguardamos seu retorno!\r\n\r\nAtt, financeiro Jogorapido Delivery!`,
    },
];
