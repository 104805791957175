import React, { FC, memo, useEffect, Fragment } from 'react';
import { ContainerPageSignup } from '../styl';
import { defTitle } from 'assets/strings';
import { Form } from '@unform/web';
import ButtonLoading from 'components/atom/ButtonLoading';
import { Link } from 'react-router-dom';
import { WarpLink } from 'pages/signin/styl';
import SelectCreatable from 'components/Form/SelectCreatable';

const PageYourBusinessComponent: FC<any> = memo(
	({ formRef, perguntas, handleSubmit, initialData }) => {
		useEffect(() => {
			document.title = `${defTitle} - Dados pessoais`;
		});
		const renderItem = (
			{ id, titulo, subtitulo, alternativas },
			indice
		) => {
			return (
				<Fragment key={`fragment-key-${indice}`}>
					{titulo && <p key={`p-${indice}`}>{titulo}</p>}
					{subtitulo && (
						<span key={`span-${indice}`}>{subtitulo}</span>
					)}
					<SelectCreatable
						key={`selectCreatable-${indice}`}
						name={`select-${id}`}
						placeholder="Clique para selecionar"
						options={alternativas}
					/>
				</Fragment>
			);
		};
		return (
			<ContainerPageSignup>
				<div className="yourCompany">
					<Form
						ref={formRef}
						initialData={initialData}
						onSubmit={handleSubmit}
					>
						{perguntas.map(renderItem)}
						<div className="space-button">
							<ButtonLoading value="continuar" loading={false} />
						</div>
						<WarpLink>
							<Link to="/"> Já sou cadastrado</Link>
						</WarpLink>
					</Form>
				</div>
			</ContainerPageSignup>
		);
	}
);
export default PageYourBusinessComponent;
