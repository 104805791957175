import ListTemplate from 'components/template/ListTemplate';

import React, { FC } from 'react';
import { useQueryFilter } from './hooks/useQueryFilter';
import { useHeader } from './hooks/useHeader';
import { useTransformUser } from './hooks/useTransformUser';

const PageListUser: FC<any> = () => {
    const props = useQueryFilter();

    const colunas = useHeader();

    const data = useTransformUser(props.response);

    return (
        <ListTemplate
            browserTitle="Usuários"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default PageListUser;
