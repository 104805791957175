import PageRecoverSignin from 'pages/recoversignin';
import PageSignin from 'pages/signin';
import PageSignup from 'pages/signup';
import React, { FC, memo, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import TemplateAuth from './partial/template';

const AuthRoute: FC = () => {
    const { loadingAutoSignin } = useContext(AuthContext);

    if (loadingAutoSignin) return null;

    return (
        <TemplateAuth>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={PageSignin}
                />
                <Route
                    exact
                    path="/recover-sign-in"
                    component={PageRecoverSignin}
                />
                <Route
                    exact
                    path="/signup"
                    component={PageSignup}
                />
            </Switch>
        </TemplateAuth>
    );
};

export default memo(AuthRoute);
