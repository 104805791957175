import React, { FC } from 'react';
import { Container } from './styl';
import CircularLoading from 'components/atom/Loading/CircularLoading';
interface IButton {
	value: string;
	onClick?: any
	className?: any
	loading?: boolean;
}
const Button: FC<IButton> = ({ value, onClick, className, loading}) => {
	return (
		<Container className={className} onClick={onClick}>
			<span>{value}</span>
			<div className={"showLoading"}>
				<CircularLoading show={loading} size={1} />
			</div>
		</Container>
	);
};
export default Button;
