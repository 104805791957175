// eslint-disable-next-line no-use-before-define
import ButtonLoading from 'components/atom/ButtonLoading';
import Icone from 'components/Icone';
import React, { FC, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import MiniCard from '../Card';
import { Container } from './styl';

interface IMiniCardColuna {
    title: string;
    status: number[];
    cards: any[];
    hasNextPage: boolean;
}
const MiniCardColuna: FC<any> = memo(
    ({ title, status, cards, hasNextPage }: IMiniCardColuna) => {
        return (
            <Container>
                <div className="coluna">
                    <div className="title">
                        {title}
                        <button
                            className="icone"
                            data-for="tooltipAtualizar"
                            data-tip={`Atualizar lista`}
                            onClick={() => void 0}
                        >
                            <ReactTooltip
                                multiline
                                textColor="white"
                                backgroundColor="var(--btn-admin-icone-hover)"
                                id="tooltipAtualizar"
                                className="tool-tip"
                                place="bottom"
                                data-type="light"
                                effect="solid"
                            />
                            <Icone
                                size="1.5rem"
                                icone="icon-atualizar"
                                color="var(--btn-admin-icone-hover)"
                            />
                        </button>
                    </div>
                    <div className="cards-wrap">
                        {cards.map((card, index) => {
                            return <MiniCard card={card} />;
                        })}
                        {hasNextPage && (
                            <div className="carregar-mais">
                                <div className="simple-button">
                                    <ButtonLoading
                                        value={'Carregar mais'}
                                        loading={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        );
    }
);
export default MiniCardColuna;
