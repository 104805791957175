import { useWindowFocus } from './useWindowFocus';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCalcTimer } from './useCalcTimer';
interface IError {
    message?: string;
    locations?: string;
    path?: string;
    name?: string;
    stack?: string;
}
export const useClientApollo = () => {
    const { start, end, getValue } = useCalcTimer();
    const { windowNoCache } = useWindowFocus();

    const [clienteApolloLogout, setClienteApolloLogout] = useState(false);

    const [bearer, setBearer] = useState(() => {
        const token = localStorage.getItem('@auth/token');
        return token ? `Bearer ${token}` : null;
    });
    const handleUpdateBearer = useCallback((propBearer = null) => {
        if (propBearer) localStorage.setItem('@auth/token', propBearer);
        else localStorage.removeItem('@auth/token');
        setBearer(propBearer ? `Bearer ${propBearer}` : null);
    }, []);

    const clientAxios = useMemo(() => {
        return axios.create({
            baseURL: process.env.REACT_APP_HOST_EXPRESS,
            headers: {
                Authorization: bearer,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    }, [bearer]);

    const authLink = useMemo(
        () =>
            setContext(({ variables, query }, { headers = {} }) => {
                const def: any = query.definitions[0];

                let authorization = bearer;

                if ('basic' in variables) {
                    const { email, senha } = variables.basic;
                    delete variables.basic;
                    const basic = Buffer.from(`${email}:${senha}`).toString(
                        'base64'
                    );
                    authorization = `Basic ${basic}`;
                }
                return {
                    headers: {
                        ...headers,
                        authorization,
                    },
                };
            }),
        [bearer]
    );
    const httpLink = useMemo(
        () =>
            new HttpLink({
                uri: process.env.REACT_APP_HOST_GRAPHQL,
            }),
        []
    );

    const wsLink = useMemo(
        () =>
            new WebSocketLink({
                uri: process.env.REACT_APP_HOST_GRAPHQL_WEBSOCKET,
                options: {
                    reconnect: true,
                    lazy: true,
                    connectionParams: {
                        headers: {
                            authorization: bearer,
                        },
                    },
                },
            }),
        [bearer, windowNoCache]
    );
    const splitLink = useMemo(
        () =>
            split(
                ({ query }) => {
                    const definition: {
                        operation?: string;
                        kind?: string;
                    } = getMainDefinition(query);
                    return (
                        definition.kind === 'OperationDefinition' &&
                        definition.operation === 'subscription'
                    );
                },
                wsLink,
                httpLink
            ),
        [wsLink, httpLink]
    );
    useEffect(start, []);

    const linkError = useMemo(
        () =>
            onError(({ graphQLErrors, networkError }) => {
                end();
                if (getValue('second') > 2) {
                    start();
                    if (graphQLErrors) networkError = graphQLErrors[0];
                    const { message, locations, path, name, stack } = (
                        graphQLErrors ? graphQLErrors[0] : networkError
                    ) as IError;
                    // console.group('UseClientApollo/error');
                    // console.info(`message "${message}"`);
                    // console.info(`locations "${locations}"`);
                    // console.info(`name: "${name}"`);
                    // console.info(`name: "${path}"`);
                    // console.info(`stack: "${stack}"`);
                    // console.groupEnd();

                    if (
                        ['jwt expired', 'invalid token'].indexOf(message) !==
                            -1 &&
                        !clienteApolloLogout
                    ) {
                        setClienteApolloLogout(true);
                    }
                }
            }),
        []
    );
    const client = useMemo(
        () =>
            new ApolloClient({
                link: linkError.concat(authLink).concat(splitLink),
                cache: new InMemoryCache(),
                assumeImmutableResults: true,
                queryDeduplication: true,
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                        errorPolicy: 'none',
                    },
                    query: {
                        fetchPolicy: 'cache-first',
                        errorPolicy: 'all',
                    },
                },
            }),
        [linkError, authLink, splitLink]
    );
    return { client, handleUpdateBearer, clientAxios, clienteApolloLogout };
};
