import React, { FC, useEffect, useCallback, useState } from 'react';
import { useField } from '@unform/core';
import { Container, LabelError, InputMoney } from './styl';

interface IMoney extends IInput {
    prefix: string;
}
const FormInputMoney: FC<IMoney> = ({
    prefix = 'R$ ',
    name,
    label,
    icon,
    className,
    defaultValue,
}) => {
    let { fieldName, registerField, clearError, error } = useField(name);
    const [value, setValue] = useState(defaultValue as string);
    const onChangeEvent = useCallback((_e, maskedvalue) => {
        setValue(maskedvalue);
    }, []);
    useEffect(() => {
        registerField({
            name: fieldName,
            clearValue: (ref) => setValue(''),
            getValue: (ref) => value,
            setValue: (ref: any, value: string) => setValue(value),
        });
    }, [fieldName, registerField, value]);
    return (
        <Container erro={error}>
            <div className={className}>
                <InputMoney
                    onFocus={clearError}
                    value={value}
                    shake={error ? 1 : undefined}
                    onChangeEvent={onChangeEvent}
                    prefix={prefix}
                />
                {icon}
            </div>
            <LabelError erro={error}>{error}</LabelError>
        </Container>
    );
};
export default FormInputMoney;
