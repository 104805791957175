import React, { FC, memo, useEffect } from 'react';
import { ContainerPageSignup, Grid } from '../styl';
import { defTitle } from 'assets/strings';
import { Form } from '@unform/web';
import Input from 'components/Form/Input';
import ButtonLoading from 'components/atom/ButtonLoading';
import { Link } from 'react-router-dom';
import { WarpLink } from 'pages/signin/styl';
import InputMask from 'components/Form/Input/InputMask';
const PageSignupComponent: FC<any> = memo(
	({ formRef, handleSubmit, initialData, mascaraTelefone }) => {
		useEffect(() => {
			document.title = `${defTitle} - Dados pessoais`;
		});
		return (
			<ContainerPageSignup>
				<Form
					initialData={initialData}
					ref={formRef}
					onSubmit={handleSubmit}
				>
					<p className="pFatura">
						Precisamos de alguns dados básicos pessoais.
					</p>
					<Grid flexStart={1}>
						<div className="coluna">
							<label htmlFor="nome">Nome</label>
							<Input name="nome" />
						</div>
						<div className="coluna">
							<label htmlFor="sobrenome">Sobrenome</label>
							<Input name="sobrenome" />
						</div>
					</Grid>
					<label htmlFor="email">E-mail</label>
					<Input name="email" />
					<label htmlFor="telefone">Telefone</label>
					<InputMask name="telefone" {...mascaraTelefone} />

					<div className="space-button">
						<ButtonLoading value="continuar" loading={false} />
					</div>
					<WarpLink>
						<Link to="/"> Já sou cadastrado</Link>
					</WarpLink>
				</Form>
			</ContainerPageSignup>
		);
	}
);
export default PageSignupComponent;
