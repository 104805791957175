import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
const entityFormat = (path, entity) => {
    const listEntity = path.split('/');
    let newPath = '';
    if (entity !== null && entity !== undefined) {
        if (typeof entity === 'string') newPath = `/${entity}`;
        else if (entity.length > 0) {
            newPath = '/' + entity.join('/');
        }
    }
    const goto = listEntity.slice(1, 3).join('/');
    let url = `/${goto}${newPath}`;
    if (url.length - 1 === url.lastIndexOf('/')) {
        url = url.substring(0, url.length - 1);
    }
    return url;
};
export const useModalHistory = () => {
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const replace = useCallback(
        (entity = undefined) => {
            const newUrl = entityFormat(path, entity);
            history.replace(newUrl);
        },
        [history, path]
    );
    const push = useCallback(
        (entity = undefined) => {
            const newUrl = entityFormat(url, entity);
            history.push(newUrl);
        },
        [history, url]
    );
    const popTo = useCallback(
        (entity = undefined) => {
            const newUrl = entityFormat(url, entity);
            history.goBack();
            setTimeout(() => history.push(newUrl), 700);
        },
        [history, url]
    );
    return { ...history, replace, push, popTo };
};
