// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
import { Icon } from './styl';

interface IIcone {
    size?: string;
    color?: string;
    icone: string;
    marginLeft?: number;
    marginRight?: number;
}
// IIcone
const Icone: FC<any> = memo(
    ({
        size = '3rem',
        color = 'var(--text-cor)',
        icone = 'icon-sd',
        className = '',
        marginLeft,
        marginRight,
    }) => {
        return (
            <Icon
                marginLeft={marginLeft}
                marginRight={marginRight}
                className={`font-ic ${icone} ${className}`}
                size={size}
                color={color}
            />
        );
    }
);
export default Icone;
