import { SelecionarEndereco } from 'components/enderecosListByCliente';
import FormularioEnderecoTemplate from 'components/template/FormularioEndereco';
import React, { FC, useContext, useEffect } from 'react';
import { IClienteEndereco } from 'types/modules/clienteEndereco';
import { AuthContext } from 'routes/auth.context';
import { IReceiver } from '../cliente';
import { useNewAddress } from './hook/useNewAddress';
import { useSelectSubmit } from './hook/useSelectSubmit';
import { ICidade } from 'types/modules/cidade';

const PageNavigateSelecionarEndereco: FC<IReceiver> = props => {
    const { unidadeSelecionada } = useContext(AuthContext);
    const [fieldsEdit, setFieldsEdit] = React.useState<
        IClienteEndereco & {
            clienteId: string;
            cidade: ICidade & {
                uf: {
                    uf: string;
                };
            };
        }
    >(null);

    const {
        hasNewAddress,
        setHasNewAddress,
        handleListarEnderecos,
        ...selectAddress
    } = useSelectSubmit(props);

    const newAddress = useNewAddress(props, handleListarEnderecos);

    useEffect(() => {
        props.setMinWidthModal('90rem');
    }, [hasNewAddress]);

    const setupLocation = {
        lat: unidadeSelecionada.geoLatitude,
        lng: unidadeSelecionada.geoLongitude,
    };

    const handleEditAddress = (
        item: IClienteEndereco & {
            clienteId: string;
            cidade: ICidade & {
                uf: {
                    uf: string;
                };
            };
        },
    ) => {
        setFieldsEdit(item);
        setHasNewAddress(false);
    };

    return hasNewAddress ? (
        <SelecionarEndereco
            labelSubmit={`${
                props.handleSubmit ? 'Salvar' : 'Ir para pagamento'
            }`}
            clienteId={props.clienteId}
            unidadeId={unidadeSelecionada.id}
            endereco_id_selecionado={props?.endereco}
            handleSubmit={(e: any) => {
                if (e.id) {
                    if (props.handleSubmit) props.handleSubmit(e.id);
                    else selectAddress.submit(e);
                } else {
                    selectAddress.submit(e);
                }
            }}
            setHasNewAddress={setHasNewAddress}
            handleEditAddress={handleEditAddress}
            handleNavigateForms={props.handleNavigateForms}
            rForm={props.formRef}
        />
    ) : (
        <FormularioEnderecoTemplate
            {...newAddress}
            fieldsEdit={fieldsEdit}
            setFieldsEdit={setFieldsEdit}
            setupLocation={setupLocation}
            origem={{ unidadeId: unidadeSelecionada.id }}
            formRef={props.formRef}
            tituloFormulario="Informe o endereço do cliente"
        />
    );
};
export default PageNavigateSelecionarEndereco;
