import CircularLoading from 'components/atom/Loading/CircularLoading';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC } from 'react';
import { useClientColeta } from './hooks/useClientColeta';
import {
    Button,
    Container,
    Header,
    Table,
    TableResponsive,
    Warp,
} from './styl';

const ListarStatusColeta: FC = () => {
    const {
        handleToggleStatus,
        isLastedCollectCanceled,
        statusColeta,
        loading,
        loadingUpdate,
    } = useClientColeta();

    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Status coleta</h1>
                        <CircularLoading
                            show={loadingUpdate}
                            size={3}
                            primaryColor="var(--btn-underline-cor)"
                        />
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Warp>
                        <CircularLoading
                            show={loading}
                            size={3}
                            primaryColor="var(--btn-underline-cor)"
                        />
                        {!loading && (
                            <TableResponsive>
                                <Table>
                                    <tbody>
                                        {statusColeta?.map((status, indice) => {
                                            const key = `${status.statusId}-${
                                                status.dataAlteracao
                                            }-${
                                                status.ativo ? 'sim' : 'nao'
                                            }-indice=${indice}`;

                                            return (
                                                <tr
                                                    key={key}
                                                    className={
                                                        status.ativo
                                                            ? ''
                                                            : 'inativo'
                                                    }>
                                                    <td width="100px">
                                                        {status.hora}
                                                    </td>
                                                    <td
                                                        style={
                                                            status.ativo
                                                                ? {
                                                                      color: status.corBackground,
                                                                  }
                                                                : {}
                                                        }>
                                                        {status.nome}
                                                    </td>
                                                    <td
                                                        style={{
                                                            minWidth: '22rem',
                                                        }}>
                                                        {status.nomeCompleto} ({' '}
                                                        {
                                                            status.qtdxchamouplayer
                                                        }{' '}
                                                        notificações )
                                                    </td>
                                                    <td>
                                                        {isLastedCollectCanceled && (
                                                            <Button
                                                                onClick={() =>
                                                                    handleToggleStatus(
                                                                        status,
                                                                    )
                                                                }>
                                                                {status.ativo
                                                                    ? 'ativo'
                                                                    : 'inativo'}
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </TableResponsive>
                        )}
                    </Warp>
                </Container>
            )}
        />
    );
};
export default ListarStatusColeta;
