import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_ADICIONAR_ENTREGADOR } from 'gql/entregador';
import { useValidadeSubmit } from 'pages/entregador/hooks/useValidadeSubmit';
import { createRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
const aSexo = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
];
export const useEntregadorAdicionar = () => {
    const [handleAdicionarEntregador, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_ENTREGADOR
    );
    async function adicionarEntregador({ data }) {
        await handleAdicionarEntregador({
            variables: {
                data,
            },
        });
    }
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);

    const sexoSelected = aSexo.find((ele) => ele.value === 'M');
    const rForm = createRef<FormHandles>();
    const handleDataValidate = useValidadeSubmit();
    const handleSubmit = async (data) => {
        const post = await handleDataValidate({ data, rForm: rForm.current });
        if (post) {
            try {
                await adicionarEntregador({
                    data: {
                        cidade: 'Goiânia',
                        uf: 'go',
                        ...data,
                    },
                });
                history.goBack();
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            }
        }
    };

    return {
        rForm,
        title: 'Adicionar player',
        titleSubmit: 'Adicionar player',
        sexoSelected,
        handleSubmit,
        aSexo,
        loading,
    };
};
