import { useMutation } from '@apollo/client';
import { GRAPHQL_ATUALIZAR_UNIDADE_CONTA } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useUnidadeContaAlterar = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConta, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_UNIDADE_CONTA
    );
    const atualizaUnidadeConta = useCallback(async ({ unidadeId, data }) => {
        try {
            await handleAtualizaConta({
                variables: {
                    unidadeId,
                    data,
                },
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    }, []);
    return {
        atualizaUnidadeConta,
        loading,
    };
};
