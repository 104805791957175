import styled, { css } from 'styled-components';

const gradiente = css`
    content: '';
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        90deg,
        rgba(125, 49, 136, 1) 0%,
        rgba(62, 157, 236, 1) 100%
    );
`;
export const Container = styled.main<any>`
    &::before {
        ${gradiente};
        z-index: 1;
        height: 13rem;
        position: fixed;
    }
    &::after {
        ${gradiente};
        z-index: 0;
        height: 21rem;
        position: absolute;
    }
`;
export const WrapContent = styled.div`
    display: flex;
`;
export const Content = styled.div`
    min-height: calc(100vh - 8rem);
    flex: 1;
    z-index: 3;
    margin: 0 1.5rem;
`;
export const WarpHeaderFixo = styled.div<any>`
    position: sticky;
    top: 0rem;
    z-index: 998;
    max-width: calc(100vw - 3rem);

    &::after {
        ${gradiente};
        background: linear-gradient(
            90deg,
            rgba(117, 63, 149, 1) 0%,
            rgba(62, 157, 236, 1) 100%
        );
        position: absolute;
        bottom: 0;
        left: -1.5rem;
        right: -1.5rem;
    }

    @media (max-width: 1439.8px) {
        &::after {
            ${gradiente};
        }
    }
`;
