// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, IconFilter } from './styl';

interface IHeaderFilter {
    titulo: string;
    marginRight: string;
    handleOrder?: any;
    placeHolder?: string;
    justifyContent?: string;
    icon?: string;
    pStyl?: any;
}
const HeaderFilter: FC<IHeaderFilter> = memo(
    ({
        titulo,
        marginRight,
        justifyContent,
        handleOrder,
        placeHolder,
        icon,
        pStyl,
    }) => {
        return (
            <Container style={{ justifyContent }}>
                {titulo && (
                    <p
                        style={{
                            marginRight: handleOrder ? 0 : marginRight,
                            ...pStyl,
                        }}
                    >
                        {titulo}
                    </p>
                )}
                {handleOrder && (
                    <div style={{ marginRight }}>
                        <button
                            data-tip={placeHolder || 'Ajuda não informada'}
                            data-for="globalToolTip"
                            onClick={handleOrder}
                        >
                            <IconFilter icone={icon || 'icon-filter-no'} />
                        </button>
                    </div>
                )}
            </Container>
        );
    }
);
export default HeaderFilter;
