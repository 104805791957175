/* eslint-disable no-undef */
/* eslint-disable import/extensions */
// eslint-disable-next-line no-use-before-define
import ButtonLoading from 'components/atom/ButtonLoading';
import InputDate from 'components/Form/Input/InputDate';
import InputFile from 'components/Form/InputFile';
import Radio from 'components/Form/Radio';
import SelectAsync from 'components/Form/SelectAsync';
import SelectCreatable from 'components/Form/SelectCreatable';
import SelectSimple from 'components/Form/SelectSimple';
import Icone from 'components/Icone';
import React, { FC, Fragment, useEffect } from 'react';

import ToolTipLabel from './partial/ToolTipLabel';

import {
    ButtonAction,
    Grid,
    Input,
    InputMask,
    InputMoney,
    InputPassword,
    InputSearch,
    TextArea,
    WarpButton,
    ZIndex,
    ZIndexInline,
} from './styl';

type IFormType =
    | 'mask'
    | 'date'
    | 'money'
    | 'password'
    | 'text'
    | 'radio'
    | 'radio-inline'
    | 'checkbox'
    | 'textarea'
    | 'number'
    | 'hidden'
    | 'search'
    | 'select-async'
    | 'select-simple'
    | 'select-creatable'
    | 'custom-component'
    | 'button-icon'
    | 'file'
    | 'title'
    | 'button';
interface IInputs {
    type: IFormType; // tipo de component
    id?: string;
    name: string; // nome do input no post
    options: {
        id: string;
        value: string;
        label: string;
    }[];
    autoFocus?: boolean;
    stylWarp?: any;
    visible: boolean;
    label?: string; // Todos as legendas dos components inputs
    icone?: string;
    placeholder?: string; // todos os inputs
    defaultValue?: string; // todos os inputs
    valueformat?: any; // função
    mask?: string; // apenas para o input mask
    component?: Element | Element[] | any; // Renderizar um componente qualquer
    loading?: boolean; // button
    value?: string; // button
    toolTipLegend?: Element;
    toolTipColor?: string;
    loadingSize?: number;
    dataClicked?: any;
    handleClick?: any;
}
interface IGrid {
    style: any;
    gridFirstWidth: number;
    items: Array<IInputs | any>;
}
interface IFormGenerator {
    rForm?: any;
    childs: Array<IInputs | IGrid | any>;
}
const renderInput = (inputs: IInputs, key, zIndex) => {
    const {
        type,
        label,
        id,
        toolTipLegend,
        valueformat,
        toolTipColor,
        stylWarp,
        ...value
    } = inputs;
    switch (type) {
        case 'hidden':
            return (
                <Input
                    key={key}
                    type="hidden"
                    {...value}
                />
            );
        case 'text':
        case 'number':
            return (
                <ZIndex
                    key={key}
                    style={stylWarp}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <Input
                        type={type}
                        {...value}
                    />
                </ZIndex>
            );
        case 'title':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <p {...value}>{label}</p>
                </ZIndex>
            );
        case 'file':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputFile {...value} />
                </ZIndex>
            );
        case 'checkbox':
        case 'radio':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <Radio
                        {...value}
                        typeInput={type}
                    />
                </ZIndex>
            );
        case 'radio-inline':
            return (
                <ZIndexInline
                    key={key}
                    zIndex={zIndex}>
                    <Radio
                        {...value}
                        typeInput={type}
                    />
                </ZIndexInline>
            );
        case 'textarea':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <TextArea
                        type={type}
                        {...value}
                    />
                </ZIndex>
            );
        case 'mask':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputMask {...value} />
                </ZIndex>
            );
        case 'date':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputDate {...value} />
                </ZIndex>
            );
        case 'money':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputMoney {...value} />
                </ZIndex>
            );
        case 'password':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputPassword {...value} />
                </ZIndex>
            );
        case 'search':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <InputSearch {...value} />
                </ZIndex>
            );
        case 'select-async':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    {value.visible !== false && (
                        <ToolTipLabel
                            htmlFor={value.name}
                            toolTipColor={toolTipColor}
                            htmlKey={key}
                            label={label}>
                            {toolTipLegend}
                        </ToolTipLabel>
                    )}
                    {value.visible !== false && <SelectAsync {...value} />}
                </ZIndex>
            );
        case 'select-simple':
            return (
                <ZIndex
                    key={key}
                    style={stylWarp}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <SelectSimple {...value} />
                </ZIndex>
            );
        case 'select-creatable':
            return (
                <ZIndex
                    key={key}
                    zIndex={zIndex}>
                    <ToolTipLabel
                        htmlFor={value.name}
                        toolTipColor={toolTipColor}
                        htmlKey={key}
                        label={label}>
                        {toolTipLegend}
                    </ToolTipLabel>
                    <SelectCreatable {...value} />
                </ZIndex>
            );
        case 'custom-component':
            const { component: Cp, ...props } = value;
            if (Cp)
                return (
                    <Cp
                        key={key}
                        {...props}
                    />
                );
            return null;
        case 'button':
            return (
                <div
                    key={key}
                    className="space-button">
                    <ButtonLoading
                        loadingSize={value.loadingSize}
                        {...(value.handleClick
                            ? {
                                  onClick: e => {
                                      if (value.handleClick) {
                                          e.preventDefault();
                                          value.handleClick(value.dataClicked);
                                      }
                                  },
                              }
                            : {})}
                        stylWarp={stylWarp}
                        loading={value.loading}
                        value={value.value || 'Salvar'}
                    />
                </div>
            );
        case 'button-icon':
            return (
                <WarpButton>
                    <ButtonAction
                        onClick={e => {
                            if (value.handleClick) {
                                e.preventDefault();
                                value.handleClick(value.dataClicked);
                            }
                        }}
                        data-for="tooltipBotoesAcao">
                        <Icone
                            color={'var(--btn-admin-icone)'}
                            icone={value.icone}
                        />
                    </ButtonAction>
                </WarpButton>
            );
        default:
            return null;
    }
};
const FormGenerator: FC<IFormGenerator> = ({ childs, rForm }) => {
    useEffect(() => {
        if (rForm && rForm.current) {
            childs.map(value => {
                if (value.valueformat && value.defaultValue)
                    value.defaultValue = value.valueformat(value.defaultValue);
                if ('items' in value) {
                    const { items } = value;
                    items.map(value2 => {
                        if (
                            rForm &&
                            value2.defaultValue &&
                            rForm.current &&
                            ['select-simple', 'select-async', 'mask'].indexOf(
                                value2.type,
                            ) >= 0
                        ) {
                            if (value2.valueformat && value2.defaultValue)
                                value2.defaultValue = value2.valueformat(
                                    value2.defaultValue,
                                );
                            rForm.current.setFieldValue(
                                value2.name,
                                value2.defaultValue,
                            );
                        }
                    });
                }
                if (
                    rForm &&
                    value.defaultValue &&
                    rForm.current &&
                    ['select-simple', 'select-async', 'mask'].indexOf(
                        value.type,
                    ) >= 0
                ) {
                    rForm.current.setFieldValue(value.name, value.defaultValue);
                }
            });
        }
    }, [rForm]);
    let zIndex: number = 9999;

    return (
        <Fragment>
            {childs.map(({ zIndexCustom = null, ...value }, index) => {
                zIndex = Number(zIndexCustom || zIndex);
                if ('items' in value) {
                    const { items, gridFirstWidth, style, gridColumn } = value;
                    return (
                        <Grid
                            zIndex={--zIndex}
                            key={`grid-${index}`}
                            flexStart={gridFirstWidth}
                            gridColumn={gridColumn}
                            total={items.length}
                            style={style}>
                            {items.map((value2, index2) => {
                                return (
                                    <div
                                        key={`grid-sub-${index}-${index2}`}
                                        className="coluna">
                                        {renderInput(
                                            value2,
                                            `input-indice-${index}-${index2}-tipo-${
                                                value2.type
                                            }-nome-${
                                                // eslint-disable-next-line no-nested-ternary
                                                'name' in value2
                                                    ? value2.name
                                                    : 'value' in value2
                                                    ? value2.value
                                                    : 'fix'
                                            }`,
                                            value2.zIndex || --zIndex,
                                        )}
                                    </div>
                                );
                            })}
                        </Grid>
                    );
                }
                return renderInput(
                    value,
                    `input-indice-${index}-tipo-${value.type}-nome-${
                        // eslint-disable-next-line no-nested-ternary
                        'name' in value
                            ? value.name
                            : 'value' in value
                            ? value.value
                            : 'fix'
                    }`,
                    value.zIndex || --zIndex,
                );
            })}
        </Fragment>
    );
};
export default FormGenerator;
