import { useMutation } from '@apollo/client';
import { IReceiver } from 'components/template/ListTemplate/hooks/usePaginationAndFilter/index.d';
import { ENTREGADOR_ALTERAR_MAQUININHA_JR } from 'gql/entregador';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IRetriever } from './index.d';
export const useMutationMaquininhaJr = (
    pagination: IReceiver | any
): IRetriever => {
    const { alertConfirm, cacheFiltroData } = useContext(AuthContext);
    const [toggleMaquininhaJr, { loading: loadingMaquininhaJr }] = useMutation(
        ENTREGADOR_ALTERAR_MAQUININHA_JR
    );

    const handleToogleMaquininhaJr = useCallback(
        async ({ entregadorId, comMaquininhaJr }) => {
            const variables = {
                input: {
                    entregadorId,
                    actived: !comMaquininhaJr,
                },
            };
            try {
                const indice = pagination.response.findIndex(
                    ({ id }) => id === entregadorId
                );
                if (indice === -1) {
                    console.error({ indice, entregadorId });
                    console.error(pagination.response);
                    throw new Error('Entregador não encontrado');
                }
                await toggleMaquininhaJr({
                    variables,
                });

                pagination.response[indice] = {
                    ...pagination.response[indice],
                    comMaquininhaJr: !comMaquininhaJr,
                };

                pagination.handleSetResponse([...pagination.response]);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [toggleMaquininhaJr, cacheFiltroData, pagination]
    );
    return {
        entregadores: pagination.response,
        handleToogleMaquininhaJr,
        loadingMaquininhaJr,
    };
};
