import { width } from './../../../../components/Table/Columns/CheckBox/index';
import styled from 'styled-components';
import { boxShadow } from 'routes/styl';

export const WarpMenu = styled.menu`
    flex-basis: var(--sidebar-width);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    flex-direction: column;
    min-height: calc(100vh - 8rem);
    z-index: 7;
    background-color: var(--bg-white);
    border-top-right-radius: var(--def-radius);
    @media (max-width: 800px) {
        border-top-left-radius: var(--def-radius);
    }
    @media (max-width: 1439.8px) {
        transition: left 0.2s ease-in-out;
        position: fixed;
        left: -20rem;
        bottom: 0;
        top: 0;
        z-index: 999;
        &.menu-aberto {
            box-shadow: 10px 0 10px rgba(0,0,0,0.15);
            left: 0;
        }
    }
`;
export const WarpMenuItems = styled.div.attrs({ className: 'menu-items' })`
    flex: 1;
    min-height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
    @media (min-width: 768px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 1px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0px;
    }
`;

export const AvisoAltoFluxo = styled.div.attrs({ className: 'menu-items' })`
    padding: 0.4rem 1rem;
    text-align: center;
    color: white;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: green;
    display: inline-block;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    @keyframes blinker { to { opacity: 0; } }
`;

export const ContainerCard = styled.aside.attrs({ className: 'card' })`
    position: absolute;
    max-width: 200px;
    top: 4rem;
    width: 100%;
    background: white;
    overflow: hidden;
    border-radius: var(--def-radius);
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${boxShadow};
`;
export const ToggleMenu = styled.button`
    display: none;
    cursor: pointer;
    margin-left: 10px;

    .icon-fechar {
        color: var(--btn-admin-icone);
        font-size: 24px;
    }

    @media (max-width: 1439.8px) {
        display: inline-block;
    }
`;
export const LogoMenu = styled.div`
    display: none;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0.8rem 1.5rem 0 1.5rem;
    height: 4rem;
    img {
        max-width: calc(var(--sidebar-width) - 7rem);
    }
    @media (max-width: 1439.8px) {
        display: flex;
        align-items: baseline;
        position: relative;
        .icon-fechar {
            position: relative;
            top: -0.25rem
        }
    }
`;
