import styled, { css } from 'styled-components';

export const Empty = styled.div``;
export const Warp = styled.div<any>`
    height: 7.5rem;
    border-top-left-radius: var(--def-radius);
    border-top-right-radius: var(--def-radius);
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: ${({ widthColumns }) => widthColumns};
    grid-gap: 1px;
    align-items: center;
    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;
export const TableWrapper = styled.table<any>`
    min-width: 100%;
`;
export const Container = styled.div<any>`
    z-index: 998;
    ${({ styleWarp }) => styleWarp}
    /* margin-right: ${({ className }) =>
    className && className.indexOf('fix-container') >= 0 ? '1.5rem' : 0}; */
`;
export const ContainerRow = styled.div<any>`
    z-index: 998;
    ${({ styleWarp }) => styleWarp}
    /* margin-right: ${({ className }) =>
    className && className.indexOf('fix-container') >= 0 ? '1.5rem' : 0}; */
`;
