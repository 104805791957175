import styled from 'styled-components';
import Icone from 'components/Icone';
import { transitionEaseBack } from 'routes/styl';
export const Container3 = styled.button<any>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: ${({ width }) => width};
    @media (max-width: 719.98px) {
        width: 100%;
        margin-bottom: 1rem;
    }
    h1,
    span {
        color: var(--bg-white);
    }
    cursor: pointer;
    div {
        display: flex;
        .font-ic {
            margin-left: 1rem;
            position: relative;
            color: var(--bg-white);
            &::after {
                content: '';
                width: 2rem;
                height: 2rem;
                top: calc(50% - 1rem - 3px);
                left: calc(50% - 1rem);
                border-radius: 50%;
                position: absolute;
                z-index: 1;
                opacity: 0;
                ${transitionEaseBack};
                transform: scale(0.5);
                background: var(--bg-white);
            }
        }
    }
    &:hover div div::before {
        opacity: 0.1;
        transform: scale(1);
    }
`;
export const IconeSetaBaixo = styled(Icone)<any>`
    color: var(--bg-white);
`;
export const Container2 = styled.header<any>`
    min-width: 20rem;
    @media (max-width: 719.98px) {
        min-width: 15rem;
    }
`;
export const Container = styled.header<any>`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 5rem;
    z-index: 3;
    button {
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        border-top-right-radius: var(--def-radius);
        border-bottom-right-radius: var(--def-radius);
        background-color: var(--btn-admin-icone);
        .font-ic {
            color: var(--bg-white);
            width: 100%;
        }
        &:hover {
            background-color: var(--btn-admin-icone-hover);
            .font-ic {
                color: var(--btn-admin-icone);
            }
        }
    }
    @media (max-width: 719.98px) {
        flex: 1
    }
`;

export const Input = styled.input<any>`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--bg-white);
    padding: 0px 10px;
    color: hsl(0, 0%, 50%);
    font-size: 1.6rem;
    border-bottom-left-radius: ${({ rounded }) => {
        return !rounded ? '20px' : 0;
    }};
    border-top-left-radius: ${({ rounded }) => {
        return !rounded ? '20px' : 0;
    }};
`;

export const LoadingContainer = styled.button<any>`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IconeSearch = styled(Icone)<any>``;
