export const transformData = (data) => {
    data.contatos = data.contatoValor.map((contato, index) => ({
        tipo: data.contatoTipo[index],
        contato,
    }));
    data.contatos.push({ tipo: 'email', contato: data.email });
    data.geoLatitude = Number(data.geoLatitude);
    data.geoLongitude = Number(data.geoLongitude);
    data.razaoSocial = data.razaoSocial || data.nomeFantasia;
    delete data['like-address'];
    delete data.email;
    delete data.contatoTipo;
    delete data.contatoValor;
    delete data.telefoneCelular;
    delete data.cnpj;
    delete data.telefoneFixo;
    delete data.cep;
    delete data.editar;
    delete data.id;
    delete data.precisao;
    delete data.distancia;
    delete data.contato;
    delete data.proximo;
    delete data.atual;
    delete data.frete;
    delete data.tipo;
    delete data.duracao;

    return Object.keys(data)
        .filter(function (k) {
            return data[k] !== null && data[k] !== undefined && data[k] !== '';
        })
        .reduce(function (acc, k) {
            acc[k] = data[k];
            return acc;
        }, {});
};
