import { useQuery, useSubscription } from '@apollo/client';
import {
    Map,
    TileLayer,
    Marker,
    Circle,
  } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { defTitle } from 'assets/strings';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import {
    ENTREGADOR_LOCATION_QUERY,
    ENTREGADOR_LOCATION_SUBSCRIPTION,
} from 'gql/entregador';
import React, {
    FC,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import MarkerCliente from 'assets/images/marker-cliente.png';
import MarkerEstabelecimento from 'assets/images/marker-estabelecimento.png';
import MarkerPlayer from 'assets/images/marker-player.png';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import {
    Container,
    Header,
    LastedUpdate,
    stylMap,
    WarpMap,
} from './styl';

import { latLngDistKmOrM } from 'libs/GeolocationUtils';
import { COLETA_SUBSCRIPTION } from 'gql/coleta';
import L from 'leaflet';
interface IPropsParams {
    unidadeLat: string;
    unidadeLng: string;
    clienteLat: string;
    clienteLng: string;
    entregadorId: string;
    statusId: string;
    coletaId: string;
}
export interface IPropsQuery {
    latitude?: number;
    longitude?: number;
    entregadorId: string;
    connection: string;
    dateGeoUpdated?: string;
}
interface IMap {
    fitBounds?: any;
}
interface IPlayerLocation {
    latitude?: number;
    longitude?: number;
}
interface IMemoMapPros {
    position?: google.maps.LatLngLiteral;
    mensagemStatusPlayer?: string;
}
export interface ISubscription {
    entregadorLocation?: {
        data?: IPropsQuery;
    };
}
export interface ISubscriptionColeta {
    coleta?: {
        data?: {
            status: [
                {
                    id: number;
                },
            ];
            entregador: {
                id: string;
            };
            id: string;
        };
    };
}
const PageClienteAtualizarEndereco: FC = () => {
    const { configuracao } = useContext(AuthContext);
    const refMap = useRef(null);

    const distanciaMaxima = useMemo(() => {
        return configuracao.find(
            ({ chave }) =>
                chave === 'DistanciaMaxima::Chamar::Player::km::Maximo',
        ).valor;
    }, [configuracao]);
    const distanciaMaximaCheckinCliente = useMemo(() => {
        return configuracao.find(
            ({ chave }) =>
                chave === 'App::Distancia::CheckInOut::Cliente::Metros',
        ).valor;
    }, [configuracao]);
    const distanciaMaximaCheckinUnidade = useMemo(() => {
        return configuracao.find(
            ({ chave }) =>
                chave === 'App::Distancia::CheckInOut::Unidade::Metros',
        ).valor;
    }, [configuracao]);

    useEffect(() => {
        document.title = `${defTitle} - Localização`;
    });

    const pQuery = useParams<IPropsParams>();
    const [statusId, setStatusId] = useState<number>(+pQuery.statusId);
    const entregadorId = useRef<string>(pQuery.entregadorId);

    const props = useMemo(() => {
        return {
            unidadeLat: +pQuery.unidadeLat,
            unidadeLng: +pQuery.unidadeLng,
            clienteLat: +pQuery.clienteLat,
            clienteLng: +pQuery.clienteLng,
            entregadorId:
                pQuery.entregadorId && pQuery.entregadorId !== 'null'
                    ? pQuery.entregadorId
                    : null,
            statusId: +pQuery.statusId,
            coletaId: pQuery.coletaId,
        };
    }, [pQuery]);
    const hasDeliveryman = [1, 2, 3, 4, 5, 10, 11, 12].indexOf(statusId) !== -1;
    const hasDeliverymanQuery =
        [1, 2, 3, 4, 5, 10, 11, 12].indexOf(props.statusId) !== -1;
    const { data, error, loading } = useQuery<{
        recuperarEntregadorLocalizacao?: IPropsQuery;
    }>(ENTREGADOR_LOCATION_QUERY, {
        fetchPolicy: 'no-cache',
        skip: !hasDeliverymanQuery,
        variables: {
            entregadorId: entregadorId.current || props.entregadorId,
        },
    });
    const { data: sub } = useSubscription<ISubscription>(
        ENTREGADOR_LOCATION_SUBSCRIPTION,
        {
            variables: {
                entregadorId: [entregadorId.current || props.entregadorId],
            },
        },
    );
    const { data: subColeta } = useSubscription<ISubscriptionColeta>(
        COLETA_SUBSCRIPTION,
        {
            variables: {
                entregadorId: entregadorId.current || props.entregadorId,
            },
        },
    );

    const playerProps: IMemoMapPros = useMemo(() => {
        let response: IMemoMapPros = {};
        const partMessage = 'Última atualização do player - ';
        if (data?.recuperarEntregadorLocalizacao) {
            response = {
                position: {
                    lat: data?.recuperarEntregadorLocalizacao?.latitude,
                    lng: data?.recuperarEntregadorLocalizacao?.longitude,
                },
                mensagemStatusPlayer:
                    partMessage +
                    data?.recuperarEntregadorLocalizacao?.dateGeoUpdated,
            };
        }
        if (sub?.entregadorLocation?.data && entregadorId.current) {
            response = {
                position: {
                    lat: sub?.entregadorLocation?.data?.latitude,
                    lng: sub?.entregadorLocation?.data?.longitude,
                },
                mensagemStatusPlayer:
                    partMessage + sub?.entregadorLocation?.data?.dateGeoUpdated,
            };
        } else if (
            !subColeta?.coleta?.data?.entregador.id &&
            !entregadorId.current
        ) {
            response = {
                mensagemStatusPlayer: 'Player não encontrado',
                position: null,
            };
        }
        return response;
    }, [data, sub, subColeta]);

    useEffect(() => {
        const map = refMap.current ? refMap.current.leafletElement : null;
        if(map){
            const boundsLeaflet = new L.LatLngBounds();
            if (sub?.entregadorLocation?.data && entregadorId.current) {
                boundsLeaflet.extend(new L.LatLng(sub?.entregadorLocation?.data?.latitude, sub?.entregadorLocation?.data?.longitude));
            } else if (data?.recuperarEntregadorLocalizacao) {
                boundsLeaflet.extend(new L.LatLng(data?.recuperarEntregadorLocalizacao?.latitude, data?.recuperarEntregadorLocalizacao?.longitude));
            }
            if([3, 4, 5].includes(statusId))
                boundsLeaflet.extend(new L.LatLng(props.clienteLat, props.clienteLng));

            if([2, 11, 10, 1, 12].includes(statusId))
                boundsLeaflet.extend(new L.LatLng(props.unidadeLat, props.unidadeLng));
            if(boundsLeaflet.getNorthEast() && boundsLeaflet.getSouthWest())
                map.fitBounds(boundsLeaflet, { padding: [15, 15] });
        }
    }, [refMap.current, sub, data, statusId]);
    useEffect(() => {
        if (
            subColeta?.coleta?.data?.status[0].id &&
            subColeta?.coleta?.data?.id === props.coletaId
        ) {
            setStatusId(subColeta?.coleta?.data?.status[0].id);
        }
        if (
            subColeta?.coleta?.data?.entregador.id &&
            subColeta?.coleta?.data?.id === props.coletaId
        ) {
            entregadorId.current = subColeta?.coleta?.data?.entregador.id;
        } else if (subColeta?.coleta?.data?.id === props.coletaId) {
            entregadorId.current = null;
        }
    }, [subColeta]);
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Localização</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <WarpMap>
                        <Map ref={refMap} zoomControl={false} center={{
                            lat: props.unidadeLat,
                            lng: props.unidadeLng,
                        
                        }} style={stylMap} zoom={16}>
                            <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                            />
                            {props.clienteLat !== 0 && (
                                <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: MarkerCliente,
                                        iconRetinaUrl: MarkerCliente,
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [24, 30],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={{
                                    lat: props.clienteLat,
                                    lng: props.clienteLng,
                                }} />
                            )}
                            {distanciaMaximaCheckinCliente &&
                                !isNaN(+distanciaMaximaCheckinCliente) &&
                                [3, 4].indexOf(statusId) !== -1 && (
                                    <Circle 
                                        center={{
                                            lat: props.clienteLat,
                                            lng: props.clienteLng,
                                        }}
                                        fillColor="blue" 
                                        radius={
                                            Number(
                                                distanciaMaximaCheckinCliente,
                                            ) * 1000
                                        }/>
                                )}
                            {props.unidadeLat !== 0 && (
                                <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: MarkerEstabelecimento,
                                        iconRetinaUrl: MarkerEstabelecimento,
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [24, 30],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={{
                                    lat: props.unidadeLat,
                                    lng: props.unidadeLng,
                                }} />
                            )}
                            {playerProps?.position && (
                                <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: MarkerPlayer,
                                        iconRetinaUrl: MarkerPlayer,
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [24, 30],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={playerProps?.position} />
                            )}
                            {distanciaMaxima &&
                            !isNaN(+distanciaMaxima) &&
                            [1, 8, 11, 10].indexOf(statusId) !==
                                -1 && (
                                <Circle
                                    center={{
                                        lat: props.unidadeLat,
                                        lng: props.unidadeLng,
                                    }}
                                    fillColor="blue" 
                                    radius={
                                        Number(distanciaMaxima) * 1000
                                    }
                                />
                            )}
                            {distanciaMaximaCheckinUnidade &&
                                    !isNaN(+distanciaMaximaCheckinUnidade) &&
                                    [12, 2].indexOf(statusId) !== -1 && (
                                        <Circle
                                            center={{
                                                lat: props.unidadeLat,
                                                lng: props.unidadeLng,
                                            }}
                                            radius={
                                                Number(
                                                    distanciaMaximaCheckinUnidade,
                                                ) * 1000
                                            }
                                        />
                                    )}
                        </Map>
                        {hasDeliveryman &&
                            playerProps?.mensagemStatusPlayer &&
                            playerProps?.position?.lat &&
                            playerProps?.position?.lng &&
                            entregadorId.current && (
                                <LastedUpdate
                                    bg={error ? 'var(--error-cor)' : undefined}>
                                    {error
                                        ? error.message
                                        : playerProps?.mensagemStatusPlayer}
                                    <br />

                                    {[1, 12, 8, 2, 11, 10].indexOf(statusId) !==
                                        -1 &&
                                        `Distância: ` +
                                            latLngDistKmOrM(
                                                playerProps?.position?.lat,
                                                playerProps?.position?.lng,
                                                props.unidadeLat,
                                                props.unidadeLng,
                                            )}
                                    {[3, 4].indexOf(statusId) !== -1 &&
                                        `Distância: ` +
                                            latLngDistKmOrM(
                                                playerProps?.position?.lat,
                                                playerProps?.position?.lng,
                                                props.clienteLat,
                                                props.clienteLng,
                                            )}
                                </LastedUpdate>
                            )}
                    </WarpMap>
                </Container>
            )}
        />
    );
};
export default PageClienteAtualizarEndereco;
