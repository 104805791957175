import SelectAsync from 'components/Form/SelectAsync';
import { IResponseQuery } from 'components/template/FormularioEndereco';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import ToolTipLabel from '../template/partial/ToolTipLabel';
import { Container, ZIndex } from './styl';

const InputGooglePlaces: React.FC<{
    apiKey: string;
    onChange?: (response: IResponseQuery) => void;
    removeLegend?: boolean;
}> = ({ apiKey, onChange, removeLegend }) => {
    const [value, setValue] = useState('');
    const refCallback = useRef((data: any) => {});
    const refLoading = useRef(false);
    const handleChange = useCallback(({ value }) => {
        onChange && onChange(value);
    }, []);

    const transformSearch = useCallback(({ value }) => {
        if (
            [
                'jardins atenas',
                'jardin atenas',
                'jardin atena',
                'jardim atenas',
                'jardim atena',
                'jardins athenas',
                'jardin athenas',
                'jardin athena',
                'jardim athenas',
                'jardim athena',
            ].indexOf(value.trim().toLowerCase()) !== -1
        ) {
            return 'jardins atenas setor mareliza';
        }

        return value
            .trim()
            .toLowerCase()
            .replace(/ed\.{0,1}\s/gi, 'edifício ')
            .replace(/^r\.{0,1}\s/gi, 'rua ')
            .replace(/\sr\.{0,1}\s/gi, 'rua ')
            .replace(/av\.{0,1}\s/gi, 'avenida ')
            .replace(/st\.{0,1}\s/gi, 'setor ')
            .replace(/sl\.{0,1}\s/gi, 'sala ')
            .replace(/^n\.{0,1}\s/gi, 'número ')
            .replace(/\sn\.{0,1}\s/gi, 'número ')
            .replace(/^nº\.{0,1}\s/gi, 'número ')
            .replace(/\snº\.{0,1}\s/gi, 'número ')
            .replace(/lt\.{0,1}\s/gi, 'lote ')
            .replace(/vl\.{0,1}\s/gi, 'vila ')
            .replace(/qd\.{0,1}\s/gi, 'quadra ')
            .replace(/vla\.{0,1}\s/gi, 'viela ')
            .replace(/res\.{0,1}\s/gi, 'residencial ')
            .replace(/cond\.{0,1}\s/gi, 'condomínio ');
    }, []);
    useEffect(() => {
        Geocode.setApiKey(apiKey);
        Geocode.setLanguage('pt');
        Geocode.setRegion('br');
    }, []);
    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (!refLoading.current && value.trim().length > 0) {
            timer = setTimeout(() => {
                refLoading.current = true;

                const search = transformSearch({ value });
                // https://developers.google.com/maps/faq?hl=pt-br#languagesupport
                Geocode.fromAddress(
                    search + (search ? ', goias brasil' : ''),
                    apiKey,
                    'pt-BR',
                    'BR',
                ).then(
                    (result: any) => {
                        refLoading.current = false;
                        const response = result.results.map(places => ({
                            label: places.formatted_address,
                            value: {
                                bairro: places.address_components
                                    ? places.address_components.find(
                                          ({ types }) =>
                                              types.includes(
                                                  'sublocality_level_1',
                                              ) ||
                                              types.includes('sublocality'),
                                      )?.long_name
                                    : '',
                                logradouro:
                                    places.address_components &&
                                    places.address_components.find(
                                        ({ types }) => types.includes('route'),
                                    )?.long_name,
                                cep: places.address_components
                                    ? places.address_components.find(
                                          ({ types }) =>
                                              types.includes('postal_code'),
                                      )?.long_name
                                    : '74000000',
                                municipio: places.address_components
                                    ? places.address_components.find(
                                          ({ types }) =>
                                              types.includes(
                                                  'administrative_area_level_2',
                                              ),
                                      )?.long_name
                                    : 'Goiânia',
                                uf: places.address_components
                                    ? places.address_components.find(
                                          ({ types }) =>
                                              types.includes(
                                                  'administrative_area_level_1',
                                              ),
                                      )?.short_name
                                    : 'GO',
                                numero: places.address_components
                                    ? places.address_components.find(
                                          ({ types }) =>
                                              types.includes('street_number'),
                                      )?.short_name
                                    : '',
                                mapa: {
                                    geolocalizacao: {
                                        lat: places.geometry.location.lat,
                                        lng: places.geometry.location.lng,
                                    },
                                },
                            } as IResponseQuery,
                        }));

                        refCallback.current(response);
                    },
                    _err => {
                        refLoading.current = false;
                        // console.error(_err);
                    },
                );
            }, 1000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [value, transformSearch]);
    if (!onChange) return null;
    return (
        <ZIndex zIndex={9999999}>
            <Container>
                <SelectAsync
                    name="like-address"
                    placeholder="Digite o endereço aqui"
                    onChange={handleChange}
                    onInputChange={(e) => {
                        setValue(e);
                    }}
                    loadOptions={(inputValue, callBack) => {
                        refCallback.current = callBack;
                    }}
                    isLoading={false}
                />
            </Container>
            {!removeLegend && (
                <div style={{
                    fontSize: '1.6rem',
                }}>
                    <strong style={{
                         fontSize: '1.6rem',
                            color: '#627081',
                            fontWeight: 'bold',
                    }}>Pesquise por:</strong><br/><br/>
                    <strong style={{
                         fontSize: '1.6rem',
                            color: '#627081',
                            fontWeight: 'bold',
                    }}>Nome de rua/avenida, o nome do setor.</strong><br/>
                    Ex: Rua T62, setor Bueno.<br/><br/>

                    <strong style={{
                         fontSize: '1.6rem',
                            color: '#627081',
                            fontWeight: 'bold',
                    }}>Nome de edifício/prédio.</strong><br/>
                    Ex: Edifício Solar Ravena.<br/>
                    Importante a informação se é residencial ou comercial.<br/><br/>

                    <strong style={{
                         fontSize: '1.6rem',
                            color: '#627081',
                            fontWeight: 'bold',
                    }}>Nome de condominio.</strong><br/>
                    Ex: Condomínio Jardins Madri.<br/>
                    Sempre colocar a palavra “condominio”na busca.<br/><br/>

                    <strong style={{
                         fontSize: '1.6rem',
                            color: '#627081',
                            fontWeight: 'bold',
                    }}> Nome de estabelecimentos.</strong><br/>
                    Ex: Shopping flamboyant.<br/><br/>
                </div>
            )}
            {!removeLegend && (
            <div style={{
                marginTop: '1rem',
            }}>
                <strong style={{
                    fontSize: '1.6rem',
                    color: 'red',

                }}>
                    OBS: Após o preenchimento pode se conferir no mapa ao lado se a localização condiz com o endereço preenchido. Confira se necessário.<br/><br/>
                    O preenchimento completo do endereço é de extrema importância para que a entrega seja feita com maior agilidade.
                </strong>
            </div>
            )}
        </ZIndex>
    );
};
export default InputGooglePlaces;