// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
import { Container, CheckBoxDisable } from './styl';

interface iCheckbox {
    onChange?: any;
    value?: string;
    hidden?: boolean;
}

export const width = '5rem';
export const marginRight = '0';
const TableColumnCheckbox: FC<iCheckbox> = memo((oProps) => {
    return (
        <Container>
            {!oProps.hidden && (
                <>
                    <input
                        value={oProps?.value}
                        onChange={oProps?.onChange}
                        type="checkbox"
                        name="linha"
                    />
                    <span />
                    <CheckBoxDisable icone="icon-certo" size="2rem" />
                </>
            )}
        </Container>
    );
});
export default TableColumnCheckbox;
