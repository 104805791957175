import { useState } from 'react';

export const useAlertConfirm = () => {
    const [dataAlertaConfirme, setDataAlertaConfirme] = useState<
        IAlertConfirm | any
    >({});

    /**
     * Criando alert global
     * @param data
     */
    async function alertConfirm(
        data: IDataAlertConfirm
    ): Promise<boolean | string> {
        const dClone = { ...data, animationClose: true };
        try {
            const messageInput = await new Promise<string | void>(
                (resolve, reject) => {
                    setDataAlertaConfirme({
                        animationClose: false,
                        ...data,
                        resolve,
                        reject,
                    });
                }
            );
            setDataAlertaConfirme({ messageInput, ...dClone });
            return messageInput ? messageInput : true;
        } catch (err) {
            setDataAlertaConfirme(dClone);
            return false;
        }
    }
    return {
        dataAlertaConfirme,
        setDataAlertaConfirme,
        alertConfirm,
    };
};
