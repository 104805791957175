import { defTitle } from 'assets/strings';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, useEffect } from 'react';
import { useMutationSaveAndEdit } from './hooks/useMutationSaveAndEdit';

const PageUpdateUser: FC = () => {
    const oProps = useMutationSaveAndEdit();

    useEffect(() => {
        document.title = `${defTitle} - ${oProps.title}`;
    });

    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={oProps.titleSubmit || 'Salvar'}
            titleModal={oProps.title}
            message={null}
        >
            {(oProps.perfils?.length > 0 ||
                oProps.isEstabelecimentoAdministrador) && (
                <FormGenerator
                    rForm={oProps.rForm}
                    childs={[
                        {
                            type: 'text',
                            label: 'Nome',
                            placeholder: 'Nome',
                            name: 'nomeCompleto',
                            defaultValue: oProps.values?.nomeCompleto,
                        },
                        {
                            type: 'text',
                            label: 'Email',
                            placeholder: 'Email',
                            name: 'email',
                            defaultValue: oProps.values?.email,
                        },
                        ...(oProps.isEstabelecimentoAdministrador
                            ? [
                                  {
                                      type: 'mask',
                                      label: 'Telefone celular',
                                      mask: '(99) 9 9999.9999',
                                      name: 'telefoneCelular',
                                      defaultValue:
                                          oProps.values?.telefoneCelular,
                                  },
                              ]
                            : [
                                  {
                                      gridFirstWidth: 0.6,
                                      items: [
                                          {
                                              type: 'mask',
                                              label: 'Telefone celular',
                                              mask: '(99) 9 9999.9999',
                                              name: 'telefoneCelular',
                                              defaultValue:
                                                  oProps.values
                                                      ?.telefoneCelular,
                                          },
                                          {
                                              type: 'select-simple',
                                              label: 'Tipo de acesso',
                                              placeholder:
                                                  'Selecione o tipo de acesso',
                                              name: 'perfil',
                                              options: oProps.perfils,
                                              defaultValue:
                                                  oProps.perfilSelected?.value,
                                          },
                                      ],
                                  },
                              ]),
                    ]}
                />
            )}
        </SimplesForm>
    );
};
export default PageUpdateUser;
