import { gql } from '@apollo/client';

export const GRAPHQL_ADICIONAR_USUARIO_UNIDADE = gql`
    mutation adicionarUsuarioUnidade(
        $unidadeId: String
        $usuarioIds: [String]
    ) {
        adicionarUsuarioUnidade(unidadeId: $unidadeId, usuarioIds: $usuarioIds)
    }
`;
export const GRAPHQL_LISTAR_USUARIO_UNIDADE = gql`
    query usuarioUnidade($unidadeId: String!) {
        usuarioUnidade(unidadeId: $unidadeId) {
            id
            nomeCompleto
            telefoneCelular
        }
    }
`;
