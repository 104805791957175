import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_AGRUPAR_COLETA } from 'gql/coleta';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useSubmitAgrupar = () => {
    const { coletaId } = useParams<any>();
    const history = useModalHistory();
    const formRef: any = useRef<FormHandles>(null);
    const { alertConfirm } = useContext(AuthContext);
    const [handleAgruparColeta, { loading }] = useMutation(
        GRAPHQL_AGRUPAR_COLETA
    );

    const handleSubmit: SubmitHandler<any> = useCallback(
        async ({ coletaIds }) => {
            try {
                if (coletaIds.length === 0) {
                    throw new Error(
                        'É necessário selecionar pelo menos uma coleta para agrupar.'
                    );
                }
                await handleAgruparColeta({
                    variables: {
                        coletaPaiId: coletaId,
                        coletaIds,
                    },
                });
                history.push('');
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        []
    );

    return { ref: formRef, onSubmit: handleSubmit };
};
