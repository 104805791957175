import React, { FC } from 'react';
import { Container } from './styl';
import CircularLoading from 'components/atom/Loading/CircularLoading';
interface IButtonLoading {
    value: string;
    loading?: boolean;
    stylWarp?: any;
    loadingSize?: number;
    id?: string;
    type?: 'button' | 'submit' | 'reset';

    onClick?: (e: any) => void;
}
const ButtonLoading: FC<IButtonLoading> = ({
    value,
    stylWarp = {},
    onClick,
    loading = false,
    loadingSize = 3,
    id,
    type = 'submit',
}) => {
    return (
        <Container
            type={type}
            {...stylWarp}
            {...(onClick ? { onClick } : {})}
            {...(id ? { id } : {})}
            disabled={loading}>
            <span>{value}</span>

            <CircularLoading
                show={loading}
                size={loadingSize}
            />
        </Container>
    );
};
export default ButtonLoading;
