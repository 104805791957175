import { useQuery, useSubscription } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { CLIENTE_SUBSCRIPTION } from 'gql/cliente';
import { COLETA_SUBSCRIPTION, GRAPHQL_LISTAR_COLETA } from 'gql/coleta';
import { GRAPHQL_CONFIGURACAO } from 'gql/configuracao';
import { useEffect, useState, useRef } from 'react';
import { IConfiguracao } from 'types/modules/configuracao';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IColeta } from '../useTransformColeta/index.d';
import { ISubscriptionColeta } from './index.d';
import { GRAPHQL_UNIDADE_SUBSCRIPTION } from 'gql/unidade';
import { ISubscriptionUnidade } from '../../../../../pages/unidades/list/hooks/useQueryFilterUnit/index.d';
import { useClientApollo } from 'routes/hooks/useClientApollo';
import { apiv2Axios } from 'services/apiv2';
import { useQuery as useQueryTransQuery } from '@tanstack/react-query';

export const useQuerySubscriptionFilter = () => {
    const { client } = useClientApollo();
    const timeAdicionalCollectt = useRef(0);
    const timeAwaitUnitCollectt = useRef(10);
    const page = useRef(0);
    const props = usePaginationAndFilter({
        dataKeyName: 'dataPedido',
        keysQueryVariavel: [
            'dataPedido',
            'id',
            'nomeEstabelecimento',
            'entregadorId',
            'entregadorNome',
            'razaoSocial',
            'numeroInterno',
            'clienteNome',
            'clienteTelefoneCelular',
            'statusPedido',
            'dataPedido',
            'turno',
        ],
    });
    const [entregadoresConnection, setEntregadoresConnection] = useState({});
    useSubscription(CLIENTE_SUBSCRIPTION, {
        onSubscriptionData({
            subscriptionData: {
                data: {
                    cliente: { data, action },
                },
            },
        }) {
            if (props.response) {
                if (action !== 'DELETE') {
                    const coletas: IColeta[] = props.response.map(coleta => {
                        if (coleta.cliente.id === data.id) {
                            coleta.cliente = { ...coleta.cliente, ...data };
                        }
                        return coleta;
                    });
                    props.handleSetResponse(coletas);
                }
            }
        },
    });
    useSubscription<ISubscriptionColeta>(COLETA_SUBSCRIPTION, {
        variables: props.variables,
        onSubscriptionData: response => {
            const {
                subscriptionData: {
                    data: {
                        coleta: { data },
                    },
                },
            } = response;
            props.onSubscriptionData(data);
        },
    });

    useQuery<{
        recuperarConfiguracao?: IConfiguracao[];
    }>(GRAPHQL_CONFIGURACAO, {
        onCompleted: ({ recuperarConfiguracao }) => {
            const timeAditionalStatus = recuperarConfiguracao.find(
                ({ chave }) => chave === 'Coleta::MargemErro::Checkin',
            );
            const timeAwait = recuperarConfiguracao.find(
                ({ chave }) =>
                    chave === 'Entregador::Espera::Estabelecimento::Minutos',
            );
            if (
                timeAditionalStatus &&
                timeAditionalStatus.valor &&
                +timeAditionalStatus.valor > 0
            ) {
                timeAdicionalCollectt.current = +timeAditionalStatus.valor;
            } else {
                timeAdicionalCollectt.current = 1;
            }
            if (timeAwait && timeAwait.valor && +timeAwait.valor > 0) {
                timeAwaitUnitCollectt.current = +timeAwait.valor;
            }
        },
    });

    useSubscription<ISubscriptionUnidade>(GRAPHQL_UNIDADE_SUBSCRIPTION, {
        variables: {},
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    unidade: { data },
                },
            },
        }) => {
            const findUnidade = props.response.find(
                ({ unidade }) => unidade.id === data.id,
            );
            if (findUnidade) {
                const newProps = props.response.map(coleta => {
                    if (coleta.unidade.id === data.id) {
                        coleta.unidade = data;
                    }
                    return coleta;
                } );
                props.onSubscriptionData(newProps)
            }
        },
    });

    const { data, isLoading: loading, refetch } = useQueryTransQuery({
        queryKey: ['coleta', props.variables],
        retryOnMount: true,
        cacheTime: 0,
        queryFn: () => apiv2Axios.get('/api/panel/collect-list', {
            params: {
                date_start: props.variables.filter?.dataPedido,
                number_internal: props.variables.filter?.numeroInterno,
                full_name_client: props.variables.filter?.clienteNome,
                company_name: props.variables.filter?.nomeEstabelecimento,
                phone_client: props.variables.filter?.clienteTelefoneCelular,
                deliveryman_name: props.variables.filter?.entregadorNome,
                status_name: props.variables.filter?.statusPedido,
                page: Number(props.context.headers['current-page']) + 1,
                perPage: 80
            }
        }),
    });
    useEffect(() => {
        refetch();
    } , [props.context.headers['current-page']]);
    useEffect(() => {
        if (data) {
            props.onCompleted({
                data: data.data.coletas,
                __typename: 'ColetaResponse',
                hasNextPage: data.data.hasNextPage,
            });
        }
    } , [data]);
    // const initRecuperarColeta = (variables?: any, aData?: any[]) => {
    //     const params = variables ? variables : props.variables;
    //     props.setLoading(true);
    //     apiv2Axios.get('/api/panel/collect-list', {
    //         params: {
    //             date_start: params.filter?.dataPedido,
    //             number_internal: params.filter?.numeroInterno,
    //             full_name_client: params.filter?.clienteNome,
    //             company_name: params.filter?.nomeEstabelecimento,
    //             phone_client: params.filter?.clienteTelefoneCelular,
    //             deliveryman_name: params.filter?.entregadorNome,
    //             status_name: params.filter?.statusPedido,
    //             page: page.current,
    //             perPage: 1
    //         }
    //     }).then(({ data }) => {
    //         props.onCompleted({
    //             data: data.coletas,
    //             __typename: 'ColetaResponse',
    //             hasNextPage: false,
    //         });
    //     }).catch(error => {
    //         props.onError(error);
    //     }).finally(() => {
    //         props.setLoading(false);
    //     });
    //     // client.query({
    //     //     fetchPolicy: 'no-cache',
    //     //     query: GRAPHQL_LISTAR_COLETA,
    //     //     context: {
    //     //         ...props.context,
    //     //         // headers: {
    //     //         //     'current-page': `${page.current}`,
    //     //         // },
    //     //     },
    //     //     variables: variables ? variables : props.variables
    //     // }).then(({ data }) => {
    //     //     console.log(data.recuperarColeta);
    //     //     props.onCompleted(data.recuperarColeta);
    //     //     // if(data.recuperarColeta.hasNextPage) {
    //     //     //     page.current = page.current + 1;
    //     //     //     const newData = [...(aData ?? []), ...data.recuperarColeta.data];
    //     //     //     initRecuperarColeta(variables, newData);
    //     //     // } else {
    //     //     //    const newData = [...aData, ...data.recuperarColeta.data];
    //     //     //    props.onCompleted({
    //     //     //     data: newData,
    //     //     //     __typename: 'ColetaResponse',
    //     //     //     hasNextPage: data.recuperarColeta.hasNextPage,
    //     //     //    }); 
    //     //     // }
    //     //     // 
    //     // }
    //     // ).catch(error => {
    //     //     props.onError(error
    //     //     );  
    //     // }).finally(() => {
    //     //     props.setLoading(false);
    //     // });
    // }

    // useEffect(() => {
    //     initRecuperarColeta();
    // }, []);

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return {
        ...props,
        timeAdicionalCollectt: timeAdicionalCollectt.current,
        timeEsperaEstabelecimento: timeAwaitUnitCollectt.current,
        dataSelectHeader: JSON_DATA_FILTER,
        entregadoresConnection,
    };
};