import { useState, useCallback } from 'react';

type IswitchComponent = 'personal' | 'business' | 'company';
export const useSteps = (dataSteps: any) => {
	// Aba selecionada
	const [switchComponent, setSwitchComponent] = useState<IswitchComponent>(
		'personal'
	);

	// Dados dos inputs de cada passo
	const [initialDataForm, setInitialDataForm] = useState<any>({});

	// Controla os formulários preenchidos
	const [steps, setSteps] = useState(dataSteps);

	// Analisa se já pode ser submetido as informações dos formulários
	const submit = useCallback(
		({ atual, proximo }) => {
			if (atual) {
				const cloneItem = { ...initialDataForm };
				cloneItem[atual] = steps[atual].ref.current.getData();
				setInitialDataForm(cloneItem);
			}
			if (proximo) {
				setSwitchComponent(proximo as IswitchComponent);
			}
		},
		[initialDataForm, steps]
	);
	return { initialDataForm, switchComponent, submit, steps, setSteps };
};
