import { gql } from '@apollo/client';
export const GRAPHQL_RECUPERAR_RESOURCES_POR_USUARIO: any = gql`
    query recuperarResourcesPorUsuario {
        recuperarResourcesPorUsuario {
            titulo
            link
            icone
            resourceFilhos {
                titulo
                link
                icone
            }
        }
    }
`;
