import { useMutation } from '@apollo/client';
import { GRAPHQL_ADICIONAR_ENDERECO_CLIENTE, GRAPHQL_REMOVER_ENDERECO_CLIENTE } from 'gql/cliente';
import { useCallback, useContext } from 'react';
import { IReceiver } from '../../cliente';
import dot from 'dot-object';
import { AuthContext } from 'routes/auth.context';

interface INewEndereco {
    cidade?: string;
    distancia?: string;
    duracao?: string;
    enderecoBairro?: string;
    enderecoCep?: string;
    enderecoComplemento?: string;
    enderecoLogradouro?: string;
    enderecoNumero?: string;
    enderecoQuadra?: string;
    enderecoLote?: string;
    enderecoPontoReferencia?: string;
    frete?: string;
    geoLatitude?: string;
    geoLongitude?: string;
    id?: string;
    precisao?: string;
    uf?: string;
}

export const useNewAddress = (props: IReceiver, handleListarEnderecos) => {
    const { alertConfirm } = useContext(AuthContext);
    const [handleAdicionarEnderecoCliente, { loading }] = useMutation<{
        adicionarEnderecoCliente: number;
    }>(GRAPHQL_ADICIONAR_ENDERECO_CLIENTE);
    const [handleRemoverEndereco, { loading: loadingRemove }] = useMutation(
        GRAPHQL_REMOVER_ENDERECO_CLIENTE,
    );
    const handleString2Number = useCallback((value) => Number(value), []);

    const handleSubmit = useCallback(
        async (dataForm: INewEndereco & {
            clienteId?: string;
        }) => {
            dot.object(dataForm, {
                geoLatitude: [handleString2Number],
                geoLongitude: [handleString2Number],
                distancia: [handleString2Number],
                duracao: [handleString2Number],
                precisao: [handleString2Number],
            });
            const data = dot.transform(
                {
                    enderecoLogradouro: 'enderecoLogradouro',
                    enderecoQuadra: 'enderecoQuadra',
                    enderecoLote: 'enderecoLote',
                    enderecoNumero: 'enderecoNumero',
                    enderecoComplemento: 'enderecoComplemento',
                    enderecoBairro: 'enderecoBairro',
                    enderecoCep: 'enderecoCep',
                    enderecoPontoReferencia: 'enderecoPontoReferencia',
                    metricas: 'distancia',
                    distancia: 'metricas.distanciaAteUnidade',
                    duracao: 'metricas.tempoAteUnidade',
                    precisao: 'metricas.precisao',
                    geoLatitude: 'latitude',
                    geoLongitude: 'longitude',
                    uf: 'uf',
                    cidade: 'cidade',
                },
                dataForm
            );
            try {
                if (data.metricas.precisao > 4) {
                    throw new Error(
                        `Detectamos que o endereço informado possui margem de erro de ${data.metricas.precisao}km. Por favor, preencha os campos faltantes do formulário de endereço para que possamos calcular seu frete`
                    );
                }
                // remove o endereço e adiciona um novo
                if(dataForm.id && dataForm.clienteId){
                    await handleRemoverEndereco({
                        variables: {
                            clienteId: dataForm.clienteId,
                            clienteEnderecoId: dataForm.id,
                            forceDelete: true,
                        },
                    });
                }
                const {
                    data: { adicionarEnderecoCliente },
                } = await handleAdicionarEnderecoCliente({
                    variables: {
                        clienteId: props.clienteId,
                        data,
                    },
                });

                props.handleNavigateForms({
                    next: 1,
                    current: 1,
                    success: true,
                    data: {
                        endereco: adicionarEnderecoCliente,
                        latitude: dataForm.geoLatitude,
                        longitude: dataForm.geoLongitude,
                        distancia: dataForm.distancia,
                        tempo: dataForm.duracao,
                        precisao: dataForm.precisao,
                        frete: dataForm.frete,
                        // cartoes: dataForm.cartoes,
                    },
                });
                handleListarEnderecos();
            } catch ({ message }: any) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Cadastro de endereço',
                    conteudo: message as string,
                });
            }
        },
        [handleListarEnderecos]
    );
    return {
        submit: handleSubmit,
        submitLoading: loading,
    };
};
