import { css } from 'styled-components';
import { transitionEaseBack } from 'routes/styl';

export const cssButtonCircular = css`
    position: relative;
    .font-ic {
        position: relative;
        z-index: 2;
        width: 1.7rem;
        height: 1.7rem;
        font-size: 1.7rem;
        margin: 0.6rem;
    }
    cursor: pointer;
    ::after {
        ${transitionEaseBack};
        content: '';
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        position: absolute;
        opacity: 0;
        transform: scale(0.5);
        background-color: var(--btn-admin-icone-hover);
        border-radius: 50%;
        position: absolute;
        z-index: 1;
    }
    &:hover::after {
        opacity: 1;
        transform: scale(1);
    }
`;

export const cssButtonCircularNoHover = css`
    position: relative;
    .font-ic {
        position: relative;
        z-index: 2;
        width: 1.7rem;
        height: 1.7rem;
        font-size: 1.7rem;
        margin: 0.6rem;
    }
    cursor: pointer;
`;

