import { shake } from 'routes/styl';
import styled from 'styled-components';

export { Input } from '../Input/styl';
export { ZIndex } from '../template/styl';
export const WarpAction = styled.div.attrs({ className: 'warpAction' })`
    margin: 0.2rem 0.5rem 0 1rem;
`;
export const Button = styled.button<any>`
    width: 3.5rem;
    height: 4.3rem;
    cursor: pointer;
`;
export const Container = styled.div.attrs({ className: 'input' })<any>`
    display: flex;
    height: 7rem;
    flex-direction: row;
    justify-content: space-between;
    .select {
        width: 100%;
        > div {
            border: none !important;
        }
    }
    .css-holabh-IndicatorsContainer {
        display: none;
    }
    /* > .react-select__control {
        border: 0.5px solid var(--bg-hover-light) !important;
    } */
`;
