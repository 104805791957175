import { useMutation, useQuery } from '@apollo/client';
import { IDataClienteQueryCliente } from 'types/queryCliente';
import { useContext } from 'react';
import {
    GRAPHQL_ATUALIZAR_CLIENTE,
    GRAPHQL_ADICIONAR_CLIENTE,
    GRAPHQL_ADICIONAR_ENDERECO_CLIENTE,
    GRAPHQL_CLIENTE,
} from 'gql/cliente';
import { AuthContext } from 'routes/auth.context';

export const useClienteAlterar = () => {
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaCliente, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_CLIENTE
    );
    async function atualizaCliente({ clienteId, data }) {
        return new Promise(async (resolve) => {
            try {
                const {
                    data: { atualizarCliente: result },
                } = await handleAtualizaCliente({
                    variables: {
                        clienteId,
                        data,
                    },
                });
                resolve(result);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
                resolve(null);
            }
        });
    }
    return {
        atualizaCliente,
        loading,
    };
};

export const useClienteAdicionar = () => {
    const { alertConfirm } = useContext(AuthContext);
    const [handleAdicionarCliente, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_CLIENTE
    );
    async function adicionarCliente({ clienteId, data }) {
        return new Promise(async (resolve, reject) => {
            try {
                const {
                    data: { adicionarCliente: result },
                } = await handleAdicionarCliente({
                    variables: {
                        data,
                    },
                });
                resolve(result);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
                resolve(null);
            }
        });
    }
    return {
        adicionarCliente,
        loading,
    };
};

export const useClienteAdicionarEndereco = () => {
    const { alertConfirm } = useContext(AuthContext);
    const [handleAdicionarEnderecoCliente, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_ENDERECO_CLIENTE
    );
    async function adicionarEnderecoCliente({ clienteId, data }) {
        return new Promise(async (resolve) => {
            try {
                const {
                    data: { adicionarEnderecoCliente: result },
                } = await handleAdicionarEnderecoCliente({
                    variables: {
                        clienteId,
                        data,
                    },
                });
                resolve(result);
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
                resolve(null);
            }
        });
    }
    return {
        adicionarEnderecoCliente,
        loading,
    };
};

export const useCliente = ({ clienteId }) => {
    const { unidadeSelecionada, unidadePersonalizada } =
        useContext(AuthContext);
    const { data, loading } = useQuery<IDataClienteQueryCliente>(
        GRAPHQL_CLIENTE,
        {
            fetchPolicy: 'no-cache',
            variables: {
                filter: {
                    // eslint-disable-next-line no-nested-ternary
                    ...(unidadeSelecionada?.id
                        ? { unidadeId: unidadeSelecionada?.id }
                        : unidadePersonalizada?.id
                        ? { unidadeId: unidadePersonalizada?.id }
                        : null),
                    id: clienteId,
                },
            },
        }
    );
    return {
        data,
        loading,
    };
};
