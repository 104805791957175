import { AxiosInstance } from 'axios';
import React, { createContext, FC, memo, useEffect } from 'react';
import { useAlertConfirm } from './hooks/useAlertConfirm';
import { useAutoSignin } from './hooks/useAutoSignin';
import { useCacheFiltroData } from './hooks/useCacheFiltroData';
import { useCacheMail } from './hooks/useCacheMail';
import { useLoadMenuAndEmpresa } from './hooks/useLoadMenuAndEmpresa';
import { useSigninAndOut } from './hooks/useSigninAndOut';

export const AuthContext = createContext<AuthContextData | null>(
    {} as AuthContextData
);
interface IProps {
    axios: AxiosInstance;
    handleUpdateBearer(bearer: string | null): void;
    clienteApolloLogout: boolean;
}
export const AuthProvider: FC<IProps> = memo(
    ({ children, handleUpdateBearer, axios, clienteApolloLogout }) => {
        // Uso interno para o component alert confirm
        const { dataAlertaConfirme, setDataAlertaConfirme, alertConfirm } =
            useAlertConfirm();

        const { loadDefaultData, ...rest } = useLoadMenuAndEmpresa();

        // Armazena a data do filtro da listagem de dados
        const { dataCacheFiltroData, setCacheFiltroData } =
            useCacheFiltroData();

        // Login e logout app
        const { loading, signIn, signOut, user, setUser } = useSigninAndOut(
            alertConfirm,
            loadDefaultData,
            setCacheFiltroData,
            handleUpdateBearer
        );
        useEffect(() => {
            if (clienteApolloLogout) {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'JWT Inválido',
                    conteudo: `Seu token JWT expirou. Favor efetue login novamente na plataforma`,
                }).finally(signOut);
            }
        }, [clienteApolloLogout]);

        // Armazena o e-mail do usuário logado para usar no auto preenchimento do campo e-mail
        const { dataCacheEmail, setCacheEmail } = useCacheMail();

        // Faz o login automatico com o token armazenado em cache
        const { loadingAutoSignin } = useAutoSignin(
            setUser,
            loadDefaultData,
            handleUpdateBearer
        );

        return (
            <AuthContext.Provider
                value={{
                    ...rest, //, unidades, unidadeSelecionada
                    signed: 'email' in user,
                    user,
                    axios,
                    loading,
                    loadingAutoSignin,
                    signIn,
                    signOut,
                    setCacheEmail,
                    cacheEmail: dataCacheEmail,
                    setCacheFiltroData,
                    cacheFiltroData: dataCacheFiltroData,
                    setDataAlertaConfirme,
                    dataAlertaConfirme,
                    alertConfirm,
                }}
            >
                {children}
            </AuthContext.Provider>
        );
    }
);
