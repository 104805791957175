import { gql } from '@apollo/client';

export const GRAPHQL_CONFIGURACAO: any = gql`
    query {
        recuperarModalTransporte {
            id
            nome
            valorKm
            valorKmRetorno
            ativo
            txMin
            txMinRetorno
            txRetorno
        }
        recuperarConfiguracao(filter: { ambiente: "private" }) {
            chave
            valor
        }
    }
`;
export const GRAPHQL_ATUALIZAR_CONFIGURACAO: any = gql`
    mutation atualizarConfiguracao($data: AtualizarConfiguracaoInput!) {
        atualizarConfiguracao(data: $data)
    }
`;

export const CONFIGURACAO_SUBSCRIPTION: any = gql`
    subscription configuracao {
        configuracao {
                chave
                valor
        }
    }
`;
