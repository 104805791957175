import React, { FC, memo, useCallback, useMemo } from 'react';
import { WarpLabel, WarpLink, WrapText } from '../styl';
import Icone from '../../../components/Icone';
import { useHistory } from 'react-router-dom';
interface IBotaoSubMenu {
    onClickSubMenu?: any;
    zIndex?: number;
    icone?: string;
    titulo: string;
    link?: string;
    resourceFilhos?: any[];
}
const BotaoSubMenu: FC<IBotaoSubMenu> = memo(
    ({ resourceFilhos, titulo, children, zIndex, onClickSubMenu }) => {
        const history = useHistory();
        const handleChange = useCallback(
            event => {
                // @ts-ignore
                if (resourceFilhos.length === 1) {
                    event.preventDefault();
                    // @ts-ignore
                    history.push(resourceFilhos[0].link);
                } else {
                    onClickSubMenu(event.target);
                }
            },
            [onClickSubMenu, resourceFilhos],
        );
        const { Warp, ...propsWarp } = useMemo(() => {
            // @ts-ignore
            if (resourceFilhos.length === 0 || resourceFilhos.length > 1) {
                return { Warp: WarpLabel, className: 'group', zIndex };
            }
            return {
                Warp: WarpLink,
                className: 'group',
                // @ts-ignore
                to: { pathname: resourceFilhos[0].link, state: { zIndex } },
            };
        }, [resourceFilhos]);

        return (
            <Warp {...propsWarp}>
                {/* @ts-ignore */}
                {resourceFilhos.length > 1 && (
                    <input
                        type="checkbox"
                        onChange={handleChange}
                        id={titulo}
                        name="menu"
                        value={titulo}
                    />
                )}
                <WrapText>
                    <p>{titulo}</p>
                    {/* @ts-ignore */}
                    {resourceFilhos.length > 0 && <span>mais</span>}
                    {/* @ts-ignore */}
                    {resourceFilhos.length > 0 && (
                        <Icone icone="icon-adicionar2" />
                    )}
                </WrapText>
                {children}
            </Warp>
        );
    },
);
export default BotaoSubMenu;
