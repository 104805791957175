import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useField } from '@unform/core';
import { Input } from './styl';
import FormCodeInputs from './FormCodeInputs';

const FormCodeInput: React.FC<IInput> = ({ name }) => {
	const [value, setValue] = useState<string>('');
	const inputRef = useRef<HTMLInputElement>(null);
	let { fieldName, clearError, registerField, error } = useField(name);
	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef.current,
			path: 'value',
		});
	}, [fieldName, registerField]);

	const props = {
		updateInputCode: setValue,
		clearError,
		error,
	};
	return (
		<Fragment>
			<Input type="hidden" ref={inputRef} defaultValue={value} />
			<FormCodeInputs {...props} />
		</Fragment>
	);
};
export default FormCodeInput;
