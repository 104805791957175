import React, { useCallback, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { removeEmpty } from 'libs/ObjectUtils';
export const useTabs = ({ abas: list }) => {
    // const [tabs, setTabs] = useState(list);
    const tabs = useRef(list);
    const keys = useRef(Object.keys(tabs.current));
    const [tabName, setTabName] = useState(keys.current[0]);

    const containerData = useMemo(() => {
        const currentItem = tabs.current[tabName];
        const Cp = currentItem.component;
        const componentProps = currentItem.props || {};

        const stepSelected = keys.current.indexOf(tabName);

        return {
            stepSelected,
            currentItem,
            width: currentItem.width,
            component: <Cp {...componentProps} />,
        };
    }, [tabName]);

    const [steps, setSteps] = useState(() => {
        let stepsTmp = {};
        keys.current.forEach((key) => {
            stepsTmp[key] = {
                nome: tabs.current[key].nome,
                formularioSucesso: false,
            };
        });
        return stepsTmp;
    });

    const updateInitialData = useCallback(
        (key, data) => {
            tabs.current[key].initialData = {
                ...tabs.current[key].initialData,
                ...removeEmpty(data),
            };
        },
        [tabs]
    );
    const getInitialDataByName = useCallback(
        (key) => {
            return tabs.current[key].initialData || {};
        },
        [tabs]
    );

    const selectTab = useCallback(
        ({ proximo, data }) => {
            unstable_batchedUpdates(() => {
                const newData =
                    data ||
                    (containerData.currentItem.formRef.current &&
                        containerData.currentItem.formRef.current.getData)
                        ? containerData.currentItem.formRef.current.getData()
                        : {};
                updateInitialData(tabName, newData);

                setSteps((prev) => {
                    const cloneSteps = { ...prev };
                    cloneSteps[tabName].formularioSucesso = true;
                    return cloneSteps;
                });
                setTabName(proximo);
            });
        },
        [tabName]
    );

    return {
        ...containerData,
        keys: keys.current,
        tabName,
        steps,
        selectTab,
        getInitialDataByName,
        updateInitialData,
    };
};
