import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import { useRelatorioEstabelecimentoAnalitico } from 'pages/unidades/modal/relatorio/hooks/estabelecimento/useRelatorioEstabelecimentoAnalitico';
import { useRelatorioEstabelecimentoSintetico } from 'pages/unidades/modal/relatorio/hooks/estabelecimento/useRelatorioEstabelecimentoSintetico';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteFatura } from './useDeleteFatura';
import { IUnidadeFatura } from './useQueryFilterFatura/index.d';
import { useToggleAdimplenteFatura } from './useToggleAdimplenteFatura';
export const useTransformFatura = (props, { administrador }) => {
    const unidadeFatura: IUnidadeFatura[] = props.response || [];
    const history = useHistory();
    const { handleDeleteFatura } = useDeleteFatura(props);
    const { handleToogleAdimplente } = useToggleAdimplenteFatura(props);
    const relatorio = {
        analitico: useRelatorioEstabelecimentoAnalitico(),
        sintetico: useRelatorioEstabelecimentoSintetico(),
    };
    const handleEnviarNotificacao = useCallback(
        ({ id: faturaId, tipoNotificacao }) => {
            history.push(
                `/admin/fatura/notificar/${faturaId}/${tipoNotificacao}`
            );
        },
        []
    );
    const handleDownloadBoleto = useCallback(({ faturaBoleto }) => {
        window.open(faturaBoleto);
    }, []);
    const handleDownloadNotaFiscal = useCallback(({ faturaNotaFiscal }) => {
        window.open(faturaNotaFiscal);
    }, []);
    const handleRecibo = useCallback(({ unidadeId, periodoDataInicial, periodoDataFinal }) => {
        window.open(process.env.REACT_APP_HOST_EXPRESS+"/invoice-period?start="+periodoDataInicial+"&unidadeId="+unidadeId+"&end="+periodoDataFinal+"");
    }, []);
    const handleDownloadRelatorio = useCallback(
        ({ fatura, tipoRelatorio = 'sintetico' }) => {
            let variables: any = {
                dataInicio: format(
                    parseISO(fatura?.periodoDataInicial),
                    'yyyy-MM-dd'
                ),
                dataFim: format(
                    parseISO(fatura?.periodoDataFinal),
                    'yyyy-MM-dd'
                ),
                ...(fatura.periodoTurno
                    ? { periodoId: fatura.periodoTurno }
                    : {}),
                ...(fatura.unidadeId ? { unidadeId: fatura.unidadeId } : {}),
            };
            relatorio[tipoRelatorio].handleClick(variables);
        },
        []
    );

    const data = useMemo(() => {
        return unidadeFatura.map((fatura) => {
            const menuCliente = [
                {
                    placeholder: 'relatório sintético',
                    titulo: 'relat. sintético',
                    click: handleDownloadRelatorio,
                    data: { fatura, tipoRelatorio: 'sintetico' },
                    icone: 'icon-ajustar',
                    status: 'ativo',
                },
                {
                    placeholder: 'relatório analítico',
                    titulo: 'relat. analítico',
                    click: handleDownloadRelatorio,
                    data: { fatura, tipoRelatorio: 'analitico' },
                    icone: 'icon-ajustar',
                    status: 'ativo',
                },
                {
                    placeholder: 'Download boleto',
                    titulo: 'Boleto',
                    click: handleDownloadBoleto,
                    data: fatura,
                    icone: 'icon-download',
                    status: 'inativo',
                },
                // {
                //     placeholder: 'Download nota fiscal',
                //     titulo: 'Nota fiscal',
                //     click: handleDownloadNotaFiscal,
                //     data: fatura,
                //     icone: 'icon-download',
                //     status: 'inativo',
                // },
            ];
            const menuAdministrador = [
                {
                    titulo: 'Download relatório sintético',
                    click: handleDownloadRelatorio,
                    data: { fatura, tipoRelatorio: 'sintetico' },
                    icone: 'icon-ajustar',
                },
                {
                    titulo: 'Download relatório analítico',
                    click: handleDownloadRelatorio,
                    data: { fatura, tipoRelatorio: 'analitico' },
                    icone: 'icon-ajustar',
                },
                {
                    titulo: 'Download boleto',
                    click: handleDownloadBoleto,
                    data: fatura,
                    icone: 'icon-download',
                },
                // {
                //     titulo: 'Download nota fiscal',
                //     click: handleDownloadNotaFiscal,
                //     data: fatura,
                //     icone: 'icon-download',
                // },
                {
                    placeholder: 'Download recibo',
                    titulo: 'Download Recibo',
                    click: handleRecibo,
                    data: fatura,
                    icone: 'icon-download',
                    status: 'ativo',
                },
                {
                    titulo: 'Notificar fatura vence em 24h',
                    click: handleEnviarNotificacao,
                    data: { ...fatura, tipoNotificacao: 0 },
                    icone: 'icon-sorizo',
                },
                {
                    titulo: 'Notificar fatura em aberto',
                    click: handleEnviarNotificacao,
                    data: { ...fatura, tipoNotificacao: 1 },
                    icone: 'icon-pensando',
                },
                {
                    titulo: 'Notificar somente inadimplência',
                    click: handleEnviarNotificacao,
                    data: { ...fatura, tipoNotificacao: 3 },
                    icone: 'icon-triste',
                },
                {
                    titulo: 'Notificar inadimplência e bloqueio',
                    click: handleEnviarNotificacao,
                    data: { ...fatura, tipoNotificacao: 2 },
                    icone: 'icon-triste',
                },
                {
                    titulo: 'Excluir relatório',
                    click: handleDeleteFatura,
                    data: fatura,
                    icone: 'icon-lixeira',
                },
                {
                    titulo: fatura?.adimplente
                        ? 'Bloquear empresa por inadimplência'
                        : 'Desbloquear empresa.',
                    click: handleToogleAdimplente,
                    data: fatura,

                    icone: fatura?.adimplente
                        ? 'icon-visualizar'
                        : 'icon-naovisualizar',
                },
            ];
            const botoes = administrador ? menuAdministrador : menuCliente;

            const startMes = format(parseISO(fatura?.periodoDataInicial), `MM`);
            const startEnd = format(
                parseISO(fatura?.faturaDataPagamento),
                `MM`
            );
            const dataStart = format(
                parseISO(fatura?.periodoDataInicial),
                startMes === startEnd ? 'dd' : `dd 'de' MMMM`,
                { locale }
            );
            const dataEnd = format(
                parseISO(fatura?.periodoDataFinal),
                `dd 'de' MMMM`,
                { locale }
            );
            const dataVenc = format(
                parseISO(fatura?.faturaDataPagamento),
                `dd 'de' MMMM`,
                { locale }
            );
            //
            return {
                lineClassName: fatura?.adimplente ? '' : 'bgRed',
                colunas: [
                    {
                        tipo: 'Texto',
                        width: '1fr',
                        marginRight: '0',
                        titulo: fatura?.nomeFantasia,
                        subtitulo: fatura?.identificador,
                    },
                    {
                        tipo: 'Texto',
                        width: '28rem',
                        marginRight: '0',
                        titulo: `${dataStart} a ${dataEnd}`,
                    },
                    {
                        tipo: 'Texto',
                        width: '17rem',
                        marginRight: '0',
                        titulo: dataVenc,
                        subtitulo: fatura?.adimplente
                            ? ''
                            : 'Pagamento pendente',
                    },
                    {
                        tipo: 'Texto',
                        titulo: '',
                        subtitulo: '',
                        width: administrador ? '27rem' : '50rem',
                        marginRight: '0',
                        temaBotao: administrador ? '1' : '2',
                        botoes,
                    },
                ],
            };
        });
    }, [unidadeFatura, administrador]);

    return data;
};
