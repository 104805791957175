import styled from 'styled-components';
import Icone from 'components/Icone';
export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;
export const IconeSucesso = styled(Icone)<any>`
	color: var(--success-cor);
	z-index: 999;
	position: absolute;
	top: 0;
	left: calc(50% - 1rem);
`;
interface ISeparator {
	colorSeparator?: string;
}
export const ContainerBtnStep = styled.button<ISeparator>`
	display: flex;
	flex: 1;
	cursor: pointer;
	justify-content: center;
	position: relative;
	padding-top: 2.5rem;
	> svg,
    span.legenda{
        font-size: 1.5rem;
        text-transform: uppercase;
    },
	span.legenda:after,
	span.icon-sucesso {
		transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
	}
	span.legenda:after {
		content: '';
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 50%;
		position: absolute;
		z-index: 1;
		top: 0rem;
		left: calc(50% - 1.1rem);
		border: 0.3rem solid;
		border-color: ${({ colorSeparator }) =>
			colorSeparator || 'var(--bg-color)'};
		background-color: var(--bg-color);
	}
	&.erro span.legenda:after {
		background-color: var(--bg-hover-light);
		transform: scale(0.9);
	}

	&.erro.selecionado span:after {
		box-shadow: inset 0px 0px 0px 2px var(--placeholder);
	}
	&:before {
		content: '';
		width: 100%;
		height: 2px;
		position: absolute;
		top: 1.05rem;
	}
	&.sucesso span {
		color: var(--success-cor);
	}
	&.anterior-erro.erro:before {
		background: linear-gradient(
			90deg,
			var(--bg-hover-light) 50%,
			var(--bg-hover-light) 51%
		);
	}
	&.anterior-erro.sucesso:before {
		background: linear-gradient(
			90deg,
			var(--bg-hover-light) 50%,
			var(--success-cor) 51%
		);
	}
	&.anterior-sucesso.sucesso:before {
		background: linear-gradient(
			90deg,
			var(--success-cor) 50%,
			var(--success-cor) 51%
		);
	}
	&.anterior-sucesso.erro:before {
		background: linear-gradient(
			90deg,
			var(--success-cor) 50%,
			var(--bg-hover-light) 51%
		);
	}
	&.anterior-vazio.sucesso:first-child::before {
		background: linear-gradient(
			90deg,
			transparent 50%,
			var(--success-cor) 51%
		);
	}
	&.anterior-vazio.erro:first-child::before {
		background: linear-gradient(
			90deg,
			transparent 50%,
			var(--bg-hover-light) 51%
		);
	}
	&.anterior-erro.sucesso:last-child::before {
		background: linear-gradient(
			90deg,
			var(--bg-hover-light) 50%,
			transparent 51%
		);
	}
	&.anterior-sucesso.sucesso:last-child::before {
		background: linear-gradient(
			90deg,
			var(--success-cor) 50%,
			transparent 51%
		);
	}
	&.anterior-erro.erro:last-child::before {
		background: linear-gradient(
			90deg,
			var(--bg-hover-light) 50%,
			transparent 51%
		);
	}
	&.anterior-sucesso.erro:last-child::before {
		background: linear-gradient(
			90deg,
			var(--success-cor) 50%,
			transparent 51%
		);
	}

	&:hover span.icon-sucesso {
		transform: scale(1.5);
	}
	&:hover span.legenda:after {
		transform: scale(1.5);
	}
`;
