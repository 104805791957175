import { useQuery } from '@apollo/client';
import { IQueryColetaPagamento } from 'pages/coleta/listar/hooks/useTransformColeta/index.d';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_BUSCAR_COLETA_PAGAMENTO } from './../../../../../gql/coleta';

export const useQueryData = () => {
    const { coletaId } = useParams<any>();

    const { loading, data } = useQuery<IQueryColetaPagamento>(
        GRAPHQL_BUSCAR_COLETA_PAGAMENTO,
        {
            variables: {
                filter: { id: coletaId },
            },
        }
    );
    const props = useMemo(() => {
        let initialData: any = {};
        let cartoes: any[] = [];
        if (data && data.recuperarColeta && data.recuperarColeta.data) {
            const coleta = data.recuperarColeta.data[0];
            const formaPagamentoId = data.recuperarFormaPagamento.find(
                ({ id }) => id === coleta.pagamento.formaPagamentoId
            );
            initialData = {
                valorTotalProdutos: coleta.valorTotalProdutos ? `${coleta.valorTotalProdutos}`.replace('.', ',') : 0,
                formaPagamentoId: {
                    label: formaPagamentoId?.nome,
                    value: {
                        id: formaPagamentoId?.id,
                        exigeTaxaRetorno: formaPagamentoId?.exigeTaxaRetorno,
                    },
                },
                valorTroco:
                    coleta.pagamento.formaPagamentoId === 1
                        ? coleta.pagamento.valorTroco
                        : null,
            };
            cartoes = data.recuperarFormaPagamento.map(
                ({ id, exigeTaxaRetorno, nome }) => ({
                    label: nome,
                    value: { id, exigeTaxaRetorno },
                })
            );
        }
        return { initialData, cartoes };
    }, [data]);
    return {
        loading,
        ...props,
    };
};
