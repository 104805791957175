import styled from 'styled-components';
import IconeCp from 'components/Icone';
import { cssButtonCircular, cssButtonCircularNoHover } from '../styl';
// REPOST
export const Container = styled.div<any>`
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    display: flex;
    div.row {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        div.tituloSub {
            display: flex;
            margin-right: 0px;
        }
    }
    div.tituloSub {
        display: flex;
        margin: 0 1rem;
        flex-direction: column;
        justify-content: end;
        flex-wrap: wrap;
        p {
            font-weight: var(--bold);
            color: black;
        }
    }
    div.containerBotao {
        display: flex;
        margin: 0 0.2rem;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 1rem;
        margin-left: 2rem;
        p {
            font-weight: var(--bold);
            color: black;
        }

    }
    div.containerBotao div.botoesAcao {
        width: 100%;
    }
    div.containerBotao.nowrap {
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 0px;
    }
    & > button {
        ${cssButtonCircular}
    }
    button.tema2 {
        width: 100%;
        & .font-ic {
            margin: 0.5rem;
        }
        div {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.5rem;
        }

        &.ativo {
            background: var(--btn-bg-small-ativo);
            div {
                color: var(--success-cor);
            }
        }
        &.inativo,
        &.cancelado {
            background: var(--btn-bg-small-inativo);
            & .font-ic {
                color: var(--error-cor);
            }
        }
        &.coletabtn {
            background: var(--bg);
            border: 1px solid var(--placeholder);
            & .font-ic {
                color: var(--btn-admin-icone);
                display: none;
            }
            min-width: 170px;
            min-height: 27px;
            justify-content: center;
        }
        &.coletabtn span {
            color: black;
            font-weight: bold;
        }
        &.coletabtnAtivo {
            background: var(--bg);
            font-weight: bold;
            border: 1px solid var(--green);
            & .font-ic {
                color: var(--btn-admin-icone);
                display: none;
            }
            min-width: 170px;
            min-height: 27px;
            justify-content: center;
        }
        &.coletabtnAtivo span {
            color: var(--green);
            font-weight: bold;
        }
        &.vazio {
            background: var(--btn-bg-small-vazio);
            & .font-ic {
                color: var(--placeholder);
            }
        }
        &.interesse {
            background: var(--btn-bg-small-interest);
            & .font-ic {
                color: var(--btn-underline-hover-cor);
            }
        }
        margin: 0.3rem;
        border-radius: 0.4rem;
        display: flex;
        padding: 0 0.6rem;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
    }

`;
export const ContainerButtons = styled.div<any>`
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    div:first-child {
        display: flex;
        margin: 0 1rem;
        flex-direction: column;
        align-items: flex-end;
        p {
            font-weight: var(--bold);
            color: black;
        }
    }
    & > button {
        ${cssButtonCircular}
    }
`;
export const ContainerButtonsTema2 = styled.div<any>`
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    align-self: center;

    button {
        & .font-ic {
            margin: 0.5rem;
        }

        &.ativo {
            background: var(--btn-bg-small-ativo);
            div {
                color: var(--success-cor);
            }
        }
        &.inativo {
            background: var(--btn-bg-small-inativo);
            & .font-ic {
                color: var(--error-cor);
            }
        }
        &.vazio {
            background: var(--btn-bg-small-vazio);
            & .font-ic {
                color: var(--placeholder);
            }
        }
        margin: 0.3rem;
        border-radius: 0.4rem;
        display: flex;
        padding: 0 0.6rem;
        align-items: center;
        cursor: pointer;
    }
`;
export const ButtonAction = styled.button<any>`
    margin-left: 1px;
    ${cssButtonCircularNoHover}
`;
export const Pen = styled(IconeCp)``;
