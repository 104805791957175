// eslint-disable-next-line no-use-before-define
import React, { FC, memo, useEffect } from 'react';
import { defTitle } from '../../../../assets/strings';
import SimplesForm from '../../../../components/modal/template/simplesForm';
import FormGenerator from '../../../../components/Form/template/FormGenerator';

const ComponentClienteEditar: FC<any> = memo(oProps => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Configurações do estabelecimento`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            titleModal={'Configurações'}
            message={''}>
            <FormGenerator
                rForm={oProps.rForm}
                childs={[
                    {
                        type: 'number',
                        label: 'Taxa JogoRápido ',
                        placeholder: 'Porcentagem',
                        name: 'porcentagemNegociacao',
                        toolTipLegend:
                            'Informe aqui o valor em porcentagem de quanto a JogoRápido cobra sobe o valor de cada corrida ( frete + tx. retorno )',
                        defaultValue: oProps?.porcentagemNegociacao
                            ? oProps?.porcentagemNegociacao
                            : null,
                    },
                ]}
            />
        </SimplesForm>
    );
});
export default ComponentClienteEditar;
