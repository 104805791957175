import { useQuery } from '@apollo/client';
import { GRAPHQL_FILTER_CLIENTE } from 'gql/cliente';
import { upperCamelCase } from 'libs/StringUteis';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IReceiver } from '..';
interface IEndereco {
    id?: string;
}
interface IUserNameIdPhoneList {
    id?: string;
    nomeCompleto?: string;
    telefoneCelular?: string;
    telefoneFixo?: string;
    email?: string;
    enderecos?: IEndereco[];
}
interface IUnidadeParametros {
    chave: string;
    valor: string;
}

export const useLoadClient = ({
    clienteId,
    unidadeId,
    nomeCompleto: nc = '',
    formRef,
}: IReceiver) => {
    const timeout = useRef(null);
    const select = useRef(null);
    const somarFrete = useRef(false);
    const { clienteId: clienteIdUpdated } = useParams<any>();
    const [defaultValue, setDefaultValue] = useState({} as any);
    clienteId = clienteIdUpdated || clienteId;
    const [nomeCompleto, setNomeCompleto] = useState(clienteIdUpdated || nc);

    const [loadingInitial, setLoadingInitial] = useState(
        clienteId !== undefined
    );

    const [dataListClient, setDataListClient] = useState<
        IUserNameIdPhoneList[]
    >([]);

    const variables = useMemo(() => {
        if (!nomeCompleto || nomeCompleto.trim().length === 0) {
            return { unidadeId, filter: { unidadeId } };
        }
        // if (/^[0-9A-Za-z]{8}-[0-9-a-zA-Z]{0,}$/.test(nomeCompleto)) {
        //     return {
        //         filter: { unidadeId, id: nomeCompleto },
        //         unidadeId,
        //     };
        // }
        return {
            filter: { unidadeId, query: nomeCompleto },
            unidadeId,
        };
    }, [nomeCompleto]);

    useQuery<{
        cliente?: IUserNameIdPhoneList[];
        recuperarUnidadeParametros: IUnidadeParametros[];
    }>(GRAPHQL_FILTER_CLIENTE, {
        variables,
        skip: nomeCompleto.length < 3,
        onCompleted: (data) => {
            somarFrete.current =
                (data.recuperarUnidadeParametros.find(
                    ({ chave }) => chave === 'CALC::DIFF::FEE::INTEGRATION'
                )?.valor || 'NOT') === 'YES';
            if (loadingInitial) {
                const clientMemory = data?.cliente?.find(
                    ({ id }) => id === clienteId
                );

                if (clientMemory?.id) {
                    const tmp = {
                        value: clientMemory.id,
                        label: `${clientMemory.nomeCompleto} ${
                            clientMemory.telefoneCelular
                                ? ' - ' + clientMemory.telefoneCelular
                                : ''
                        }`,
                    };
                    setDefaultValue(tmp);
                    formRef.current.setFieldValue('clienteId', tmp.value);
                    formRef.current.setFieldValue('nomeCompleto', tmp.value);
                }
            }

            setDataListClient((prevDataList) => [
                ...prevDataList,
                ...data?.cliente,
            ]);

            if (select.current && select.current.callBack) {
                const response = data?.cliente?.map(
                    ({ id, nomeCompleto: nome, telefoneCelular }) => ({
                        novo: false,
                        value: id,
                        label: `${nome} ${
                            telefoneCelular ? ' - ' + telefoneCelular : ''
                        }`,
                    })
                );
                // if (
                //     nomeCompleto.trim().length > 5 &&
                //     nomeCompleto.trim().indexOf(' ') !== -1
                // ) {
                //     const value = upperCamelCase(nomeCompleto);
                const value = upperCamelCase(nomeCompleto);
                response.unshift({
                    novo: true,
                    value: !(/@/.test(value)) && !(/\d+/.test(value)) ? value : '',
                    label: `Clique aqui para cadastrar`,
                });
                // }
                select.current.callBack(response);
            }
            setLoadingInitial(false);
        },
    });

    const loadOptions = useCallback(
        (search, callBack) => {
            select.current = { callBack };
            if (search.trim() === '') {
                const response = dataListClient?.map(
                    ({ id, nomeCompleto: nome, telefoneCelular }) => ({
                        value: id,
                        label: `${nome} ${
                            telefoneCelular ? ' - ' + telefoneCelular : ''
                        }`,
                    })
                );

                callBack(response);
            } else {
                if (timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    setNomeCompleto(search);
                }, 400);
            }
        },
        [dataListClient]
    );

    const handlePushDataListCliente = useCallback((data) => {
        setDataListClient((prevDataList) => {
            return [...prevDataList, data];
        });

        formRef.current.setFieldValue('clienteId', data.id);

        setDefaultValue({
            value: data.id,
            label: `${data.nomeCompleto} - ${data.telefoneCelular}`,
        });
    }, []);

    const handleSetDefaultValue = useCallback((data) => {
        if (data.label.indexOf('Clique aqui para cadastrar "') !== -1) {
            data.label = data.value;
        }
        if (formRef.current.setFieldValue) {
            formRef.current.setFieldValue('clienteId', data.value);
        }

        setDefaultValue(data);
    }, []);

    const itemSelecionado = useMemo(() => {
        if ('value' in defaultValue) {
            const clienteFind = dataListClient?.find(
                ({ id }) => id === defaultValue.value
            );
            if (formRef.current?.setFieldValue) {
                const endereco = clienteFind?.enderecos?.map(({ id }) => id);
                if (endereco && endereco.length > 0) {
                    formRef.current?.setFieldValue(
                        'endereco',
                        endereco.shift()
                    );
                }
            }
            return clienteFind;
        }
        return null;
    }, [defaultValue, dataListClient]);

    return {
        somarFrete: somarFrete.current,
        loadingInitial,
        loadOptions,
        defaultValue,
        handleSetDefaultValue,
        handlePushDataListCliente,
        itemSelecionado,
    };
};
