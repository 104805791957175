import ListTemplate from 'components/template/ListTemplate';
import React, { FC, useContext, useMemo } from 'react';
import { useHeader } from './hooks/useHeader';
import { useQueryPermissions } from './hooks/useQueryPermissions';
import { useQuerySubscriptionFilter } from './hooks/useQuerySubscriptionFilter';
import { useTotalEntregadorEscalado } from './hooks/useTotalEntregadorEscalado';
import { useTransformColeta } from './hooks/useTransformColeta';
import { ModalInfoPlayer } from '../../../components/infoPlayer';
import { AuthContext } from 'routes/auth.context';
const ListCollection: FC<any> = () => {
    const totalEscalado = useTotalEntregadorEscalado();
    const permissoes = useQueryPermissions({
        searchResourceId: 'lista-coleta',
        action: 'editar',
    });
    const props = useQuerySubscriptionFilter();
    const colunas = useHeader();
    const { configuracao, unidadeSelecionada } = useContext(AuthContext);
    const data = useTransformColeta({
        coletas: props.response,
        permissoes,
        totalEntregadorEscalado: totalEscalado,
        timeAdicionalCollectt: props.timeAdicionalCollectt,
        timeEsperaEstabelecimento: props.timeEsperaEstabelecimento,
        configuracao,
        unidadeSelecionada,
    });
    const response = useMemo(() => {
        return {
            coletasSemPlayer: props.response.filter(
                ({ entregador, status }) =>
                    (!entregador || !entregador.id) &&
                    status && status.length > 0 &&
                    [1, 10, 11].indexOf(status[0].id) !== -1,
            ).length,
            coletasNaoConcluidas: props.response.filter(({ status }) => {
                return (
                    status && status.length > 0 &&
                    [2, 3, 4, 5, 8, 12].indexOf(status[0].id) !== -1
                );
            }).length,
            playersBusy: props.response
                .filter(({ status }) => {
                    return (
                        status && status.length > 0 &&
                        [1, 2, 3, 4, 5, 10, 11, 12].indexOf(status[0].id) !== -1
                    );
                })
                .map(({ entregador }) => entregador.id),
        };
    }, [props.response]);

    return (
        <>
            <ListTemplate
                addFilterDate={true}
                browserTitle="Listar coletas"
                colunas={colunas}
                data={data}
                {...props}
            />
            {permissoes.perfilAdministrador && (
                <ModalInfoPlayer
                    {...response}
                    connection={props.entregadoresConnection}
                />
            )}
        </>
    );
};
export default ListCollection;
