import styled, { css } from 'styled-components';
export const ContainerBody = styled.div`
    div:nth-child(3){
        border-bottom: 0px;
    }
`;

export const Container = styled.div`
	position: relative;
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 1rem 0;
`;
export const ContainerLastChild = styled.div`
	position: relative;
    display: flex;
    padding: 1rem 0;
`;

export const ImageRound = styled.a`
    width: 15rem;
    height: 10rem;
    background: #ccc;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
`;

export const ContainerTitleAndButton = styled.div`
	position: relative;
    display: flex;
`;

export const BlcTitleAndButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
        font-size: 2.0rem;
    }
    p{
        margin: 0px;
    }
    button:first-child{
        margin-right: 1rem;
    }
    button{
        margin-top: 1rem;
    }
    button span {
        font-size: 1.4rem;
    }
    button#reprovarcnh_frente, button#reprovarcnh_verso, button#reprovarcrlv{
        background: red;
    }
    width: 100%;
`;

export const BlcImage = styled.img`
	object-fit: contain;
    width: 100%;
    height: 100%;
`;
