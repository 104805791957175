import { useLazyQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_ANALITICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { mascaraCep } from 'libs/MascaraCep';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { addColumn } from '../../uteis/addColumn';
export const useRelatorioEstabelecimentoAnalitico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_ANALITICO,
        {
            onCompleted: response => {
                const content: any = [
                    [
                        'Criação do pedido',
                        'Chamar entregador',
                        'Aceite',
                        'Checkin Unidade',
                        'Checkout Unidade',
                        'Checkin Cliente',
                        'Checkout Cliente',
                        'Numero interno',
                        'Modal',
                        'Nome entregador',
                        'Nome cliente',
                        'Distância percorrida',
                        'Setor',
                        // 'Cep',
                        'Rota google maps',
                        'Período',
                        'Produto',
                        // 'Frete',
                        'Taxa retorno',
                        'Frete Jogo Rápido',
                        // 'Taxa Jr ( % )',
                        // 'Taxa Jr ( valor )',
                        '',
                        'Coluna',
                        'Valor',
                    ],
                ];

                let totalProdutos = 0;
                let totalFrete = 0;
                let totalJr = 0;

                response.relatorioEntregasAnalitico.forEach(coleta => {
                    const createCollectt = coleta.dataPedido
                        ? parseISO(coleta.dataPedido)
                        : new Date();
                    const callPlayer = coleta.dataPrimeiraChamada
                        ? parseISO(coleta.dataPrimeiraChamada)
                        : new Date();
                    const aceite = coleta.dataConfirmacaoAceite
                        ? parseISO(coleta.dataConfirmacaoAceite)
                        : new Date();
                    const checkInUnidade = coleta.coletaDataCheckinUnidade
                        ? parseISO(coleta.coletaDataCheckinUnidade)
                        : aceite;
                    const checkOutUnidade = coleta.coletaDataCheckoutUnidade
                        ? parseISO(coleta.coletaDataCheckoutUnidade)
                        : checkInUnidade;
                    const checkInCliente = coleta.coletaDataCheckinCliente
                        ? parseISO(coleta.coletaDataCheckinCliente)
                        : checkOutUnidade;
                    const checkOutCliente = coleta.coletaDataCheckoutCliente
                        ? parseISO(coleta.coletaDataCheckoutCliente)
                        : checkInCliente;
                    content.push([
                        format(
                            new Date(
                                Math.min(
                                    ...[
                                        createCollectt.getTime(),
                                        callPlayer.getTime(),
                                        aceite.getTime(),
                                    ],
                                ),
                            ),
                            `dd/MM/yyyy HH:mm`,
                        ),
                        format(
                            new Date(
                                Math.min(
                                    ...[callPlayer.getTime(), aceite.getTime()],
                                ),
                            ),
                            `dd/MM/yyyy HH:mm`,
                        ),
                        format(
                            new Date(
                                Math.max(
                                    ...[
                                        createCollectt.getTime(),
                                        callPlayer.getTime(),
                                        aceite.getTime(),
                                    ],
                                ),
                            ),
                            `dd/MM/yyyy HH:mm`,
                        ),
                        format(checkInUnidade, `dd/MM/yyyy HH:mm`),
                        format(checkOutUnidade, `dd/MM/yyyy HH:mm`),
                        format(checkInCliente, `dd/MM/yyyy HH:mm`),
                        format(checkOutCliente, `dd/MM/yyyy HH:mm`),
                        coleta.numeroInterno || '',
                        coleta.modalTransporte || 'moto',
                        (coleta.entregadorNome || '').replace(
                            /[^a-zA-Z\u00C0-\u00FF\s]/gi,
                            '',
                        ),
                        (coleta.clienteNome || '').replace(
                            /[^a-zA-Z\u00C0-\u00FF\s]/gi,
                            '',
                        ),
                        coleta.linkRotaGoogleMaps || '',
                        (coleta.enderecoBairro || '').replace(
                            /[^a-zA-Z\u00C0-\u00FF\s]/gi,
                            '',
                        ),
                        // mascaraCep(coleta.enderecoCep || ''),
                        `${(coleta.distanciaPercorrida || 0)
                            .toFixed(3)
                            .replace('.', ',')}`,
                        coleta.periodoNome || '',
                        formatarMoeda(coleta.valorTotalProdutos || 0),
                        coleta.valorTaxaRetorno ? 'Sim' : 'Não',
                        // formatarMoeda(coleta.valorFrete || 0),
                        // formatarMoeda(coleta.valorTaxaRetorno || 0),
                        formatarMoeda(
                            ((coleta.valorFrete || 0) +
                                (coleta.valorTaxaRetorno || 0) + coleta.valorPercNegociado),
                        ),
                        // `${Math.round(
                        //     (coleta.porcentagemNegociacao || 0) * 100,
                        // )}%`,
                        // formatarMoeda(coleta.valorPercNegociado || 0),
                        '',
                    ]);
                    totalProdutos += coleta.valorTotalProdutos || 0;

                    totalFrete +=
                        (coleta.valorFrete || 0) +
                        (coleta.valorTaxaRetorno || 0);
                    totalJr += coleta.valorPercNegociado || 0;
                });
                addColumn({
                    content,
                    columnStart: 23,
                    itens: [
                        {
                            label: 'Frete Jogo Rapido',
                            value: formatarMoeda(totalFrete + totalJr),
                        },
                        // {
                        //     label: 'Entregadores',
                        //     value: formatarMoeda(totalFrete),
                        // },
                        // {
                        //     label: 'Taxa Jr',
                        //     value: formatarMoeda(totalJr),
                        // },
                        {
                            label: 'Quant. entregas',
                            value: response.relatorioEntregasAnalitico.length,
                        },
                        {
                            label: 'Vendas totais',
                            value: formatarMoeda(totalProdutos),
                        },
                    ],
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO ANALÍTICO ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`,
                    )}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        },
    );
    const handleClick = useCallback(
        variables => {
            handleQuery({ variables });
        },
        [handleQuery],
    );
    return {
        handleClick,
        loading,
    };
};
