import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.footer.attrs({ className: 'footer-container' })`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: 1px solid var(--bg-hover-light);
    margin: 4rem auto 2rem auto;
    width: 100%;
    max-width: 550px;
    @media (min-width: 991px) {
        margin: 2rem 0rem 0rem 0rem;
        max-width: initial;
        width: initial;
    }
    & nav,
    & div {
        display: flex;
        align-items: center;
    }
    & span {
        padding-right: 1rem;
    }
`;
export const WarpLink = styled(Link)`
    padding: 1rem;
    border-radius: 50%;
    background-color: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 1.5rem;
    @media (max-width: 320px) {
        margin-right: 0.5rem;
    }
    cursor: pointer;
    circle,
    path {
        fill: var(--text-cor);
    }
    &:hover {
        background-color: var(--bg-hover-light);
        circle,
        path {
            fill: black;
        }
    }
`;
