import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_BUSCAR_BANCO } from 'gql/banco';
import { GRAPHQL_BUSCAR_ENTREGADOR } from 'gql/entregador';
import { useSelectAwaitFetch } from 'hooks/useSelectAwaitFetch';
import { trim } from 'libs/StringUteis';
import { matchCpf, matchTelefone } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import ComponentClienteEditar from './Component';
import { useUnidadeContaAlterar } from './hooks/useUnidadeContaAlterar';
import { usePix } from './hooks/usePix';
import { GRAPHQL_UNIDADE } from 'gql/unidade';

interface IParams {
    unidadeId: string;
}
interface IContaBanco {
    id?: string;
    nome?: string;
}

interface IContaBancaria {
    id?: string;
    titularNome?: string;
    titularCpf?: string;
    contaAgenciaDv?: string;
    contaAgencia?: number;
    contaNumeroDv?: number;
    contaNumero?: number;
    contaTipo?: string;
    pixTipo?: 'cpf' | 'email' | 'telefone' | 'aleatorio';
    pixConta?: string;
    contaBanco?: IContaBanco;
}
export interface IPlayer {
    id?: string;
    nomeCompleto?: string;
    sexo?: string;
    email?: string;
    telefone?: string;
    contaBancaria?: IContaBancaria;
}
const tipoConta = [
    { label: 'Poupança', value: 'POUPANCA' },
    { label: 'Conta corrente', value: 'CONTACORRENTE' },
    { label: 'Conta salário', value: 'CONTASALARIO' },
    { label: 'Conta pagamento', value: 'CONTAPAGAMENTO' },
    { label: 'Outra', value: 'OUTRA' },
];

const PageEditarEntregador: FC = () => {
    // pega o id cliente da url
    const { unidadeId } = useParams<IParams>();
    const [unidade, setUnidade] = useState<IPlayer>({} as IPlayer);
    const { data: responseData } = useQuery<{
        unidade?: {
            data?: IPlayer[];
        };
    }>(GRAPHQL_UNIDADE, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id: unidadeId,
            },
        },
    });
    useEffect(() => {
        const unidadeDto = responseData?.unidade?.data[0] || {};
        setUnidade(unidadeDto);
    }, [responseData]);

    const pix = usePix(unidade);
    const { atualizaUnidadeConta, loading } = useUnidadeContaAlterar();
    const rForm = createRef<FormHandles>();

    const contaTipoSelected = tipoConta.find(
        (o) => `${o.value}` === `${unidade?.contaBancaria?.contaTipo}`
    );

    // Inicio: Hook filter select async
    const { select: selectAsyncClient } = useSelectAwaitFetch({
        rootKey: 'banco',
        transformPostSendMutation: (nome) => ({
            filter: {
                nome,
            },
        }),
        transformPostSendMutationStart: () => ({
            filter: {},
        }),
        transformOption: (nome, banco) => {
            return {
                value: banco.id,
                label: banco.nome,
            };
        },
        isSelected: ({ value: id }, value) => {
            return id === value;
        },
        gql: GRAPHQL_BUSCAR_BANCO,
    });

    const handleSubmit = async (data) => {
        const { setErrors } = rForm.current as FormHandles;
        try {
            const pixContaYup = {
                telefone: yup
                    .string()
                    .required('Telefone inválido')
                    .matches(matchTelefone, 'Telefone Inválido'),
                cpf: yup
                    .string()
                    .required('CPF inválido')
                    .matches(matchCpf, 'CPF Inválido'),
                email: yup
                    .string()
                    .min(3, 'Email inválido')
                    .max(60)
                    .email('Email inválido')
                    .required('Email não informado'),
                aleatorio: yup
                    .string()
                    .min(20, 'Chave aleatória inválido')
                    .max(100, 'Chave aleatória muito longa')
                    .required('Chave aleatória não informado'),
            };
            const dataYup = {
                ...data,
                pixTipo:
                    typeof data.pixTipo === 'string'
                        ? ''
                        : data.pixTipo.pixTipo,
            };
            const pixConta = { pixConta: pixContaYup[dataYup.pixTipo] } || {};

            await YupUtilsTry(dataYup, setErrors, {
                pixTipo: yup
                    .string()
                    .required('Selecione um tipo de pagamento Pix'),
                contaBancoId: yup.string().required('Nome do banco inválido'),
                titularNome: yup.string().required('Nome do titular inválido'),
                titularCpf: yup
                    .string()
                    .required('CPF inválido')
                    .matches(matchCpf, 'CPF Inválido'),
                ...pixConta,
                contaAgencia: yup.string().required('Agência inválido'),
                contaAgenciaDv: yup.string().required('Dv da agência inválido'),
                contaNumero: yup.string().required('Conta inválido'),
                contaNumeroDv: yup.string().required('Dv da conta inválido'),
                contaTipo: yup.string().required('Tipo de conta inválido'),
            });

            data.titularNome = trim(data.titularNome);
            data.contaBancoId = Number(data.contaBancoId);
            data.contaAgencia = Number(data.contaAgencia);
            data.contaAgenciaDv = Number(data.contaAgenciaDv);
            data.contaNumero = Number(data.contaNumero);
            data.pixTipo = data.pixTipo.pixTipo;
            data.pixConta = data.pixConta;
            try {
                await atualizaUnidadeConta({
                    data,
                    unidadeId,
                });
            } catch (err1) {
                console.error(err1);
            }
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };
    const oProps = {
        pix,
        values: unidade,
        contaTipoSelected,
        loading,
        tipoConta,
        selectAsyncClient,
        rForm,
        handleSubmit,
    };
    return <ComponentClienteEditar {...oProps} />;
};
export default PageEditarEntregador;
