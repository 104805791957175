import React, { FC, memo } from 'react';
import { Loading, Container } from './styl';

interface ICircularLoading {
    show?: boolean;
    size?: number;
    sizeBorder?: number;
    primaryColor?: string;
    secondaryColor?: string;
    marginLeft?: number;
    marginRight?: number;
}
const CircularLoading: FC<ICircularLoading | any> = ({
    show = false,
    size = 15,
    sizeBorder = 2,
    primaryColor = 'var(--bg-white)',
    secondaryColor = 'rgba(255,255,255,.2)',
    marginLeft,
    marginRight,
}) => {
    return (
        <Container
            show={show}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <Loading
                size={size}
                sizeBorder={sizeBorder}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
        </Container>
    );
};
export default memo(CircularLoading);
