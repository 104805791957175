import React, { FC, useRef, useEffect, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';
import CPSelectAsync from 'react-select/async';
import { useField } from '@unform/core';
import { colourStyles, Container, LabelError } from '../SelectCreatable/styl';

const selectStyles = colourStyles();
const SelectAsync: FC<any> = ({ name, stylWarp = {}, ...rest }) => {
    const selectRef = useRef(null);
    const {
        fieldName,
        registerField,
        clearError,

        defaultValue,
        error,
    } = useField(name);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            setValue: (ref, value) => {
                ref.select.select.setValue(value || null);
            },
            getValue: (ref: any) => {
                if (rest.isMulti) {
                    if (!ref.select.state.value) {
                        return [];
                    }
                    return ref.select.state.value.map(
                        (option: OptionTypeBase) => option.value,
                    );
                }
                if (!ref.select.state.value) {
                    return '';
                }
                return ref.select.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);
    const handlerFocus = useCallback(
        e => {
            clearError();
        },
        [clearError],
    );
    return (
        <Container
            erro={error}
            style={stylWarp}>
            <CPSelectAsync
                onFocus={handlerFocus}
                styles={selectStyles}
                cacheOptions
                defaultValue={defaultValue}
                defaultOptions
                ref={selectRef}
                classNamePrefix="react-select"
                {...rest}
            />
            <LabelError erro={error}>{error}</LabelError>
        </Container>
    );
};
export default SelectAsync;
