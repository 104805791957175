import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_INTERESSE_ESCALA } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { useCallback, useContext, useRef } from 'react';
import { AuthContext } from 'routes/auth.context';
export const usePlayerScaled = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const currentDate = useRef({ dataInicio: '', dataFim: '' });
    const tagScaleRef = useRef('');

    const handleNormalizeData = useCallback(({ scaled, interest, name }) => {
        if (tagScaleRef.current === 'ESCALADO') {
            return scaled && scaled.includes(name) ? tagScaleRef.current : '';
        }
        return scaled && scaled.includes(name)
            ? tagScaleRef.current
            : interest && interest.includes(name)
            ? 'INTERESSADO'
            : '';
    }, []);

    const [handleQuery, { loading }] = useLazyQuery<{
        relatorioEntregadoresEscala: {
            interest: string[];
            scaled: string[];
            nomeCompleto: string;
        }[];
    }>(GRAPHQL_INTERESSE_ESCALA, {
        onCompleted: (response) => {
            const content: any = [
                [
                    'Entregador',
                    'Café',
                    'Almoço',
                    'Lanche',
                    'Janta',
                    'Madrugada',
                ],
            ];

            response.relatorioEntregadoresEscala.forEach((response) => {
                let data = [
                    response.nomeCompleto,
                    handleNormalizeData({ ...response, name: 'Café' }),
                    handleNormalizeData({
                        ...response,
                        name: 'Almoço',
                    }),
                    handleNormalizeData({
                        ...response,
                        name: 'Lanche',
                    }),
                    handleNormalizeData({
                        ...response,
                        name: 'Janta',
                    }),
                    handleNormalizeData({
                        ...response,
                        name: 'Madrugada',
                    }),
                ];
                const total = data.filter((item) => item === '').length;

                if (![1, 5].includes(total)) content.push(data);
            });

            handleGenerateCsv({
                fileName: `RELATÓRIO ${tagScaleRef.current} ${currentDate.current.dataInicio}`,
                content,
            });
        },
        onError: ({ message }) => {
            alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message,
            });
        },
    });

    const handleClick = useCallback(
        (variables, tagScale) => {
            tagScaleRef.current = tagScale;
            currentDate.current = variables;
            handleQuery({ variables });
        },
        [handleQuery]
    );

    return { handleClick, loading };
};
