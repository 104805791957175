import styled, { css } from 'styled-components';
export const ContainerBody = styled.div`
    display: flex;
    margin-bottom: 3rem;
    /* @media (max-width: 768px) {
        flex-direction: column;
        display: block;
    } */
`;
export const Conteiner = styled.div``;
export const stylMap = {
    width: '100%',
    height: '100%',
};
const inputStyl = css`
	border: 1px solid var(--bg-hover-light);
	border-radius: var(--def-radius);
`;
export const ContainerForm = styled.div<any>`
	flex: 1;
	display: flex;
	justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
	p {
		margin: 3rem auto;
		font-size: var(--size-p);
	}
	label {
		display: flex;
	}
	form {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		.space-button {
			width: 100%;
			display: flex;
			margin-top: 1.5rem;
		}
		.select > div {
			${inputStyl}
		}
	}
`;
export const GoogleMapWrap = styled.div<any>`
    min-width: 50%;
    @media (max-width: 768px) {
        height: 200px;
    }
`;

