import { IQueryUnidade } from '../../../list/hooks/useQueryFilterUnit/index.d';
import { GRAPHQL_LISTAR_UNIDADE_IDENTIFICADORES } from './../../../../../gql/unidade';
import { useCallback, useState, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { IChange } from 'components/Form/InputFile';
import { abreviarNome } from 'libs/StringUteis';

export const useUploadProcUnidade = () => {
    const callbackAndFiles = useRef({} as IChange);

    const [handleLoadUnidade] = useLazyQuery<IQueryUnidade>(
        GRAPHQL_LISTAR_UNIDADE_IDENTIFICADORES,
        {
            onCompleted: (data) => {
                const identificadores: string[] = data.unidade?.data.map(
                    ({ identificador }) =>
                        identificador
                            .replace(/\D/gi, '')
                            .replace(/\D/gi, '')
                            .substring(0, 14)
                );

                const newFile = callbackAndFiles.current.acceptedFiles.map(
                    (file) => {
                        const identificador = file.name
                            .replace(/\D/gi, '')
                            .substr(0, 14);
                        const indice = identificadores.indexOf(identificador);

                        return {
                            file,
                            id:
                                indice !== -1
                                    ? data.unidade?.data[indice].id
                                    : null,
                            name:
                                indice !== -1
                                    ? abreviarNome(
                                          data.unidade?.data[indice]
                                              .nomeFantasia
                                      )
                                    : 'Unidade não identificada',
                        };
                    }
                );
                callbackAndFiles.current.callback(newFile);
            },
        }
    );

    const onChange = useCallback((response: IChange) => {
        const identificadores: string[] = response.acceptedFiles.map(
            ({ name }) => name.replace(/\D/gi, '').substr(0, 14)
        );

        callbackAndFiles.current = response;
        if (identificadores.length > 0) {
            handleLoadUnidade({
                variables: { filter: { identificadores } },
            });
        }
    }, []);

    return {
        onChange,
    };
};
