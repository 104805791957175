import styled from 'styled-components';
import IconeCp from 'components/Icone';
import { cssButtonCircular } from '../styl';
export const Container = styled.div`
	align-items: center;
	justify-content: flex-end;
	div:first-child {
		display: flex;
		margin: 0 1rem;
		flex-direction: column;
		align-items: flex-end;
		p {
			font-weight: var(--bold);
			color: black;
		}
	}
	button {
		${cssButtonCircular}
	}
`;
export const Icone = styled(IconeCp)<any>``;
