import React, { FC, useContext, useEffect, useMemo } from 'react';
import { NavigateContext } from '../context';
import ComponentEmpresa from './Component';
import { usePessoaJuridico } from './hooks/usePessoaJuridico';
import { usePessoaFisica } from './hooks/usePessoaFisica';
import { useTipoCliente } from './hooks/useTipoCliente';
import { useContatosFinanceiro } from './hooks/useContatosFinanceiro';

const PageEmpresa: FC<any> = () => {
    const {
        cnpj: { loadBusiness, loading, data: dataCnpj },
        tabs: {
            selectTab,
            updateInitialData,
            currentItem: { formRef, initialData = {} },
        },
    } = useContext(NavigateContext);
    const pessoaJuridico = usePessoaJuridico({
        loadBusiness,
        loading,
        dataCnpj,
        selectTab,
        updateInitialData,
        formRef,
    });
    const tipoCliente = useTipoCliente({ formRef, initialData });
    const pessoaFisica = usePessoaFisica({ formRef, selectTab });
    const inputsFinanceiro = useContatosFinanceiro(initialData);

    const props = useMemo(() => {
        if (tipoCliente.clienteJuridico === null) {
            return {
                rForm: formRef,
                inputs: [tipoCliente.form],
                handleSubmit: tipoCliente.handleSubmit,
            };
        }

        return {
            initialData,
            rForm: formRef,
            inputs: [
                tipoCliente.form,
                ...(tipoCliente.clienteJuridico
                    ? pessoaJuridico.inputs
                    : pessoaFisica.inputs),
                ...inputsFinanceiro,
            ],
            handleSubmit: tipoCliente.clienteJuridico
                ? pessoaJuridico.handleSubmit
                : pessoaFisica.handleSubmit,
        };
    }, [
        tipoCliente,
        initialData,
        pessoaJuridico,
        pessoaFisica,
        inputsFinanceiro,
    ]);

    return <ComponentEmpresa {...props} />;
};
export default PageEmpresa;
