import { useQuery } from '@apollo/client';
import { COLETA_ENTREGADORES_ESCALADOS } from 'gql/coleta';
import React, { FC, useMemo, useState } from 'react';
import {
    IPropsViewInfoColeta,
    IPropsViewInfoPlayer,
    ViewInfoPlayer,
} from './ViewInfoPlayer';
export interface IRecuperarEntregadoresEscaladosHoje {
    entregadorId: string;
    entregadorNomeCompleto: string;
    entregadorTelefone: string;
    entregadorLatitude: number;
    entregadorLongitude: number;
    entregadorDataGeo: string;
    entregadorOnline: boolean;
}

export interface IEntregadoresConectados {
    [key: string]: string;
}
export const ModalInfoPlayer: FC<
    IPropsViewInfoColeta & {
        connection: IEntregadoresConectados;
        playersBusy: string[];
    }
> = ({ connection: subConnection, playersBusy, ...props }) => {
    const [totalPlayerEscalados, setEntregadorEscala] = useState<
        IRecuperarEntregadoresEscaladosHoje[]
    >([]);

    useQuery<{
        recuperarEntregadoresPeriodoVigente?: IRecuperarEntregadoresEscaladosHoje[];
    }>(COLETA_ENTREGADORES_ESCALADOS, {
        onCompleted: ({ recuperarEntregadoresPeriodoVigente }) => {
            setEntregadorEscala(recuperarEntregadoresPeriodoVigente);
        },
    });

    const entregadores: IPropsViewInfoPlayer = useMemo(() => {
        const totalPlayersOnline = totalPlayerEscalados.filter(
            ({ entregadorOnline, entregadorId }) => {
                const sub = subConnection[entregadorId];

                if (sub) {
                    return sub === 'online';
                }

                return entregadorOnline;
            }
        );
        const totalPlayersOnlineDesocupado = totalPlayerEscalados.filter(
            ({ entregadorOnline, entregadorId }) => {
                const sub = subConnection[entregadorId];
                const ocupado = playersBusy.includes(entregadorId);
                if (sub) {
                    return sub === 'online' && !ocupado;
                }

                return entregadorOnline && !ocupado;
            }
        );

        const totalPlayerOffline = totalPlayerEscalados.filter(
            ({ entregadorOnline, entregadorId }) => {
                const sub = subConnection[entregadorId];
                if (sub) {
                    return sub !== 'online';
                }

                return !entregadorOnline;
            }
        );

        const totalPlayerOfflineOcupado = [];
        const totalPlayerOfflineDesocupado = [];
        totalPlayerOffline.forEach((data) => {
            if (playersBusy.indexOf(data.entregadorId) !== -1) {
                totalPlayerOfflineOcupado.push(data);
            } else {
                totalPlayerOfflineDesocupado.push(data);
            }
        });

        const totalPlayerBusy = totalPlayerEscalados.filter(
            ({ entregadorId }) => {
                return playersBusy.indexOf(entregadorId) !== -1;
            }
        );

        return {
            totalPlayersOnlineDesocupado,
            totalPlayerOfflineDesocupado,
            totalPlayerOfflineOcupado,
            totalPlayerEscalados,
            totalPlayersOnline,
            totalPlayerOffline,
            totalPlayerBusy,
        };
    }, [totalPlayerEscalados, playersBusy, subConnection]);

    return (
        <ViewInfoPlayer
            {...{
                ...entregadores,
                ...props,
            }}
        />
    );
};
