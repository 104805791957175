import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesFormNoModal from 'components/modal/template/simplesFormNoModal';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentEmpresa: FC<any> = (oProps) => {
    useEffect(() => {
        document.title = `${defTitle} - Empresa`;
    });

    return (
        <SimplesFormNoModal
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            initialData={oProps.initialData}
            loading={oProps.loading}
            titleSubmit={'Continuar'}
        >
            <FormGenerator rForm={oProps.rForm} childs={oProps.inputs} />
        </SimplesFormNoModal>
    );
};
export default memo(ComponentEmpresa);
