import { Form } from '@unform/web';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import { useManagerInputs } from 'pages/coleta/cadastrar/pagamento/hooks/useManagerInputs';
import InputPreco from 'pages/coleta/cadastrar/pagamento/partial/inputPreco';
import React, { FC, memo } from 'react';
import { useQueryData } from './hooks/useQueryData';
import { useSubmitEditarPagamento } from './hooks/useSubmitEditarPagamento';
import { Container, Header } from './styl';

const PagePagamentoColeta: FC<any> = () => {
    const { loading, ...rest } = useQueryData();

    const {
        loading: loadingLSubmit,
        ref,
        ...unformProps
    } = useSubmitEditarPagamento();
    const { childsForm } = useManagerInputs({
        loading: loadingLSubmit,
        ...rest,
    });

    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Editar pagamento</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {<CircularLoading show={loading} />}

                    {!loading && (
                        <Form
                            ref={ref}
                            {...unformProps}
                            initialData={rest.initialData}>
                            <InputPreco
                                name="valorTotalProdutos"
                                descricao="NÃO INCLUSO FRETE"
                                thousandSeparator="."
                                prefix=""
                            />
                            <FormGenerator childs={childsForm} />
                        </Form>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PagePagamentoColeta);
