// eslint-disable-next-line no-use-before-define
import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import FormGenerator from 'components/Form/template/FormGenerator';
import SimplesForm from 'components/modal/template/simplesForm';
import React, { FC, memo, useEffect } from 'react';

const ComponentConfiguracaoPadrao: FC<any> = memo(oProps => {
    useEffect(() => {
        document.title = `${defTitle} - Editar configuração padrão`;
    }, []);

    return (
        <SimplesForm
            widthModal="80rem"
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            loadingData={oProps.loadingData}
            titleModal={'Configuração padrão'}
            message={null}>
            {!oProps.loadingData && (
                <FormGenerator
                    rForm={oProps.rForm}
                    childs={[
                        {
                            gridColumn: '1fr 1fr 1fr 1fr',
                            items: [
                                {
                                    type: 'money',
                                    label: 'Taxa mínima (Moto)',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_TaxaMinima_Valor_Moto',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_TaxaMinima_Valor_Moto'
                                        ],
                                },
                                {
                                    type: 'money',
                                    toolTipLegend: (
                                        <span>
                                            Valor que será pago por km rodado a
                                            <br />
                                            cada km que ultrapassar a "Dist.
                                            <br />
                                            mínima a ser paga"
                                        </span>
                                    ),
                                    label: 'Km adicional (Moto)',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_Adicional_Km_Moto',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_Adicional_Km_Moto'
                                        ],
                                },
                                {
                                    type: 'money',
                                    toolTipLegend: (
                                        <span>
                                            Valor que será adicionado
                                            <br />e repassado a jogo rapido
                                        </span>
                                    ),
                                    label: 'Adicional JogoRapido',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Valor_Adicional_Corrida',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Valor_Adicional_Corrida'
                                        ],
                                },
                                {
                                    type: 'money',
                                    toolTipLegend: (
                                        <span>
                                            Valor que será adicionado
                                            <br />e repassado ao entregador
                                        </span>
                                    ),
                                    label: 'Adicional Entregador',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Valor_Adicional_Corrida_Entregador',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Valor_Adicional_Corrida_Entregador'
                                        ],
                                },
                            ],
                        },
                        {
                            gridColumn: '1fr 1fr',
                            items: [
                                {
                                    type: 'money',
                                    label: 'Taxa mínima (Moto Retorno)',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_TaxaMinima_Valor_Moto_Retorno',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_TaxaMinima_Valor_Moto_Retorno'
                                        ],
                                },
                                {
                                    type: 'money',
                                    toolTipLegend: (
                                        <span>
                                            Valor que será pago por km rodado a
                                            <br />
                                            cada km que ultrapassar a "Dist.
                                            <br />
                                            mínima a ser paga"
                                        </span>
                                    ),
                                    label: 'Km adicional (Moto Retorno)',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_Adicional_Km_Moto_Retorno',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_Adicional_Km_Moto_Retorno'
                                        ],
                                },
                            ],
                        },
                        {
                            gridColumn: '1fr 1.3fr',
                            items: [
                                {
                                    type: 'number',
                                    label: 'Qtd. chamadas a coleta não atendidas',
                                    placeholder:
                                        'Quantidade de chamadas a coleta',
                                    toolTipLegend:
                                        'Quantas vezes o player pode ser chamado para atender uma coleta. Lembrando que o player é chamado a cada 20 segundos',
                                    name: 'Entregador_Qtd_Chamadas_Nao_Atendidas',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Entregador_Qtd_Chamadas_Nao_Atendidas'
                                        ] || 5,
                                },
                                {
                                    type: 'text',
                                    label: 'Margem de erro em minutos (intervalo entre status)',
                                    toolTipLegend:
                                        'Tempo em minutos que o sistema irá adicionar ao tempo de espera do status do pedido',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Coleta_MargemErro_Checkin',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Coleta_MargemErro_Checkin'
                                        ],
                                },
                            ],
                        },
                        {
                            type: 'number',
                            label: 'Tempo de espera (minutos)',
                            placeholder: 'Minutos',
                            toolTipLegend:
                                'Tempo máximo de espera do player no estabelecimento para iniciar a corrida',
                            name: 'Entregador_Espera_Estabelecimento_Minutos',
                            defaultValue:
                                oProps.defaultValues[
                                    'Entregador_Espera_Estabelecimento_Minutos'
                                ] || 5,
                        },
                        {
                            gridColumn: '1.5fr 1.5fr 2fr',
                            items: [
                                {
                                    type: 'mask',
                                    mask: '(99) 9 9999.9999',
                                    label: 'Whatsapp',
                                    toolTipLegend: (
                                        <span>
                                            <b>Entregadores:</b>
                                            <br />
                                            Número de telefone para envio de
                                            <br />
                                            mensagens via whatsapp do suporte
                                            <br />
                                            técnico
                                        </span>
                                    ),
                                    placeholder: 'Digite aqui o valor',
                                    name: 'App_Player_Call',

                                    defaultValue:
                                        oProps.defaultValues['App_Player_Call'],
                                },
                                {
                                    type: 'number',
                                    label: 'Prazo pagamento',
                                    toolTipLegend: (
                                        <span>
                                            <b>Financeiro:</b>
                                            <br />
                                            Informe quantos dias após a geração
                                            <br />
                                            da fatura o cliente tem para efetuar
                                            <br />
                                            o pagamento antes do bloqueio da
                                            <br />
                                            conta. Lembrando que a conta do
                                            <br />
                                            cliente será bloqueada 3 dias
                                            <br />
                                            após o vencimento automaticamente.
                                        </span>
                                    ),
                                    placeholder: 'Fatura vence em',
                                    name: 'Fatura_QtdDia_Vencimento',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Fatura_QtdDia_Vencimento'
                                        ],
                                },
                                {
                                    type: 'text',
                                    label: '% de faturamento',
                                    toolTipLegend: (
                                        <>
                                            <span>
                                                <b>Financeiro:</b> <br />
                                                Valor em % que é cobrado do
                                                <br />
                                                estabelecimento em cima do frete
                                                <br />+ tx retorno
                                            </span>
                                        </>
                                    ),
                                    placeholder:
                                        '% a cobrar sobe o valor do frete',
                                    name: 'Fatura_Percentagem_Negociacao',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Fatura_Percentagem_Negociacao'
                                        ],
                                },
                            ],
                        },

                        {
                            gridColumn: '1.5fr 1.5fr 1.5fr 2fr',
                            items: [
                                {
                                    type: 'text',
                                    label: 'Chamar player (raio)',
                                    toolTipLegend:
                                        'Dist. máxima que deve chamar o player em KM',
                                    placeholder: 'Chamar o player',
                                    name: 'DistanciaMaxima_Chamar_Player_km',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'DistanciaMaxima_Chamar_Player_km'
                                        ],
                                },
                                {
                                    type: 'text',
                                    label: 'Chamar player (raio máximo)',
                                    toolTipLegend:
                                        'Dist. máxima que o raio pode chegar em KM quando for aumentando o raio',
                                    placeholder: 'Ex: 7',
                                    name: 'DistanciaMaxima_Chamar_Player_km_Maximo',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'DistanciaMaxima_Chamar_Player_km_Maximo'
                                        ],
                                },
                                {
                                    type: 'text',
                                    label: 'CheckIn cliente (km)',
                                    placeholder: 'Dist. ex. 0.1 = 100m',
                                    toolTipLegend:
                                        'Dist. máxima permitida para dar checkIn no cliente',
                                    name: 'App_Distancia_CheckInOut_Cliente_Metros',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'App_Distancia_CheckInOut_Cliente_Metros'
                                        ],
                                },
                                {
                                    type: 'text',
                                    label: 'CheckIn estabelecimento (km)',
                                    placeholder: 'Dist. ex. 0.1 = 100m',
                                    toolTipLegend:
                                        'Dist. máxima permitida para dar checkIn no estabelecimento',
                                    name: 'App_Distancia_CheckInOut_Unidade_Metros',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'App_Distancia_CheckInOut_Unidade_Metros'
                                        ],
                                },
                            ],
                        },
                        {
                            gridFirstWidth: 0.7,
                            gridColumn: '1.5fr 1.5fr 1.5fr 2fr',
                            items: [
                                {
                                    type: 'text',
                                    label: 'Versão do APP',
                                    placeholder: 'Versão do APP',
                                    name: 'App_Version',
                                    toolTipLegend:
                                        'Versão do APP que o player deve ter instalado em seu celular',
                                    defaultValue:
                                        oProps.defaultValues['App_Version'],
                                },
                                {
                                    type: 'select-simple',
                                    label: 'Sair da escala?',
                                    name: 'Bloquear_Sair_Escala',
                                    toolTipLegend:
                                        'Se o player pode sair da escala ou não',
                                    options: [
                                        {
                                            label: 'Sim',
                                            value: '0',
                                        },
                                        {
                                            label: 'Não',
                                            value: '1',
                                        },
                                    ],
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Bloquear_Sair_Escala'
                                        ],
                                },
                                {
                                    type: 'number',
                                    label: 'Dist. mínima (km)',
                                    toolTipLegend:
                                        'Referente a taxa mínima do modal transporte',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_DistanciaMinima_Km',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_DistanciaMinima_Km'
                                        ],
                                },
                                {
                                    type: 'number',
                                    label: 'Dist. máxima (km)',
                                    toolTipLegend:
                                        'Distância máxima de atendimento',
                                    placeholder: 'Digite aqui o valor',
                                    name: 'Frete_DistanciaMaxima_Km',
                                    defaultValue:
                                        oProps.defaultValues[
                                            'Frete_DistanciaMaxima_Km'
                                        ],
                                },
                            ],
                        },
                        {
                            gridFirstWidth: 0.7,
                            gridColumn: '1.5fr 1.5fr',
                            items: [
                                {
                                    type: 'text',
                                    label: 'Mensagem aviso alto fluxo',
                                    placeholder: 'Mensagem aviso alto fluxo',
                                    name: 'Mensagem_Alto_Fluxo',
                                    toolTipLegend:
                                        'Mensagem que será exibida quando estiver em alto fluxo',
                                    defaultValue: oProps.defaultValues[
                                        'Mensagem_Alto_Fluxo'
                                    ]
                                        ? oProps.defaultValues[
                                              'Mensagem_Alto_Fluxo'
                                          ]
                                        : 'Alta demanda / tempo chuvoso. Valor dinâmico',
                                },
                                {
                                    type: 'select-simple',
                                    label: 'Mostrar aviso alto fluxo?',
                                    name: 'Mostrar_Aviso_Alto_Fluxo',
                                    toolTipLegend:
                                        'Se exibe ou não o aviso de alto fluxo',
                                    options: [
                                        {
                                            label: 'Sim',
                                            value: '1',
                                        },
                                        {
                                            label: 'Não',
                                            value: '0',
                                        },
                                    ],
                                    defaultValue: oProps.defaultValues[
                                        'Mostrar_Aviso_Alto_Fluxo'
                                    ]
                                        ? oProps.defaultValues[
                                              'Mostrar_Aviso_Alto_Fluxo'
                                          ]
                                        : '0',
                                },
                            ],
                        },
                    ]}
                />
            )}
        </SimplesForm>
    );
});
export default ComponentConfiguracaoPadrao;
