import React, { FC, memo } from 'react';
import { WarpLabel, WarpLink, WarpNotLink } from './styl';
import Icone from 'components/Icone';
interface IBotaoMenu {
    onClickSubMenu?: any;
    zIndex?: Number;
    icone?: string;
    titulo: string;
    link?: string;
    submenu?: any[];
    indice: number;
}
const BotaoMenu: FC<IBotaoMenu> = memo(
    ({ onClickSubMenu, link, titulo, zIndex, icone, indice }) => {
        console.log(titulo);
        const Warp = link ? WarpLink : WarpNotLink;
        const WarpProps = link
            ? {
                  to: {
                      pathname: link,
                      state: { zIndex: zIndex || indice },
                  },
                  highlights: titulo === 'Criar coleta' ? true : false,
              }
            : {
                  zIndex: zIndex || indice,
                  onClick: onClickSubMenu,
                  highlights: titulo === 'Criar coleta' ? true : false,
              };

        return (
            <Warp
                {...WarpProps}
                className={`indice-${indice} ${
                    link
                        ? 'item'
                        : indice === 0
                        ? 'grouper first'
                        : 'grouper notfirst'
                }`}>
                {icone && (
                    <div className="icone-menu">
                        <Icone
                            icone={icone}
                            size="2rem"
                        />
                    </div>
                )}

                <p>{titulo}</p>
            </Warp>
        );
    },
);
export default BotaoMenu;
