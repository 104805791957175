import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { ContainerPageSignin, WarpLink } from '../../signin/styl';
import { defTitle } from 'assets/strings';
import ButtonLoading from 'components/atom/ButtonLoading';
import FormCodeInput from 'components/Form/InputCode';
const ComponentSendCodeView: FC<any> = ({
	formRef,
	initialData,
	handleSubmit,
	cacheEmail,
	handlerGotoSendCode,
}) => {
	useEffect(() => {
		document.title = `${defTitle} - Confirmar código`;
	});

	return (
		<ContainerPageSignin>
			<h1>Código de confirmação</h1>
			<p>
				Digite o código de verificação que recebeu no email{' '}
				<b>{cacheEmail}</b> para validar seu código.
			</p>
			<Form
				initialData={initialData}
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<FormCodeInput name="code" />
				<WarpLink>
					<a href="/" onClick={handlerGotoSendCode}>
						Enviar código novamente
					</a>
				</WarpLink>
				<div className="space-button">
					<ButtonLoading value="Alterar senha" loading={false} />
				</div>
				<WarpLink>
					<Link to="/">Lembrei minha senha</Link>
				</WarpLink>
			</Form>
		</ContainerPageSignin>
	);
};
export default ComponentSendCodeView;
