import styled from 'styled-components';
export const Container = styled.header<any>`
    position: relative;
    z-index: 99;
    padding: 2rem 0;
    align-items: center;
    .grupo-categoria-search {
        display: flex;
        flex: 1;
        height: var(--h-input);
        border-radius: var(--def-radius);
        button {
            div {
                margin: auto 5px;
                span {
                    font-size: 18px;
                    @media (max-width: 719.98px) {
                        font-size: 16px;
                    }
                    @media (max-width: 479.98px) {
                        font-size: 14px;
                    }

                }
                @media (max-width: 719.98px) {
                    margin: 0 auto;
                    align-items: center;
                }
            }
        }
        @media (max-width: 719.98px) {
            flex-wrap: wrap;
            height: auto;
        }
    }
`;
