import { useQuery } from '@apollo/client';
import { GRAPHQL_ENQUETE_PROSPECTO } from 'gql/signup';
import { useState, useEffect } from 'react';

export const useProspecto = () => {
	// Dados da enquete do formulário de cadastro
	const { data } = useQuery(GRAPHQL_ENQUETE_PROSPECTO);

	// Id da enquete prospecto
	const [id, setId] = useState(null);

	// Perguntas da enquete prospecto
	const [perguntas, setPerguntas] = useState(null);

	// Dados tratados da enquete recebida
	const [enqueteProspecto, setEnqueteProspecto] = useState([]);

	// Tratando dados recebidos da enquete para apresentar ao usuário
	useEffect(() => {
		if (data) {
			if ('enqueteAtivaProspecto' in data) {
				const perguntas = data.enqueteAtivaProspecto.perguntas.map(
					({ alternativas, ...rest }) => {
						const filter = alternativas.map(({ valor }) => ({
							value: valor,
							label: valor,
						}));
						return {
							...rest,
							alternativas: filter,
						};
					}
				);
				setId(data.enqueteAtivaProspecto.id);
				setPerguntas(data.enqueteAtivaProspecto.perguntas);
				setEnqueteProspecto({
					...data,
					enqueteAtivaProspecto: {
						...data.enqueteAtivaProspecto,
						perguntas,
					},
				});
			}
		}
	}, [data, setId, setPerguntas]);

	// Filtra os dados da enquete
	useEffect(() => {
		if (data && 'enqueteAtivaProspecto' in data) {
			const perguntas = data.enqueteAtivaProspecto.perguntas.map(
				({ alternativas, ...rest }) => {
					const filter = alternativas.map(({ valor }) => ({
						value: valor,
						label: valor,
					}));
					return {
						...rest,
						alternativas: filter,
					};
				}
			);
			setEnqueteProspecto({
				...data,
				enqueteAtivaProspecto: {
					...data.enqueteAtivaProspecto,
					perguntas,
				},
			});
		}
	}, [data]);

	return {
		enqueteProspecto,
		perguntas,
		id,
	};
};
