import { useCallback, useRef } from 'react';
type ITypeInterval = 'second' | 'minute' | 'hour';
export const useCalcTimer = () => {
    const timeStart = useRef<number>(new Date().getTime());
    const timeEnd = useRef<number>(new Date().getTime());
    const start = useCallback(() => {
        timeStart.current = new Date().getTime();
    }, []);
    const end = useCallback(() => {
        timeEnd.current = new Date().getTime();
    }, []);
    const getValue = useCallback((type: ITypeInterval = 'second') => {
        const seconds = (timeEnd.current - timeStart.current) / 1000;
        switch (type) {
            case 'second':
                return seconds;
            case 'minute':
                return seconds / 60;
            default:
                return seconds / 60 / 60;
        }
    }, []);
    return { start, end, getValue };
};
