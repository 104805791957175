import { useQuery, useSubscription } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import {
    ENTREGADOR_SUBSCRIPTION,
    GRAPHQL_LISTAR_ENTREGADOR,
} from 'gql/entregador';
import { useEffect, useMemo } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IEscalas, IQueryEntregador, ISubscriptionEntregador } from './index.d';
export const useQuerySubscriptionFilter = () => {
    const props = usePaginationAndFilter({
        dataKeyName: 'dataEscala',
        keysQueryVariavel: [
            'dataEscala',
            'nomeCompleto',
            'status',
            'telefone',
            'email',
            'turno',
        ],
    });

    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);
    const variables = useMemo(() => {
        const clone = { ...props.variables.filter } as any;

        return { filter: clone };
    }, [props]);

    useSubscription<ISubscriptionEntregador>(ENTREGADOR_SUBSCRIPTION, {
        variables,
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    entregador: { data },
                },
            },
        }) => {
            props.onSubscriptionData(data, ({ prevList, nextChild }) => {
                return prevList.map(item => {
                    if (item.id === nextChild.id) {
                        const dataEscalaPrev = item.escalas as IEscalas[];
                        const dataEscalaNest = nextChild.escalas as IEscalas[];
                        let dataEscala = dataEscalaPrev;

                        if (
                            dataEscalaPrev.length > 0 &&
                            dataEscalaNest.length > 0
                        ) {
                            const dataPrev = dataEscalaPrev[0].data;
                            const dataNest = dataEscalaNest[0].data;
                            if (dataPrev === dataNest) {
                                dataEscala = dataEscalaNest;
                            }
                        }

                        return {
                            ...item,
                            escalas: data.escalas,
                        };
                    }
                    return item;
                });
            });
        },
    });

    const { loading } = useQuery<IQueryEntregador>(GRAPHQL_LISTAR_ENTREGADOR, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables,
        onCompleted: ({ recuperarEntregador }) => {
            props.onCompleted(
                {
                    data: recuperarEntregador.data,
                    hasNextPage: recuperarEntregador.hasNextPage,
                },
                props.context.headers['current-page'] !== '0',
            );
        },
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, dataSelectHeader };
};
