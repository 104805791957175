import React, { FC, useCallback, useEffect, memo, useContext } from 'react';
import PageSignupComponent from './component';

import { SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import { YupUtilsTry, YupUtilsCatch } from 'libs/yup/ShapeValidation';
import { useGetCnpj } from 'hooks/useGetCnpj';
import { SignupContext } from '../context';
import { matchCep, matchCnpj } from 'libs/yup/matches';
const PageSignup: FC = memo(() => {
	const {
		steps: {
			company: { ref: formRef },
		},
		initialDataForm,
		switchComponent,
		loading: loadingSave,
		submit,
	} = useContext(SignupContext);

	const { loading, data, loadBusiness } = useGetCnpj();
	const handleLoadBusiness = useCallback(
		(e) => {
			e.preventDefault();
			loadBusiness(formRef);
		},
		[formRef, loadBusiness]
	);
	useEffect(() => {
		if (data && 'fantasia' in data) {
			const {
				fantasia,
				numero,
				municipio,
				uf,
				cep,
				bairro,
				complemento,
				logradouro,
			} = data;
			formRef.current.setFieldValue('razaoSocial', fantasia);
			formRef.current.setFieldValue('enderecoLogradouro', logradouro);
			formRef.current.setFieldValue('enderecoNumero', numero);
			formRef.current.setFieldValue('enderecoCep', cep);
			formRef.current.setFieldValue('enderecoComplemento', complemento);
			formRef.current.setFieldValue('cidade', municipio);
			formRef.current.setFieldValue('uf', uf);
			formRef.current.setFieldValue('enderecoBairro', bairro);
		}
	}, [data, formRef]);
	const handleSubmit: SubmitHandler<IFormLogin> = async (data, { reset }) => {
		const { setErrors } = formRef.current;
		try {
			await YupUtilsTry(data, setErrors, {
				cnpj: yup.string().matches(matchCnpj, 'CNPJ Inválido'),
				razaoSocial: yup.string().required('Informe a razão social'),
				enderecoCep: yup.string().matches(matchCep, 'CEP inválido'),
			});
			submit({ atual: 'company' });
		} catch (err) {
			YupUtilsCatch(err, setErrors);
		}
	};
	const props: any = {
		formRef,
		loading,
		loadingSave,
		data,
		initialData: initialDataForm[switchComponent],
		handleSubmit,
		handleLoadBusiness,
	};
	return <PageSignupComponent {...props} />;
});
export default PageSignup;
