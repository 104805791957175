import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext, useRef } from 'react';
import { AuthContext } from 'routes/auth.context';
export const useEntregadorRelatorioPgtoSinteticoNotaFiscal = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const currentDate = useRef({ dataInicio: '', dataFim: '' });

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'CPF ou CNPJ',
                        'Nome Completo',
                        'Rua / Avenida',
                        'End. Número',
                        'End. Complemento',
                        'End. Bairro',
                        'End. Cep',
                        'Taxa Jogo Rápido',
                        'Intermediação',
                        'Cobrança',
                    ],
                ];

                response.relatorioPlayerPgtoSintetico.forEach((props) => {
                    content.push([
                        props.cpfCnpj ? props.cpfCnpj.replace(/[^0-9]/g, '') : 'Não informado',
                        props.entregadorNome,
                        props.endLogradouro || 'Não informado',
                        props.endNumero || 'Não informado',
                        props.endComplemento || 'Não informado',
                        props.endBairro || 'Não informado',
                        props.endCEP ? props.endCEP.replace(/[^0-9]/g, '') : 'Não informado',
                        formatarMoeda(props.valorPercNegociado),
                        formatarMoeda(props.valorPercNegociado * 0.3),
                        formatarMoeda(props.valorPercNegociado * 0.7),
                    ]);
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO PLAYER SINTÉTICO NOTA FISCAL ${currentDate.current.dataInicio} à ${currentDate.current.dataFim}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );
    const handleClick = useCallback(
        (variables) => {
            currentDate.current = variables;
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return { handleClick, loading };
};
