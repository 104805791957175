import { useQuery } from '@apollo/client';
import { IRecuperarEntregadoresEscaladosHoje } from 'components/infoPlayer';
import { addHours, format, addMinutes, parse } from 'date-fns';
import {
    COLETA_ENTREGADORES_ESCALADOS,
    COLETA_E_ENTREGADORES_ESCALADOS,
    RECUPERAR_STATUS_COLETA,
} from 'gql/coleta';
import { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSubmitEditarStatus } from './useSubmitEditarStatus';

interface IStatusColeta {
    ativo: boolean;
    corBackground: string;
    corForeground: string;
    dataAlteracao: string;
    entregadorId?: string;
    id: number;
    statusId: number;
    nome: string;
    nomeCompleto: string | null;
    qtdxchamouplayer: number;
}
interface IOption {
    value: string;
    label: string;
}

const allStatus = [
    {
        id: 8,
        nome: 'Salvo',
    },
    {
        id: 1,
        nome: 'Chamando entregador',
    },
    {
        id: 2,
        nome: 'Aceito',
    },
    {
        id: 3,
        nome: 'Checkin Unidade',
    },
    {
        id: 4,
        nome: 'Checkout Unidade',
    },
    {
        id: 5,
        nome: 'Checkin Cliente',
    },
    {
        id: 6,
        nome: 'Concluido',
    },
];
const dataParseSchema = 'yyyy-MM-dd HH:mm:ss';
const statusEmpty: IStatusColeta = {
    dataAlteracao: format(new Date(), dataParseSchema),
    nome: 'Chamando entregador',
    nomeCompleto: null,
    entregadorId: null,
    qtdxchamouplayer: 0,
    statusId: 1,
    corBackground: '#FF0000',
    corForeground: '#FFFFFF',
    id: 0,
    ativo: true,
};

export const useQueryData = () => {
    const { coletaId } = useParams<any>();
    const { data, loading } = useQuery<{
        recuperarEntregadoresPeriodoVigente?: IRecuperarEntregadoresEscaladosHoje[];
        statusColeta?: IStatusColeta[];
    }>(COLETA_E_ENTREGADORES_ESCALADOS, {
        variables: { coletaId },
    });

    const dateCollecttRef = useRef(new Date());
    const [optionSelected, setOptionSelected] = useState({} as IOption);

    const optionsPlayer = useMemo((): IOption[] => {
        if (data && data.recuperarEntregadoresPeriodoVigente) {
            const selected = data.statusColeta[0];
            const result = data.recuperarEntregadoresPeriodoVigente
                .concat([
                    {
                        entregadorId: selected.entregadorId,
                        entregadorNomeCompleto: selected.nomeCompleto,
                        entregadorTelefone: '',
                        entregadorLatitude: 0,
                        entregadorLongitude: 0,
                        entregadorDataGeo: '',
                        entregadorOnline: false,
                    },
                ])
                .map((item) => {
                    return {
                        value: item.entregadorId,
                        label: item.entregadorNomeCompleto,
                    };
                });
            return [...new Map(result.map((m) => [m.value, m])).values()];
        }
        return [];
    }, [data]);

    const statusUnique = useMemo(() => {
        if (data && data.statusColeta) {
            let tmpCtrol: any = {};

            const statusUnique = data.statusColeta
                .filter((item) => {
                    // removendo itens duplicados
                    if (tmpCtrol[item.statusId]) {
                        return false;
                    }
                    // verificando se o item faz parte dos status que vamos atualizar.
                    if (
                        !(
                            item.ativo &&
                            allStatus.findIndex(
                                ({ id }) => id === item.statusId
                            ) !== -1
                        )
                    ) {
                        return false;
                    }
                    // verificando se o status em questão tem duplicação
                    const duplicatedLasted = data.statusColeta.filter(
                        ({ statusId }) => statusId === item.statusId
                    );
                    // Verificando se o status duplicado em questão é o último
                    if (duplicatedLasted.length > 0) {
                        const maxDateColetaStatus = duplicatedLasted.reduce(
                            (prev, next) => {
                                if (!prev.dataAlteracao) {
                                    return next;
                                }

                                const nextDate = parse(
                                    next.dataAlteracao.substring(0, 19),
                                    dataParseSchema,
                                    new Date()
                                );
                                const prevDate = parse(
                                    prev.dataAlteracao.substring(0, 19),
                                    dataParseSchema,
                                    new Date()
                                );
                                if (nextDate.getTime() > prevDate.getTime()) {
                                    return prev;
                                }

                                return next;
                            },
                            {} as IStatusColeta
                        );
                        const prevDate = parse(
                            item.dataAlteracao.substring(0, 19),
                            dataParseSchema,
                            new Date()
                        );
                        const nextDate = parse(
                            maxDateColetaStatus.dataAlteracao.substring(0, 19),
                            dataParseSchema,
                            new Date()
                        );

                        if (nextDate.getTime() > prevDate.getTime()) {
                            return false;
                        }
                    }
                    // salvando dados para controle
                    tmpCtrol[item.statusId] = true;
                    return true;
                })
                .sort((a, b) => {
                    // ordenando do mais novo para o mais velho
                    const nextDate = parse(
                        b.dataAlteracao.substring(0, 19),
                        dataParseSchema,
                        new Date()
                    );
                    const prevDate = parse(
                        a.dataAlteracao.substring(0, 19),
                        dataParseSchema,
                        new Date()
                    );
                    return nextDate.getTime() - prevDate.getTime();
                });

            return statusUnique;
        }

        return [statusEmpty];
    }, [data]);

    useEffect(() => {
        if (optionsPlayer) {
            setOptionSelected(() => {
                const lastedStatus = statusUnique.find((c) => c.entregadorId);
                if (lastedStatus && lastedStatus.entregadorId) {
                    return optionsPlayer.find(
                        (item) => item.value === lastedStatus.entregadorId
                    );
                }
                return optionsPlayer[0];
            });
        }
    }, [optionsPlayer]);

    const handleChangeSelect = useCallback((e) => {
        setOptionSelected(e);
    }, []);

    const response = useMemo(() => {
        if (!optionSelected || statusUnique.length === 0) {
            return [];
        }
        const firstStatus = statusUnique[statusUnique.length - 1];

        let horaStatus = parse(
            firstStatus.dataAlteracao.substring(0, 19),
            dataParseSchema,
            new Date()
        );

        dateCollecttRef.current = new Date(horaStatus);

        return allStatus.map((itemMap, indice) => {
            const itemSelected = statusUnique.find((c) => {
                return c.statusId === itemMap.id;
            });

            if (itemSelected && itemSelected.ativo) {
                horaStatus = parse(
                    itemSelected.dataAlteracao.substring(0, 19),
                    dataParseSchema,
                    new Date()
                );
            } else {
                horaStatus.setSeconds(horaStatus.getSeconds() + 1);
            }

            const cloneDate = new Date(horaStatus);

            return {
                id: itemMap.id,
                nome: itemMap.nome,
                data: cloneDate,
                horaAlteracao: format(cloneDate, 'HH:mm:ss'),
                entregador: optionSelected.label,
            };
        });
    }, [allStatus, statusUnique, optionSelected]);

    const dataSubmit = useSubmitEditarStatus({
        firstDate: dateCollecttRef.current,
        coletaId,
    });

    useEffect(() => {
        if (optionSelected && dataSubmit.ref.current) {
            dataSubmit.ref.current.setFieldValue(
                'entregador',
                optionSelected.value
            );
        }
    }, [dataSubmit, optionSelected]);

    return {
        loading,
        optionsPlayer,
        handleChangeSelect,
        allStatus: response,
        ...dataSubmit,
    };
};
