import { useQuery } from '@apollo/client';
import { usePaginationAndFilter } from 'components/template/ListTemplate/hooks/usePaginationAndFilter';
import { GRAPHQL_RECUPERAR_PROSPECTO } from 'gql/prospecto';
import { useEffect, useMemo } from 'react';
import JSON_DATA_FILTER from '../../data/filter.json';
import { IQueryUnidade } from './index.d';
export const useQueryFilterUnit = () => {
    const dataSelectHeader = useMemo(() => JSON_DATA_FILTER, []);

    const props = usePaginationAndFilter();

    // useSubscription<ISubscriptionUnidade>(GRAPHQL_RECUPERAR_PROSPECTO, {
    //     variables: props.variables,
    //     onSubscriptionData: ({
    //         subscriptionData: {
    //             data: {
    //                 unidade: { data },
    //             },
    //         },
    //     }) => {
    //         props.onSubscriptionData(data);
    //     },
    // });
    const { loading } = useQuery<IQueryUnidade>(GRAPHQL_RECUPERAR_PROSPECTO, {
        fetchPolicy: 'no-cache',
        context: props.context,
        variables: props.variables,
        onCompleted: ({ recuperarProspecto }) => {
            props.onCompleted(recuperarProspecto);
        },
        onError: props.onError,
    });

    useEffect(() => {
        props.setLoading(loading);
    }, [loading]);

    return { ...props, dataSelectHeader };
};
