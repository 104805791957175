import { subDays, subMonths } from 'date-fns';
import { YupUtilsTry } from 'libs/yup/ShapeValidation';
import * as Yup from 'yup';
export const validacao = async ({ setErrors, data }) => {
    const newDate = {
        dataFim: data.dataFim,
        dataInicio: data.dataInicio,
        tipoRelatorio: data.tipoRelatorio,
        dataVencimentoBoleto: data.dataVencimentoBoleto,
        // notasFiscais: data?.notasFiscais?.length,
        boletos: data?.boletos?.length,
    };

    return await YupUtilsTry(newDate, setErrors, {
        dataInicio: Yup.date()
            .min(
                subMonths(subDays(newDate.dataFim, 7), 1),
                `Intervalo não pode ser superior a 37 dias`
            )
            .max(Yup.ref('dataFim'), 'Não pode ser superior a data fim'),
        dataFim: Yup.date().max(
            new Date(),
            'Não pode ser superior a data atual'
        ),
        tipoRelatorio: Yup.string().when('boletos', (boletos, schema) => {
            return schema.test({
                test: (tipoDeRelatorio) => {
                    if (boletos === undefined) {
                        if (!tipoDeRelatorio) {
                            return false;
                        }
                    }
                    return true;
                },
                message: 'É necessário selecionar um tipo de relatório',
            });
        }),
        dataVencimentoBoleto: Yup.date().min(
            new Date(),
            'O vencimento da fatura tem que ser superior a data atual'
        ),
        boletos: Yup.number().test({
            test: (boletos) => {
                if (data.tipoRelatorio && data.boletos) return false;
                return true;
            },
            message: 'Não é possível selecionar o tipo de relatório com os anexos de boleto',
        }),
        // notasFiscais: Yup.number().when(
        //     'dataVencimentoBoleto',
        //     (dtVenc, schema) => {
        //         return schema.test({
        //             test: (qtdNf) => !dtVenc || qtdNf > 0,
        //             message: 'É necessário anexar pelo menos uma nota fiscal',
        //         });
        //     }
        // ),
        // boletos: Yup.number().when('notasFiscais', (nf, schema) => {
        //     return schema
        //         .test({
        //             test: () => {
        //                 if (data.tipoRelatorio && data.boletos) return false;
        //                 return true;
        //             },
        //             message:
        //                 'Não é possível selecionar o tipo de relatório com os anexos de boleto e nota fiscal',
        //         })
        //         .test({
        //             test: (blt) => {
        //                 return nf === blt;
        //             },
        //             message:
        //                 'Cada boleto deve conter sua nota fiscal correspondente',
        //         })
        //         .test({
        //             test: () => {
        //                 return (data.boletos || []).every((value, index) => {
        //                     const nameNf = data.notasFiscais[index].file.name
        //                         .replace(/\D/gi, '')
        //                         .substring(0, 14);
        //                     const nameBlt = value.file.name
        //                         .replace(/\D/gi, '')
        //                         .substring(0, 14);
        //                     return nameBlt === nameNf;
        //                 });
        //             },
        //             message:
        //                 'Os boletos aqui anexados devem ter uma nota fiscal correspondente anexada no campo acima.',
        //         })
        //         .test({
        //             test: () =>
        //                 (data.boletos || []).every((value, index) => {
        //                     const nameBlt = value.file.name.replace(/\D/gi, '');
        //                     return nameBlt.length > 10;
        //                 }),
        //             message:
        //                 'O nome dos boletos e notas fiscais anexados ser o CNPJ do cliente.',
        //         });
        // }),
    });
};
