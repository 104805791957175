import React, { FC, useRef, useCallback, FormEvent } from 'react';

import { SubmitHandler, FormHandles } from '@unform/core';
import ComponentSendCodeView from './Component';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import * as yup from 'yup';
import { matchCode } from 'libs/yup/matches';
const initialData = {
	code: '',
};

const PageSendCode: FC<any> = ({ setSwitchComponent, cacheEmail, setCode }) => {
	const formRef: any = useRef<FormHandles>(null);
	const handleSubmit: SubmitHandler<any> = async (data, { reset }) => {
		const { setErrors } = formRef.current;
		try {
			await YupUtilsTry(data, setErrors, {
				code: yup
					.string()
					.matches(matchCode, 'Código de autorização deve conter 5 números')
					.required('Código de autorização não informada'),
			});
			setCode(data.code);
			setSwitchComponent('new-password');
			reset();
		} catch (err) {
			YupUtilsCatch(err, setErrors);
		}
	};
	const handlerGotoSendCode = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			setSwitchComponent('inform-email-and-receive-code');
		},
		[setSwitchComponent]
	);
	const props: any = {
		initialData,
		formRef,
		cacheEmail,
		setSwitchComponent,
		handlerGotoSendCode,
		handleSubmit,
	};
	return <ComponentSendCodeView {...props} />;
};
export default PageSendCode;
