import { useQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_NOME_USUARIO } from 'gql/usuario';
import { useCallback, useRef, useState } from 'react';
interface IUserNameIdPhoneList {
    id?: string;
    nomeCompleto?: string;
    telefoneCelular?: string;
}
export const useLoadUser = () => {
    const select = useRef(null);

    const [nomeCompleto, setNomeCompleto] = useState('');

    const transformData = useCallback(
        (data: { usuario?: { data?: IUserNameIdPhoneList[] } }) => {
            return data?.usuario?.data?.map(
                ({ id, nomeCompleto: nome, telefoneCelular }, key) => ({
                    value: id,
                    key,
                    label: `${nome} ${
                        telefoneCelular ? ' - ' + telefoneCelular : ''
                    }`,
                })
            );
        },
        []
    );

    const handleFilterData = useCallback(
        (data: { usuario?: { data?: IUserNameIdPhoneList[] } }) => {
            if (select.current && select.current.callBack) {
                const response = transformData(data);
                select.current.callBack(response);
            }
        },
        [select]
    );

    const { data: dataUsuarios } = useQuery<{
        usuario?: {
            data?: IUserNameIdPhoneList[];
        };
    }>(GRAPHQL_LISTAR_NOME_USUARIO, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                nomeCompleto,
            },
        },
        onCompleted: handleFilterData,
    });

    const loadOptions = useCallback((search, callBack) => {
        select.current = { callBack };
        if (search.trim() === '') {
            const response = transformData(dataUsuarios);
            callBack(response);
        } else {
            setNomeCompleto(search);
        }
    }, []);

    return loadOptions;
};
