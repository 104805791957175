import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentClienteEditar: FC<any> = memo((oProps) => {
    useEffect(() => {
        document.title = `${defTitle} - ${oProps.title}`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={oProps.titleSubmit || 'Salvar'}
            titleModal={oProps.title}
            message={null}
        >
            <FormGenerator
                rForm={oProps.rForm}
                childs={[
                    {
                        type: 'text',
                        label: 'Nome',
                        placeholder: 'Nome',
                        name: 'nomeCompleto',
                        defaultValue: oProps.values?.nomeCompleto,
                    },
                    {
                        type: 'number',
                        label: 'Cpf / Cnpj',
                        placeholder: 'Cpf / Cnpj',
                        name: 'cpfCnpj',
                        defaultValue: oProps.values?.cpfCnpj,
                    },
                    {
                        type: 'hidden',
                        label: '',
                        placeholder: '',
                        name: 'tipoPessoa',
                        defaultValue: oProps.values?.tipoPessoa ?? 'F',
                    },
                    {
                        type: 'hidden',
                        label: '',
                        placeholder: '',
                        name: 'endCEP',
                        defaultValue: oProps.values?.endCEP ?? '',
                    },
                    {
                        type: 'hidden',
                        label: '',
                        placeholder: '',
                        name: 'endLogradouro',
                        defaultValue: oProps.values?.endLogradouro ?? '',
                    },
                    {
                        type: 'hidden',
                        label: '',
                        placeholder: '',
                        name: 'endBairro',
                        defaultValue: oProps.values?.endBairro ?? '',
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        placeholder: 'Email',
                        name: 'email',
                        defaultValue: oProps.values?.email,
                    },
                    {
                        gridFirstWidth: 1,
                        items: [
                            {
                                type: 'mask',
                                label: 'Telefone',
                                mask: '(99) 9 9999.9999',
                                name: 'telefone',
                                defaultValue: oProps.values?.telefone,
                            },
                            {
                                type: 'select-simple',
                                label: 'Sexo',
                                placeholder: 'Selecione um sexo',
                                name: 'sexo',
                                options: oProps.aSexo,
                                defaultValue: oProps.sexoSelected,
                            },
                        ],
                    },
                    {
                        type: 'text',
                        label: 'Código da máquina JR',
                        placeholder: 'Código da máquina JR',
                        name: 'machineCode',
                        defaultValue: oProps.values?.machineCode,
                    },
                ]}
            />
        </SimplesForm>
    );
});
export default ComponentClienteEditar;
