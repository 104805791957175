import { FormHandles } from '@unform/core';
import { matchCartao, matchCartaoDataValida, matchCpf } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import * as yup from 'yup';
import ComponentInserirSaldo from './Component';
import { useInserirSaldo } from './hooks/useInserirSaldo';
import { useTypePayment } from './hooks/useTypePayment';
interface IParams {
    unidadeId: string;
}

const PageInserirSaldo: FC = () => {
    const { unidadeId } = useParams<IParams>();
    const { unidadeSelecionada, unidades, alertConfirm } =
        useContext(AuthContext);
    const refUnidadeId = useRef(null);

    useEffect(() => {
        if (unidadeId) {
            refUnidadeId.current = unidadeId;
        } else if (unidadeSelecionada.id) {
            refUnidadeId.current = unidadeSelecionada.id;
        } else if (unidades.length === 1) {
            refUnidadeId.current = unidades[0].id;
        }
    }, [unidadeId, unidadeSelecionada, unidades]);

    const inputs = useTypePayment();
    const { handleInserirSaldo, loading } = useInserirSaldo();
    const rForm = createRef<FormHandles>();

    const handleSubmit = async (data) => {
        const { setErrors } = rForm.current as FormHandles;
        try {
            if (!refUnidadeId.current) {
                throw new Error('Não foi possível identificar a unidade');
            }
            await YupUtilsTry(data, setErrors, {
                titularCartao: yup
                    .string()
                    .min(4, 'Titular deve ter no mínimo 4 caracteres')
                    .required('Informe o nome do titular do cartão'),
                titularCpf: yup
                    .string()
                    .matches(matchCpf, 'CPF inválido')
                    .required('Cpf é obrigatório'),
                cartaoNumero: yup
                    .string()
                    .matches(matchCartao, 'Cartão de crédito inválido')
                    .required('Cartão de crédito é obrigatório'),
                cartaoCvc: yup
                    .string()
                    .min(3, 'CVC inválido')
                    .required('CVC é obrigatório'),
                cartaoValidade: yup
                    .string()
                    .matches(
                        matchCartaoDataValida,
                        'Data de validade do cartão inválido'
                    )
                    .required('Data de validade do cartão é obrigatório'),
            });
            await handleInserirSaldo({
                ...data,
                unidadeId: refUnidadeId.current,
            });
        } catch (err: any) {
            if (err.name !== 'ValidationError') {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: err.message,
                });
            } else {
                YupUtilsCatch(err, setErrors);
            }
        }
    };
    const oProps = {
        inputs,
        loading,
        rForm,
        handleSubmit,
    };
    return <ComponentInserirSaldo {...oProps} />;
};
export default PageInserirSaldo;
