import Icone from 'components/Icone';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Container, Dropdown } from './styl';

interface IDropdownMenu {
    botoes?: {
        titulo?: string;
        placeholder?: string;
        hidden?: boolean;
        disabled?: boolean;
        click?: any;
        data?: object;
        icone: string;
        color: string;
        status?: string;
    }[];
    classe?: string;
    alignRight?: boolean;
}

export const DropdownMenu: FC<IDropdownMenu> = ({
    botoes,
    classe,
    alignRight = false,
}) => {
    const [show, setShow] = useState(false);
    const classes: string[] = ['menu-dropdown'];

    if (alignRight) {
        classes.push('menu-dropdown-right');
    }

    if (show) {
        classes.push('show');
    }

    const closeDropdown = useCallback(
        event => {
            if (
                event.target &&
                event.target.parentNode &&
                event.target.parentNode.classList &&
                event.target.parentNode.classList.contains &&
                !event.target.parentNode.classList.contains('menu-dropdown')
            ) {
                setShow(false);
            }
        },
        [show, setShow],
    );

    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);
        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, [closeDropdown]);

    return (
        <Container className={classe}>
            <button onClick={() => setShow(true)}>
                <Icone icone="icon-dot-vertical" />
            </button>
            <Dropdown className={classes.join(' ')}>
                {botoes.map((botao, index) => {
                    return (
                        <button
                            key={`${index}botoes`}
                            onClick={() => {
                                if (
                                    botao.disabled === undefined ||
                                    botao.disabled === false
                                )
                                    botao.click(botao.data);
                            }}>
                            <Icone icone={botao.icone} />
                            {botao.titulo || botao.placeholder}
                        </button>
                    );
                })}
            </Dropdown>
        </Container>
    );
};
