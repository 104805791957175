export const latLngDist = (lat1, lng1, lat2, lng2) => {
    lat1 = Number(lat1);
    lng1 = Number(lng1);
    lat2 = Number(lat2);
    lng2 = Number(lng2);
    const _min = Math.min(lat1, lng1, lat2, lng2);
    if (_min === 0) {
        return 0;
    }
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (dist < 0) {
        dist *= -1;
    }
    // Convertendo para KM
    dist = dist * 1.609344;
    // Convertendo para metros;
    return Number((dist * 1000).toFixed(2));
};
export const latLngDistKmOrM = (lat1, lng1, lat2, lng2) => {
    const dist = getHaversineDistance({
        lat: lat1,
        lng: lng1
    }, {
        lat: lat2,
        lng: lng2
    });
    if (dist < 1) {
        return dist.toFixed(3).replace('0.', '') + 'm';
    }
    return dist.toFixed(3) + 'km';
};
export const metro2km = (metros) => {
    const km = Number((metros / 1000).toFixed(2));
    if (km < 1) return `${km}m`;
    return `${km}km`;
};
export const metroHumanize = (metros) => {
    const km = Number(metros.toFixed(3));
    if (km < 1) return `${km.toFixed(3)}m`;
    return `${km.toFixed(3)}km`;
};

export const getHaversineDistance = (coords1, coords2) => {

    function toRad(x) {
            return x * Math.PI / 180;
    }

    var dLat = toRad(coords2.lat - coords1.lat);
    var dLon = toRad(coords2.lng - coords1.lng)

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(coords1.lat)) *
            Math.cos(toRad(coords2.lat)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

    return 12742 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}
