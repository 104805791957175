import ListTemplate from 'components/template/ListTemplate';
import React, { FC } from 'react';
import { useTransformEscala } from './hooks/useTransformEscala';
import { useHeader } from './hooks/useHeader';
import { useQuerySubscriptionFilter } from './hooks/useQuerySubscriptionFilter';

const EscalaVagas: FC = () => {
    const props = useQuerySubscriptionFilter();
    const colunas = useHeader();
    const data = useTransformEscala({ escalas: props.recuperarVagasEscala });
    return (
        <ListTemplate
            disableFilter={true}
            addFilterDate={false}
            browserTitle="Players e escalas"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default EscalaVagas;
