import { useLazyQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_ANALITICO_ENTREGADOR } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { addColumn } from '../../uteis/addColumn';
export const useEntregadorRelatorioPgtoAnalitico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_ANALITICO_ENTREGADOR,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Data aceite',
                        'Estabelecimento',
                        'Numero interno',
                        'Modal',
                        'Entregador',
                        'Período',
                        'Tx. retorno',
                        'Adicional Entregador',
                        'Frete',
                        'Frete + Tx. retorno + Adicional Entregador',
                        'Garantido',
                        'H. aceite',
                        'Km Rodado',
                        'H. checkin estabelecimento',
                        'H. checkout estabelecimento',
                        'H. checkin cliente',
                        'H. checkout cliente',
                        '',
                        'Coluna',
                        'Valor',
                    ],
                ];
                let totalTaxaRetorno = 0;
                let totalFrete = 0;
                let totalGarantido = 0;
                let qtdEntregas = 0;

                const emptyLine = Array.from(
                    { length: content[0].length - 2 },
                    (v, k) => '*'
                );
                emptyLine[emptyLine.length - 1] = '';

                response.relatorioEntregadorAnalitico.forEach((props) => {
                    totalGarantido += props.valorGarantido;
                    const dateBr = format(
                        parseISO(props.dataConfirmacaoAceite),
                        `dd/MM/yyyy`
                    );
                    content.push([
                        dateBr,

                        props.unidadeNomeFantasia,
                        props.numeroInterno,
                        props.modalTransporte,
                        props.entregadorNome,
                        props.periodoNome,
                        formatarMoeda(props.valorTaxaRetorno),
                        formatarMoeda((props.valorAdicionalCorridaEntregador || 0)),
                        formatarMoeda(props.valorFrete),
                        formatarMoeda(
                            props.valorFrete + props.valorTaxaRetorno + (props.valorAdicionalCorridaEntregador || 0)
                        ),
                        formatarMoeda(props.valorGarantido),
                        props.dataConfirmacaoAceite
                            ? format(
                                  parseISO(props.dataConfirmacaoAceite),
                                  `HH:mm`
                              )
                            : '',
                            `${(props.distanciaPercorrida || 0).toFixed(3).replace('.', ',')}`,
                        props.coletaDataCheckinUnidade
                            ? format(
                                  parseISO(props.coletaDataCheckinUnidade),
                                  `HH:mm`
                              )
                            : '',
                        props.coletaDataCheckoutUnidade
                            ? format(
                                  parseISO(props.coletaDataCheckoutUnidade),
                                  `HH:mm`
                              )
                            : '',
                        props.coletaDataCheckinCliente
                            ? format(
                                  parseISO(props.coletaDataCheckinCliente),
                                  `HH:mm`
                              )
                            : '',
                        props.coletaDataCheckoutCliente
                            ? format(
                                  parseISO(props.coletaDataCheckoutCliente),
                                  `HH:mm`
                              )
                            : '',
                        '',
                    ]);

                    totalTaxaRetorno += props.valorTaxaRetorno;
                    totalFrete += props.valorFrete;
                    if (props.valorFrete > 0 || props.valorTaxaRetorno > 0) {
                        qtdEntregas++;
                    }
                });
                addColumn({
                    content,
                    columnStart: 16,
                    itens: [
                        {
                            label: 'Valor a pagar',
                            value: formatarMoeda(
                                totalFrete + totalTaxaRetorno + totalGarantido
                            ),
                        },
                        {
                            label: 'Total retorno',
                            value: formatarMoeda(totalTaxaRetorno),
                        },
                        {
                            label: 'Total frete',
                            value: formatarMoeda(totalFrete),
                        },
                        {
                            label: 'Total garantido',
                            value: formatarMoeda(totalGarantido),
                        },
                        {
                            label: 'Quant. entregas',
                            value: qtdEntregas,
                        },
                    ],
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO PLAYER ANALÍTICO ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`
                    )}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );
    const handleClick = useCallback(
        (variables) => {
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return { handleClick, loading };
};
