import React, { FC, memo } from 'react';
import { Container, Icone } from './styl';

export const width = 'minmax(10rem, 1fr)';
export const marginRight = '1rem';
const TableColumnSelect: FC<any> = memo((props) => {
    return (
        <Container>
            <div>
                <p style={{ color: props.color }}>{props.titulo}</p>
            </div>
            <button
                onClick={() => {
                    props.clickStatus(props);
                }}
                data-tip
                data-for="statusEditarTip"
            >
                <Icone icone="icon-selecionar2" />
            </button>
        </Container>
    );
});
export default TableColumnSelect;
