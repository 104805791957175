// eslint-disable-next-line no-use-before-define
import React, {
    FC,
    useContext,
    useCallback,
    useState,
    useRef,
    useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { defTitle } from 'assets/strings';
import { AuthContext } from 'routes/auth.context';
// eslint-disable-next-line import/extensions
import ComponentCalendario from './Component';

const PageCalendario: FC = () => {
    const { disableFutureDates } = useParams<any>();
    const { setCacheFiltroData, cacheFiltroData } = useContext(AuthContext);
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Calendário`;
    });
    const calendarRef = useRef(null);
    const [value, setValue] = useState(new Date(cacheFiltroData));
    const clickDay = useCallback(function callback(data, closeModal) {
        setCacheFiltroData(data);
        closeModal();
    }, []);

    const oProps = {
        value,
        disableFutureDates,
        setValue,
        clickDay,
        calendarRef,
    };
    return <ComponentCalendario {...oProps} />;
};
export default PageCalendario;
