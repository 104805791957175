// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
// eslint-disable-next-line import/extensions
import Filter from './columns/Filter';
// eslint-disable-next-line import/extensions
import Actions from './columns/Actions';
import { Container, Empty, Warp } from './styl';

interface IParams {
    colunas: any;
    styleWarp?: any;
    className?: any;
}
const componentList = {
    filter: Filter,
    actions: Actions,
    empty: Empty,
};
const TableHeader: FC<IParams> = memo(oProps => {
    const colunsWidth = [];
    const columns = oProps.colunas.map(
        (
            {
                tipo,
                colName,
                width,
                marginRight,
                justifyContent = 'flex-end',
                key,
                ...rest
            },
            index,
        ) => {
            const CP = componentList[colName || 'filter'];
            colunsWidth.push(width);
            const pStyl = {};
            if (CP)
                return (
                    <CP
                        {...rest}
                        pStyl={pStyl}
                        marginRight={marginRight}
                        justifyContent={justifyContent}
                        key={`column-name-${key}-${index}`}
                    />
                );
            return null;
        },
    );
    const widthColumns = colunsWidth.join(' ');
    return (
        <Container
            className={oProps.className}
            styleWarp={oProps.styleWarp}>
            <Warp widthColumns={widthColumns}>{columns}</Warp>
        </Container>
    );
});
export default TableHeader;
