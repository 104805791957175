import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { format } from 'date-fns';
import { GRAPHQL_ATUALIZAR_STATUS_COLETA } from 'gql/coleta';
import { StatusName } from 'pages/coleta/listar/constants/status-name-2-id';
import { useCallback, useContext, useRef } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useSubmitEditarStatus = ({ firstDate, coletaId }) => {
    const history = useModalHistory();
    const formRef = useRef<FormHandles>(null);
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizarStatusColeta, { loading: loadingButton }] =
        useMutation(GRAPHQL_ATUALIZAR_STATUS_COLETA);

    const toggleStatus = useCallback((e) => {
        const formData = formRef.current.getData();

        let maxCount = +e.target.value;
        if(maxCount === 8){
            maxCount = 0;
        } else {
            maxCount++;
        }

        const data = formData.data.map((itemMap, indice) => {
            const id = indice + 1;
            if (maxCount >= id) {
                return { ...itemMap, ativo: [`${indice}`] };
            }
            return { ...itemMap, ativo: [] };
        });
        const newData = { ...formData, data };
        console.log(newData);
        formRef.current.setData(newData);
    }, []);

    const handleSubmit: SubmitHandler<any> = useCallback(
        async (data) => {
            try {
                const dateHourNow = +(new Date().getHours() +''+ new Date().getMinutes() +''+ new Date().getSeconds());
                const date = format(firstDate, 'yyyy-MM-dd');
                const dataFiltered = data.data.filter(
                    (item) => item.ativo.length > 0
                );
                let prevHour = 0;
                const lastDataFiltered = dataFiltered[dataFiltered.length - 1];
                const coletaStatus = dataFiltered.map((item) => {
                    const newHour = +item.horario.replace(/\D/g, '');
                    const statusId = +item.ativo[0];
                    if (newHour < prevHour) {
                        throw new Error(
                            `Horário do status "${
                                StatusName[`_${statusId}`]
                            }" está menor do que o status "${
                                StatusName[`_${statusId - 1}`]
                            }"`
                        );
                    }
                    prevHour = newHour;
                    return {
                        statusId,
                        dataAlteracao: `${date} ${item.horario}.776`,
                        entregadorId: data.entregador,
                        coletaId,
                    };
                });
                await handleAtualizarStatusColeta({
                    variables: { coletaStatus },
                });
                history.popTo();
            } catch ({ message }: any) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [history, firstDate]
    );

    return {
        ref: formRef,
        toggleStatus,
        loadingButton,
        onSubmit: handleSubmit,
    };
};
