import { useField } from '@unform/core';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { colourStyles, Container, LabelError } from '../SelectCreatable/styl';

const selectStyles = colourStyles();
const SelectSimple: FC<any> = ({ name, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, registerField, defaultValue, clearError, error } =
        useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            setValue: (refSelect, text) => {
                const value =
                    typeof text === 'string'
                        ? rest.options.find(({ value }) => value === text)
                        : text;
                refSelect.state.inputValue = value;

                if (refSelect.props.onChange) {
                    refSelect.props.onChange(value);
                }
                if (value) {
                    refSelect.select.setValue(value);
                } else {
                    refSelect.select.setValue(null);
                }
            },
            getValue: (refSelect: any) => {
                if (rest.isMulti) {
                    if (!refSelect.state.value) {
                        return [];
                    }
                    return refSelect.state.value.map(
                        (option: OptionTypeBase) => option.value
                    );
                }
                const value = !refSelect.state.value
                    ? ''
                    : refSelect.state.value.value;

                return value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);
    const handlerFocus = useCallback(
        (e) => {
            clearError();
        },
        [clearError]
    );
    const def = (() => {
        if (
            selectRef.current &&
            selectRef.current.state &&
            selectRef.current.state.value
        ) {
            return selectRef.current.state;
        } else if (defaultValue) {
            return defaultValue;
        } else if (rest.defaultValue) {
            return rest.defaultValue;
        }
        return undefined;
    })();

    return (
        <Container erro={error}>
            <Select
                error={error}
                onFocus={handlerFocus}
                styles={selectStyles}
                ref={selectRef}
                classNamePrefix="react-select"
                {...rest}
                defaultValue={def}
            />
            <LabelError erro={error}>{error}</LabelError>
        </Container>
    );
};
export default SelectSimple;
