// REPOST
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import {
    GRAPHQL_CANCELAR_ESCALA,
    GRAPHQL_ESCALAR_ENTREGADOR,
} from 'gql/entregador';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IPropsHandleToogleEscale } from './index.d';

export const useMutationScale = () => {
    const { alertConfirm, cacheFiltroData } = useContext(AuthContext);

    const [handleCancelarEscala] = useMutation(GRAPHQL_CANCELAR_ESCALA);
    const [handleEscalarEntregador] = useMutation(GRAPHQL_ESCALAR_ENTREGADOR);
    const handleToogleEscale = useCallback(
        async ({
            status,
            ativo,
            entregadorId,
            periodoId,
        }: IPropsHandleToogleEscale): Promise<void> => {
            const dataEscala = format(cacheFiltroData, 'yyyy-MM-dd');

            if (['ativo', 'interesse', 'cancelado'].includes(status)) {
                try {
                    if (ativo) {
                        await handleCancelarEscala({
                            variables: {
                                dataEscala,
                                entregadorId,
                                periodoId: Number(periodoId),
                            },
                        });
                    } else {
                        await handleEscalarEntregador({
                            variables: {
                                dataEscala,
                                entregadorId,
                                periodoId: Number(periodoId),
                            },
                        });
                    }
                } catch ({ message }) {
                    await alertConfirm({
                        tipo: 'erro',
                        titulo: 'Alerta',
                        conteudo: message as string,
                    });
                }
            } else {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: 'Não é possivel escalar um entregador inativo',
                });
            }
        },
        [cacheFiltroData]
    );
    return { handleToogleEscale };
};
