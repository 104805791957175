import { matchCnpj } from 'libs/yup/matches';
import * as yup from 'yup';
export const validationYupFields = {
    identificador: yup
        .string()
        .matches(matchCnpj, 'CNPJ Inválido')
        .required('CNPJ não informado'),
    nomeFantasia: yup.string().required('Informe o nome fantasia'),
    razaoSocial: yup.string().required('Informe a razão social'),
    email: yup.string().email('Email inválido').required('Informe um e-mail'),
    nomeResponsavel: yup
        .string()
        .min(3, 'Nome do responsável inválido')
        .max(60)
        .required('Nome do responsável não informado'),
    contatoTipo: yup
        .array()
        .of(yup.string().required('Campo obrigatório'))
        .when('contatoTipoCpf', (_contatoTipoCpf, schema) => {
            return schema.test({
                test: (listContatoTipo) => {
                    return listContatoTipo.indexOf('email-financeiro') !== -1;
                },
                message: 'Informe o e-mail do financeiro',
            });
        }),
    contatoValor: yup.array().of(yup.string().required('Campo obrigatório')),
};
