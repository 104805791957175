import { useMemo } from 'react';

export const useHeader = () => {
    return useMemo(
        () => [
            {
                titulo: 'Perfil de permissão',
                key: 0,
                width: '1fr',
                justifyContent: 'center',
            },
        ],
        []
    );
};
