import styled, { css } from 'styled-components';
import {
    boxShadow,
    botaoCirculo,
    transitionEaseBack,
    botaoCirculoHover,
} from 'routes/styl';
import InputSearchCp from 'components/Form/InputSearchChange';

const inputStyl = css`
    border: 1px solid var(--bg-hover-light);
    border-radius: var(--def-radius);
`;
export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    max-width: 86rem;
    min-width: 86rem;
    padding: 2rem;
    @media (max-width: 960px) {
        max-width: 100%;
        min-width: 100%;
    }
`;
export const Header = styled.header`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    position: relative;
    min-height: 5rem;
    button {
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    button:first-child {
        display: flex;
        align-items: center;
        white-space: nowrap;
        span {
            ${transitionEaseBack};
            margin-left: 0.5rem;
        }
        .font-ic {
            ${botaoCirculo}
        }
        &:hover {
            span {
                transform: translateX(1rem);
            }
            .font-ic::after {
                ${botaoCirculoHover}
            }
        }
    }
    .closeButton {
        padding: 0 1rem;

        ${botaoCirculo}
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    svg {
        width: 2rem;
        height: 2rem;
        path {
            color: var(--btn-admin-icone);
        }
    }

    @media (max-width: 960px) {
        /* flex-direction: column; */
    }
`;
export const Content = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    grid-gap: 1rem;
    overflow-y: auto;
    margin-bottom: 2rem;

    @media (max-width: 960px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }

`;
export const Input = styled.input`
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 0.5rem;
    font-size: var(--size-span);
    background-color: var(--bg-white);
    border: 1px solid var(--bg-hover-light);
`;
export const WarpInput = styled.div`
    width: 500px;
    max-width: 100%;
    height: 3.4rem;
    position: relative;
    margin-left: 20px;
    &:focus-within .font-ic {
        opacity: 0;
    }
    .font-ic {
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
        right: 10px;
        z-index: 2;
    }
    @media (max-width: 960px) {
        width: auto;
    }
`;
export const InputSearchChange = styled(InputSearchCp)<any>`
    ${inputStyl}
`;
