import { useMutation, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import {
    GRAPHQL_BUSCAR_ENTREGADOR,
} from 'gql/entregador';
import { GRAPHQL_ATUALIZAR_DOCUMENTO } from 'gql/entregadorDocumento';
import { IPlayer } from 'pages/banco/formulario';
import {
    createRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';

export const useEntregadorDocumento = () => {
    const { id } = useParams<any>();
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [entregador, setEntregador] = useState<IPlayer>({} as IPlayer);
    const [handleAtualizaDocumento, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_DOCUMENTO
    );

    const atualizaDocumento = useCallback(
        async ({ entregadorId, data }) => {
            await handleAtualizaDocumento({
                variables: {
                    entregadorId,
                    data,
                },
            });
        },
        [handleAtualizaDocumento]
    );

    const { data: responseData } = useQuery<{
        recuperarEntregador?: {
            data?: IPlayer[];
        };
    }>(GRAPHQL_BUSCAR_ENTREGADOR, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id,
            },
        },
    });

    useEffect(() => {
        const entregadorDto = responseData?.recuperarEntregador?.data[0] || {};
        setEntregador(entregadorDto);
    }, [responseData]);

    const rForm = createRef<FormHandles>();
    const handleSubmit = useCallback(
        async (data) => {
            try {
                setEntregador({ ...entregador, ...data });
                await atualizaDocumento({
                    data: {
                        documento: data.documento,
                        approved: data.approved,
                    },
                    entregadorId: id,
                });
                history.goBack();
            } catch ({ message }: any) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            }

        },
        [entregador, rForm, history, id]
    );

    return {
        rForm,
        title: 'Validar documento',
        titleSubmit: 'Validar documento',
        values: entregador,
        handleSubmit,
        loading,
    };
};
