import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, useState } from 'react';
import { Container, Header } from '../adicionar-administrador/styl';
import { useQueryRelatorio } from './hooks/useQueryRelatorio';
import { useSelectOptions } from './hooks/useSelectOptions';
import { useTitle } from './hooks/useTitle';
import { useFormTipoRelatorio } from './hooks/useFormTipoRelatorio';
import DATA_SELECT_PERIODO from './data/selectPeriodo.json';
import DATA_SELECT_FORMA_PAGAMENTO from './data/formaPagamento.json';
const PageRelatorioUnidade: FC = () => {
    const props = useQueryRelatorio();
    const acessoRelatorio = useSelectOptions();
    const { inputTipo, perfilUnidadeAdministrador } = useFormTipoRelatorio();
    const titulo = useTitle();

    const [typeReportSelected, setTypeReportSelected] = useState({
        value: '',
        label: '',
    });

    const hidePaymentSelect =
        [
            'entregadorCorridaIntervalo',
            'entregadorRejeitadasAna',
            'entregadorRejeitadasSin',
        ].indexOf(typeReportSelected.value) !== -1;

    return (
        <Modal
            render={({ closeModal }) => (
                <Container width="80">
                    <Header>
                        <h1>{titulo}</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Form
                        ref={props.formRef}
                        onSubmit={props.handleSubmit}>
                        <FormGenerator
                            childs={[
                                {
                                    gridFirstWidth: 1,
                                    items: [
                                        {
                                            type: 'date',
                                            label: 'Data início',
                                            toolTipLegend:
                                                'Informa data início para gerar o relatório.',

                                            placeholder: 'Data início',
                                            defaultValue: props.dataStart,
                                            // maxDate: props.maxDate,
                                            name: 'dataInicio',
                                        },
                                        {
                                            type: 'date',
                                            label: 'Data fim',
                                            toolTipLegend:
                                                'Informa data fim para gerar o relatório.',

                                            placeholder: 'Data fim',
                                            defaultValue: props.dataEnd,
                                            // maxDate: props.maxDate,
                                            name: 'dataFim',
                                        },
                                    ],
                                },
                                ...(location.pathname.indexOf('/escala/') !==
                                    -1 ||
                                (inputTipo.length <= 1 &&
                                    perfilUnidadeAdministrador)
                                    ? [
                                          {
                                              gridColumn: !hidePaymentSelect
                                                  ? '.7fr 1fr 1.5fr'
                                                  : '1fr 1fr',
                                              items: [
                                                  DATA_SELECT_PERIODO,
                                                  ...(!hidePaymentSelect
                                                      ? [
                                                            DATA_SELECT_FORMA_PAGAMENTO,
                                                        ]
                                                      : []),
                                                  {
                                                      type: 'select-simple',
                                                      label: 'Tipo de relatório',
                                                      toolTipLegend:
                                                          'Escolha o tipo de relatótio que deseja exportar',
                                                      placeholder:
                                                          'Selecione...',
                                                      name: 'tipoRelatorio',
                                                      defaultValue:
                                                          typeReportSelected,
                                                      options: acessoRelatorio,
                                                      onChange: data =>
                                                          setTypeReportSelected(
                                                              data,
                                                          ),
                                                  },
                                              ],
                                          },
                                      ]
                                    : [{}]),
                                ...inputTipo,
                                {
                                    type: 'button',
                                    value: 'Salvar',
                                    loading: props.loading,
                                },
                            ]}
                        />
                    </Form>
                </Container>
            )}
        />
    );
};
export default PageRelatorioUnidade;
