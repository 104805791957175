import { defTitle } from 'assets/strings';
import SuperFiltro from 'components/SuperFiltro';
import TableHeader from 'components/Table/Header';
import TableTd from 'components/Table/Td';

import React, { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import { WarpHeaderFixo } from 'routes/admin/partial/template/styl';
import { useHeader } from './hooks/useHeader';
import { useTransformData } from './hooks/useTransformData';

import { useQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_USUARIO_PERFIL } from '../../../gql/usuario';
import { ContainerPage } from './styl';

const ListarPerfil: FC<any> = ({ loadingSearch = false, onSearch = null }) => {
    const [tooltipMount, setTooltipMount] = useState(false);
    const { data: dataServer } = useQuery(GRAPHQL_LISTAR_USUARIO_PERFIL, {
        fetchPolicy: 'no-cache',
    });

    const colunas = useHeader();

    const data = useTransformData(dataServer);
    const fetchPaginacao = () => {
        /** */
    };
    const hasMore = false;
    useEffect(() => {
        document.title = `${defTitle} - Perfil`;
        const timeout = setTimeout(() => {
            setTooltipMount(true);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <>
            <WarpHeaderFixo>
                <SuperFiltro
                    loadingSearch={loadingSearch}
                    onSearch={onSearch}
                />
                <TableHeader className={'fix-container'} colunas={colunas} />
            </WarpHeaderFixo>
            <ContainerPage>
                <InfiniteScroll
                    dataLength={20}
                    next={fetchPaginacao}
                    loader={
                        <h4 style={{ textAlign: 'center' }}>Carregando...</h4>
                    }
                    hasMore={hasMore}
                >
                    {data.map((data, key) => (
                        <TableTd
                            height={12}
                            className={`fix-container`}
                            colunas={data}
                            key={key}
                        />
                    ))}
                </InfiniteScroll>
            </ContainerPage>
            {tooltipMount && (
                <ReactTooltip
                    html={true}
                    backgroundColor="var(--btn-admin-icone)"
                    id="tooltipBotoesAcao"
                />
            )}
        </>
    );
};
export default ListarPerfil;
