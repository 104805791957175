import { useContext } from 'react';
import { appendPrependString } from 'libs/StringUteis';
import { AuthContext } from 'routes/auth.context';
import { useMutation } from '@apollo/client';
import { IEnderecoQueryCliente, INovoEndereco } from 'types/queryCliente';
import { GRAPHQL_REMOVER_ENDERECO_CLIENTE } from 'gql/cliente';
import { metroHumanize } from 'libs/GeolocationUtils';
import { IClienteEndereco } from 'types/modules/clienteEndereco';
import { ICidade } from 'types/modules/cidade';
export const useDataEndereco = ({
    handleNavigateForms,
    setHasNewAddress,
    handleEditAddress
}: {
    handleNavigateForms: any;
    setHasNewAddress?: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditAddress?: (item: IClienteEndereco & {
        clienteId: string;
        cidade: ICidade & {
            uf: {
                uf: string;
            };
        };
    }) => void;
}) => {
    const [handleRemoverEndereco, { loading }] = useMutation(
        GRAPHQL_REMOVER_ENDERECO_CLIENTE
    );
    const { alertConfirm } = useContext(AuthContext);
    const filterNovo = (item: INovoEndereco) => {
        return item;
    };
    const filterEndereco = (item: IEnderecoQueryCliente) => {
        const freteMoto = item?.frete.find(({ id }) => id === '1');
        return {
            id: item.id,
            titulo: item.enderecoBairro,
            distancia: metroHumanize(
                (freteMoto?.distancia || 0) + (freteMoto?.precisao || 0)
            ),
            subtitulo: `${item.enderecoLogradouro}
      ${appendPrependString(item?.enderecoNumero, ', nº ')}
      ${appendPrependString(item?.enderecoQuadra, ', quadra ')}
      ${appendPrependString(item?.enderecoLote, ', lote ')}
      ${appendPrependString(item?.enderecoComplemento, ', ')}
      ${appendPrependString(item?.enderecoPontoReferencia, ', ')}
      ${appendPrependString(item.enderecoCep, ' - ')}
      `,
            acoes: [
                {
                    classIc: ' icon-editar',
                    action: (e) => handleEdit(e, item),
                },
                {
                    classIc: ' icon-lixeira2',
                    action: (e) => handleExcluir(e, item),
                },
            ],
        };
    };
    const filterData = (data) => {
        return 'titulo' in data ? filterNovo(data) : filterEndereco(data);
    };
    const handleEdit = async (e, item) => {
        e.preventDefault();
        handleEditAddress(item);
    };
    const handleExcluir = async (e, item) => {
        e.preventDefault();
        try {
            const resultConfirm = await alertConfirm({
                tipo: 'info',
                titulo: 'Deletar',
                conteudo: `Deseja realmente deletar este item?`,
            });

            if (resultConfirm) {
                await handleRemoverEndereco({
                    variables: {
                        clienteId: item.clienteId,
                        clienteEnderecoId: item.id,
                    },
                });
            }
        } catch ({ message }: any) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    };
    return { filterData };
};
