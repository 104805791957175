import { FormHandles, SubmitHandler } from '@unform/core';
import { matchCpf } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import { useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import * as yup from 'yup';
import nomeFantasiaEEmailAdministrador from '../data/nome-fantasia-email-administrador.json';
import nomeFinanceiro from '../data/nome-financeiro.json';

export const usePessoaFisica = ({ formRef, selectTab }) => {
    const { alertConfirm } = useContext(AuthContext);
    const handleSubmit: SubmitHandler<any> = async (data) => {
        const { setErrors } = formRef.current as FormHandles;
        try {
            await YupUtilsTry(data, setErrors, {
                nomeResponsavel: yup
                    .string()
                    .min(3, 'Nome do responsável inválido')
                    .max(60)
                    .required('Nome do responsável não informado'),
                identificador: yup
                    .string()
                    .matches(matchCpf, 'CPF Inválido')
                    .required('CPF não informado'),
                email: yup
                    .string()
                    .email('Email inválido')
                    .required('Informe um e-mail'),
                contatoTipo: yup
                    .array()
                    .of(yup.string().required('Campo obrigatório'))
                    .when('contatoTipoCpf', (_contatoTipoCpf, schema) => {
                        return schema.test({
                            test: (listContatoTipo) => {
                                return (
                                    listContatoTipo.indexOf(
                                        'email-financeiro'
                                    ) !== -1
                                );
                            },
                            message: 'Informe o e-mail do financeiro',
                        });
                    }),
                contatoValor: yup
                    .array()
                    .of(yup.string().required('Campo obrigatório')),
            });
            selectTab({
                proximo: 'endereco',
                data,
            });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                YupUtilsCatch(err, setErrors);
            } else {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo:
                        'Para cadastrar um novo cliente é necessário informar o e-mail do departamento financeiro do cliente.',
                });
            }
        }
    };
    return {
        handleSubmit,
        inputs: [
            {
                gridFirstWidth: 1.7,
                items: [
                    nomeFinanceiro,
                    {
                        type: 'mask',
                        label: 'CPF',
                        mask: '999.999.999-99',
                        placeholder: 'Cpf',
                        name: 'identificador',
                    },
                ],
            },
            nomeFantasiaEEmailAdministrador,
        ],
    };
};
