import styled from 'styled-components';
export const stylMap = {
    width: '100%',
    height: '100%',
    borderRadius: 'var(--def-radius)',
};
export const Container = styled.div`
    width: calc(100% - 1.5rem);
    height: calc(100vh - 3rem);
    position: sticky;
    top: 1.5rem;
    border-radius: var(--def-radius);
    text-align: center;
    overflow: hidden;
`;

export const ContainerSearch = styled.form`
    position: absolute;
    top: 1rem;
    grid-template-columns: 1fr 5rem;
    background-color: white;
    z-index: 99999;
    width: 100%;
    left: 1rem;
    display: flex;
    padding-left: 1rem;
    height: var(--h-input);
    border-radius: var(--def-radius);
    max-width: 720px;
`;
export const WarpLoading = styled.div<any>`
    padding: 2rem;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-white);
    margin-left: -78px;
    margin-top: -50px;
    border-radius: 2rem;
    p {
        margin-top: 1rem;
        color: var(--btn-admin-icone);
    }
`;
