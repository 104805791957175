import React, { FC, memo } from 'react';
import { ContainerTd } from './styl';
import * as Columns from '../Columns';

const TableTd: FC<{
    colunas?: any[];
    className?: string;
    propsClassName?: any;
    height?: number;
}> = memo(({ colunas, propsClassName, className, height }) => {
    const colunsWidth = [];
    const columns = colunas?.map(({ tipo, ...rest }, key) => {
        const CP = Columns[tipo];
        colunsWidth.push(rest.width);
        if (CP) return <CP {...rest} key={`column-name-${key}`} />;
        return null;
    });

    const widthColumns = colunsWidth.join(' ');

    return (
        <ContainerTd
            height={height}
            propsClassName={propsClassName}
            className={className}
            widthColumns={widthColumns}
        >
            {columns}
        </ContainerTd>
    );
});
export default TableTd;
