import React, { FC, createRef } from 'react';
import Modal from 'components/modal/base';
import ComponentUnidadesCadastrar from './Component';
import { NavigateProvider } from './context';
import PageEmpresa from './empresa';
import PageEndereco from './endereco';
import { FormHandles } from '@unform/core';
import { useFetchSubmit } from './hooks/useFetchSubmit';
const UnidadesCadastrar: FC = () => {
    const prosSubmit = useFetchSubmit();
    const formularios = {
        dadosEmpresa: {
            component: PageEmpresa,
            nome: 'Empresa',
            width: '60rem',
            initialData: {},
            formRef: createRef<FormHandles>(),
        },
        endereco: {
            props: prosSubmit,
            component: PageEndereco,
            nome: 'Endereço',
            width: '95rem',
            initialData: {},
            formRef: createRef<FormHandles>(),
        },
    };

    return (
        <Modal
            render={({ closeModal }) => (
                <NavigateProvider dataTabs={formularios}>
                    <ComponentUnidadesCadastrar
                        title="Cadastro de estabelecimento"
                        closeModal={closeModal}
                    />
                </NavigateProvider>
            )}
        />
    );
};
export default UnidadesCadastrar;
