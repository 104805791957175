import React, { FC, memo, RefObject } from 'react';
import { Container, ContainerForm, Header } from './styl';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ButtonLoading from 'components/atom/ButtonLoading';
interface IModalSubmitProps {
    reset(): void;
}
interface IModalSimplesForm extends IFCProps {
    rForm: RefObject<FormHandles>;
    handleSubmit(data: any, props: IModalSubmitProps): void;
    loading: boolean;
    initialData?: any;
    titleSubmit: string;
}
const ModalTemplateSimplesForm: FC<IModalSimplesForm> = ({
    rForm,
    handleSubmit,
    loading,
    titleSubmit,
    initialData,
    children,
}) => {
    return (
        <ContainerForm>
            <Form
                ref={rForm}
                initialData={initialData}
                onSubmit={async (data, props) =>
                    await handleSubmit(data, props)
                }
            >
                {children}
                <div className="space-button">
                    <ButtonLoading value={titleSubmit} loading={loading} />
                </div>
            </Form>
        </ContainerForm>
    );
};
export default memo(ModalTemplateSimplesForm);
