import { gql } from '@apollo/client';

export const GRAPHQL_LISTAR_USUARIO = gql`
    query usuario($filter: UsuarioFilter) {
        usuario(filter: $filter) {
            data {
                id
                dataCadatro
                nomeCompleto
                telefoneCelular
                email
                emailConfirmado
                celularConfirmado
                ativo
                perfil {
                    id
                    nome
                    administrador
                }
            }
        }
    }
`;
export const GRAPHQL_LISTAR_USUARIO_E_PERFIL = gql`
    query usuarioPerfil($filter: UsuarioFilter) {
        perfil {
            id
            nome
            administrador
        }
        usuario(filter: $filter) {
            data {
                id
                dataCadatro
                nomeCompleto
                telefoneCelular
                email
                emailConfirmado
                celularConfirmado
                ativo
                perfil {
                    id
                    nome
                    administrador
                }
            }
        }
    }
`;
export const GRAPHQL_USUARIO_SUBSCRIPTION = gql`
    subscription usuario {
        usuario {
            data {
                id
                dataCadatro
                nomeCompleto
                telefoneCelular
                email
                emailConfirmado
                celularConfirmado
                ativo
                perfil {
                    nome
                    administrador
                }
            }
            action
        }
    }
`;
export const GRAPHQL_LISTAR_NOME_USUARIO = gql`
    query usuario($filter: UsuarioFilter) {
        usuario(filter: $filter) {
            data {
                id
                nomeCompleto
                telefoneCelular
            }
        }
    }
`;
export const GRAPHQL_LISTAR_USUARIO_PERFIL = gql`
    query perfilUsuario {
        perfil {
            id
            nome
            administrador
            permissoes {
                resourceId
                incluir
                editar
                excluir
                ler
            }
        }
        resources {
            id
            titulo
            resourceFilhos {
                id
                titulo
            }
        }
    }
`;
export const GRAPHQL_USUARIO_PERMISSOES = gql`
    query perfilUsuario {
        perfil {
            id
            administrador
            permissoes {
                resourceId
                editar
            }
        }
    }
`;
export const GRAPHQL_ALTERNANCIA_ATIVO_USUARIO: any = gql`
    mutation alternanciaAtivoUsuario($usuarioId: ID, $ativo: Boolean) {
        alternanciaAtivoUsuario(usuarioId: $usuarioId, ativo: $ativo)
    }
`;
export const GRAPHQL_ATUALIZAR_USUARIO: any = gql`
    mutation atualizarUsuario($usuarioId: ID!, $data: UsuarioInput) {
        atualizarUsuario(usuarioId: $usuarioId, data: $data)
    }
`;
export const GRAPHQL_ADICIONAR_USUARIO: any = gql`
    mutation adicionarUsuario($data: UsuarioInput) {
        adicionarUsuario(data: $data)
    }
`;
export const GRAPHQL_ALTERAR_SENHA: any = gql`
    mutation alterarSenhaViaEmail($usuarioId: ID!) {
        alterarSenhaViaEmail(usuarioId: $usuarioId)
    }
`;
