import { useCallback, useEffect, useState } from 'react';
import { useCalcTimer } from './useCalcTimer';

export const useWindowFocus = () => {
    const [noCache, setNocache] = useState(0);
    const [status, setStatus] = useState('focus');

    const { start, end, getValue } = useCalcTimer();
    const windowFocus = useCallback(() => {
        end();
        if (
            getValue('minute') >=
            (process.env.NODE_ENV === 'development' ? 100 : 5)
        ) {
            setNocache((value) => value + 1);
        }
        setStatus('focus');
    }, []);
    const windowBlur = useCallback(() => {
        start();
        setStatus('blur');
    }, []);
    useEffect(() => {
        window.addEventListener('focus', windowFocus);
        window.addEventListener('blur', windowBlur);
        return () => {
            window.removeEventListener('focus', windowFocus);
            window.removeEventListener('blur', windowBlur);
        };
    }, [windowFocus, windowBlur]);

    return { windowNoCache: noCache, windowStatus: status };
};
