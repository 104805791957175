import { botaoCirculo, botaoCirculoHover, boxShadow } from 'routes/styl';
import styled from 'styled-components';

export const WarpMap = styled.div`
    max-width: 100%;
    width: 460px;
    height: 400px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
`;
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h1 {
        color: var(--btn-admin-icone);
    }
    button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
`;
export const stylMap = {
    width: '100%',
    height: 400,
};
export const WarpLoading = styled.div<any>`
    padding: 2rem;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-white);
    margin-left: -78px;
    margin-top: -50px;
    border-radius: 2rem;
    p {
        margin-top: 1rem;
        color: var(--btn-admin-icone);
    }
`;
export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 100%;
    max-width: 500px;
    transition: 0.3s width ease-in-out;
    ${boxShadow};
    padding: 2rem 2rem 3rem 2rem;
`;
export const LastedUpdate = styled.p<any>`
    position: absolute;
    bottom: -2rem;

    background-color: ${({ bg }) => bg || 'var(--btn-underline-cor)'};
    color: var(--bg-white);

    border-radius: 10px;
    padding: 1rem 1.5rem;
    font-size: var(--size-span);
    font-family: var(--font);
    text-align: center;
    z-index: 9999;
`;
