import { useLazyQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext, useRef } from 'react';
import { AuthContext } from 'routes/auth.context';
export const useEntregadorRelatorioPgtoSintetico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();
    const currentDate = useRef({ dataInicio: '', dataFim: '' });

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Player',
                        'Qtd. coletas',
                        'Tx. retorno',
                        'Garantido',
                        'Frete',
                        'Valor a pagar',
                        'Tipo de PIX',
                        'Conta PIX',
                        'Tipo Pessoa',
                        'Cpf / Cnpj',
                        'Endereço',
                        'Número',
                        'Complemento',
                        'Bairro',
                        'Cep',
                        'Dados Atualizado',
                    ],
                ];

                response.relatorioPlayerPgtoSintetico.forEach((props) => {
                    content.push([
                        props.entregadorNome,
                        props.qtdeColetas,
                        formatarMoeda(props.valorTotalTaxaRetorno),
                        formatarMoeda(props.valorGarantidoPgto),
                        formatarMoeda(props.valorTotalFrete),
                        formatarMoeda(
                            props.valorTotalTaxaRetorno +
                                props.valorTotalFrete +
                                props.valorGarantidoPgto + (props.valorTotalAdicionalCorridaEntregador || 0)
                        ),
                        props.pixTipo,
                        props.pixConta,
                        props.tipoPessoa || '',
                        props.cpfCnpj || '',
                        props.endLogradouro || '',
                        props.endNumero || '',
                        props.endComplemento || '',
                        props.endBairro || '',
                        props.endCEP || '',
                        props.dadosAtualizado ? 'Sim' : 'Não',
                    ]);
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO PLAYER SINTÉTICO ${currentDate.current.dataInicio} à ${currentDate.current.dataFim}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );
    const handleClick = useCallback(
        (variables) => {
            currentDate.current = variables;
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return { handleClick, loading };
};
