import Icone from 'components/Icone';
import React, { FC, memo } from 'react';
import { Container, WarpLink } from './styl';
const AtomsFooterSocial: FC = () => {
	return (
		<Container>
			<nav>
				<WarpLink to="/" target="_blank">
					<Icone icone="icon-facebook" />
				</WarpLink>
				<WarpLink to="/" target="_blank">
					<Icone icone="icon-instagram" />
				</WarpLink>
				<WarpLink to="/" target="_blank">
					<Icone icone="icon-linkedin" />
				</WarpLink>
				<WarpLink to="/" target="_blank">
					<Icone icone="icon-twitter" />
				</WarpLink>
			</nav>
			<div>
				<span>© 2020</span>
				<WarpLink to="/" target="_blank">
					<Icone icone="icon-sd" />
				</WarpLink>
			</div>
		</Container>
	);
};
export default memo(AtomsFooterSocial);
