import { gql } from '@apollo/client';

const selectClienteColeta = `
    id
    nomeCompleto
    unidade {
        id
        nomeFantasia
    }
    telefoneFixo
    telefoneCelular
    email
    enderecos {
        id
        enderecoCep
        enderecoLogradouro
        enderecoNumero
        enderecoQuadra
        enderecoLote
        enderecoBairro
        enderecoComplemento
        enderecoPontoReferencia
        principal
        latitude
        longitude
        cidade {
            nome
            uf {
                uf
                estado
            }
        }
        frete {
            nome
            id
            distancia
            precisao
            txRetorno
            frete
        }
    }
`;

export const GRAPHQL_FILTER_CLIENTE: any = gql`
    query filtrarCliente($filter: ClienteFilter, $unidadeId: ID) {
        cliente(filter: $filter) {
            id
            nomeCompleto
            telefoneCelular
            telefoneFixo
            email
            enderecos {
                id
            }
        }
        recuperarUnidadeParametros(unidadeId: $unidadeId) {
            chave
            valor
        }
    }
`;

export const GRAPHQL_BUSCAR_ENDERECO_CLIENTE: any = gql`
    query filtrarClienteEndereco($filter: ClienteFilter) {
        cliente(filter: $filter) {
            ${selectClienteColeta}
        }
        recuperarFormaPagamento{
            id
            nome
            exigeTaxaRetorno
        }
    }
`;

export const GRAPHQL_ADICIONAR_CLIENTE: any = gql`
	mutation adicionarCliente($data: ClienteInput) {
		adicionarCliente(data: $data) {
			${selectClienteColeta}
		}
	}
`;

export const GRAPHQL_ADICIONAR_ENDERECO_CLIENTE: any = gql`
    mutation adicionarEnderecoCliente(
        $clienteId: ID!
        $data: ClienteEnderecoInput!
    ) {
        adicionarEnderecoCliente(clienteId: $clienteId, data: $data)
    }
`;

export const GRAPHQL_ATUALIZAR_ENDERECO_COLETA: any = gql`
    mutation atualizarColetaClienteEnderecoId(
        $coletaId: ID!
        $clienteEnderecoId: Int!
    ) {
        atualizarColetaClienteEnderecoId(
            coletaId: $coletaId
            clienteEnderecoId: $clienteEnderecoId
        )
    }
`;

export const GRAPHQL_REMOVER_ENDERECO_CLIENTE: any = gql`
    mutation removerEnderecoCliente($clienteId: ID!, $clienteEnderecoId: ID!, $forceDelete: Boolean) {
        removerEnderecoCliente(
            clienteId: $clienteId
            clienteEnderecoId: $clienteEnderecoId
            forceDelete: $forceDelete
        )
    }
`;

export const GRAPHQL_ACEITAR_NOVO_ENDERECO_CLIENTE: any = gql`
    mutation aceitarNovoEnderecoEnviadoPeloPlayer(
        $coletaId: ID
        $clienteEnderecoId: Int
    ) {
        aceitarNovoEnderecoEnviadoPeloPlayer(
            coletaId: $coletaId
            clienteEnderecoId: $clienteEnderecoId
        )
    }
`;

export const GRAPHQL_REPROVAR_NOVO_ENDERECO_CLIENTE: any = gql`
    mutation reprovarNovoEnderecoEnviadoPeloPlayer(
        $coletaId: ID
        $clienteEnderecoId: Int
    ) {
        reprovarNovoEnderecoEnviadoPeloPlayer(
            coletaId: $coletaId
            clienteEnderecoId: $clienteEnderecoId
        )
    }
`;

export const GRAPHQL_CLIENTE: any = gql`
	query cliente($filter: ClienteFilter) {
		cliente(filter: $filter) {
			${selectClienteColeta}
		}
	}
`;

export const GRAPHQL_ATUALIZAR_CLIENTE: any = gql`
    mutation atualizarClienteContainer($clienteId: ID, $data: ClienteUpdate) {
        atualizarCliente(clienteId: $clienteId, data: $data)
    }
`;

export const GRAPHQL_ATUALIZAR_PAGAMENTO: any = gql`
    mutation alterarPagamento($data: ColetaAlterarPagamento!) {
        alterarPagamento(data: $data)
    }
`;

export const CLIENTE_SUBSCRIPTION: any = gql`
    subscription clienteContainer($unidadeId: String, $clienteId: String) {
        cliente(unidadeId: $unidadeId, clienteId: $clienteId) {
            data {
                id
                nomeCompleto
                telefoneFixo
                telefoneCelular
                email
                enderecos {
                    id
                    enderecoLogradouro
                    enderecoNumero
                    enderecoQuadra
                    enderecoLote
                    enderecoComplemento
                    enderecoBairro
                    enderecoCep
                    enderecoPontoReferencia
                    latitude
                    longitude

                    frete {
                        nome
                        id
                        distancia
                        precisao
                        frete
                    }
                }
            }
            action
        }
    }
`;
