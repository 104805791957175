import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { defTitle } from 'assets/strings';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { Container, Header, stylMap, WarpMap } from './styl';

const PageEditarStatus: FC = () => {
    const { configuracao } = useContext(AuthContext);
    const keyMaps = useMemo(() => {
        return configuracao.find(({ chave }) => chave === 'key_maps').valor;
    }, [configuracao]);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: keyMaps,
    });
    useEffect(() => {
        document.title = `${defTitle} - Localização`;
    });
    const { latitude, longitude } = useParams<any>();

    const lat = Number(latitude);
    const lng = Number(longitude);
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Localização</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <WarpMap>
                        {isLoaded && (
                            <GoogleMap
                                center={{ lat, lng }}
                                zoom={17}
                                mapContainerStyle={stylMap}
                                options={{
                                    // styles: styleMaps,
                                    scrollwheel: true,
                                    streetViewControl: false,
                                    fullscreenControl: true,
                                    mapTypeControl: false,
                                }}
                            >
                                {lat && lng && (
                                    <Marker
                                        position={{
                                            lat,
                                            lng,
                                        }}
                                        draggable={false}
                                    />
                                )}
                            </GoogleMap>
                        )}
                    </WarpMap>
                </Container>
            )}
        />
    );
};
export default PageEditarStatus;
