import ListTemplate from 'components/template/ListTemplate';
import React, { FC } from 'react';
import { useHeaderUnit } from './hooks/useHeaderUnit';
import { useQueryFilter } from './hooks/useQuery';
import { useTransformData } from './hooks/useTransformData';

const PageListMaquinasEntregador: FC<any> = () => {
    console.log('listar entregador maquina');

    
    const props = useQueryFilter();

    const colunas = useHeaderUnit();
    const data = useTransformData(props.response);

    return (
        <ListTemplate
            browserTitle="Máquinas Entregador"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default PageListMaquinasEntregador;
