export const addColumn = ({ content, columnStart, itens }) => {
    const total = content.length;
    if (total >= 0) {
        const collenctionTotal = content[0].length;
        let counter = 1;
        itens.forEach(({ label, value }) => {
            if (counter >= total) {
                const item = Array.from(
                    { length: collenctionTotal },
                    (v, k) => ''
                );
                content.push(item);
            }
            content[counter][columnStart] = label;
            content[counter][columnStart + 1] = value;
            counter++;
        });
    }
    return content;
};
