import styled from 'styled-components';
import { shake } from 'routes/styl';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
export const colourStyles = (params: any = null) => ({
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                ? data.color
                : isFocused
                ? 'var(--bg-hover-light)'
                : null,
            color: isDisabled
                ? 'var(--bg-hover-light)'
                : isSelected || isFocused
                ? 'var(--text-cor)'
                : 'var(--placeholder)',
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontSize: '1.6rem',
            textAlign: 'left',
            ':active': {
                ...styles[':active'],
                color:
                    !isDisabled &&
                    (isSelected ? data.color : 'var(--bg-white)'),
            },
            ':hover': {
                cursor: 'pointer',
            },
            fontWeight: data.novo ? 'bold' : 'normal',
        };
    },
    input: (styles) => ({
        ...styles,
        input: {
            fontSize: '1.6rem!important',
        },
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bg-hover-light)',
        ...(params && params.indicatorSeparator
            ? params.indicatorSeparator
            : {}),
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        ...(params && params.indicatorsContainer
            ? params.indicatorsContainer
            : {}),
        path: {
            fill: 'var(--btn-underline-cor)',
        },
    }),
    control: (styles) => ({
        ...styles,
        height: '4.5rem',
        border: '0!important',
        boxShadow: '0!important',
        '&:hover': {
            border: '0!important',
            boxShadow: '0!important',
        },
        borderRadius: 'var(--def-radius)',
        ...(params && params.control ? params.control : {}),
    }),
    placeholder: (styles) => ({ ...styles, fontSize: '1.6rem' }),
    singleValue: (styles, { data }) => ({
        ...styles,
        cursor: 'pointer',
        fontSize: '1.6rem',
    }),
});
export const Container = styled.div.attrs({ className: 'select' })<any>`
    display: flex;
    height: 7rem;
    flex-direction: column;
    > div {
        z-index: 2;
    }
    &:nth-child(1) {
        z-index: 6;
    }
    &:nth-child(2) {
        z-index: 5;
    }
    &:nth-child(3) {
        z-index: 4;
    }
    &:nth-child(4) {
        z-index: 3;
    }
    &:nth-child(5) {
        z-index: 2;
    }
    > div > div {
        background-color: ${({ erro }) =>
            erro ? 'var(--error-cor-br)' : 'var(--bg-white)'};
        animation: ${({ erro }) => (erro ? shake : '')} 0.5s 1 ease-in-out;
    }
`;
export const LabelError: any = styled.span<any>`
    color: var(--error-cor);
    padding: 0.5rem 1rem 0;
    text-align: right;
    opacity: ${({ erro }) => (erro ? 1 : 0)};
    transition: all 0.4s linear;
    z-index: 3;
`;
