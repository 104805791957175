// eslint-disable-next-line no-use-before-define
import React, { FC, memo } from 'react';
import { Container, IconMenu } from './styl';

const HeaderActions: FC = memo(() => {
    return (
        <Container>
            <button>
                <IconMenu icone="icon-ajuste" />
            </button>
        </Container>
    );
});
export default HeaderActions;
