import { gql } from '@apollo/client';

const argumentos = `
    token
    email
	emailConfirmado
	telefoneCelular
	ativo
    id
	nomeCompleto
    perfil {
        id
      }
`;
export const GRAPHQL_CONECTAR: any = gql`
	mutation usuarioConectar {
		usuarioConectar {
			${argumentos}
		}
	}
`;
export const GRAPHQL_AUTO_LOGIN: any = gql`
	query usuarioAutoConectar{
		usuarioAutoConectar {
			${argumentos}
		}
	}
`;
