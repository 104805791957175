import styled from 'styled-components';

export const ContainerTd = styled.div<any>`
    background-color: white;
    &:nth-child(2n + 1) {
        background-color: var(--bg-white);
    }
    border-bottom: 1px solid var(--bg-hover-light);

    &.bgRed {
        background-color: var(--error-bg-error) !important;
    }
    &.bgOrange {
        background-color: var(--error-cor-br);
    }
    position: relative;
    &.group-search,
    &.group-start {
        min-height: 8rem;
        background-color: var(--btn-bg-small);
        margin-top: 10px;
        border-top: 4px solid var(--btn-admin-icone-hover);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
    }
    &.group-search::after,
    &.group-start::after {
        content: 'AGRUPAMENTO';
        color: var(--btn-admin-icone);
        font-size: 1rem;
        position: absolute;
        font-weight: bold;
        background-color: var(--btn-admin-icone-hover);
        padding: 0rem 1rem 0.4rem 1rem;
        border-bottom-right-radius: 0.4rem;
        text-align: center;
        left: 0;
        top: -1px;
    }
    &.time-collectt::before {
        ${(props) => {
            let response = '';
            if (props && props.propsClassName && props.propsClassName.content) {
                response = props.propsClassName.content;
            }
            if (!response || response.length < 3) {
                return null;
            }
            return `content: "${response}";`;
        }}
        color: var(--bg-white);
        font-size: 1rem;
        position: absolute;
        font-weight: bold;
        background-color: var(--error-cor);
        padding: 0.4rem 0.4rem 0.4rem 1rem;
        border-bottom-right-radius: 0rem;
        border-top-left-radius: 0.4rem;
        border-radius: 0.4rem;
        text-align: center;
        left: 0rem;
        right: auto;
        top: auto;
        bottom: 0px;
    }

    &.group-search {
        min-height: 9rem;
        border-top: 4px solid var(--text-cor);
        border-bottom: 10px solid var(--bg-color);
        background-color: white;
    }
    &.group-search::after {
        content: 'PROCURANDO AGRUPAMENTO';
        background-color: var(--text-cor);
        color: var(--bg-white);
    }
    &.group {
        background-color: var(--btn-bg-small);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
    }
    &.group-end {
        background-color: var(--btn-bg-small);
        margin-bottom: 10px;
        border-bottom: 1px solid var(--btn-admin-icone-hover);
        border-left: 1px solid var(--btn-admin-icone-hover);
        border-right: 1px solid var(--btn-admin-icone-hover);
    }
    &.attention {
        background-color: var(--error-bg-error);
        border-bottom-color: var(--error-cor);
    }
    &.attention.group-start::after {
        background-color: var(--error-cor);
        color: white;
    }
    &.attention.group-end,
    &.attention.group-start {
        border-left: 1px solid var(--error-cor);
        border-right: 1px solid var(--error-cor);
    }
    &.attention.group-end {
        border-bottom-color: var(--error-cor);
    }
    &.attention.group-start {
        border-top-color: var(--error-cor);
    }
    min-height: ${({ height }) => height || 8}rem;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: ${({ widthColumns }) => widthColumns};
    grid-gap: 1px;
    padding-top: 1rem;
    padding-bottom: 1rem;

    text-align: right;
    & > * {
        display: flex;
    }
    /* margin-right: ${({ className }) =>
        className.indexOf('fix-container') >= 0 ? '1.5rem' : 0}; */
`;
