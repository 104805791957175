import Button from 'components/atom/Button';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo } from 'react';
import Calendar from 'react-calendar';
import { Container, Header } from './styl';

const ComponentCalendario: FC<any> = (oProps) => {
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Calendário</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Calendar
                        ref={oProps.calendarRef}
                        onChange={oProps.setValue}
                        onClickDay={(data) => {
                            oProps.clickDay(data, closeModal);
                        }}
                        value={oProps.value}
                        maxDate={oProps.disableFutureDates ? new Date() : null}
                    />
                    <Button
                        className="full-width"
                        onClick={(data) => {
                            oProps.clickDay(new Date(), closeModal);
                        }}
                        value={'Hoje'}
                    />
                </Container>
            )}
        />
    );
};
export default memo(ComponentCalendario);
