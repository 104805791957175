import { useQuery } from '@apollo/client';
import logo from 'assets/images/logo.svg';
import BotaoMenu from 'components/BotaoMenu';
import BotaoSubMenu from 'components/BotaoMenu/SubMenu';
import Icone from 'components/Icone';
import { GRAPHQL_RECUPERAR_RESOURCES_POR_USUARIO } from 'gql/resources';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import StickyBox from 'react-sticky-box';
import {
    ContainerCard,
    LogoMenu,
    ToggleMenu,
    WarpMenu,
    WarpMenuItems,
} from './styl';

const useMenu = ({ handleOpenMenuMobile }) => {
    const [menu, setMenu] = useState<HTMLInputElement>(null);

    const handleClickSubMenu = useCallback(
        inputDom => {
            if (menu !== null) {
                menu.checked = false;
            }
            setMenu(inputDom);
        },
        [menu],
    );
    const closeSubmenu = useCallback(
        event => {
            if (
                event.target &&
                event.target.parentNode &&
                event.target.parentNode.parentNode &&
                event.target.parentNode.parentNode.classList &&
                event.target.parentNode.parentNode.classList.contains &&
                !event.target.parentNode.parentNode.classList.contains('group')
            ) {
                handleClickSubMenu(null);
            }

            if (
                event.target &&
                event.target.closest &&
                ((event.target.closest('.menu') &&
                    event.target.closest('.item')) ||
                    (!event.target.closest('.menu') &&
                        !event.target.closest('.toggle-menu')))
            ) {
                handleOpenMenuMobile(false);
            }
        },
        [handleClickSubMenu, handleOpenMenuMobile],
    );

    useEffect(() => {
        document.addEventListener('click', closeSubmenu);
        return () => {
            document.removeEventListener('click', closeSubmenu);
        };
    }, [closeSubmenu]);

    const renderMenu = (botao, indice) => (
        <BotaoMenu
            {...botao}
            zIndex={100 - indice}
            indice={indice}
            key={`${botao.id}-${indice}`}
        />
    );
    const renderSubmenu = (botao, indice) => (
        <BotaoSubMenu
            zIndex={70 - indice}
            onClickSubMenu={handleClickSubMenu}
            {...botao}
            indice={indice}
            key={`${botao.id}-${indice}`}>
            {botao.resourceFilhos.length > 1 && (
                <ContainerCard>
                    {botao.resourceFilhos.map(renderMenu)}
                </ContainerCard>
            )}
        </BotaoSubMenu>
    );
    const renderHtml = (botao, indice) => {
        if (botao.resourceFilhos.length > 0)
            return renderSubmenu(botao, indice);
        return renderMenu(botao, indice);
    };
    return {
        renderHtml,
    };
};
const MenuAdmin: FC<{
    menuOpen: boolean;
    handleOpenMenuMobile: (open: boolean) => void;
}> = ({ menuOpen, handleOpenMenuMobile }) => {
    const { data: resultQueryData } = useQuery(
        GRAPHQL_RECUPERAR_RESOURCES_POR_USUARIO,
    );
    const { renderHtml } = useMenu({ handleOpenMenuMobile });
    const itensMenu =
        resultQueryData && 'recuperarResourcesPorUsuario' in resultQueryData
            ? resultQueryData.recuperarResourcesPorUsuario
            : [];

    return (
        <>
            <WarpMenu className={menuOpen ? 'menu menu-aberto' : 'menu'}>
                <StickyBox
                    offsetTop={20}
                    offsetBottom={20}
                    style={{
                        zIndex: 6,
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: 'column',
                    }}>
                    <LogoMenu>
                        <img
                            src={logo}
                            className="img-fluid"
                            alt=""
                        />
                        <ToggleMenu onClick={() => handleOpenMenuMobile(false)}>
                            <Icone icone="icon-fechar" />
                        </ToggleMenu>
                    </LogoMenu>
                    <WarpMenuItems>{itensMenu.map(renderHtml)}</WarpMenuItems>
                </StickyBox>
            </WarpMenu>
        </>
    );
};
export default MenuAdmin;
