import { useLazyQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_ANALITICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { addColumn } from '../../uteis/addColumn';
export const useRelatorioMestreAnalitico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv(); //
    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_ANALITICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Data',
                        'Horário aceite',
                        'Estabelecimento',
                        'Numero interno',
                        'Nome entregador',
                        'Nome cliente',
                        'Período',
                        'Produto',
                        'Tx. Retorno',
                        'Frete',
                        'Tx. retorno + frete',
                        'Taxa Jr ( valor )',
                        'Distância Percorrida',
                        'Status de pagamento',
                        '',
                        'Coluna',
                        'Valor',
                    ],
                ];

                let totalProdutos = 0;
                let totalFrete = 0;
                let totalJr = 0;
                let txRetorno = 0;

                response.relatorioEntregasAnalitico.forEach((coleta) => {
                    content.push([
                        format(
                            parseISO(coleta.dataConfirmacaoAceite),
                            `dd/MM/yyyy`
                        ),
                        format(parseISO(coleta.dataConfirmacaoAceite), `HH:mm`),
                        `${coleta.unidadeNomeFantasia} ( ${Math.round(
                            coleta.porcentagemNegociacao * 100
                        )}% )`,
                        coleta.numeroInterno,
                        coleta.entregadorNome,
                        coleta.clienteNome,
                        coleta.periodoNome,
                        formatarMoeda(coleta.valorTotalProdutos),
                        formatarMoeda(coleta.valorTaxaRetorno),
                        formatarMoeda(coleta.valorFrete),
                        formatarMoeda(
                            coleta.valorFrete + coleta.valorTaxaRetorno
                            ),
                        formatarMoeda(coleta.valorPercNegociado),
                        `${(coleta.distanciaPercorrida || 0).toFixed(3).replace('.', ',')}`,
                        coleta.prePago ? 'Pre-pago' : 'Pós-pago',
                        '',
                    ]);

                    totalProdutos += coleta.valorTotalProdutos;
                    if (!coleta.prePago) {
                        txRetorno += coleta.valorTaxaRetorno;
                        totalFrete += coleta.valorFrete;
                        totalJr += coleta.valorPercNegociado;
                    }
                });
                addColumn({
                    content,
                    columnStart: 15,
                    itens: [
                        {
                            label: 'Valor a pagar',
                            value: formatarMoeda(
                                totalFrete + totalJr + txRetorno
                            ),
                        },
                        {
                            label: 'Tx. retorno',
                            value: formatarMoeda(txRetorno),
                        },
                        {
                            label: 'Frete',
                            value: formatarMoeda(totalFrete),
                        },
                        {
                            label: 'Taxa Jr',
                            value: formatarMoeda(totalJr),
                        },
                        {
                            label: 'Quant. entregas',
                            value: response.relatorioEntregasAnalitico.length,
                        },
                        {
                            label: 'Vendas totais',
                            value: formatarMoeda(totalProdutos),
                        },
                    ],
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO ANALÍTICO - ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`
                    )}`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );
    const handleClick = useCallback(
        (variables) => {
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return {
        handleClick,
        loading,
    };
};
