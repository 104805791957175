import { useCallback, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { GRAPHQL_ADICIONAR_PROSPECTO } from 'gql/signup';
import { AuthContext } from 'routes/auth.context';
import { useHistory } from 'react-router-dom';
export const useSubmit = ({
	steps,
	initialDataForm,
	perguntas,
	id: enqueteId,
	setSteps,
}) => {
	const history = useHistory();
	const { alertConfirm } = useContext(AuthContext);

	// Adicionar prospecto
	const [handleAdicionarProspecto, { loading }] = useMutation(
		GRAPHQL_ADICIONAR_PROSPECTO
	);

	// Marca o o step que estiver preenchido corretamente
	const names = Object.keys(steps);
	const _filterStepsSucesso = useCallback(() => {
		let cloneSteps = { ...steps };
		let hasSubmit = true;
		names.forEach((key) => {
			const _sucesso = !!initialDataForm[key];
			if (hasSubmit && !_sucesso) hasSubmit = _sucesso;
			cloneSteps[key].formularioSucesso = _sucesso;
			return _sucesso;
		});
		setSteps(cloneSteps);
		return hasSubmit;
	}, [initialDataForm, names, setSteps, steps]);

	// Cadastro de prospecto
	const submitCadastro = useCallback(async () => {
		const oTmpPost = {
			...initialDataForm.personal,
			...initialDataForm.business,
		};
		const data = {
			...initialDataForm.company,
			nomeResponsavel: `${oTmpPost.nome} ${oTmpPost.sobrenome}`,
			telefone: oTmpPost.telefone,
			email: oTmpPost.email,
			enqueteResposta: perguntas.map(({ id }) => ({
				enqueteId,
				enquetePerguntaId: id,
				resposta: oTmpPost[`select-${id}`],
			})),
		};
		try {
			await handleAdicionarProspecto({
				variables: {
					data,
				},
			});
			history.replace('/');
			await alertConfirm({
				tipo: 'sucesso',
				conteudo:
					'Meus parabéns 👋 seu cadastro está pronto. Logo entraremos em contato',
			});
		} catch ({ message }) {
			await alertConfirm({
				tipo: 'erro',
				conteudo: message,
			});
		}
	}, [
		alertConfirm,
		enqueteId,
		handleAdicionarProspecto,
		history,
		initialDataForm.business,
		initialDataForm.company,
		initialDataForm.personal,
		perguntas,
	]);

	useEffect(() => {
		const sucesso = _filterStepsSucesso();
		if (sucesso) submitCadastro();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDataForm]);
	return {
		loading,
		names,
	};
};
