import { useCallback, useMemo, useState } from 'react';
import { mascaraCpf } from 'libs/MascaraCpf';
import maskPhone from 'libs/maskPhone';
interface ITipoConta {
    pixTipo?: string;
    mask?: string;
    tipo?: string;
    placeholder?: string;
}
interface IInput {
    label: string;
    value: {
        pixTipo: string;
        mask: string;
        tipo: string;
        placeholder: string;
    }
}
export const usePix = () => {
    const [pixTipo, setPixTipo] = useState<ITipoConta>({} as ITipoConta);
    const options = useMemo(
        () => [
            {
                value: {
                    pixTipo: 'telefone',
                    mask: '(99) 9 9999.9999',
                    tipo: 'mask',
                    placeholder: '(xx) x xxxx.xxxx',
                },
                label: 'telefone',
            },
            {
                value: {
                    pixTipo: 'cpf',
                    mask: '999.999.999-99',
                    tipo: 'mask',
                    placeholder: 'xxx.xxx.xxx-xx',
                },
                label: 'CPF',
            },
            {
                value: {
                    pixTipo: 'email',
                    tipo: 'text',
                    placeholder: 'E-mail',
                },
                label: 'E-mail',
            },
            {
                value: {
                    pixTipo: 'aleatorio',
                    tipo: 'text',
                    placeholder: 'Chave aleatória',
                },
                label: 'Chave aleatória',
            },
        ],
        []
    );

    const handleChange = useCallback((data) => {
        if (data && data.value) {
            setPixTipo(data.value);
        }
    }, []);

    const selectPix = useMemo(
        () => ({
            type: 'select-simple',
            label: 'Tipo de chave pix',
            placeholder: 'Selecione',
            name: 'pixTipo',
            onChange: handleChange,
            options,
        }),
        []
    );

    return {
        gridFirstWidth: 0.7,
        items: [
            selectPix,
            {
                type: 'text',
                label: 'Chave pix',
                placeholder: 'sua chave pix',
                name: 'pixConta',
                onKeyUp: (e) => {
                    if(pixTipo.pixTipo === 'cpf' && e.target.value) {
                        const newValue = mascaraCpf(e.target.value);
                        e.target.value = newValue;
                    } else if(pixTipo.pixTipo === 'telefone' && e.target.value) {
                        const newValue = maskPhone(e.target.value);
                        e.target.value = newValue;
                    }

                },
                options: [],
            },
        ],
    };
};
