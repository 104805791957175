import {
    botaoCirculo,
    botaoCirculoHover,
    boxShadow,
    transitionEaseBack,
} from 'routes/styl';
import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 80rem;
    ${boxShadow};
    padding: 2rem;
    p {
        text-align: center;
        padding: 2rem 0;
        color: var(--btn-admin-icone);
        font-size: 1.8rem;
    }
    > span {
        text-align: center;
        margin-bottom: 2rem;
        display: block;
    }
    .space-button {
        margin-top: 2rem;
    }
`;
export const WarpLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
`;
export const Header = styled.header`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    button {
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    button:first-child {
        display: flex;
        align-items: center;
        span {
            ${transitionEaseBack};
            margin-left: 0.5rem;
        }
        .font-ic {
            ${botaoCirculo}
        }
        &:hover {
            span {
                transform: translateX(1rem);
            }
            .font-ic::after {
                ${botaoCirculoHover}
            }
        }
    }
    button:last-child {
        padding: 0 1rem;

        ${botaoCirculo}
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    svg {
        width: 2rem;
        height: 2rem;
        path {
            color: var(--btn-admin-icone);
        }
    }
`;
export const Secao = styled.div`
    border-bottom: 2px solid #e2e2e2;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    h2 {
        font-size: 1.5rem;
        color: var(--btn-admin-icone);
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }
    p,
    p > strong {
        text-align: left;
        font-size: 1.5rem;
        color: var(--text-cor);
        padding: 0;
        margin-bottom: 0.5rem;
    }
    p > strong {
        font-weight: 600;
        min-width: 25%;
        display: inline-block;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
    border: none;
    thead th {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: left;
        color: var(--text-cor);
        background-color: var(--bg-color);
        padding: 1rem;
    }
    tr,
    td {
        padding: 1rem;
        font-size: 1.5rem;
        color: var(--text-cor);
    }

    tr:nth-child(even) {
        border-radius: var(--def-radius);
        background-color: #f2f2f2;
    }
    .inativo {
        td {
            color: var(--placeholder);
        }
        button {
            background: var(--btn-bg-small-inativo);
            color: var(--placeholder);
        }
    }
    width: 100%;
`;
