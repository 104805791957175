import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';

import React, { FC, memo, RefObject, useEffect } from 'react';
import { IPropsNavigateForms } from '..';
import { ContainerForm } from '../../../unidades/modal/create/styl';
import { ICollectData } from '../hooks/useContainerDataForm';
import { useLoadClient } from './hooks/useLoadClient';
import { useSelectClient } from './hooks/useSelectClient';
import { useSubmit } from './hooks/useSubmit';
import ButtonLoading from 'components/atom/ButtonLoading';

interface ICliente {
    id?: string;
    nomeCompleto?: string;
    telefoneCelular?: string;
    telefoneFixo?: string;
    email?: string;
}
export interface IQueryCliente {
    cliente?: ICliente[];
}
export interface IReceiver extends ICollectData {
    formRef: RefObject<FormHandles>;
    handleNavigateForms(props: IPropsNavigateForms): void;
    setMinWidthModal?: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit?: (id: number) => Promise<void>;
}

const PageNavigateSelecionarCliente: FC<IReceiver> = memo(props => {
    const options = useLoadClient(props);
    useEffect(() => {
        props.setMinWidthModal('50rem');
    }, []);

    const { formsChilds, setLoading } = useSelectClient(options);
    const { handleSubmit } = useSubmit(props, options, setLoading);
    return (
        <>
        {/* {typeof options.defaultValue.novo === 'undefined'  && (
            <div style={{
                textAlign: 'center',
                marginTop: '2rem',
            }}>
                <div style={{
                    marginBottom: '2rem'

                }}>
                    <ButtonLoading onClick={() => {
                        options.handleSetDefaultValue({ novo: true, label: 'Clique aqui para cadastrar' });
                    }} value={'Criar novo cliente'}/>
                </div>
                <p style={{
                    fontSize: '2.3rem',
                    fontWeight: 'bold',
                    marginBottom: '0px',
                }}>
                    OU
                </p>
            </div>
        )} */}
        <ContainerForm>
            <Form
                ref={props?.formRef}
                onSubmit={handleSubmit}>
                {!options.defaultValue.novo && (
                    <p
                        style={{
                            fontSize: '2.3rem',
                            fontWeight: 'bold',
                            marginBottom: '0px',
                        }}>
                        Pesquise pelo nome, email ou telefone
                    </p>
                )}
                <FormGenerator childs={formsChilds} />
            </Form>
        </ContainerForm>
        </>
    );
});
export default PageNavigateSelecionarCliente;
