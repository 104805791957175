import styled from 'styled-components';
import { shake } from 'routes/styl';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 38rem;
	height: 8.5rem;
`;
export const LabelError: any = styled.span<any>`
	color: var(--error-cor);
	padding: 1rem 1rem 0;
	text-align: center;
	opacity: ${({ erro }) => (erro ? 1 : 0)};
	transition: all 0.4s linear;
`;
export const WarpInputs: any = styled.div<any>`
	display: flex;
	position: relative;
	overflow-x: hidden;
	justify-content: space-between;
	animation: ${({ erro }) => (erro ? shake : '')} 0.5s 1 ease-in-out;
	> div {
		width: 6rem;
		height: 6rem;
		background: var(--bg-white);
		border-radius: 10px;
		background-color: ${({ erro }) =>
			erro ? 'var(--error-cor-br)' : 'var(--bg-white)'};
		border-radius: var(--def-radius);
	}
`;
export const Input = styled.input<any>`
	width: 100%;
	height: 100%;
	font-size: var(--size-h1);
	text-align: center;
`;
