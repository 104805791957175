import locale from 'date-fns/locale/pt-BR';
import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';

export const useTransformEscala = (props: any) => {
    const history = useModalHistory();
    const newData = useMemo(() => {
        if (!props.escalas) {
            return [];
        }
        return props.escalas.data.map(item => {
            const dataEscala = format(
                parseISO(item.data),
                `eeee, dd 'de' MMMM`,
                {
                    locale,
                },
                );
            return {
                lineClassName: '',
                colunas: [
                    {
                        tipo: 'Texto',
                        width: 'minmax(20rem, 1fr)',
                        titulo: dataEscala,
                    },
                    ...Array.from(
                        item.escala,
                        ({ vagas, vagasPreenchidas, periodoId }) => ({
                            tipo: 'Texto',
                            temaBotao: '2',
                            width: '18rem',
                            flexDirection: 'row',
                            titulo: '',
                            subtitulo: '',
                            botoesInline: [
                                {
                                    icone: 'icon-ajuste',
                                    titulo: `${vagasPreenchidas} de ${vagas} preenchidas`,
                                    status: 'ativo',
                                    click: () => {
                                        history.push(`entregadores/${item.data}/${periodoId}`);
                                        },
                                    data: {},
                                },
                            ],
                        }),
                    ),
                ],
            };
        });
    }, [props.escalas, history]);

    return newData;
};
