import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_ATUALIZAR_CLIENTE } from 'gql/cliente';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useSubmitEditarCliente = () => {
    const { clienteId } = useParams<any>();
    const history = useModalHistory();
    const formRef: any = useRef<FormHandles>(null);
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaCliente, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_CLIENTE
    );

    const handleSubmit: SubmitHandler<any> = useCallback(async (data) => {
        try {
            await handleAtualizaCliente({
                variables: {
                    clienteId,
                    data,
                },
            });
            history.push(`coleta~cadastrar/${clienteId}`);
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    }, []);

    return { ref: formRef, onSubmit: handleSubmit };
};
