import { FormHandles, SubmitHandler } from '@unform/core';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import { useCallback, useEffect } from 'react';
import nomeFantasiaEEmailAdministrador from '../../data/nome-fantasia-email-administrador.json';
import nomeFinanceiro from '../../data/nome-financeiro.json';
import { validationYupFields } from './validation';
import { useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import * as yup from 'yup';
export const usePessoaJuridico = ({
    loadBusiness,
    loading,
    dataCnpj,
    selectTab,
    updateInitialData,
    formRef,
}) => {
    const { alertConfirm } = useContext(AuthContext);
    const handleLoadBusiness = useCallback(
        (e) => {
            e.preventDefault();
            updateInitialData('dadosEmpresa', {});
            loadBusiness(formRef);
        },
        [formRef, loadBusiness]
    );

    useEffect(() => {
        if (dataCnpj && 'fantasia' in dataCnpj) {
            // const value = formRef.current.getFieldValue('contatoValor[0]');
            // if (value.trim() === '') {
            //     if (dataCnpj.telefoneCelular) {
            //         formRef.current.setFieldValue(
            //             'contatoTipo[0]',
            //             'celular-financeiro'
            //         );
            //         formRef.current.setFieldValue(
            //             'contatoValor[0]',
            //             dataCnpj.telefoneCelular
            //         );
            //     } else if (dataCnpj.telefoneFixo) {
            //         formRef.current.setFieldValue(
            //             'contatoTipo[0]',
            //             'fixo-financeiro'
            //         );
            //         formRef.current.setFieldValue(
            //             'contatoValor[0]',
            //             dataCnpj.telefoneFixo
            //         );
            //     }
            // }
            formRef.current.setFieldValue('email', dataCnpj.email);
            formRef.current.setFieldValue('nomeFantasia', dataCnpj.fantasia);
            formRef.current.setFieldValue('razaoSocial', dataCnpj.nome);
            updateInitialData('endereco', {
                enderecoBairro: dataCnpj.bairro,
                enderecoCep: dataCnpj.cep,
                enderecoComplemento: dataCnpj.complemento,
                enderecoLogradouro: dataCnpj.logradouro,
                cidade: dataCnpj.municipio,
                enderecoNumero: dataCnpj.numero,
                uf: dataCnpj.uf,
            });

            if (dataCnpj.representantes.length > 0) {
                formRef.current.setFieldValue(
                    'nomeResponsavel',
                    dataCnpj.representantes[0].value
                );
            }
            formRef.current.setFieldValue('identificador', dataCnpj.cnpj);
        }
    }, [dataCnpj, formRef, updateInitialData]);

    const handleSubmit: SubmitHandler<any> = async (data) => {
        const { setErrors } = formRef.current as FormHandles;
        try {
            await YupUtilsTry(data, setErrors, validationYupFields);
            selectTab({
                proximo: 'endereco',
                data,
            });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                YupUtilsCatch(err, setErrors);
            } else {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo:
                        'Para cadastrar uma nova empresa é necessário informar o e-mail do departamento financeiro do cliente.',
                });
            }
        }
    };

    return {
        handleSubmit,
        inputs: [
            {
                gridFirstWidth: 1,
                items: [
                    {
                        type: 'search',
                        label: 'Cnpj',
                        mask: '99.999.999/9999-99',
                        placeholder: 'Cnpj',
                        name: 'identificador',
                        action: handleLoadBusiness,
                        loading: loading,
                    },
                    {
                        type: 'text',
                        label: 'Razão social',
                        placeholder: 'Razão social',
                        name: 'razaoSocial',
                    },
                ],
            },
            nomeFantasiaEEmailAdministrador,
            nomeFinanceiro,
        ],
    };
};
