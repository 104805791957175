import { addColumn } from './../../uteis/addColumn';
import { useLazyQuery } from '@apollo/client';
import { format, parse } from 'date-fns';
import { GRAPHQL_LISTAR_RELATORIO_SINTETICO } from 'gql/relatorio';
import { useCreateCsv } from 'hooks/useCreateCsv';
import { formatarMoeda } from 'libs/NumberUtils';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';

export const useRelatorioMestreAnaliticoPeriodoSintetico = () => {
    const { alertConfirm } = useContext(AuthContext);
    const { handleGenerateCsv } = useCreateCsv();

    const [handleQuery, { loading }] = useLazyQuery(
        GRAPHQL_LISTAR_RELATORIO_SINTETICO,
        {
            onCompleted: (response) => {
                const content: any = [
                    [
                        'Data',
                        'Período',
                        'Modal',
                        'Nome fantasia',
                        'Qtd. coletas',
                        'Produto',
                        'Tx. Retorno',
                        'Frete',
                        'Pagar player',
                        'Taxa JogoRápido',
                        'Receber do estabelecimento',
                        '',
                        'Coluna',
                        'Valor',
                    ],
                ];
                let totalCorridas = 0;
                let totalFrete = 0;
                let totalTaxaJr = 0;
                let totalProduto = 0;
                let totalTxRetorno = 0;
                let totalAReceber = 0;

                response.relatorioEntregasAnaliticoPeriodoDiaSintetico.forEach(
                    (props) => {
                        content.push([
                            format(
                                parse(props.data, 'yyyy-MM-dd', new Date()),
                                `dd/MM/yyyy`
                            ),
                            props.periodoNome,
                            props.modalTransporte,
                            `${props.nomeFantasia} ( ${props.porcentagemNegociacao}% )`,
                            props.qtdeColetas,
                            formatarMoeda(props.valorProduto),
                            formatarMoeda(props.valorRetorno),
                            formatarMoeda(props.valorFrete),
                            formatarMoeda(props.totalEntregador),
                            formatarMoeda(props.taxaJogoRapido),
                            formatarMoeda(props.totalAReceberUnidade),
                            '',
                        ]);

                        totalTxRetorno += props.valorRetorno;
                        totalCorridas += props.qtdeColetas;
                        totalFrete += props.valorFrete;
                        totalTaxaJr += props.taxaJogoRapido;
                        totalProduto += props.valorProduto;
                        totalAReceber += props.totalAReceberUnidade;
                    }
                );
                addColumn({
                    content,
                    columnStart: 11,
                    itens: [
                        {
                            label: 'Valor a pagar',
                            value: formatarMoeda(totalAReceber),
                        },
                        {
                            label: 'Quant. entregas',
                            value: totalCorridas,
                        },
                        {
                            label: 'Total tx. retorno',
                            value: formatarMoeda(totalTxRetorno),
                        },
                        {
                            label: 'Entregadores',
                            value: formatarMoeda(totalFrete),
                        },
                        {
                            label: 'Taxa Jr',
                            value: formatarMoeda(totalTaxaJr),
                        },
                        {
                            label: 'Vendas totais',
                            value: formatarMoeda(totalProduto),
                        },
                    ],
                });

                handleGenerateCsv({
                    fileName: `RELATÓRIO SINTÉTICO ${format(
                        new Date(),
                        `dd-MM-yyyy HH'h e 'mm'm'`
                    )} ( Este relatório não leva em consideração o garantido)`,
                    content,
                });
            },
            onError: ({ message }) => {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            },
        }
    );

    const handleClick = useCallback(
        (variables) => {
            handleQuery({ variables });
        },
        [handleQuery]
    );
    return {
        handleClick,
        loading,
    };
};
