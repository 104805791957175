import React, { FC } from 'react';
import styled from 'styled-components';
import logo from 'assets/images/logo.svg';
export const Hr = styled.div`
    background: var(--btn-gradient-active-cor);
    height: 0.5rem;
`;
const Container = styled.div`
    margin: 0px auto;
    padding: 25px;
    background-color: white;
    width: 100%;
    max-width: 980px;
    text-align: justify;
    p,
    strong {
        font-size: 1.2rem;
    }
    strong {
        font-weight: bold;
    }
    p {
        margin-bottom: 20px;
    }
    h1 {
        margin-bottom: 15px;
    }
    h3 {
        font-size: 15px;
    }
    h2 {
        font-size: 20px;
    }
    & header {
        display: flex;
        justify-content: flex-end;
        padding: 2.5rem 0 2.5rem 0;
    }
`;
const PoliticaPage: FC<any> = () => {
    return (
        <>
            <Hr />
            <Container>
                <header>
                    <img src={logo} className="img-fluid" alt="" />
                </header>
                <h1>Política de privacidade</h1>
                <p>
                    Nós utilizamos os seus dados e os dados da sua loja como
                    parte da nossa missão de proporcionar um serviço cada vez
                    melhor. Essa Política de Privacidade (
                    <strong>“Política”</strong>) descreve como obtemos,
                    armazenamos, utilizamos e compartilhamos essas informações.
                </p>
                <p>
                    A proteção dos seus dados e da sua privacidade é muito
                    importante para o JogoRápido. Portanto, recomendamos que
                    você conheça melhor as nossas práticas e, em caso de
                    dúvidas, entre em contato conosco.
                </p>
                <p>
                    Você consente, de forma livre e expressa, que seus dados e
                    informações da sua loja sejam coletados, armazenados,
                    tratados e compartilhados, conforme as regras dispostas
                    nesta Política de Privacidade.
                </p>
                <p>
                    Ao acessar a plataforma JogoRápido para Restaurantes, você
                    garante de forma expressa que é plenamente capaz, nos termos
                    da legislação vigente, para exercer e gozar de todos os
                    serviços.
                </p>
                <p>
                    Informamos ainda a você que o serviço prestado pelo
                    JogoRápido para Restaurantes não é direcionado a menores de
                    18 anos.
                </p>
                <h2>Quais tipos de informações coletamos?</h2>
                <p>
                    Ao utilizar o JogoRápido para Restaurantes, nós coletamos
                    algumas informações importantes para que possamos prestar um
                    serviço adequado para você. Abaixo detalhamos quais são
                    essas informações.
                </p>
                <p>
                    Para acessar os serviços do JogoRápido para Restaurantes
                    você deverá ser uma loja parceira do JogoRápido e utilizar
                    seu login e senha de acesso da plataforma de gerenciamento
                    denominada <strong>“Portal do Parceiro”</strong>.
                </p>
                <p>
                    Quando você se inscreve para ser uma loja parceira do
                    JogoRápido, poderemos obter uma série de informações sobre
                    você, tais como: nome empresarial, número de inscrição no
                    CNPJ, endereço eletrônico, endereço de correspondência,
                    dados bancários, senha, número de telefone, preferências de
                    contato e informações similares de titularidade dos seus
                    representantes legais e/ou funcionários que tenham acesso ao
                    JogoRápido para Restaurantes (nome, número de inscrição no
                    CPF, endereço eletrônico, número de telefone, entre outras).
                    Tais informações cadastrais são fornecidas por você no
                    momento da contratação dos serviços prestados pelo
                    JogoRápido e/ou da realização do seu cadastro no Portal do
                    Parceiro, e o tratamento desses dados não é realizado pelo
                    JogoRápido para Restaurantes.
                </p>
                <p>
                    Além dessas informações de login e senha, nós podemos
                    coletar algumas informações automaticamente sobre os
                    dispositivos a partir dos quais você acessa o JogoRápido
                    para Restaurantes. Dentre essas informações, estão endereços
                    IP, tipo de navegador e idioma, provedor de serviços de
                    Internet (ISP), páginas de consulta e saída, sistema
                    operacional, informações sobre data e horário, dados sobre a
                    sequência de cliques, fabricante do dispositivo, operadora,
                    modelo, redes Wi-Fi, número de telefone, entre outras que
                    poderão ser coletadas pelo JogoRápido para Restaurantes.
                </p>
                <p>
                    Nós também poderemos obter informações sobre suas atividades
                    no Aplicativo JogoRápido para Restaurantes. Essas
                    informações são agregadas, portanto anônimas, e usadas para
                    nos ajudar a entender quais partes dos nossos produtos e
                    serviços atraem mais interesse dos usuários. Dados agregados
                    são considerados informações não-pessoais para fins desta
                    Política.
                </p>
                <h2>Como utilizamos essas informações?</h2>

                <h3>Promover, melhorar e desenvolver nossos serviços</h3>
                <p>
                    Podemos usar essas informações coletadas para nos ajudar a
                    desenvolver, oferecer e melhorar os nossos produtos e
                    serviços destinados a você, existentes ou serem criados.
                </p>
                <p>
                    Também poderemos usar suas informações para fins internos,
                    tais como auditoria, análise de dados e pesquisas para
                    aprimorar os produtos, serviços e comunicações com as lojas
                    parceiras, e geração de análises estatísticas com respeito
                    ao uso dos nossos serviços.
                </p>

                <h3>Avaliação de lojas</h3>
                <p>
                    Ao responder comentários das avaliações dos clientes
                    JogoRápido, você nos autoriza a publicar e utilizar tais
                    respostas nos Websites ou nos Aplicativos do JogoRápido e em
                    quaisquer materiais de marketing ou publicidade, bem como a
                    analisar, processar e tratar essa resposta de forma isolada
                    ou agregada nos termos desta Política. Para tanto, apenas
                    identificamos você através do nome da sua loja.
                </p>

                <h3>Suporte</h3>
                <p>
                    Ao enviar mensagens ao suporte do JogoRápido para
                    Restaurantes você garante que as informações, tais como o
                    e-mail e o telefone informados, são da loja e não
                    informações pessoais. Poderemos usar tais informações para
                    responder sua mensagem e entrar em contato com você.
                </p>

                <h3>Comunicação</h3>
                <p>
                    Periodicamente poderemos usar as suas informações para
                    enviar avisos e notificações importantes, como comunicados
                    sobre compras, alterações em prazos, condições e políticas.
                    Como estas informações são importantes para a sua interação
                    com o JogoRápido para Restaurantes, você não poderá optar
                    por não receber esse tipo de comunicação, já que são
                    inerentes e essenciais ao uso do serviço.
                </p>

                <h3>Segurança e Prevenção a Fraude</h3>
                <p>
                    Nós podemos também utilizar esses dados coletados para
                    aprimorar a segurança e melhorar os serviços e as ofertas do
                    JogoRápido destinadas a você. Dessa forma, podemos analisar
                    e solucionar problemas técnicos, bem como identificar e
                    coibir fraudes na utilização do nosso serviço.
                </p>

                <h3>Marketing</h3>
                <p>
                    Nós poderemos usar suas informações e da sua loja para
                    enviar publicidade, bem como outros materiais promocionais
                    voltados ao marketing de nossos serviços e de novidades para
                    você, o que inclui campanhas digitais (tais como marketing
                    direcionado em redes sociais e notificações push) e não
                    digitais (como divulgação em radiodifusão, outdoors,
                    panfletos, entre outros).
                </p>

                <h2>Como as informações são armazenadas?</h2>

                <h3>Onde as informações são armazenadas?</h3>
                <p>
                    As suas informações são armazenadas em serviços de nuvem
                    confiáveis de parceiros que podem estar localizados no
                    Brasil ou em outros países que ofereçam serviço de
                    armazenamento de nuvem confiáveis e usualmente utilizados
                    por empresas de tecnologia, tais como Estados Unidos da
                    América (EUA) e em países da América Latina e da Europa.
                </p>
                <p>
                    Na contratação desses serviços, o JogoRápido sempre busca
                    empresas que empregam alto nível de segurança no
                    armazenamento de suas informações, estabelecendo contratos
                    que não violam as definições de privacidade previstas nessa
                    Política.
                </p>

                <h3>Por quanto tempo as informações são armazenadas?</h3>
                <p>
                    O JogoRápido armazena as suas informações durante o período
                    necessário para as finalidades apresentadas nesta Política,
                    respeitando o período de retenção de dados determinado pela
                    legislação aplicável.
                </p>

                <h2>Como respondemos a requerimentos legais?</h2>
                <p>
                    O JogoRápido também poderá compartilhar suas informações com
                    autoridades policiais ou judiciais, autoridades públicas
                    competentes ou outros terceiros, dentro e fora do país em
                    que você reside, caso seja requerido pela legislação
                    aplicável, por decisão judicial e por requisição de
                    autoridades, ou se necessário para responder a processos
                    judiciais ou para participar em eventuais litígios ou
                    disputas de qualquer natureza.
                </p>
                <p>
                    Nestas situações, o JogoRápido cooperará com as autoridades
                    competentes na medida em que discricionariamente entenda
                    necessário e adequado em relação a qualquer investigação de
                    ilícitos, infrações a direitos de propriedade industrial ou
                    intelectual, ou outra atividade que seja ilegal ou que possa
                    expor o JogoRápido ou seus usuários a qualquer
                    responsabilidade legal ou lhes impor riscos, ressalvadas
                    hipóteses de sigilo de informações constantes na legislação
                    aplicável.
                </p>
                <p>
                    Além disso, o JogoRápido se reserva o direito de
                    compartilhar informações sobre seus usuários a terceiros
                    quando houver motivos suficientes para considerar que a
                    atividade de um usuário é suspeita, ilegal ou prejudicial ao
                    JogoRápido ou a terceiros.
                </p>
                <p>
                    Este direito será utilizado pelo JogoRápido quando
                    considerar apropriado ou necessário para manter a
                    integridade e a segurança do seu serviço, para o cumprimento
                    do seu contrato com o JogoRápido e para cumprimento desta
                    Política, e com o intuito de cooperar com a execução e
                    cumprimento da lei, independentemente de existir ou não uma
                    ordem judicial ou administrativa para tanto.
                </p>

                <h2>Como protegemos suas informações?</h2>
                <p>
                    O JogoRápido emprega seus melhores esforços para respeitar e
                    proteger suas informações pessoais contra perda, roubo ou
                    qualquer modalidade de uso indevido, bem como contra acesso
                    não autorizado, divulgação, alteração e destruição.
                </p>
                <p>
                    Utilizamos o princípio de privacy by design, respeitando a
                    sua privacidade e protegendo seus dados nos nossos processos
                    internos como um todo.
                </p>
                <p>
                    Só tratamos os seus dados mediante alto grau de segurança,
                    implementando as melhores práticas em uso na indústria para
                    a proteção de dados, tais como técnicas de criptografia,
                    monitoração e testes de segurança periódicos. Contudo, não é
                    possível garantir completamente a não ocorrência de
                    interceptações e violações dos sistemas e bases de dados do
                    JogoRápido, uma vez que a internet possui sua estrutura de
                    segurança em permanente aperfeiçoamento.
                </p>

                <h2>
                    Como iremos notificá-lo em caso de mudanças a essa Política?
                </h2>
                <p>
                    O JogoRápido poderá atualizar essa Política periodicamente,
                    sendo que a versão em vigor será sempre a mais recente. Se
                    fizermos alguma alteração na Política em termos materiais,
                    podemos colocar um aviso nos nossos Websites, Aplicativos,
                    Portal do Parceiro ou te enviar um e-mail, juntamente com a
                    Política atualizada. Por isso é sempre importante manter
                    seus dados de contato atualizados.
                </p>

                <h2>
                    Como entrar em contato com JogoRápido em caso de dúvidas?
                </h2>
                <p>
                    Se você tiver algum questionamento ou dúvida com relação à
                    Política de Privacidade do JogoRápido ou qualquer prática
                    aqui descrita, entre em contato conosco através da nossa
                    página de suporte na Plataforma.
                </p>
            </Container>
        </>
    );
};
export default PoliticaPage;
