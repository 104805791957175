import React, { FC } from 'react';
import ComponentClienteEditar from './Component';
import { useEntregadorAlterar } from './hooks/useEntregadorAlterar';

const PageEditarEntregador: FC = () => {
    const oProps = useEntregadorAlterar();

    return <ComponentClienteEditar {...oProps} />;
};
export default PageEditarEntregador;
