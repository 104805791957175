import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { ENTREGADOR_ALTERAR_STATUS } from 'gql/entregador';
import { useCallback, useContext } from 'react';
import { AuthContext } from 'routes/auth.context';
import { IRetriever } from './index.d';
export const useMutationScaledTogglePlayer = (): IRetriever => {
    const { alertConfirm, cacheFiltroData } = useContext(AuthContext);
    const [toggleActive, { loading: loadingToogleActive }] = useMutation(
        ENTREGADOR_ALTERAR_STATUS
    );

    const handleToogleActive = useCallback(
        async ({ entregadorId, ativo }) => {
            const dataEscala = format(cacheFiltroData, 'yyyy-MM-dd');
            const variables = {
                entregadorId,
                ativo: !ativo,
                dataEscala,
            };
            try {
                await toggleActive({
                    variables,
                });
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Alerta',
                    conteudo: message as string,
                });
            }
        },
        [toggleActive, cacheFiltroData]
    );
    return {
        handleToogleActive,
        loadingToogleActive,
    };
};
