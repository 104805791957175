import { Form } from '@unform/web';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryData } from './hooks/useQueryData';
import { useSubmitEditarCliente } from './hooks/useSubmitEditarCliente';
import { Container, Header } from './styl';
import dataInput from './data/inputs.json';
const PageEditarClienteColeta: FC<any> = () => {
    const { fieldName } = useParams<any>();
    const { loading, data } = useQueryData();
    const { ref, ...unformProps } = useSubmitEditarCliente();
    useEffect(() => {
        if (data && data[fieldName] && ref.current) {
            ref.current.setFieldValue(fieldName, data[fieldName]);
        }
    }, [data, ref]);
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Editar cliente</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {<CircularLoading show={loading} />}

                    {!loading && (
                        <Form ref={ref} {...unformProps}>
                            <p>
                                Atualizar o campo {dataInput[fieldName].label}
                            </p>
                            <FormGenerator
                                childs={[
                                    dataInput[fieldName],
                                    {
                                        type: 'button',
                                        value: 'Salvar',
                                        loading: false,
                                    },
                                ]}
                            />
                        </Form>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PageEditarClienteColeta);
