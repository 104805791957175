import { FormHandles } from '@unform/core';
import { createRef, RefObject, useCallback, useContext, useState } from 'react';
import { AuthContext } from 'routes/auth.context';
export interface ICollectData {
    id?: string;
    clienteId?: string;
    unidadeId?: string;
    nomeCompleto?: string;
    endereco?: string;
    modalTransporteId?: number;
    dataPedido?: string;
    valorTotalProdutos?: number;
    valorFrete?: number;
    statusId?: number;
    latitude?: number;
    longitude?: number;
    distancia?: number;
    precisao?: number;
    frete?: number;
}
interface IReturn {
    formRef: RefObject<FormHandles>;
    dataForm: ICollectData;
    handleSetDataForm(data?: any): void;
}
export const useContainerDataForm = (): IReturn => {
    const { unidadeSelecionada } = useContext(AuthContext);
    const formRef = createRef<FormHandles>();
    const [dataForm, setDataForm] = useState<ICollectData>({
        unidadeId: unidadeSelecionada?.id,
    });

    const handleSetDataForm = useCallback(
        (data = null) => {
            const dados: ICollectData = data || formRef?.current?.getData();

            for (const key in dados) {
                if (
                    typeof dados[key] === 'string' &&
                    dados[key].trim() === ''
                ) {
                    delete dados[key];
                }
            }

            setDataForm({ ...dataForm, ...dados });
        },
        [formRef, dataForm]
    );

    return { formRef, dataForm, handleSetDataForm };
};
