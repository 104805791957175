import styled, { css } from 'styled-components';
import {} from 'routes/styl';

import InputPasswordCp from 'components/Form/Input/InputPassword';
import InputCp from 'components/Form/Input';
import TextAreaCp from 'components/Form/Input/TextArea';
import InputMaskCp from 'components/Form/Input/InputMask';
import InputSearchCp from 'components/Form/InputSearch';
import InputMoneyCp from 'components/Form/Input/InputMoney';
import { cssButtonCircular } from 'components/Table/Columns/styl';

const inputStyl = css`
    border: 1px solid var(--bg-hover-light);
    border-radius: var(--def-radius);
`;
export const ZIndex = styled.div<any>`
    z-index: ${({ zIndex }) => zIndex || 1};
    div > .react-select__control {
        border: 1px solid var(--bg-hover-light) !important;
    }
`;
export const ZIndexInline = styled.div<any>`
    z-index: ${({ zIndex }) => zIndex || 1};
    div > div {
        display: flex;
        flex-direction: row;
        padding-left: 0px;
    }
    div > div > label {
        margin-right: 10px;
    }
`;
export const Input = styled(InputCp)<any>`
    ${inputStyl}
`;

export const TextArea = styled(TextAreaCp)<any>`
    ${inputStyl}
`;
export const InputMask = styled(InputMaskCp)<any>`
    ${inputStyl}
`;
export const InputSearch = styled(InputSearchCp)<any>`
    ${inputStyl}
`;
export const InputMoney = styled(InputMoneyCp)<any>`
    ${inputStyl}
`;
export const InputPassword = styled(InputPasswordCp)<any>`
    ${inputStyl}
`;
export const WarpLabel = styled.div`
    display: flex;
    justify-content: space-between;
    button {
        cursor: pointer;
        div {
            margin-top: -0.7rem;
        }
    }
    button ~ div {
        p {
            color: white !important;
            text-align: center;
        }
    }
`;
export const Grid = styled.div<any>`
    display: grid;
    grid-gap: 2rem;
    position: relative;

    z-index: ${({ zIndex }) => zIndex || 1};
    ${({ style }) => style};
    grid-template-columns: ${({ flexStart, gridColumn }) => {
        if (gridColumn) return gridColumn;
        return `${flexStart}fr 1fr`;
    }};
    .coluna {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 479.98px) {
        display: flex;
        flex-wrap: wrap;
        grid-gap: inherit;
        .coluna {
            width: 100%;
        }
    }
`;

export const FragmentCustom = styled.div<any>`
    width: 100%;
    label {
        padding: 0px;
        margin-bottom: 10px;
        width: 100%;
    }
    label.label_label {
        padding-left: 10px;
        width: 100%;
    }
`;
export const ButtonAction = styled.button<any>`
    > .font-ic {
        position: static !important;
    }
    ${cssButtonCircular}
`;
export const WarpButton = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
