import {
    botaoCirculo,
    botaoCirculoHover,
    boxShadow,
    transitionEaseBack,
} from 'routes/styl';
import styled from 'styled-components';

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 50rem;
    ${boxShadow};
    padding: 2rem;
    p {
        text-align: center;
        padding: 2rem 0;
        color: var(--btn-admin-icone);
        font-size: 1.8rem;
    }
    > span {
        text-align: center;
        margin-bottom: 2rem;
        display: block;
    }
    .space-button {
        margin-top: 2rem;
    }
`;
export const Header = styled.header`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    button {
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    button:first-child {
        display: flex;
        align-items: center;
        span {
            ${transitionEaseBack};
            margin-left: 0.5rem;
        }
        .font-ic {
            ${botaoCirculo}
        }
        &:hover {
            span {
                transform: translateX(1rem);
            }
            .font-ic::after {
                ${botaoCirculoHover}
            }
        }
    }
    button:last-child {
        padding: 0 1rem;

        ${botaoCirculo}
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
    svg {
        width: 2rem;
        height: 2rem;
        path {
            color: var(--btn-admin-icone);
        }
    }
`;
