import { FormHandles } from '@unform/core';
import { createRef, RefObject, useCallback, useMemo, useState } from 'react';
interface ITabs {
    titulo?: string;
    nome?: string;
    formularioSucesso?: boolean;
    selected?: boolean;
}
interface IPropsFormTabSuccess {
    current: number;
    success: boolean;
}
interface IReturn {
    tabs: ITabs[];
    indexSelected: number;
    itemSelected: ITabs;
    handleSelectTab(indexTabNext: number): void;
    handleFormTabSuccess(props: IPropsFormTabSuccess): void;
}
export const useTabs = (): IReturn => {
    const [tabs, setTabs] = useState<ITabs[]>([
        {
            titulo: 'Cliente',
            nome: 'cliente',
            formularioSucesso: false,
        },
        {
            titulo: 'Endereço',
            nome: 'endereço',
            formularioSucesso: false,
        },
        {
            titulo: 'Finalizar pedido',
            nome: 'finalizar',

            formularioSucesso: false,
        },
    ]);
    const handleSelectTab = useCallback((indexTabNext) => {
        setTabs((prevTabs) =>
            prevTabs.map((item, key) => {
                item.selected = indexTabNext === key;
                return item;
            })
        );
    }, []);
    const handleFormTabSuccess = useCallback((props: IPropsFormTabSuccess) => {
        setTabs((prevTabs) => {
            const index = prevTabs.findIndex((_, key) => props.current === key);
            if (index !== -1) {
                prevTabs[index].formularioSucesso = props.success;
            }
            return prevTabs;
        });
    }, []);
    const indexSelected = useMemo(() => {
        const indice = tabs.findIndex(({ selected }) => selected);
        return indice >= 0 ? indice : 0;
    }, [tabs]);
    return {
        tabs,
        indexSelected,
        itemSelected: tabs[indexSelected],
        handleSelectTab,
        handleFormTabSuccess,
    };
};
