import React, { FC, memo, useEffect } from 'react';
import Modal from 'components/modal/base';
import Icone from 'components/Icone';
import { defTitle } from 'assets/strings';
import { Container, Header } from './styl';

const ComponentEnderecoEditar: FC<any> = memo(({ children }) => {
    useEffect(() => {
        document.title = `${defTitle} - Editar endereço`;
    });
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Editar Endereço</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {children}
                </Container>
            )}
        />
    );
});
export default ComponentEnderecoEditar;
