import { gql } from '@apollo/client';
// REPOST
const gqlDefault = `
	id
	nomeCompleto
	email
	telefone
    connection
    geoLatitude
    geoLongitude
    status
    sdkVersion
    appVersion
    modalTransporteId
    modalTransporteNome
    comMaquininhaJr
    dataCadastro
    recebeColeta
    machineCode
	escalas {
		periodo {
			id
			nome
			horaInicio
			horaFim
			ativo
		}
		data
		dataRegistro
        tenhoInteresse
        periodoId
		dataEncerramento
		dataCancelamento
	}

`;
export const GRAPHQL_LISTAR_ENTREGADOR: any = gql`
    query queryRecuperarEntregador($filter: EntregadorFiltro) {
        recuperarEntregador(filter: $filter) {
            data {
                ${gqlDefault}
                documentos {
                    documento
                    pathArquivo
                    approved
                }
            }
            hasNextPage
        }
    }
`;

export const GRAPHQL_BUSCAR_ENTREGADOR: any = gql`
    query queryRecuperarEntregador($filter: EntregadorFiltro) {
        recuperarEntregador(filter: $filter) {
            data {
                id
                nomeCompleto
                sexo
                email
                sdkVersion
                appVersion
                machineCode
                tipoPessoa
                cpfCnpj
                endCEP
                endLogradouro
                endBairro
                telefone
                contaBancaria {
                    id
                    titularNome
                    titularCpf
                    contaBanco {
                        id
                        nome
                    }
                    contaAgencia
                    contaAgenciaDv
                    contaNumero
                    contaNumeroDv
                    contaTipo
                    pixTipo
                    pixConta
                }
                documentos {
                    documento
                    pathArquivo
                    approved
                }
            }
            hasNextPage
        }
    }
`;

export const ENTREGADOR_SUBSCRIPTION: any = gql`
    subscription entregador{
        entregador {
            data{
                ${gqlDefault}
            }
            action
        }
    }
`;

export const GRAPHQL_BUSCAR_ENTREGADOR_ESCALADO: any = gql`
    mutation entregadorEscaladoContainer(
        $unidadeId: ID!
        $distanciaMaxima: Int!
        $entregadorNome: String
    ) {
        entregadorEscalado(
            unidadeId: $unidadeId
            distanciaMaxima: $distanciaMaxima
            entregadorNome: $entregadorNome
        ) {
            ${gqlDefault}
        }
    }
`;

export const GRAPHQL_ADICIONAR_ENTREGADOR: any = gql`
    mutation adicionarEntregador($data: AddEntregadorInput!) {
        adicionarEntregador(data: $data)
    }
`;
export const GRAPHQL_TOTAL_ESCALADO_ENTREGADOR: any = gql`
    query {
        totalEntregadorEscalado
    }
`;

export const GRAPHQL_RECUPERAR_VIEWPORT: any = gql`
    query {
        recuperarViewPort{
            totalEntregadoresEscalados {
                nomeCompleto
            }
            totalEntregadoresOnline {
                nomeCompleto
            }
            totalEntregadoresOffline{
                nomeCompleto
            }
            totalEntregadoresOcupados{
                nomeCompleto
            }
            totalEntregadoresOcupadosOnline{
                nomeCompleto
            }
            totalEntregadoresOcupadosOffline{
                nomeCompleto
            }
            totalEntregadoresDesocupados{
                nomeCompleto
            }
            totalEntregadoresDesocupadosOnline{
                nomeCompleto
            }
            totalEntregadoresDesocupadosOffline{
                nomeCompleto
            }
            coletasNaoConcluidas{
                ultimoDataStatus
                coletaId
                entregadorId
                numeroInterno
                clienteNomeCompleto
                clienteLatitude
                clienteLongitude
                unidadeNomeCompleto
                unidadeLatitude
                unidadeLongitude
            }
            coletasSemPlayer
        }
    }
`;

export const GRAPHQL_ATUALIZAR_ENTREGADOR: any = gql`
    mutation atualizarEntregador(
        $entregadorId: ID!
        $data: EntregadorAtualizarInput!
    ) {
        atualizarEntregador(entregadorId: $entregadorId, data: $data)
    }
`;

export const GRAPHQL_ATUALIZAR_ENTREGADOR_CONTA: any = gql`
    mutation atualizarEntregadorConta(
        $entregadorId: ID!
        $data: EntregadorContaInput!
    ) {
        atualizarEntregadorConta(entregadorId: $entregadorId, data: $data)
    }
`;

export const GRAPHQL_ESCALAR_ENTREGADOR: any = gql`
    mutation escalarEntregador(
        $dataEscala: String!
        $entregadorId: ID!
        $periodoId: Int!
    ) {
        escalarEntregador(
            dataEscala: $dataEscala
            entregadorId: $entregadorId
            periodoId: $periodoId
        )
    }
`;

export const GRAPHQL_CANCELAR_ESCALA: any = gql`
    mutation cancelarEscalaEntregador(
        $dataEscala: String!
        $entregadorId: ID!
        $periodoId: Int!
    ) {
        cancelarEscalaEntregador(
            dataEscala: $dataEscala
            entregadorId: $entregadorId
            periodoId: $periodoId
        )
    }
`;

export const ENTREGADOR_ALTERAR_STATUS: any = gql`
    mutation alternanciaAtivoEntregador(
        $entregadorId: ID!
        $ativo: Boolean!
        $dataEscala: String!
    ) {
        alternanciaAtivoEntregador(
            entregadorId: $entregadorId
            ativo: $ativo
            dataEscala: $dataEscala
        )
    }
`;
export const ENTREGADOR_ALTERAR_MODAL: any = gql`
    mutation modalEntregadorToogle($input: InputModalIdplayerId) {
        modalEntregadorToogle(input: $input)
    }
`;
export const ENTREGADOR_ALTERAR_MAQUININHA_JR: any = gql`
    mutation maquininhaEntregadorToogle($input: InputMaquininhaPlayerId) {
        maquininhaEntregadorToogle(input: $input)
    }
`;
export const ENTREGADOR_LOCATION_SUBSCRIPTION: any = gql`
    subscription entregadorLocation($entregadorId: [ID]) {
        entregadorLocation(entregadorId: $entregadorId) {
            data {
                entregadorId
                latitude
                longitude
                connection
                dateGeoUpdated
            }
            action
        }
    }
`;
export const ENTREGADOR_LOCATION_QUERY: any = gql`
    query recuperarEntregadorLocalizacao($entregadorId: ID!) {
        recuperarEntregadorLocalizacao(entregadorId: $entregadorId) {
            latitude
            longitude
            dateGeoUpdated
        }
    }
`;
export const GRAPHQL_ATENCAO_ENTREGADOR: any = gql`
    mutation chamarPlayer($coletaId: ID) {
        chamarPlayer(coletaId: $coletaId)
    }
`;

export const GRAPHQL_ENTREGADOR_UPDATE_LAT_LNG: any = gql`
    query queryAppEntregadorUpdLatLng(
        $entregadorId: ID!
        $lat: Float!
        $lng: Float!
        $perfilId: Int!
        $connection: String
    ) {
        appEntregadorUpdLatLng(
            entregadorId: $entregadorId
            lat: $lat
            lng: $lng
            perfilId: $perfilId
            connection: $connection
        )
    }
`;
export const GRAPHQL_LISTAR_ENTREGADORES_ESCALADOS: any = gql`
    query entregadoresEscala($entregadorId: [ID], $data: String!, $periodoId: Int) {
        entregadorEscala(entregadorId: $entregadorId, data: $data, periodoId: $periodoId) {
            entregadorId
            periodo {
                id,
                nome,
                horaInicio,
                horaFim
            }
            data
            dataCancelamento
            entregador {
                nomeCompleto
                telefone
            }
        }
    }
`;
export const GRAPHQL_TOGGLE_ENTREGADOR_ESCALA_STATUS: any = gql`
    mutation toggleEntregadorEscalaStatus($dataEscala: String!, $periodoId: Int!, $entregadorId: String!) {
        toggleEntregadorEscalaStatus(dataEscala: $dataEscala, periodoId: $periodoId, entregadorId: $entregadorId)
    }
`;

export const ENTREGADOR_ALTERAR_RECEBE_COLETA: any = gql`
    mutation toogleRecebeColeta(
        $entregadorId: ID!
        $recebeColeta: Boolean!
    ) {
        toogleRecebeColeta(
            entregadorId: $entregadorId
            recebeColeta: $recebeColeta
        )
    }
`;
