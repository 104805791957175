import { botaoCirculo, botaoCirculoHover, boxShadow } from 'routes/styl';
import styled from 'styled-components';

export const WarpMap = styled.div`
    width: 460px;
    min-height: 90px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    p {
        text-align: center;
        margin-top: 2rem;
        span {
            color: red;
        }
    }
`;
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    h1 {
        color: var(--btn-admin-icone);
    }
    button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
`;
export const stylMap = {
    width: '100%',
    height: 400,
};

export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
    width: 500px;
    transition: 0.3s width ease-in-out;
    ${boxShadow};
    padding: 2rem 2rem 2rem 2rem;
`;
