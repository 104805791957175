import { useMutation } from '@apollo/client';
import { GRAPHQL_CONFIGURACOES_UNIDADE_PARAMETROS } from 'gql/unidade';
import { useCallback } from 'react';
import { IUnidade } from '../../../../types/modules/unidade';

export const useToggleActiveColeta = () => {
    const [toggleActive, { loading: loadingToogleColeta }] = useMutation(
        GRAPHQL_CONFIGURACOES_UNIDADE_PARAMETROS
    );
    const handleToogleColeta = useCallback(
        ({ id, unidadeParametro }: IUnidade) => {
            const typeBlockColeta = unidadeParametro.find(
                ({ chave }) => chave === 'BLOCK::COLETA'
            )?.valor;
            const boolenColeta = typeBlockColeta === 'NO' || !typeBlockColeta ? 'YES' : 'NO';
            const unidadeParametroFilter = unidadeParametro?.filter(
                ({ chave }) => chave !== 'BLOCK::COLETA'
            ).map(({ chave, valor }) => ({ chave, valor }));
            toggleActive({
                variables: { unidadeId: id, data: [
                    ...unidadeParametroFilter,
                    {
                        chave: 'BLOCK::COLETA',
                        valor: boolenColeta,
                    },
                ] },
            });
        },
        [toggleActive]
    );
    return {
        handleToogleColeta,
        loadingToogleColeta,
    };
};
