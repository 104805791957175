import { matchTelefone } from 'libs/yup/matches';
import * as yup from 'yup';

export const yupValidationProps = {
    Frete_TaxaMinima_Valor_Moto: yup
        .number()
        .min(1, 'O valor mínimo deve ser de R$ 1,00')
        .required('O valor é obrigatório'),
    Frete_TaxaMinima_Valor_Moto_Retorno: yup
        .number()
        .min(1, 'O valor mínimo deve ser de R$ 1,00')
        .required('O valor é obrigatório'),
    // Frete_TaxaMinima_Valor_Carro: yup
    //     .number()
    //     .min(1, 'O valor mínimo deve ser de R$ 1,00')
    //     .required('O valor é obrigatório'),
    Frete_Adicional_Km_Moto: yup
        .number()
        .min(1, 'O valor mínimo deve ser de R$ 1,00')
        .required('O valor é obrigatório'),
    Frete_Adicional_Km_Moto_Retorno: yup
        .number()
        .min(0, 'O valor mínimo deve ser de R$ 1,00')
        .required('O valor é obrigatório'),
    // Frete_Adicional_Km_Carro: yup
    //     .number()
    //     .min(1, 'O valor mínimo deve ser de R$ 1,00')
    //     .required('O valor é obrigatório'),
    // Unidade_TaxaRetorno_Valor_Moto: yup
    //     .number()
    //     .min(1, 'Taxa de retorno deve ser maior que R$ 5,00')
    //     .required('Informe a quantidade mínima de chamadas de player'),
    // Unidade_TaxaRetorno_Valor_Carro: yup
    //     .number()
    //     .min(1, 'Taxa de retorno deve ser maior que R$ 5,00')
    //     .required('Informe a quantidade mínima de chamadas de player'),
    Entregador_Qtd_Chamadas_Nao_Atendidas: yup
        .number()
        .min(1, 'Qtd. mínima 1x (20seg)')
        .max(9, 'Qtd. mínima 9x (3min)')
        .required('Informe a quantidade de chamadas para a coleta'),

    App_Player_Call: yup
        .string()
        .length(16, 'Whatsapp inválido')
        .matches(matchTelefone, 'Telefone Inválido')
        .required('Informe a quantidade mínima de chamadas de player'),
    Fatura_QtdDia_Vencimento: yup
        .number()
        .min(
            2,
            'Uma fatura só pode ser gerada com pelo menos 2 dias de prazo para pagamento'
        )
        .max(
            30,
            'Uma fatura só pode ser gerada com no máximo 30 dias de prazo para pagamento'
        )
        .required(
            'Informe quantos dias o cliente tem para pagar uma fatura após a data de envio'
        ),
    DistanciaMaxima_Chamar_Player_km: yup
        .number()
        .min(0, 'Distância informada menor que o valor mínimo ( 0 ) permitido')
        .max(
            25,
            'Distância informada maior que o valor máximo ( 25 ) permitido'
        )
        .required('Informe a distância em KM máxima de atendimento'),
    Fatura_Percentagem_Negociacao: yup
        .number()
        .min(
            0,
            'Percentagem informada menor que o valor mínimo ( 0 ) permitido'
        )
        .max(
            100,
            'Percentagem informada maior que o valor máximo ( 100 ) permitido'
        )
        .required(
            'Informe a percentagem padrão que deve ser cobrada do estabelecimento'
        ),
    App_Distancia_CheckInOut_Cliente_Metros: yup
        .number()
        .required('Distância checkin cliente'),
    App_Distancia_CheckInOut_Unidade_Metros: yup
        .number()
        .required('Distância checkin estabelecimento'),
    App_Version: yup
        .string()
        .required('É necessário informar a versão do APP do entregador'),
    Coleta_MargemErro_Checkin: yup
        .number()
        .required(
            'Informe o tempo mínimo para o checkin do entregador na unidade'
        ),
    Entregador_Espera_Estabelecimento_Minutos: yup
        .number()
        .required(
            'Informe o tempo de espera no estabelecimento para o entregador'
        ),
    Frete_DistanciaMaxima_Km: yup
        .number()
        .min(1, 'Não pode ser menor que 1')
        .required('O valor é obrigatório'),
};
