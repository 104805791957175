import { Form } from '@unform/web';
import ButtonLoading from 'components/atom/ButtonLoading';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import FormInputMask from 'components/Form/Input/InputMask';
import Radio from 'components/Form/Radio';
import SelectSimple from 'components/Form/SelectSimple';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo } from 'react';
import { useQueryData } from './hooks/useQueryData';
import { Container, Header, Table } from './styl';
const PageEditarStatusColeta: FC<any> = () => {
    const {
        handleChangeSelect,
        loading,
        allStatus,
        optionsPlayer,
        ref,
        loadingButton,
        toggleStatus,
        ...unformProps
    } = useQueryData();
    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>Editar status da coleta</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {<CircularLoading show={loading} />}

                    {!loading && (
                        <Form ref={ref} {...unformProps}>
                            <label className="title-title">
                                Selecione o entregador desta coleta
                            </label>
                            <SelectSimple
                                onChange={handleChangeSelect}
                                name="entregador"
                                options={optionsPlayer}
                            />
                            <label className="title-title">
                                Selecione até o status que deseja alterar.
                            </label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20px' }}></th>
                                        <th style={{ width: '120px' }}>
                                            Horário
                                        </th>
                                        <th>Status</th>
                                        <th>Entregador</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allStatus.length > 0 &&
                                        allStatus.map((status, index) => {
                                            if(status.id !== 8){
                                                return (
                                                    <tr key={`status-${status.id}`}>
                                                        <td className="input-checkbox">
                                                            <Radio
                                                                id={`data-${index}`}
                                                                name={`data[${index}][ativo]`}
                                                                options={[
                                                                    {
                                                                        id: `data-${index}`,
                                                                        value: `${status.id}`,
                                                                        label: '',
                                                                    },
                                                                ]}
                                                                typeInput="checkbox"
                                                                onClick={(e) => {
                                                                    toggleStatus(e);
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="input-text">
                                                            <FormInputMask
                                                                name={`data[${index}][horario]`}
                                                                mask={'99:99:99'}
                                                                defaultValue={
                                                                    status.horaAlteracao
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <label>
                                                                {status.nome}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                {status.entregador}
                                                            </label>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                </tbody>
                            </Table>
                            <div className="space-button">
                                <ButtonLoading
                                    loading={loadingButton}
                                    value={'Salvar'}
                                />
                            </div>
                        </Form>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PageEditarStatusColeta);
