import { useMutation } from '@apollo/client';
import { FormHandles, SubmitHandler } from '@unform/core';
import { GRAPHQL_ATUALIZAR_PAGAMENTO } from 'gql/cliente';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useSubmitEditarPagamento = () => {
    const { coletaId } = useParams<any>();
    const history = useModalHistory();
    const formRef: any = useRef<FormHandles>(null);
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaPagamento, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_PAGAMENTO
    );

    const handleSubmit: SubmitHandler<any> = useCallback(async (dataSubmit) => {
        try {
            const data = {
                coletaId,
                valorTroco:
                    dataSubmit.formaPagamentoId.id === 1
                        ? Number(
                              (dataSubmit.valorTroco || `0`).replace(
                                  /[^0-9|\.]/gi,
                                  ''
                              )
                          )
                        : undefined,
                formaPagamentoId: dataSubmit.formaPagamentoId.id,
                valorTotalProdutos: dataSubmit.valorTotalProdutos && dataSubmit.valorTotalProdutos !== '0,00' ? Number(dataSubmit.valorTotalProdutos.replace(',','.')) : 0,
            };

            await handleAtualizaPagamento({
                variables: {
                    data,
                },
            });
            history.goBack();
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Alerta',
                conteudo: message as string,
            });
        }
    }, []);

    return { loading, ref: formRef, onSubmit: handleSubmit };
};
