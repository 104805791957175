import { useMutation } from '@apollo/client';
import dot from 'dot-object';
import { GRAPHQL_ADICIONAR_COLETA } from 'gql/coleta';
import { useCallback, useContext } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import * as yup from 'yup';
export const useSubmit = (props) => {
    const history = useModalHistory();
    const { alertConfirm, unidadeSelecionada } = useContext(AuthContext);

    const [handleAdicionarColeta, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_COLETA
    );

    const handleString2Integer = useCallback(
        (value: string | number) => Number(`${value}`.replace(/[^0-9]/gi, '')),
        []
    );
    const handleString2NumberDecimal = useCallback((value: string | number) => {
        if (typeof value === 'string') {
            return Number(
                Number(
                    `${value}`.replace(/[R$\s]/gi, '').replace(/,/gi, '')
                ).toFixed(2)
            );
        }
        return Number(value);
    }, []);

    const submit = useCallback(
        async (dataForm) => {

            try {
                if(dataForm.valorTotalProdutos)
                    dataForm.valorTotalProdutos = dataForm.valorTotalProdutos.replace('.','').replace(',','.');
                if (loading) {
                    throw new Error(
                        'Seu pedido já está sendo salvo. Favor aguarde.'
                    );
                }
                if (!unidadeSelecionada || !unidadeSelecionada.id) {
                    throw new Error(
                        'Selecione o estabelecimento que deseja criar seu pedido [code 3]'
                    );
                }

                // Colocando os dados tudo em apenas um lugar e tratando os numeros
                dataForm = dot.object(
                    {
                        ...props,
                        ...dataForm,
                        formaPagamentoId: dataForm.formaPagamentoId.id,
                        frete: props.fretes.find(
                            ({ id }) => id === `${dataForm.modalTransporteId}`
                        ).frete,
                        unidadeId: unidadeSelecionada.id,
                        produtos: [],
                        valorTroco: dataForm.valorTroco || '0,00',
                    },
                    {
                        endereco: [handleString2Integer],
                        modalTransporteId: [handleString2Integer],
                        formaPagamentoId: [handleString2Integer],
                        frete: [handleString2NumberDecimal],
                        valorTotalPago: [handleString2NumberDecimal],
                        valorTotalProdutos: [handleString2NumberDecimal],
                        valorTroco: [handleString2NumberDecimal],
                    }
                );

                // Validando se o cliente preencheu todas as etapas.
                await yup
                    .object()
                    .shape({
                        frete: yup
                            .number()
                            .required(
                                'Selecione um endereço de entrega na aba endereço'
                            ),
                        valorTotalProdutos: yup
                            .number()
                            .required('Informe o valor do pedido'),
                        formaPagamentoId: yup
                            .number()
                            .min(1, 'Selecione a forma de pagamento')
                            .required('Selecione a forma de pagamento'),
                        statusId: yup
                            .number()
                            .min(0, 'Selecione a status do pedido')
                            .required('Selecione a status do pedido'),
                        modalTransporteId: yup
                            .number()
                            .min(1, 'Selecione um tipo de transporte')
                            .required('Selecione um tipo de transporte'),
                        clienteId: yup
                            .string()
                            .required('Selecione um cliente na aba cliente'),
                        unidadeId: yup
                            .string()
                            .required(
                                'Selecione um estabelecimento na aba estabelecimento'
                            ),
                    })
                    .validate(dataForm, {
                        abortEarly: true,
                    });

                const data = dot.transform(
                    {
                        unidadeId: 'unidadeId',
                        clienteId: 'clienteId',
                        endereco: 'clienteEnderecoId',
                        modalTransporteId: 'modalTransporteId',
                        statusId: 'statusId',

                        frete: 'valorFrete',
                        valorTotalProdutos: 'valorTotalProdutos',
                        produtos: 'produtos',
                        situacao: 'pagamento.situacao',
                        formaPagamentoId: 'pagamento.formaPagamentoId',
                        valorTotalPago: 'pagamento.valorTotalPago',
                        valorTroco: 'pagamento.valorTroco',
                    },
                    {
                        ...props,
                        ...dataForm,
                        produtos: [],
                        situacao: 'pendente',
                        valorTotalPago:
                            dataForm.frete + dataForm.valorTotalProdutos,
                    }
                );

                await handleAdicionarColeta({
                    variables: { data },
                });
                history.push('');
            } catch ({ message }) {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Adicionar coleta',
                    conteudo: message as string,
                });
            }
        },
        [props, loading]
    );
    return {
        submit,
        submitLoading: loading,
    };
};
1;
2;
3;
4;
5;
