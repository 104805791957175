import { useCallback } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { IRetriever } from './index.d';

export const useHandleOpenModal = (): IRetriever => {
    const history = useModalHistory();

    const handleEditPlayer = useCallback(async ({ entregadorId }) => {
        history.push(`entregador/${entregadorId}`);
    }, []);

    const handleValidateDocument = useCallback(async ({ entregadorId }) => {
        history.push(`entregador-documento/${entregadorId}`);
    }, []);

    const handleReportPlayer = useCallback(async ({ entregadorId }) => {
        history.push(`relatorio/${entregadorId}`);
    }, []);

    const handleDataBackPlayer = useCallback(async ({ entregadorId }) => {
        history.push(`banco/${entregadorId}`);
    }, []);

    const handleCustomNotifyPlayer = useCallback(async ({ entregadorId }) => {
        history.push(`notificacao~push/${entregadorId}`);
    }, []);

    return {
        handleEditPlayer,
        handleValidateDocument,
        handleReportPlayer,
        handleDataBackPlayer,
        handleCustomNotifyPlayer,
    };
};
