import styled, { css } from 'styled-components';
export const Conteiner = styled.div``;
const inputStyl = css`
	border: 1px solid var(--bg-hover-light);
	border-radius: var(--def-radius);
`;
export const Container = styled.div<any>`
    padding: 0 0.5rem;
    .coluna {
        background-color: var(--bg-hover-light);
        border-radius: var(--def-radius);
            height: calc(100vh - 20rem);
            max-height: calc(100vh - 20rem);
        display: flex;
        flex-direction: column;

        .title {
            background-color: var(--btn-admin-icone);
            border-radius: var(--def-radius);
            height: 40px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            position: relative;
            :before {
                content: "";
                position: absolute;
                bottom: -15px;
                transform: translateX(-50%);
                left: 50%;
                border: solid 8px transparent;
                border-top-color: var(--btn-admin-icone);
                z-index: 1;
            }
            .icone {
                margin-left: 0.5rem;
            }
        }

        .cards-wrap {
            flex: 1;
            overflow-y: auto;
            margin-bottom: 0.5rem;
        }

        .carregar-mais {
            padding: 0 0.5rem;
        }
    }
`;

