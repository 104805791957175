import { useQuery } from '@apollo/client';
import { GRAPHQL_UNIDADE } from 'gql/unidade';
import { useParams } from 'react-router-dom';
export interface IPlayer {
    id?: string;
    nomeCompleto?: string;
    sexo?: string;
    email?: string;
    telefone?: string;
}
interface IParams {
    unidadeId: string;
}
const optionsValores = [
    { label: 'R$ 1,00', value: 1 },
    { label: 'R$ 100,00', value: 100 },
    { label: 'R$ 150,00', value: 150 },
    { label: 'R$ 200,00', value: 200 },
    { label: 'R$ 250,00', value: 250 },
    { label: 'R$ 300,00', value: 300 },
    { label: 'R$ 400,00', value: 400 },
    { label: 'R$ 500,00', value: 500 },
    { label: 'R$ 600,00', value: 600 },
    { label: 'R$ 700,00', value: 700 },
    { label: 'R$ 800,00', value: 800 },
    { label: 'R$ 900,00', value: 900 },
    { label: 'R$ 1000,00', value: 1000 },
    { label: 'R$ 1200,00', value: 1200 },
    { label: 'R$ 1400,00', value: 1400 },
    { label: 'R$ 1600,00', value: 1600 },
    { label: 'R$ 1800,00', value: 1800 },
    { label: 'R$ 2000,00', value: 2000 },
    { label: 'R$ 2400,00', value: 2400 },
    { label: 'R$ 2800,00', value: 2800 },
    { label: 'R$ 3000,00', value: 3000 },
    { label: 'R$ 3500,00', value: 3500 },
    { label: 'R$ 4000,00', value: 4000 },
];
export const useTypePayment = () => {
    const { unidadeId } = useParams<IParams>();
    const { data: responseData } = useQuery<{
        unidade?: {
            data?: IPlayer[];
        };
    }>(GRAPHQL_UNIDADE, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id: unidadeId,
            },
        },
    });
    return [
        {
            gridFirstWidth: 2,
            items: [
                {
                    type: 'text',
                    label: 'Titular do cartão',
                    placeholder: 'Titular do cartão',
                    toolTipLegend: 'Informe o nome do titular do cartão',
                    name: 'titularCartao',
                    defaultValue: '',
                },
                {
                    type: 'mask',
                    label: 'CPF do titular',
                    mask: '999.999.999-99',
                    placeholder: 'xxx.xxx.xxx-xx',
                    toolTipLegend: 'Informe o CPF do titular do cartão',
                    name: 'titularCpf',
                    defaultValue: '',
                },
            ],
        },
        {
            gridColumn: '2.9fr 1fr 2fr',
            items: [
                {
                    type: 'mask',
                    label: 'Número do cartão',
                    mask: '9999 9999 9999 9999',
                    toolTipLegend: 'Informe número do cartão',
                    placeholder: 'Número cartão',
                    name: 'cartaoNumero',
                    defaultValue: '',
                },
                {
                    type: 'mask',
                    label: 'CVC',
                    mask: '999',
                    placeholder: 'cvc',
                    name: 'cartaoCvc',
                    defaultValue: '',
                },
                {
                    type: 'mask',
                    label: 'Cartão validade',
                    mask: '99/9999',
                    placeholder: 'xx/xxxx',
                    toolTipLegend:
                        'Informe a data de validade do cartão Mês/Ano',
                    name: 'cartaoValidade',
                    defaultValue: '',
                },
            ],
        },
        {
            type: 'select-simple',
            label: 'Valor a ser pago',
            placeholder: 'Valor a ser pago',
            toolTipLegend:
                'Selecione o valor que deseja inserir de crédito no cartão',
            name: 'valor',
            defaultValue: optionsValores[5],
            options: optionsValores,
        },
    ];
};
