import { gql } from '@apollo/client';

export const GRAPHQL_LISTAR_PERFIL = gql`
    query perfil {
        perfil {
            id
            nome
            administrador
        }
    }
`;
