import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_BUSCAR_BANCO } from 'gql/banco';
import { GRAPHQL_BUSCAR_ENTREGADOR } from 'gql/entregador';
import { useSelectAwaitFetch } from 'hooks/useSelectAwaitFetch';
import { trim } from 'libs/StringUteis';
import { matchCpf, matchTelefone } from 'libs/yup/matches';
import { YupUtilsCatch, YupUtilsTry } from 'libs/yup/ShapeValidation';
import React, { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import ComponentClienteEditar from './Component';
import { useEntregadorContaAlterar } from './hooks/useEntregadorContaAlterar';
import { usePix } from './hooks/usePix';

interface IParams {
    idEntregador: string;
}
interface IContaBanco {
    id?: string;
    nome?: string;
}

interface IContaBancaria {
    id?: string;
    titularNome?: string;
    titularCpf?: string;
    contaAgenciaDv?: string;
    contaAgencia?: number;
    contaNumeroDv?: number;
    contaNumero?: number;
    contaTipo?: string;
    pixTipo?: 'cpf' | 'email' | 'telefone' | 'aleatorio';
    pixConta?: string;
    contaBanco?: IContaBanco;
}
export interface IPlayer {
    id?: string;
    nomeCompleto?: string;
    sexo?: string;
    email?: string;
    telefone?: string;
    contaBancaria?: IContaBancaria;
}
const tipoConta = [
    { label: 'Poupança', value: 'POUPANCA' },
    { label: 'Conta corrente', value: 'CONTACORRENTE' },
    { label: 'Conta salário', value: 'CONTASALARIO' },
    { label: 'Conta pagamento', value: 'CONTAPAGAMENTO' },
    { label: 'Outra', value: 'OUTRA' },
];

const PageEditarEntregador: FC = () => {
    const rForm = createRef<FormHandles>();
    // pega o id cliente da url
    const { idEntregador } = useParams<IParams>();
    const [initialData, setInitialData] = useState(null);
    const { loading: loadingBancoEntregador } = useQuery<{
        recuperarEntregador?: {
            data?: IPlayer[];
        };
    }>(GRAPHQL_BUSCAR_ENTREGADOR, {
        fetchPolicy: 'no-cache',
        variables: {
            filter: {
                id: idEntregador,
            },
        },
        onCompleted: ({
            recuperarEntregador: {
                data: [dataEntregador],
            },
        }) => {
            setInitialData({
                contaAgencia: dataEntregador.contaBancaria.contaAgencia,
                contaAgenciaDv: dataEntregador.contaBancaria.contaAgenciaDv,
                contaNumero: dataEntregador.contaBancaria.contaNumero,
                contaNumeroDv: dataEntregador.contaBancaria.contaNumeroDv,
                titularNome: dataEntregador.contaBancaria.titularNome,
                titularCpf: dataEntregador.contaBancaria.titularCpf,
                contaTipo: dataEntregador.contaBancaria.contaTipo,
                contaBancoId: dataEntregador.contaBancaria.contaBanco.id,
                contaBancoName: dataEntregador.contaBancaria.contaBanco.nome,
                pixConta: dataEntregador.contaBancaria.pixConta,
                pixTipo: dataEntregador.contaBancaria.pixTipo,
            });
        },
    });
    const pix = usePix();

    useEffect(() => {
        if (initialData && rForm?.current) {
            rForm?.current?.setData(initialData);
            rForm?.current?.setFieldValue(
                'pixTipo',
                pix.items[0].options.find(
                    ({ value }) => value.pixTipo === initialData.pixTipo,
                ),
            );
            rForm?.current?.setFieldValue('contaBancoId', {
                value: initialData.contaBancoId,
                label: initialData.contaBancoName,
            });
            rForm?.current?.setFieldValue('contaTipo', {
                value: initialData.contaTipo,
                label: tipoConta.find(
                    ({ value }) => value === initialData.contaTipo,
                )?.label,
            });
            rForm?.current?.setFieldValue('pixConta', initialData.pixConta);
        }
    }, [initialData]);

    const { atualizaEntregadorConta, loading } = useEntregadorContaAlterar();

    const { select: selectAsyncClient } = useSelectAwaitFetch({
        rootKey: 'banco',
        transformPostSendMutation: nome => ({
            filter: {
                nome,
            },
        }),
        transformPostSendMutationStart: () => ({
            filter: {},
        }),
        transformOption: (nome, banco) => {
            return {
                value: banco.id,
                label: banco.nome,
            };
        },
        isSelected: ({ value: id }, value) => {
            return id === value;
        },
        gql: GRAPHQL_BUSCAR_BANCO,
    });

    const handleSubmit = async data => {
        const { setErrors } = rForm.current as FormHandles;
        try {
            const pixContaYup = {
                telefone: yup
                    .string()
                    .required('Telefone inválido')
                    .matches(matchTelefone, 'Telefone Inválido'),
                cpf: yup
                    .string()
                    .required('CPF inválido')
                    .matches(matchCpf, 'CPF Inválido'),
                email: yup
                    .string()
                    .min(3, 'Email inválido')
                    .max(60)
                    .email('Email inválido')
                    .required('Email não informado'),
                aleatorio: yup
                    .string()
                    .min(20, 'Chave aleatória inválido')
                    .max(100, 'Chave aleatória muito longa')
                    .required('Chave aleatória não informado'),
            };
            const dataYup = {
                ...data,
                pixTipo:
                    typeof data.pixTipo === 'string'
                        ? ''
                        : data.pixTipo.pixTipo,
            };
            const pixConta = { pixConta: pixContaYup[dataYup.pixTipo] } || {};

            await YupUtilsTry(dataYup, setErrors, {
                pixTipo: yup
                    .string()
                    .required('Selecione um tipo de pagamento Pix'),
                contaBancoId: yup.string().required('Nome do banco inválido'),
                titularNome: yup.string().required('Nome do titular inválido'),
                titularCpf: yup
                    .string()
                    .required('CPF inválido')
                    .matches(matchCpf, 'CPF Inválido'),
                ...pixConta,
                contaAgencia: yup.string().required('Agência inválido'),
                contaAgenciaDv: yup.string().required('Dv da agência inválido'),
                contaNumero: yup.string().required('Conta inválido'),
                contaNumeroDv: yup.string().required('Dv da conta inválido'),
                contaTipo: yup.string().required('Tipo de conta inválido'),
            });

            data.titularNome = trim(data.titularNome);
            data.contaBancoId = Number(data.contaBancoId);
            data.contaAgencia = Number(data.contaAgencia);
            data.contaAgenciaDv = Number(data.contaAgenciaDv);
            data.contaNumero = Number(data.contaNumero);
            data.pixTipo = data.pixTipo.pixTipo;
            data.pixConta = data.pixConta;
            try {
                await atualizaEntregadorConta({
                    data,
                    entregadorId: idEntregador,
                });
            } catch (err1) {
                console.error(err1);
            }
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };
    const oProps = {
        pix,
        loadingBancoEntregador,
        initialData,
        loading,
        tipoConta,
        selectAsyncClient,
        rForm,
        handleSubmit,
    };
    return <ComponentClienteEditar {...oProps} />;
};
export default PageEditarEntregador;
