import FormularioEnderecoTemplate from 'components/template/FormularioEndereco';
import React, { FC, useContext } from 'react';
import { NavigateContext } from '../context';

const PageEndereco: FC<any> = ({ handleFetchSubmit, loading }) => {
    const formCurrentName = 'endereco';
    const formNextName = 'dadosEmpresa';
    const { tabs } = useContext(NavigateContext);

    const props = {
        submit: (data) =>
            handleFetchSubmit({
                ...tabs.getInitialDataByName('dadosEmpresa'),
                ...data,
            }),
        submitDefaultProps: { atual: formCurrentName, proximo: formNextName },
        submitLoading: loading,
        tituloFormulario: 'Informe o endereço da empresa',
        destino: tabs.getInitialDataByName('endereco'),
        removeLegend: true,
        formRef: tabs.currentItem.formRef,
    };

    return <FormularioEnderecoTemplate {...props} />;
};
export default PageEndereco;
