// eslint-disable-next-line no-use-before-define
import React, { useCallback, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { defTitle } from 'assets/strings';
// eslint-disable-next-line import/extensions
import Page404Component from './component';

const Page404: FC = () => {
    const history = useHistory();
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Page404`;
    });
    const handlerBack = useCallback(() => {
        history.replace('/');
    }, [history]);
    const props = {
        handlerBack,
    };
    return <Page404Component {...props} />;
};
export default Page404;
