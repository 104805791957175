// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';
import SuperFiltroSearch from './Search';
import { Container } from './styl';
import { AvisoAltoFluxo } from '../../routes/admin/partial/MenuAdmin/styl';
import { useConfiguracaoContext } from '../../contexts/configuracao.context/hook.context';

const SuperFiltro: FC<any> = oProps => {
    const { configuracao } = useConfiguracaoContext();
    const existeAvisoAltoFluxo =
        configuracao?.['Mostrar_Aviso_Alto_Fluxo'] === '1';
    return (
        <Container
            style={{
                paddingTop: 0,
            }}>
            {existeAvisoAltoFluxo && (
                <div
                    style={{
                        textAlign: 'center',
                    }}>
                    <AvisoAltoFluxo
                        dangerouslySetInnerHTML={{
                            __html: configuracao['Mensagem_Alto_Fluxo'],
                        }}
                    />
                </div>
            )}
            <div className="grupo-categoria-search">
                <SuperFiltroSearch {...oProps} />
            </div>
        </Container>
    );
};
export default SuperFiltro;
