import { Form } from '@unform/web';
import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import { AtomFreteMapa } from 'components/atom/map/AtomFreteMapa';
import AtomMarkerMyLocationMove from 'components/atom/map/AtomMarkerMyLocationMove';
import InputGooglePlaces from 'components/Form/InputGooglePlace';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
    bairro,
    cep,
    complemento,
    tipoEndereco,
    endereco as enderecoComponent,
    enderecoPontoReferencia,
    formulario,
    numero,
} from './formulario.json';
import { ContainerForm, WarpMap } from './styl';

const ComponentEndereco: FC<any> = ({
    formRef,
    destino,
    handleLoadAddress,
    loadingLoadAddress,
    myLocationProps,
    handleSubmit,
    submitLoading,
    tituloFormulario,
    inputName,
    handleLoadAddressByNumber,
    distanciaEFrete,
    handleResponseSearchAdress,
    blockFindByCep,
    viewFields,
    setViewFields,
}) => {
    useEffect(() => {
        document.title = `${defTitle} - Endereço`;
    });
    const [typeAddress, setTypeAddress] = useState(null);

    const iconInput = useMemo(() => {
        if (!blockFindByCep) {
            if (loadingLoadAddress) {
                return (
                    <CircularLoading
                        size={2}
                        marginRight={10}
                        secondaryColor="var(--btn-admin-icone)"
                        show={true}
                    />
                );
            }
            return (
                <Icone
                    marginRight={10}
                    icone="icon-lupa"
                    size="2rem"
                    color="var(--placeholder)"
                />
            );
        }
        return (
            <Icone
                marginRight={10}
                icone="icon-cadeadofechado"
                size="2rem"
                color="var(--placeholder)"
            />
        );
    }, [loadingLoadAddress, blockFindByCep]);


    return (
        <ContainerForm>
            <Form
                initialData={destino}
                ref={formRef}
                onSubmit={handleSubmit}>
                <p>{tituloFormulario}</p>

                {handleResponseSearchAdress && (
                    <InputGooglePlaces
                        onChange={(e) => {
                            setViewFields(true);
                            handleResponseSearchAdress(e);
                        }}
                        apiKey={myLocationProps.keyMaps}
                        removeLegend={viewFields}
                    />
                )}
                <FormGenerator
                    childs={viewFields ? [
                        {
                            gridFirstWidth: 1.6,
                            items: [
                                {
                                    ...cep,
                                    onBlur: handleLoadAddress,
                                    icon: iconInput,
                                    disabled: blockFindByCep,
                                },
                                {
                                    ...numero,
                                    icon: blockFindByCep ? <></> : iconInput,
                                    onBlur: handleLoadAddressByNumber,
                                    zIndex: 99999,
                                },
                            ],
                        },
                        {
                            gridFirstWidth: 1.6,
                            items: [
                                {
                                    ...enderecoComponent,
                                    icon: (
                                        <Icone
                                            marginRight={10}
                                            icone="icon-cadeadofechado"
                                            size="2rem"
                                            color="var(--placeholder)"
                                        />
                                    ),
                                },
                                {
                                    ...bairro,
                                    icon: (
                                        <Icone
                                            marginRight={10}
                                            icone="icon-cadeadofechado"
                                            size="2rem"
                                            color="var(--placeholder)"
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            gridFirstWidth: 1.6,
                            items: [
                                {
                                    "toolTipLegend": "O campo é obrigatório",
                                    "label": "Quadra",
                                    "type": "text",
                                    "name": "enderecoQuadra",
                                    "placeholder": "Quadra",
                                    "toolTipColor": "var(--placeholder)"
                                },
                                {
                                    "toolTipLegend": "O campo é obrigatório",
                                    "label": "Lote",
                                    "type": "text",
                                    "name": "enderecoLote",
                                    "placeholder": "Lote",
                                    "toolTipColor": "var(--placeholder)"
                                }
                            ],
                        },
                        complemento,
                        // complemento,
                        enderecoPontoReferencia,
                        ...formulario,
                        {
                            type: 'button',
                            label: 'Adicionar cliente',
                            enabled: false,
                            loading: submitLoading || loadingLoadAddress,
                        },
                    ] : []}
                />
            </Form>

            <WarpMap height={handleResponseSearchAdress ? '68rem' : '60rem'}>
                
                    <AtomMarkerMyLocationMove
                        {...myLocationProps}
                        loading={false}
                        bounds={null}
                        setViewFields={setViewFields}
                    />
                
                {distanciaEFrete.valorFrete && (
                    <AtomFreteMapa {...distanciaEFrete} />
                )}
            </WarpMap>
        </ContainerForm>
    );
};
export default memo(ComponentEndereco);
