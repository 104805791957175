import { useQuery } from '@apollo/client';
import { GRAPHQL_LISTAR_USUARIO_UNIDADE } from 'gql/usuarioUnidade';
import { useParams } from 'react-router-dom';

export const useLoadUserUnit = () => {
    const { unidadeId } = useParams<any>();
    const { data, loading, error } = useQuery(GRAPHQL_LISTAR_USUARIO_UNIDADE, {
        fetchPolicy: 'no-cache',
        variables: {
            unidadeId,
        },
    });
    return !loading && error === undefined ? data.usuarioUnidade : null;
};
