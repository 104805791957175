import { useState, useEffect } from 'react';

export const useCacheMail = () => {
	// Armazena o e-mail do usuário logado para usar no auto preenchimento do campo e-mail
	const [dataCacheEmail, setDataCacheEmail] = useState<string>('');

	useEffect(() => {
		const email = localStorage.getItem('@auth/email');
		if (!!email) {
			setCacheEmail(email);
		}
	}, []);

	// Salvando cache do e-mail
	function setCacheEmail(email) {
		localStorage.setItem('@auth/email', email);
		setDataCacheEmail(email);
	}
	return {
		dataCacheEmail,
		setCacheEmail,
	};
};
