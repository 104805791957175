import axios from "axios";

const getAuthToken = () => {
    return localStorage.getItem('@auth/token');
};

export const apiv2Axios = axios.create({
    baseURL: process.env.REACT_APP_HOST_APIV2,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiv2Axios.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
        config.headers['authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});