import { useQuery } from '@apollo/client';
import { GRAPHQL_DELLA_TV } from 'gql/dellatv';
import { useEffect, useState } from 'react';

export const useSlide = () => {
    const { data, loading } = useQuery(GRAPHQL_DELLA_TV);

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            if (!loading) {
                setIndex((p) => (p + 1) % data.tvDellaFotos.length);
            }
        }, 7000);
        return () => clearInterval(timer);
    }, [data, loading]);
    return { loading, slide: loading ? {} : data.tvDellaFotos[index] };
};
export default useSlide;
