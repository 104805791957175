import { gql } from '@apollo/client';

const gqlColeta = `
	id
    paraPresente
    avisoSonoroLastedStatusId
	numeroInterno
    dataPedido
    nomeCliente
    valorTotalProdutos
    valorFrete
    coletaPaiId
    coletaEPai
    pedido_ifood_id
	pedido_anotaai_id
    localizer_number_ifood
    modalTransporteId
    modalTransporte{
        id
        txMinRetorno
        valorKmRetorno
        txRetorno
        txMinRetorno
    }
    historicoEnderecos {
        id
        enderecoLogradouro
        enderecoNumero
		enderecoQuadra
		enderecoLote
        enderecoComplemento
        enderecoBairro
        enderecoCep
        metricaDistUnidade
        metricaTempoUnidade
    }
	unidade{
		id
        razaoSocial
        nomeFantasia
        geoLatitude
        geoLongitude
        porcentagemNegociacao
        unidadeParametro{
            chave
            valor
        }
	}
    pagamento{
        valorTroco
        formaPagamentoId
        formaPagamentoNome
        exigirFotoComprovante
        exigeTaxaRetorno
    }
	cliente {
		id
		email
		nomeCompleto
		telefoneFixo
		telefoneCelular
		endereco {
			id
            novoEnderecoNaoAprovado
			enderecoCep
			enderecoNumero
			enderecoQuadra
			enderecoLote
			enderecoBairro
			enderecoLogradouro
			enderecoComplemento
			enderecoPontoReferencia
			latitude
			longitude
            metricaDistUnidade
            metricaTempoUnidade
            metricaPrecisao
            frete {
                nome
                id
                distancia
                precisao
                frete
            }
		}
	}
	entregador {
		id
		nomeCompleto
		telefone
        connection
	}
	status {
        id
        nome
        distanciaPercurso
		tempoPercurso
        corBackground
		dataAlteracao
        nomeCompleto
        qtdxchamouplayer
        ordem
	}
    taxaMinimaMoto
    kmAdicionalMoto
    adicionalJogoRapido
    adicionalEntregador
    taxaMinimaRetornoMoto
    kmAdicionalRetornoMoto
    kmDistanciaMinima
    kmDistanciaMaxima
    metricaDistUnidade
    metricaPrecisao
    porcentagemNegociacao
`;

const gqlColetaByStatus = `
    id
    numeroInterno
    dataPedido
    dataAgendamento
    valorTotalProdutos
    coletaPaiId
    valorFrete
    unidadeId
    clienteId
    entregadorId
    modalTransporteId
    unidade
    cliente
    entregador
    modal
    status
    statusId
    dataAlteracao
    primeiroAceiteDataStatus
`;
export const GRAPHQL_ADICIONAR_COLETA: any = gql`
	mutation adicionarColeta($data: ColetaInput) {
		adicionarColeta(data: $data)
	}
`;

export const GRAPHQL_STATUS_COLETA: any = gql`
	mutation alterarStatusColetaContainer(
		$coletaId: ID
		$statusId: Int
		$entregadorId: ID
	) {
		alterarStatusColeta(
			coletaId: $coletaId
			statusId: $statusId
			entregadorId: $entregadorId
		)
	}
`;

export const GRAPHQL_AGRUPAR_COLETA: any = gql`
	mutation agruparColetaContainer($coletaPaiId: ID, $coletaIds: [ID]) {
		agruparColeta(coletaPaiId: $coletaPaiId, coletaIds: $coletaIds)
	}
`;

export const GRAPHQL_DESAGRUPAR_COLETA: any = gql`
	mutation desagruparColetaContainer($coletaId: ID) {
		desagruparColeta(coletaId: $coletaId)
	}
`;
export const GRAPHQL_LISTAR_COLETA: any = gql`
	query coletaContainer($filter: ColetaFilter) {
		recuperarColeta(filter: $filter) {
			data {
                ${gqlColeta}
                
            }
            hasNextPage
		}
		recuperarConfiguracao(filter: { ambiente: "private" }) {
			chave
			valor
		}
	}
`;
export const GRAPHQL_LISTAR_COLETA_STATUS: any = gql`
	query coletaContainer($status: [Int], $filter: ColetaFilter) {
		recuperarColetaByStatus(status: $status, filter: $filter) {
			data {
                ${gqlColetaByStatus}
            }
            hasNextPage
		}
	}
`;
export const GRAPHQL_BUSCAR_COLETA_PAGAMENTO: any = gql`
	query coletaContainer($filter: ColetaFilter) {
		recuperarColeta(filter: $filter) {
			data {
                ${gqlColeta}
            }
            hasNextPage
		}
        recuperarFormaPagamento{
            id
            nome
            exigeTaxaRetorno
        }
	}
`;
export const GRAPHQL_COLETA_AGRUPAVEL: any = gql`
	query agrupavelColetaContainer($coletaId: ID) {
		agrupavelColeta(coletaId: $coletaId) {
			value
			label
		}
	}
`;
export const GRAPHQL_COLETA_LOCALIZACAO: any = gql`
	query recuperarColetaById($coletaId: ID) {
		recuperarColetaById(coletaId: $coletaId) {
			unidadeLat
			unidadeLng
			clienteLat
			clienteLng
			cliente
			razaoSocial
			keyMaps
		}
		recuperarColegaLocationById(coletaId: $coletaId) {
			lat
			lng
		}
	}
`;

export const GRAPHQL_COLETA_PUBLIC: any = gql`
	query recuperarColetaByIdPublic($coletaId: ID) {
		recuperarColetaByIdPublic(coletaId: $coletaId) {
			id
			coletaLastedStatusId
			statusId
			entregadorLatitude
			entregadorLongitude
            cliente{
                endereco {
                    latitude
                    longitude
                }
            }
            entregador {
                id
            }
		}
	}
`;

export const COLETA_SUBSCRIPTION: any = gql`
	subscription coletaContainer($filter: ColetaFilter) {
		coleta(filter: $filter) {
			data{
                ${gqlColeta}
                configuracao(filter: { chave: "Unidade::TaxaRetorno::Valor" }) {
                    chave
                    valor
                }
            }
            action
		}
	}
`;

export const RECUPERAR_STATUS_COLETA = gql`
	query statusColeta($coletaId: ID!) {
		statusColeta(coletaId: $coletaId) {
			statusId
			nome
			dataAlteracao
			entregadorId
			corForeground
			corBackground
			nomeCompleto
			qtdxchamouplayer
			ativo
		}
	}
`;
export const STATUS_COLETA_TOGGLE_ATIVO = gql`
	mutation toogleActiveColetaStatus(
		$coletaId: ID!
		$statusId: Int!
		$dataAlteracao: String!
		$ativo: Boolean!
	) {
		toogleActiveColetaStatus(
			coletaId: $coletaId
			statusId: $statusId
			dataAlteracao: $dataAlteracao
			ativo: $ativo
		)
	}
`;
export const COLETA_ENTREGADOR_ESCALADO: any = gql`
	query coletaContainer {
		recuperarEntregadoresPeriodoVigente {
			entregadorId
			entregadorNomeCompleto
			entregadorTelefone
			entregadorLatitude
			entregadorLongitude
			entregadorDataGeo
			entregadorOnline
		}
		recuperarColetasNaoConcluidas {
			unidadeNomeCompleto
			unidadeLatitude
			unidadeLongitude
			clienteLatitude
			clienteLongitude
			clienteNomeCompleto
			numeroInterno
			coletaId
			entregadorId
			statusId
		}
	}
`;
export const COLETA_ENTREGADORES_ESCALADOS: any = gql`
	query recuperarEntregadoresPeriodoVigente {
		recuperarEntregadoresPeriodoVigente {
			entregadorId
			entregadorNomeCompleto
			entregadorTelefone
			entregadorLatitude
			entregadorLongitude
			entregadorDataGeo
			entregadorOnline
		}
	}
`;
export const COLETA_E_ENTREGADORES_ESCALADOS: any = gql`
	query recuperarEntregadoresPeriodoVigente($coletaId: ID!) {
		recuperarEntregadoresPeriodoVigente {
			entregadorId
			entregadorNomeCompleto
			entregadorTelefone
			entregadorLatitude
			entregadorLongitude
			entregadorDataGeo
			entregadorOnline
		}
		statusColeta(coletaId: $coletaId) {
			statusId
			nome
			dataAlteracao
			entregadorId
			corForeground
			corBackground
			nomeCompleto
			qtdxchamouplayer
			ativo
		}
	}
`;

export const GRAPHQL_ATUALIZAR_STATUS_COLETA = gql`
	mutation atualizarStatusColeta($coletaStatus: [ColetaStatusInput!]!) {
		atualizarStatusColeta(coletaStatus: $coletaStatus)
	}
`;

export const GRAPHQL_ATUALIZAR_STATUS_COLETA_SALVO = gql`
	mutation atualizarStatusColeta($coletaId: ID) {
		alterarStatusColetaSalvo(coletaId: $coletaId)
	}
`;
