import { useMutation } from '@apollo/client';
import { GRAPHQL_CONFIGURACOES_UNIDADE } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useEntregadorConfiguracaoAlterar = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConfiguracoes, { loading }] = useMutation(
        GRAPHQL_CONFIGURACOES_UNIDADE
    );
    const atualizaUnidadeConfiguracoes = useCallback(
        async ({ unidadeId, data }) => {
            try {
                await handleAtualizaConfiguracoes({
                    variables: {
                        unidadeId,
                        data,
                    },
                });
                history.goBack();
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        },
        []
    );
    return {
        atualizaUnidadeConfiguracoes,
        loading,
    };
};
