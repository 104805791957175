import { useMutation } from '@apollo/client';
import { GRAPHQL_ATUALIZAR_UNIDADE_TIPO_PAGAMENTO } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
export const useTipoPagamentoAlterar = () => {
    const history = useHistory();
    const { alertConfirm } = useContext(AuthContext);
    const [handleAtualizaConta, { loading }] = useMutation(
        GRAPHQL_ATUALIZAR_UNIDADE_TIPO_PAGAMENTO
    );
    const atualizaTipoPagamento = useCallback(
        async ({ unidadeId, tipoPagamento }) => {
            try {
                await handleAtualizaConta({
                    variables: {
                        unidadeId,
                        tipoPagamento,
                    },
                });
                history.goBack();
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message as string,
                });
            }
        },
        []
    );
    return {
        atualizaTipoPagamento,
        loading,
    };
};
