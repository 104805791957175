export const removeEmpty = (obj) => {
    if (!obj) return {};
    Object.keys(obj).forEach((k) => {
        if (
            obj[k] === null ||
            obj[k] === undefined ||
            (typeof obj[k] === 'string' && obj[k].trim() === '')
        ) {
            obj[k] === null && delete obj[k];
        }
    });
    return obj;
};
