import { useQuery, useSubscription } from '@apollo/client';
import clienteComPlayer from 'assets/images/cliente-com-player.png';
import markerPlayer from 'assets/images/marker-player.png';
import {
    Map,
    TileLayer,
    Marker,
    // PropTypes as MapPropTypes
  } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { defTitle } from 'assets/strings';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import { GRAPHQL_COLETA_PUBLIC } from 'gql/coleta';
import { ENTREGADOR_LOCATION_SUBSCRIPTION, ENTREGADOR_SUBSCRIPTION } from 'gql/entregador';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BlcMessage, Container, Message, stylMap, WarpLoad } from './styl';
import L from 'leaflet';
interface IMap {
    fitBounds?: any;
}
export const AcompanharEntregador: FC = () => {
    const [latLngCliente, setLatLngCliente] = useState({
        lat: 0,
        lng: 0,
    });
    const [latLngEntregador, setLatLngEntregador] = useState({
        lat: 0,
        lng: 0,
    });
    const [center, setCenter] = useState({
        lat: -16.702038277190702,
        lng: -49.26757205109623,
    });
    const [entregadorId, setEntregadorId] = useState('');
    const [statusId, setStatusId] = useState(0);
    useEffect(() => {
        document.title = `${defTitle} - Coleta`;
    }, []);
    const apiLoc = useLocation();
    const [coleta, setColeta] = useState<any>(null);
    const { data, loading } = useQuery(GRAPHQL_COLETA_PUBLIC, {
        variables: {
            coletaId: apiLoc.pathname.replace('/acompanhar-entregador/', ''),
        },
    });
    useEffect(() => {
        if (
            data &&
            data.recuperarColetaByIdPublic &&
            data.recuperarColetaByIdPublic
        ) {
            const coleta = data.recuperarColetaByIdPublic;
            setColeta(coleta);
            setStatusId(coleta.statusId);
            setEntregadorId(coleta.entregador.id);
            setLatLngEntregador({
                lat: coleta.entregadorLatitude,
                lng: coleta.entregadorLongitude,
            });
            if (
                coleta.cliente &&
                coleta.cliente.endereco &&
                coleta.cliente.endereco.latitude
            ) {
                setLatLngCliente({
                    lat: coleta.cliente.endereco.latitude,
                    lng: coleta.cliente.endereco.longitude,
                });
            }
        }
    }, [data]);
    useSubscription<{
        entregadorLocation: {
            data: {
                latitude: number;
                longitude: number;
                id: string;
            };
        };
    }>(ENTREGADOR_LOCATION_SUBSCRIPTION, {
        variables: {
            entregadorId,
        },
        onSubscriptionData: ({
            subscriptionData: {
                data: {
                    entregadorLocation: { data },
                },
            },
        }) => {
            setLatLngEntregador({
                lat: data.latitude,
                lng: data.longitude,
            });
        },
    });
    useEffect(() => {
        const boundsLeaflet = new L.LatLngBounds();
        boundsLeaflet.extend(new L.LatLng(latLngEntregador.lat, latLngEntregador.lng));
        boundsLeaflet.extend(new L.LatLng(latLngCliente.lat, latLngCliente.lng));
        setCenter(boundsLeaflet.getCenter());
    }, [latLngEntregador]);
    return (
        <>
            {![4, 5].includes(statusId) && (
                <BlcMessage>
                    <Message>
                        Está coleta ainda não foi iniciada pelo entregador, ou
                        já foi finalizada.
                    </Message>
                </BlcMessage>
            )}
            {latLngEntregador.lat === 0 ||
                ((statusId === 4 || statusId === 5) && (
                    <>
                        <WarpLoad>
                            <CircularLoading
                                show={true}
                                size={3}
                                primaryColor="var(--bg-hover-light)"
                                secondaryColor="var(--btn-underline-cor)"
                            />
                        </WarpLoad>
                    </>
                ))}
            {latLngEntregador.lat !== 0 &&
                (statusId === 4 || statusId === 5) &&
                // (true) &&
                (
                    <Container>
                        <>
                        <Map zoomControl={false} style={stylMap} center={center} zoom={16}>
                            <TileLayer
                            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                            />
                            {latLngEntregador.lat !== 0 && (
                                <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: markerPlayer,
                                        iconRetinaUrl: markerPlayer,
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [24, 30],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={{
                                    lat: latLngEntregador.lat,
                                    lng: latLngEntregador.lng,
                                }} />
                            )}
                            {latLngCliente.lat !== 0 && (
                                <Marker 
                                icon={
                                    new L.Icon({
                                        iconUrl: clienteComPlayer,
                                        iconRetinaUrl: clienteComPlayer,
                                        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                        iconSize: [24, 30],
                                        iconAnchor: [12, 41],
                                        popupAnchor: [1, -34],
                                        tooltipAnchor: [16, -28],
                                        shadowSize: [41, 41]
                                    })
                                } position={{
                                    lat: latLngCliente.lat,
                                    lng: latLngCliente.lng,
                                }} />
                            )}
                        </Map>
                        </>
                    </Container>
                )}
        </>
    );
};
