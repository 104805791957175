import { Form } from '@unform/web';
import FormGenerator from 'components/Form/template/FormGenerator';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC } from 'react';
import { Container, Header } from '../adicionar-administrador/styl';
import { useQueryRelatorio } from './hooks/useQueryRelatorio';
const PageRelatorioUnidade: FC = () => {
    const props = useQueryRelatorio();

    return (
        <Modal
            render={({ closeModal }) => (
                <Container width="80">
                    <Header>
                        <h1>Relatório máquina jogo rápido</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    <Form
                        ref={props.formRef}
                        onSubmit={props.handleSubmit}>
                        <FormGenerator
                            childs={[
                                {
                                    gridFirstWidth: 1,
                                    items: [
                                        {
                                            type: 'date',
                                            label: 'Data início',
                                            toolTipLegend:
                                                'Informa data início para gerar o relatório.',

                                            placeholder: 'Data início',
                                            defaultValue: props.dataStart,
                                            name: 'dataInicio',
                                        },
                                        {
                                            type: 'date',
                                            label: 'Data fim',
                                            toolTipLegend:
                                                'Informa data fim para gerar o relatório.',

                                            placeholder: 'Data fim',
                                            defaultValue: props.dataEnd,
                                            // maxDate: props.maxDate,
                                            name: 'dataFim',
                                        },
                                    ],
                                },
                                {
                                    type: 'button',
                                    value: 'Salvar',
                                    loading: props.loading,
                                },
                            ]}
                        />
                    </Form>
                </Container>
            )}
        />
    );
};
export default PageRelatorioUnidade;
