import React from 'react';
import InputMask from 'components/Form/Input/InputMask';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import { WarpAction, Button, IcSearch, Container } from './styl';
interface IInputSearch {
	action(event: any): void;
	loading: boolean;
	className?: any;
	name?: string;
	mask?: string;
	placeholder?: string;
}
const InputSearch: React.FC<IInputSearch> = ({
	action,
	loading,
	className,
	name,
	mask,
	placeholder,
}) => {
	return (
		<Container>
			<InputMask
				className={className}
				name={name}
				onChange={action}
				mask={mask}
				placeholder={placeholder}
				icon={
					<WarpAction>
						{loading ? (
							<CircularLoading
								show={true}
								size={2}
								primaryColor="var(--bg-hover-light)"
								secondaryColor="var(--btn-underline-cor)"
							/>
						) : (
								<Button onClick={action}>
									<IcSearch
										icone="icon-lupa"
										size="2.5rem"
										color="var(--btn-underline-cor)"
									/>
								</Button>
							)}
					</WarpAction>
				}
			/>
		</Container>
	);
};
export default InputSearch;
