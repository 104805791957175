import { moveIn, moveOut } from 'routes/styl';
import styled from 'styled-components';

export const ContentPage = styled.div`
    position: relative;
    z-index: 99;
    max-width: calc(100vw - 3rem);
    overflow-x: scroll;
    background-color: #fff;
    border-top-left-radius: var(--def-radius);
    border-top-right-radius: var(--def-radius);
`;
export const WarpLoading = styled.div`
    animation: 500ms ${moveIn} cubic-bezier(0.165, 0.84, 0.44, 1);
    &.close {
        animation: ${moveOut} 100ms forwards;
    }
    position: fixed;
    z-index: 999;
    background: var(--bg-hover-light);
    bottom: -1px;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5),
        0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% + 8rem);
`;
export const Spacer = styled.div`
    width: 100%;
    height: 100px;
`;
