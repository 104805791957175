import ListTemplate from 'components/template/ListTemplate';
import React, { FC } from 'react';
import { useHeaderUnit } from './hooks/useHeaderUnit';
import { useQueryFilterUnit } from './hooks/useQueryFilterUnit';
import { useTransformProspect } from './hooks/useTransformProspect';

const PageListProspectus: FC<any> = () => {
    const props = useQueryFilterUnit();

    const colunas = useHeaderUnit();
    const data = useTransformProspect(props.response);

    return (
        <ListTemplate
            browserTitle="Prospecto"
            colunas={colunas}
            data={data}
            {...props}
        />
    );
};
export default PageListProspectus;
