import CircularLoading from 'components/atom/Loading/CircularLoading';
import Icone from 'components/Icone';
import Modal from 'components/modal/base';
import React, { FC, memo } from 'react';
import { useQueryData } from './hooks/useQueryData';
import { Button, Container, Header, Table } from './styl';
const PageEntregadoresEscala: FC<any> = () => {
    const {
        titulo,
        entregadoresEscala,
        toggleEntregadorEscalaStatus,
        loading,
    } = useQueryData();

    return (
        <Modal
            render={({ closeModal }) => (
                <Container>
                    <Header>
                        <h1>{titulo}</h1>
                        <button onClick={closeModal}>
                            <Icone
                                icone="icon-fechar"
                                size="2rem"
                                color="var(--btn-admin-icone)"
                            />
                        </button>
                    </Header>
                    {<CircularLoading show={loading} />}

                    {!loading && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Entregador</th>
                                    <th>Telefone</th>
                                    <th style={{ width: '120px' }}>Situação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entregadoresEscala &&
                                    entregadoresEscala.length > 0 &&
                                    entregadoresEscala.map((item, index) => {
                                        return (
                                            <tr
                                                key={`entregador-${index}`}
                                                className={
                                                    item.dataCancelamento
                                                        ? 'inativo'
                                                        : 'ativo'
                                                }>
                                                <td>
                                                    <label>
                                                        {
                                                            item.entregador
                                                                .nomeCompleto
                                                        }
                                                    </label>
                                                </td>
                                                <td>
                                                    <label>
                                                        {
                                                            item.entregador
                                                                .telefone
                                                        }
                                                    </label>
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            toggleEntregadorEscalaStatus(
                                                                item,
                                                                index,
                                                            );
                                                        }}>
                                                        {item.dataCancelamento
                                                            ? 'inativo'
                                                            : 'ativo'}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    )}
                </Container>
            )}
        />
    );
};
export default memo(PageEntregadoresEscala);
