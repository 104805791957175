import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import {
    GRAPHQL_BUSCAR_UNIDADE_FATURA,
    GRAPHQL_DELETE_UNIDADE_FATURA,
} from './../../../../gql/unidadeFatura';
import { AuthContext } from './../../../../routes/auth.context';

export const useDeleteFatura = (props) => {
    const { alertConfirm } = useContext(AuthContext);
    const [deleteFatura, {}] = useMutation(GRAPHQL_DELETE_UNIDADE_FATURA);
    const handleDeleteFatura = useCallback(async ({ id: faturaId }) => {
        const resultConfirm = await alertConfirm({
            tipo: 'info',
            titulo: 'Deletar',
            textOk: 'sim',
            conteudo: `Deseja realmente cancelar esta cobrança?`,
        });
        if (resultConfirm) {
            try {
                await deleteFatura({
                    variables: { faturaId },
                    refetchQueries: [
                        {
                            query: GRAPHQL_BUSCAR_UNIDADE_FATURA,
                            context: props.context,
                            variables: props.variables,
                        },
                    ],
                });
            } catch ({ message }) {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: message,
                });
            }
        }
    }, []);
    return { handleDeleteFatura };
};
