import {createContext} from 'react';

export const Context = createContext(
    {} as {
        configuracao: {
            [key: string]: string;
        };
        actionSetConfiguracao: (configuracao: {
            [key: string]: string;
        }[]) => void;
        loadingData: boolean;
    },
);
