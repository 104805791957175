import { useLazyQuery, useQuery } from '@apollo/client';
import CircularLoading from 'components/atom/Loading/CircularLoading';
import { LoadingContainer } from 'components/SuperFiltro/Search/styl';
import { endOfWeek, format, getDay, parse, startOfWeek } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { GRAPHQL_MEDIA_VAGAS_NOVA_ESCALA } from 'gql/periodo';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
type IWeek = {
    data: string;
    qtdCafe: number;
    qtdAlmoco: number;
    qtdLanche: number;
    qtdJanta: number;
    qtdMadrugada: number;
    qtdCafeSugestao: number;
    qtdAlmocoSugestao: number;
    qtdLancheSugestao: number;
    qtdJantaSugestao: number;
    qtdMadrugadaSugestao: number;
};

type IVagas = {
    mediaCorridasEntregador: number;
    mediaEscalada: number;
    sugestaoEscala: number;
    vagas: number;
    mediaCorridas: number;
    periodoId: number;
    periodo: string;
    data: string;
};

type IPeriodoData = {
    mediaVagasNovaEscala: IVagas[];
};
export const useQueryPeriodo = () => {
    const startWeek = useMemo(
        () =>
            startOfWeek(new Date(), {
                weekStartsOn: 1,
            }),
        [],
    );
    const variablesRef = useRef({
        dataStart: format(startWeek, 'yyyy-MM-dd'),
        maxInterval: 60,
    });

    const [handleUpdate, { data, loading }] = useLazyQuery<IPeriodoData>(
        GRAPHQL_MEDIA_VAGAS_NOVA_ESCALA,
        { fetchPolicy: 'network-only' },
    );
    useEffect(() => handleUpdate({ variables: variablesRef.current }), []);
    const filterDate = useCallback(date => {
        const day = getDay(date);
        return day === 1;
    }, []);

    const filterScaleAmount = useMemo(() => {
        return [
            {
                gridColumn: '1fr 1fr',
                items: [{ type: 'title', label: 'FILTRAR' }],
            },
            {
                gridColumn: '1fr 1fr 130px',
                items: [
                    {
                        type: 'number',
                        label: 'Calculo de nova escala baseado em:',
                        placeholder: 'Média de dias',
                        toolTipLegend:
                            'Neste campo você deve informar quantos dias anteriores a data de hoje você deseja utilizar para calcular a nova escala.',
                        name: 'periodoMedio',
                        defaultValue: variablesRef.current.maxInterval,
                        onChange: event =>
                            (variablesRef.current.maxInterval =
                                +event.target.value),
                    },
                    {
                        type: 'date',
                        label: 'Data de criação da escala',
                        placeholder: 'Data',
                        minDate: startWeek,
                        toolTipLegend:
                            'Selecione uma data para criar a escala. A escala criada será baseada na data selecionada.',
                        name: 'Entregador_Espera_Estabelecimento_Minutos',
                        filterDate,
                        onChange: (newDate: Date) =>
                            (variablesRef.current.dataStart = format(
                                newDate,
                                'yyyy-MM-dd',
                            )),
                        defaultValue: parse(
                            variablesRef.current.dataStart,
                            'yyyy-MM-dd',
                            new Date(),
                        ),
                    },
                    {
                        type: 'button',
                        value: 'Filtrar',
                        icone: 'icon-search',
                        loading,
                        loadingSize: 2,
                        handleClick: () => {
                            handleUpdate({ variables: variablesRef.current });
                        },
                        stylWarp: {
                            height: '4.5rem',
                            'margin-top': '10px',
                            'padding-left': loading ? '30px' : '',
                            background: 'var(--text-cor)',
                        },
                    },
                ],
            },
        ];
    }, [startWeek, loading]);

    const inputsVacancies = useMemo(() => {
        if (!data?.mediaVagasNovaEscala || loading) return [];
        const dates = Object.values(
            data?.mediaVagasNovaEscala?.reduce((acc, item) => {
                acc[item.data] = acc[item.data] || [
                    {
                        type: 'title',
                        label: format(
                            parse(item.data, 'yyyy-MM-dd', new Date()),
                            "'Vagas 'eee',' dd MMMM'.'",
                            { locale: ptBR },
                        ).toUpperCase(),
                    },
                ];
                console.log(acc[item.data]);
                acc[item.data].push({
                    type: 'number',
                    label: `${item.periodo} (${item.sugestaoEscala})`,
                    toolTipLegend: (
                        <span>
                            Médias com base nos últimos 60 dias
                            <br />
                            Corridas no turno: {item.mediaCorridas};
                            <br />
                            Corridas por entregador:{' '}
                            {item.mediaCorridasEntregador}
                            <br />
                            Entregadores escalados: {item.mediaEscalada}
                            <br />
                            Sugestão de nova escala: {item.sugestaoEscala}
                        </span>
                    ),
                    placeholder: 'Vagas',
                    name: `vagas[${item.data}][${item.periodoId}]`,
                    defaultValue: item.vagas ? item.vagas : item.sugestaoEscala,
                });
                return acc;
            }, {}),
        );
        return dates
            .map(items => {
                const [title, ...rest] = items as any[];
                return [
                    {
                        gridColumn: '1fr',
                        items: [title],
                    },
                    {
                        gridColumn: '.8fr .9fr 1.2fr',
                        items: rest,
                    },
                ];
            })
            .flat();
    }, [data?.mediaVagasNovaEscala, loading]);

    const inputs = filterScaleAmount.concat(inputsVacancies);

    return {
        inputs,
        loading,
    };
};
