import styled, { css } from 'styled-components';
import {
	boxShadow,
	botaoCirculo,
	// transitionEaseBack,
	botaoCirculoHover,
} from 'routes/styl';

import InputCp from 'components/Form/Input';
import InputMaskCp from 'components/Form/Input/InputMask';
import InputSearchCp from 'components/Form/InputSearch';

const inputStyl = css`
	border: 1px solid var(--bg-hover-light);
	border-radius: var(--def-radius);
`;
export const Container = styled.div.attrs({ className: 'modal-center' })<any>`
	width: ${({ width }) => width};
    max-width: calc(100vw - 3rem);
	transition: 0.3s width ease-in-out;
	${boxShadow};
	padding: 2rem 2rem 3rem 2rem;
`;
export const WarpMap = styled.div`
	position: relative;
	width: 50rem;
	margin-left: 2rem;
	height: 40rem;
	margin-top: 3rem;
	position: relative;
	background-color: var(--bg-color);
`;
export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3rem;
	h1 {
		color: var(--btn-admin-icone);
	}
	button {
		padding: 0 1rem;
		${botaoCirculo}
		.font-ic {
			position: relative;
			z-index: 2;
		}
		&:hover::after {
			${botaoCirculoHover}
		}
	}
`;

export const ContainerForm = styled.div<any>`
	flex: 1;
	display: flex;
	justify-content: space-between;
	p {
        margin: 3rem auto;
		font-size: var(--size-p);
	}
	label {
		display: flex;
	}
	form {
        display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		.space-button {
            width: 100%;
			display: flex;
			margin-top: 1.5rem;
		}
		.select > div {
            ${inputStyl}
		}
	}
`;

export const Input = styled(InputCp)<any>`
	${inputStyl}
`;
export const InputMask = styled(InputMaskCp)<any>`
	${inputStyl}
`;
export const InputSearch = styled(InputSearchCp)<any>`
	${inputStyl}
`;
