import { useQuery } from '@apollo/client';
import { GRAPHQL_USUARIO_PERMISSOES } from 'gql/usuario';
import { useMemo } from 'react';
interface IPermissoes {
    resourceId?: string;
    editar?: boolean;
}
interface IPerfil {
    id?: number;
    administrador?: boolean;
    permissoes?: IPermissoes[];
}
interface IQuery {
    perfil?: IPerfil[];
}
interface IProps {
    searchResourceId?: string;
    action?: string;
}
export const useQueryPermissions = ({
    searchResourceId = 'lista-coleta',
    action = 'editar',
}: IProps = {}) => {
    const { data } = useQuery<IQuery>(GRAPHQL_USUARIO_PERMISSOES);
    const administrador = useMemo(() => {
        if (data?.perfil && data?.perfil.length > 0) {
            const indiceAdministrador = data?.perfil.findIndex(
                ({ administrador }) => administrador
            );
            return indiceAdministrador !== -1;
        }
        return false;
    }, [data]);

    const perfilAdministrador = useMemo(() => {
        if (data?.perfil && data?.perfil.length > 0) {
            const indiceAdministrador = data?.perfil.findIndex(
                ({ id }) => id === 1
            );
            return indiceAdministrador !== -1;
        }
        return false;
    }, [data]);
    const perfilUnidadeAdministrador = useMemo(() => {
        if (data?.perfil && data?.perfil.length > 0) {
            const indiceAdministrador = data?.perfil.findIndex(
                ({ id }) => id === 2
            );
            return indiceAdministrador !== -1;
        }
        return false;
    }, [data]);

    const agrupavel = useMemo(() => {
        if (administrador) {
            return true;
        } else if (data?.perfil && data?.perfil.length > 0) {
            const oPermissao = data?.perfil[0].permissoes.find(
                ({ resourceId }) => resourceId === searchResourceId
            );
            return oPermissao && oPermissao[action];
        }
        return false;
    }, [data]);

    return {
        agrupavel,
        administrador,
        perfilAdministrador,
        perfilUnidadeAdministrador,
    };
};
