import styled, { css } from 'styled-components';
import { shake } from 'routes/styl';
import ReactInputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';

const ic = css`
    width: 2rem;
    height: 2rem;
    fill: var(--text-cor);
`;
export const ContainerToogle = styled.a`
    cursor: pointer;
    padding: 1.5rem;
`;
export const Container = styled.div.attrs({ className: 'input' })<any>`
    display: flex;
    height: 7rem;
    flex-direction: column;
    > div {
        background-color: ${({ erro }) =>
            erro ? 'var(--error-cor-br)' : 'var(--bg-white)'};
        height: var(--h-input);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-radius: var(--def-radius);
        animation: ${({ erro }) => (erro ? shake : '')} 0.5s 1 ease-in-out;
        position: relative;

        border: 1px solid var(--bg-hover-light);

        &:after {
            content: '';
            width: 1rem;
            color: var(--error-cor);
            display: ${({ erro }) => (erro ? 'list-item' : 'none')};
            margin-right: 1rem;
            list-style-position: inside;
            font-size: var(--size-h4);
        }
        input {
            padding: 0 1rem;
        }
    }
`;
export const ContainerTextarea = styled.div.attrs({
    className: 'textarea',
})<any>`
    display: flex;
    flex-direction: column;
    > div {
        background-color: ${({ erro }) =>
            erro ? 'var(--error-cor-br)' : 'var(--bg-white)'};
        min-height: 100px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 0 1rem;
        border-radius: var(--def-radius);
        animation: ${({ erro }) => (erro ? shake : '')} 0.5s 1 ease-in-out;
        position: relative;
        overflow: hidden;
        &:after {
            margin-right: ${({ erro }) => (erro ? '0rem' : '-2.5rem')};
            content: '';
            width: 1rem;
            color: var(--error-cor);
            display: list-item;
            list-style-position: inside;
            font-size: var(--size-h4);
        }
    }
`;
export const LabelError: any = styled.span<any>`
    color: var(--error-cor);
    padding: 0.5rem 1rem 0;
    text-align: right;
    opacity: ${({ erro }) => (erro ? 1 : 0)};
    transition: all 0.4s linear;
    z-index: 3;
`;
export const Input = styled.input<any>`
    display: flex;
    width: 100%;
    height: 100%;
`;
export const Textarea = styled.textarea<any>`
    display: flex;
    width: 100%;
    resize: none;
    padding: 5px 0px;
    font-size: 1.6rem;
`;
export const InputHidden = styled(Input)`
    display: none;
`;
export const InputMask = styled(ReactInputMask)<any>`
    display: flex;
    width: 100%;
    height: 100%;
`;
export const InputMoney = styled(CurrencyInput)<any>`
    display: flex;
    width: 100%;
    height: 100%;
`;
