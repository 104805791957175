import { gql } from '@apollo/client';

export const GRAPHQL_QUERY_SHIPPING_IFOOD = gql`
    query iFoodOrders {
        iFoodOrders {
            id
            pedidos {
                pedido_ifood_id
                cliente
                pedido_delivery_id
                cep
                endereco
                complemento
                ponto_referencia
                numero
                bairro
                cidade
                uf
                telefone_contato
                forma_pagamento
                total_pedido
                valor_frete
                tipo_transporte
                troco
                latitude
                longitude
                forma_pagamento_id
            }
        }
    }
`;
export const GRAPHQL_CAPTURE_ORDER_IFOOD: any = gql`
    mutation captureOrder($action: String, $order: ICaptureOrder) {
        captureOrder(action: $action, order: $order)
    }
`;

export interface IIFoodOrders {
    iFoodOrders?: {
        pedidos?: IPedidos[];
    };
}
export interface IPedidos {
    bairro: string;
    cep: string;
    cidade: string;
    cliente: string;
    complemento: string;
    endereco: string;
    forma_pagamento: string;
    forma_pagamento_id: string;
    latitude: string;
    longitude: string;
    numero: string;
    pedido_delivery_id: String;
    pedido_ifood_id?: string;
    ponto_referencia: string;
    telefone_contato: string;
    tipo_transporte: string;
    total_pedido: string;
    troco: string;
    uf: string;
    valor_frete: string;
}
