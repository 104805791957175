import styled, { css } from 'styled-components';
import { boxShadow, botaoCirculo, botaoCirculoHover } from 'routes/styl';

export const Container = styled.div.attrs({ className: 'dropdown-wrap' })<any>`
    position: relative;
    > button {
        padding: 0 1rem;
        ${botaoCirculo}
        .font-ic {
            position: relative;
            z-index: 2;
            width: 1.7rem;
            height: 1.7rem;
            font-size: 1.7rem;
            margin: 0.6rem;
            color: var(--btn-admin-icone);
        }
        &:hover::after {
            ${botaoCirculoHover}
        }
    }
`;
export const Dropdown = styled.div`
    position: absolute;
    top: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 0;
    min-width: 240px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    transform: translateY(-2rem);
    ${boxShadow}
    &.menu-dropdown-right {
        right: 5px;
    }
    &:not(.menu-dropdown-right) {
        left: 5px;
    }
    &.show {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }
    button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1.5rem;
        text-align: left;
        cursor: pointer;
        &:hover {
            background-color: #f6f6f6;
        }
        .font-ic {
            font-size: 1.7rem;
            color: var(--btn-admin-icone);
            width: 1.7rem;
            height: 1.7rem;
            margin-right: 1.2rem;
        }
    }
    button + button {
        border-top: 1px solid #e2e2e2;
    }
`;
