import styled, { keyframes } from 'styled-components';
const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const scale = keyframes`
	from {
		opacity:0;
		transform:scale(.5);
	}
	to{
		opacity:1;
		transform:scale(1);
	}
`;
export const Container = styled.div<any>`
    display: ${({ show }) => {
        return show ? 'block' : 'none';
    }};
    animation: ${scale} 0.2s ease-out;
    ${({ marginLeft }) => (marginLeft ? `margin-left:${marginLeft}px;` : '')}
    ${({ marginRight }) =>
        marginRight ? `margin-right:${marginRight}px;` : ''}
`;
export const Loading = styled.div<any>`
    position: relative;
    text-indent: -9999em;
    border-top: ${({ sizeBorder }) => sizeBorder || 2}px solid
        ${({ secondaryColor }) => secondaryColor};
    border-right: ${({ sizeBorder }) => sizeBorder || 2}px solid
        ${({ secondaryColor }) => secondaryColor};
    border-bottom: ${({ sizeBorder }) => sizeBorder || 2}px solid
        ${({ secondaryColor }) => secondaryColor};
    border-left: ${({ sizeBorder }) => sizeBorder || 2}px solid
        ${({ primaryColor }) => primaryColor};
    transform: translateZ(0);
    animation: ${load} 1s infinite linear;
    width: ${props => props.size}rem;
    height: ${props => props.size}rem;
    border-radius: 50%;
    :after {
        border-radius: 50%;
        width: ${props => props.size}rem;
        height: ${props => props.size}rem;
    }
`;
