import React, { FC } from 'react';
import { IStatusQr } from '..';

export const ReadQrCode: FC<IStatusQr> = ({ qrCode }) => {
    return (
        <>
            <p className="mb">
                Escaneie o QR Code abaixo para conectar seu whatsapp ao sistema.
                Lembre-se, todos os dias seu whatsapp será desconectado as 2
                horas da manhã, com isto, será necessário você fazer a reconexão
                novamente quando for utilizar a ferramenta.
            </p>
            <img src={qrCode} alt="" />
        </>
    );
};
