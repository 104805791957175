import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GRAPHQL_FILTER_CLIENTE } from './../../../../../gql/cliente';
import { AuthContext } from './../../../../../routes/auth.context';
interface ICliente {
    email: string;
    id: string;
    nomeCompleto: string;
    telefoneCelular: string;
    telefoneFixo: string;
}
export const useQueryData = () => {
    const { unidadeSelecionada } = useContext(AuthContext);
    const { clienteId } = useParams<any>();

    const { loading, data } = useQuery<{
        cliente?: ICliente[];
    }>(GRAPHQL_FILTER_CLIENTE, {
        variables: {
            filter: { unidadeId: unidadeSelecionada.id, id: clienteId },
            unidadeId: unidadeSelecionada.id,
        },
    });

    return {
        loading,
        data: !loading && data.cliente.length > 0 ? data.cliente[0] : {},
    };
};
