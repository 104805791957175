import { FormHandles } from '@unform/core';
import React, { createRef, FC } from 'react';
import ComponentEscalaCriar from './Component';
import { useMutationPeriodo } from './hooks/useMutationPeriodo';
import { useQueryPeriodo } from './hooks/useQueryPeriodo';
const PageEscalaCriar: FC = () => {
    const { inputs, loading: loadingData } = useQueryPeriodo();
    const { loading, atualizaPeriodo } = useMutationPeriodo();

    const rForm = createRef<FormHandles>();

    const oProps = {
        rForm,
        inputs,
        loading,
        loadingData,
        handleSubmit: atualizaPeriodo,
    };
    return <ComponentEscalaCriar {...oProps} />;
};
export default PageEscalaCriar;
