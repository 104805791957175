import { transformData } from './../command/transformData';
import { useMutation } from '@apollo/client';
import { GRAPHQL_ADICIONAR_UNIDADE } from 'gql/unidade';
import { useCallback, useContext } from 'react';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';

export const useFetchSubmit = () => {
    // Controle de fluxo de navegação
    const history = useModalHistory();

    // Acesso ao alert para mensagem de erro se necessário
    const { alertConfirm } = useContext(AuthContext);

    // Mutação que é responsável por salvar os dados
    const [handleSaveUnidade, { loading }] = useMutation(
        GRAPHQL_ADICIONAR_UNIDADE
    );
    const handleFetchSubmit = useCallback(
        async (data) => {
            data = transformData(data);

            try {
                await handleSaveUnidade({
                    variables: {
                        data,
                    },
                });
                history.push('');
                await alertConfirm({
                    tipo: 'sucesso',
                    titulo: 'Cadastro de empresa',
                    conteudo: `Empresa ${data.nomeFantasia} cadastrada com sucesso!`,
                });
            } catch ({ message }) {
                await alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: `Falha ao salvar a unidade [${message}]`,
                });
            }
        },
        [alertConfirm, handleSaveUnidade, history]
    );
    return { handleFetchSubmit, loading };
};
