import React, { FC, memo } from 'react';
import { Container } from './styl';
import Step from './Step';
interface ISubmit {
    proximo: string;
}
interface IStep {
    nome: string;
    formularioSucesso: boolean;
}

interface IComponentStepByStep {
    stepSelected: number;
    fases: any;
    submit: any;
    colorSeparator?: string;
}

const ComponentStepByStep: FC<IComponentStepByStep> = memo(
    ({ stepSelected, fases, colorSeparator, submit }) => {
        let classeAnterior = 'anterior-vazio';
        const values: IStep[] = Object.values(fases);
        const keys: any = Object.keys(fases);
        return (
            <Container>
                {values.map(({ nome, formularioSucesso }, index) => {
                    const indice = keys[index];
                    const eFaseAtual =
                        stepSelected === index ? 'selecionado' : '';
                    const classeAtual = formularioSucesso ? 'sucesso' : 'erro';
                    const classeAgrupada = `${classeAnterior} ${classeAtual} ${eFaseAtual}`;
                    classeAnterior = `anterior-${classeAtual}`;
                    return (
                        <Step
                            colorSeparator={colorSeparator}
                            nome={nome}
                            submit={submit}
                            indice={indice}
                            key={`${nome}-${index}`}
                            classes={classeAgrupada}
                        />
                    );
                })}
            </Container>
        );
    },
);
export default ComponentStepByStep;
