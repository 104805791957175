import { useLazyQuery, useApolloClient } from '@apollo/client';
import { GRAPHQL_RESOURCE_UNIDADE_CONFIGURACAO, GRAPHQL_UNIDADE_FILTER } from 'gql/resourcesUnidadeConfiguracao';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { IConfiguracao } from 'types/modules/configuracao/index';
import { IModalTransporte } from 'types/modules/modalTransporte';
import { IResource } from 'types/modules/resource';
import { IUnidade } from 'types/modules/unidade';
interface IuseLoadMenuAndEmpresa {
    recuperarResourcesPorUsuario?: Array<IResource>;
    unidade?: {
        data?: Array<IUnidade>;
    };
    recuperarConfiguracao?: Array<IConfiguracao>;
    recuperarModalTransporte?: Array<IModalTransporte>;
}
export const useLoadMenuAndEmpresa = () => {
    const [
        lazyMenuEmpresa,
        { data, error },
    ] = useLazyQuery<IuseLoadMenuAndEmpresa>(
        GRAPHQL_RESOURCE_UNIDADE_CONFIGURACAO,
        {
            context: {
                headers: {
                    'limit-per-page': 100,
                },
            },
        }
    );
    const loadDefaultData = useCallback(() => {
        lazyMenuEmpresa();
    }, [lazyMenuEmpresa]);
    const client = useApolloClient();
    // dataConfiguracao
    const [unidades, setUnidades] = useState<Array<IUnidade>>([]);
    const [configuracao, setConfiguracao] = useState<Array<IConfiguracao>>([]);
    const [modalTransporte, setModalTransporte] = useState<Array<IModalTransporte>>([]);
    const [unidadeSelecionada, setDataUnidadeSelecionada] = useState<IUnidade>(
        {} as IUnidade
    );
    const [
        unidadePersonalizada,
        setDataUnidadePersonalizada,
    ] = useState<IUnidade | null>(null);

    useEffect(() => {
        if (data && data?.recuperarConfiguracao) {
            unstable_batchedUpdates(async () => {
                setConfiguracao(data?.recuperarConfiguracao);
                setModalTransporte(data?.recuperarModalTransporte);
                if (data?.unidade?.data?.length > 0) {
                    setUnidades(data?.unidade?.data);
                    const sId = localStorage.getItem('@oUnidade/id');
                    const sIdPersonalizada = localStorage.getItem(
                        '@oUnidadePersonalizada/id'
                    );
                    let oUnidadeSelecionada = null;
                    let oUnidadePersonalizada = null;
                    let unidadeSearch: {
                        data: {
                            unidade: {
                                data: Array<IUnidade>;
                            }
                        }
                    } = null;
                    if (sId) {
                        unidadeSearch = await client.query({
                            query: GRAPHQL_UNIDADE_FILTER,
                            variables: {
                                filter: {
                                    id: sId,
                                },
                            },
                        });
                        if (unidadeSearch.data.unidade.data.length) oUnidadeSelecionada = unidadeSearch.data.unidade.data[0];
                    }
                    if (sIdPersonalizada && unidadeSearch.data.unidade.data.length) {
                        if (unidadeSearch.data.unidade.data.length) oUnidadePersonalizada = unidadeSearch.data.unidade.data[0];
                    }
                    if (data?.unidade?.data?.length === 1) {
                        oUnidadeSelecionada = data?.unidade?.data[0];
                    }
                    setUnidadeSelecionada(oUnidadeSelecionada || {});
                    setUnidadePersonalizada(oUnidadePersonalizada || {});
                }
            });
        }
    }, [data]);
    // Salvando a unidade seleciona no storage
    const setUnidadeSelecionada = (unidade: IUnidade) => {
        if (unidade && unidade.id) {
            localStorage.setItem('@oUnidade/id', unidade.id);
            setDataUnidadeSelecionada(unidade || ({} as IUnidade));
        } else {
            localStorage.removeItem('@oUnidade/id');
            setDataUnidadeSelecionada(null);
        }
    };
    // Salvando a unidade seleciona no storage
    const setUnidadePersonalizada = (IUnidade: IUnidade) => {
        if (IUnidade && IUnidade.id) {
            localStorage.setItem('@oUnidadePersonalizada/id', IUnidade.id);
            setDataUnidadePersonalizada(IUnidade);
        } else {
            localStorage.removeItem('@oUnidadePersonalizada/id');
            setDataUnidadePersonalizada(null);
        }
    };
    return {
        loadDefaultData,
        unidades,
        configuracao,
        modalTransporte,
        unidadeSelecionada,
        setUnidadeSelecionada,
        unidadePersonalizada,
        setUnidadePersonalizada,
        setUnidades,
    };
};
