import Icone from 'components/Icone';
import styled from 'styled-components';

export const Container = styled.label`
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    cursor: pointer;
    input {
        display: none;
    }
    position: relative;
    span {
        position: absolute;
        width: 2rem;
        height: 2rem;
        background-color: var(-bg-white);
        border: 1px solid var(--bg-hover-light);
        border-radius: 4px;
    }
    .font-ic {
        display: none;
    }
    input:checked ~ span {
        border: none;
        background-color: var(--btn-admin-icone-hover);
    }
    input:checked ~ span ~ .font-ic {
        display: block;
        z-index: 2;
    }
`;
export const CheckBoxDisable = styled(Icone)<any>``;
