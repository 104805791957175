import styled from 'styled-components';
import { moveIn, moveOut } from 'routes/styl';
export const GroupRoot = styled.div.attrs({ id: 'group-root-modal' })`
    z-index: 11 !important;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    + main {
        overflow: hidden;
        position: fixed;
        height: 100vh;
        width: 100vw;
    }
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Warp = styled.div`
    animation: 500ms ${moveIn} cubic-bezier(0.165, 0.84, 0.44, 1);
    &.close {
        animation: ${moveOut} 100ms forwards;
    }
    background-color: var(--bg-white);
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    margin: auto 0;
`;
