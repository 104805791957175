import CircularLoading from 'components/atom/Loading/CircularLoading';
import { colourStyles } from 'components/Form/SelectCreatable/styl';
import { format } from 'date-fns';
import React, {
    FC,
    Fragment,
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import CreatableSelect from 'react-select/creatable';
import { useModalHistory } from 'routes/admin/hook/useModalHistory';
import { AuthContext } from 'routes/auth.context';
import {
    Container,
    Container2,
    Container3,
    IconeSearch,
    IconeSetaBaixo,
    Input,
    LoadingContainer,
} from './styl';

const SuperFiltroSearch: FC<any> = memo(
    ({
        onSearch,
        dataSelect,
        addFilterDate = false,
        loadingSearch,
        disableFutureDates,
    }) => {
        const { cacheFiltroData } = useContext(AuthContext);
        const history = useModalHistory();
        const inputEl = useRef(null);
        const selectRef = useRef(null);
        const formData = useRef({} as any);
        const subFieldsSelectRef = useRef(null);

        const [filterSelectValue, setFilterSelectValue] = useState(
            dataSelect && dataSelect.length > 0 ? dataSelect[0] : ''
        );

        const selectStyles = useMemo(
            () =>
                colourStyles({
                    indicatorSeparator: {
                        backgroundColor: 'none',
                    },
                    indicatorsContainer: {
                        borderRight: '1px solid var(--bg-hover-light)',
                    },
                    control: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                }),
            []
        );
        const selectSubFieldsStyles = useMemo(
            () =>
                colourStyles({
                    indicatorSeparator: {
                        backgroundColor: 'none',
                    },
                    indicatorsContainer: {
                        borderRight: '1px solid var(--bg-hover-light)',
                    },
                    control: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    },
                }),
            []
        );
        const hanfleSubmit = useCallback(() => {
            if (onSearch) onSearch(formData.current);
        }, []);
        const handleChange = useCallback((e) => hanfleSubmit(), []);
        const handleChangeSelect = useCallback(
            (option) => {
                inputEl.current.value = '';
                if (
                    subFieldsSelectRef.current &&
                    subFieldsSelectRef.current.state &&
                    subFieldsSelectRef.current.state.value.inputName
                ) {
                    formData.current[
                        subFieldsSelectRef.current.state.value.inputName
                    ] = '';
                }
                if (
                    filterSelectValue &&
                    filterSelectValue.value &&
                    filterSelectValue.value.length > 1
                ) {
                    formData.current[filterSelectValue.value] = '';
                }
                if (option.value && option.value.length > 1) {
                    formData.current[option.value] = '';
                }
                setFilterSelectValue(option);
                hanfleSubmit();
            },
            [inputEl, hanfleSubmit, filterSelectValue, setFilterSelectValue]
        );
        const handleChangeSelectSubfield = useCallback(
            (option) => {
                if (option.inputName && option.inputName.length > 1) {
                    formData.current[option.inputName] = option.value;
                }

                hanfleSubmit();
            },
            [inputEl]
        );
        const handleKeyDown = useCallback(
            (e) => {
                if (
                    filterSelectValue &&
                    filterSelectValue.value &&
                    filterSelectValue.value.length > 1
                ) {
                    formData.current[filterSelectValue.value] = e.target.value;
                }
                if (e.key === 'Enter') {
                    handleChange(e);
                }
            },
            [handleChange, filterSelectValue]
        );
        const handleCalendario = useCallback(
            (e) => {
                history.push(`calendario`);
            },
            [disableFutureDates]
        );
        useEffect(() => {
            const isSubmit = !!formData.current.dataSelecionada;
            formData.current.dataSelecionada = format(
                cacheFiltroData,
                'yyyy-MM-dd'
            );
            if (isSubmit) {
                hanfleSubmit();
            }
        }, [cacheFiltroData]);

        const placeholder = useMemo(() => {
            const option = dataSelect.find(
                (o) => o.value === filterSelectValue.value
            );
            return option ? option.label : '';
        }, [filterSelectValue]);

        return (
            <>
                {addFilterDate && (
                    <Container3 onClick={handleCalendario}>
                        <div>
                            <span>
                                {format(
                                    new Date(cacheFiltroData),
                                    'dd/MM/yyyy'
                                )}
                            </span>
                            <IconeSetaBaixo
                                icone="icon-selecionar2"
                                size="2rem"
                            />
                        </div>
                    </Container3>
                )}

                {dataSelect && (
                    <>
                        <Container2>
                            <CreatableSelect
                                ref={selectRef}
                                placeholder="Todos"
                                styles={selectStyles}
                                onChange={handleChangeSelect}
                                options={dataSelect}
                                defaultValue={
                                    dataSelect.length ? dataSelect[0] : null
                                }
                            />
                        </Container2>
                        {dataSelect.map(
                            ({ subFields, value, inputName }, key) => {
                                if (
                                    filterSelectValue &&
                                    inputName === filterSelectValue.inputName &&
                                    subFields
                                ) {
                                    return (
                                        <Container2
                                            key={`subField-${key}-${value}-${inputName}-${subFields.length}`}
                                        >
                                            <CreatableSelect
                                                ref={subFieldsSelectRef}
                                                placeholder="Todos"
                                                styles={selectSubFieldsStyles}
                                                onChange={
                                                    handleChangeSelectSubfield
                                                }
                                                options={subFields.map(
                                                    (option) => ({
                                                        ...option,
                                                        inputName: inputName,
                                                    })
                                                )}
                                                defaultValue={
                                                    subFields.length
                                                        ? {
                                                              inputName: value,
                                                              ...subFields[0],
                                                          }
                                                        : null
                                                }
                                            />
                                        </Container2>
                                    );
                                }
                                return null;
                            }
                        )}
                    </>
                )}
                <Container>
                    <Input
                        rounded={dataSelect}
                        ref={inputEl}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder || 'Pesquisar...'}
                    />
                    {!loadingSearch ? (
                        <button onClick={(e) => {
                            formData.current.dataSelecionada = format(
                                cacheFiltroData,
                                'yyyy-MM-dd'
                            );
                            formData.current[filterSelectValue.value] = inputEl.current.value;
                            handleChange(e);
                        }}>
                            <IconeSearch
                                loadingSearch
                                icone="icon-search"
                                size="2.5rem"
                            />
                        </button>
                    ) : (
                        <LoadingContainer>
                            <CircularLoading show={true} size={2} />
                        </LoadingContainer>
                    )}
                </Container>
            </>
        );
    }
);
export default SuperFiltroSearch;
