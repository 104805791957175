// eslint-disable-next-line no-use-before-define
import React, { FC, memo, useEffect } from 'react';
import { defTitle } from 'assets/strings';
import SimplesForm from 'components/modal/template/simplesForm';
import FormGenerator from 'components/Form/template/FormGenerator';

const ComponentClienteEditar: FC<any> = memo((oProps) => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = `${defTitle} - Editar dados bancário`;
    });
    return (
        <SimplesForm
            rForm={oProps.rForm}
            handleSubmit={oProps.handleSubmit}
            loading={oProps.loading}
            titleSubmit={'Salvar'}
            titleModal={'Dados bancário'}
            message={null}
        >
            <FormGenerator
                rForm={oProps.rForm}
                childs={[
                    oProps.pix,
                    {
                        gridFirstWidth: 1,
                        items: [
                            {
                                type: 'select-async',
                                label: 'Nome do banco',
                                toolTipLegend: null,
                                placeholder: 'Selecione',
                                name: 'contaBancoId',
                                defaultValue: oProps.values?.contaBancaria
                                    ?.contaBanco
                                    ? {
                                          value:
                                              oProps.values.contaBancaria
                                                  .contaBanco.id,
                                          label:
                                              oProps.values.contaBancaria
                                                  .contaBanco.nome,
                                      }
                                    : null,
                                ...oProps.selectAsyncClient,
                            },
                            {
                                type: 'select-simple',
                                label: 'Tipo de conta',
                                placeholder: 'Selecione',
                                name: 'contaTipo',
                                options: oProps.tipoConta,
                                defaultValue: oProps.contaTipoSelected,
                            },
                        ],
                    },
                    {
                        gridFirstWidth: 1,
                        items: [
                            {
                                type: 'text',
                                label: 'Nome',
                                placeholder: 'Nome do titular',
                                name: 'titularNome',
                                defaultValue:
                                    oProps.values.contaBancaria?.titularNome,
                            },
                            {
                                type: 'mask',
                                label: 'CPF',
                                mask: '999.999.999-99',
                                placeholder: 'Cpf',
                                name: 'titularCpf',
                                defaultValue:
                                    oProps.values.contaBancaria?.titularCpf,
                            },
                        ],
                    },
                    {
                        gridFirstWidth: 2,
                        items: [
                            {
                                type: 'number',
                                label: 'Agência',
                                placeholder: 'Agência somente números',
                                name: 'contaAgencia',
                                defaultValue:
                                    oProps.values?.contaBancaria?.contaAgencia,
                            },
                            {
                                type: 'number',
                                label: 'Dv',
                                placeholder: 'Dv da agência',
                                name: 'contaAgenciaDv',
                                defaultValue:
                                    oProps.values?.contaBancaria
                                        ?.contaAgenciaDv,
                            },
                        ],
                    },
                    {
                        gridFirstWidth: 2,
                        items: [
                            {
                                type: 'number',
                                label: 'Conta',
                                placeholder: 'Conta somente números',
                                name: 'contaNumero',
                                defaultValue:
                                    oProps.values?.contaBancaria?.contaNumero,
                            },
                            {
                                type: 'number',
                                label: 'Dv',
                                placeholder: 'Dv da conta',
                                name: 'contaNumeroDv',
                                defaultValue:
                                    oProps.values?.contaBancaria?.contaNumeroDv,
                            },
                        ],
                    },
                ]}
            />
        </SimplesForm>
    );
});
export default ComponentClienteEditar;
