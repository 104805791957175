import styled from 'styled-components';
import { boxShadow } from 'routes/styl';
export const Container = styled.button`
    flex: 1;
    height: 5.5rem;
    border-radius: 1.5rem;
    background: var(--btn-gradient-active-cor);
    &.cancel {
        background: var(--btn-gradient-reject-cor);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    span {
        color: var(--bg-white);
        font-size: var(--size-h4);
        transition: transform 0.3s ease-out;
        padding: 0px 0px 0px 4px;
    }
    background-size: 150% 5.5rem;
    &:hover {
        background-position: 100%;
        ${boxShadow}
    }
    &:disabled span {
        transform: translateX(-2rem);
    }
    &:disabled {
        background-position: 100%;
        cursor: none;
    }
    .showLoading {
        width: 11px;
    }
`;
