import { useMemo } from 'react';
import JSON_DATA_HEADER from '../data/header.json';
import JSON_DATA_HEADER_USER from '../data/headerUser.json';
export const useHeaderUnit = ({ administrador }) => {
   let newJSON_DATA_HEADER = JSON_DATA_HEADER;
    if(!administrador){
        newJSON_DATA_HEADER = JSON_DATA_HEADER_USER;
    }
    return useMemo(() => {
        return [
            ...newJSON_DATA_HEADER,
            {
                key: 5,
                width: '1.5fr',
                marginRight: '1rem',
            },
        ];
    }, []);
};
