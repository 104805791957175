import styled from 'styled-components';
import Icone from 'components/Icone';
import { boxShadow, textShadow, transitionEaseBack } from 'routes/styl';
import { Link } from 'react-router-dom';

export const Container = styled.div.attrs({ className: 'header' })`
    flex: 1;
    z-index: 3;
    span,
    p,
    h1 {
        ${textShadow};
        color: var(--bg-white);

        &.titulo-unidades {
            @media (max-width: 1439.8px) {
                font-size: 2rem;
            }

            @media (max-width: 479.8px) {
                font-size: 1.3rem;
            }
        }
    }
    display: flex;
    .logout {
        button {
            padding: 2rem;
        }
    }
    .icon-voltar {
        padding: 0 2rem;
    }

    .adicionar-credito {
        margin: 2rem;
        a {
            height: var(--h-input);
            padding-left: 2rem;
            padding-right: 2rem;
            color: white;
            line-height: normal;
            font-style: normal;
            border-radius: var(--def-radius);
            transition: background-color 0.3s ease-in-out;
            background-color: var(--btn-admin-icone);
            &:hover {
                background-color: var(--btn-admin-icone-hover);
                color: var(--btn-admin-icone);
            }
            .font-ic {
                display: none;
            }
            span {
                font-size: 1.4rem;
            }

            @media (max-width: 719.8px) {
                border-radius: 50%;
                padding-left: 0;
                padding-right: 0;
                width: var(--h-input);
                &:hover {
                    color: var(--btn-admin-icone);
                    .font-ic {
                        color: var(--btn-admin-icone);
                        &::before {
                            opacity: 1;
                        }
                    }
                }
                .font-ic {
                    display: inherit;
                }
                span {
                    display: none;
                }
            }
        }
    }
    .selecionar-estabelecimento {
        flex: 1;
    }
    .credenciais_usuario {
    }
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button,
    a {
        display: flex;
        text-decoration: none;
        font-style: none;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        &:hover > div::before {
            opacity: 0.1;
            transform: scale(1);
        }
    }
`;
export const CardUser = styled(Link)`
    position: relative;

    &::after {
        content: '';
        width: 1px;
        margin-left: 1rem;
        opacity: 0.3;
        background: var(--bg-white);
        display: block;
        height: 4.5rem;
    }
    .nomeEmail {
        margin: auto 0.5rem auto 0.5rem;
    }
    .avatar {
        border-radius: 50%;
        width: 31px;
        height: 31px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: var(--bg-white);
        img {
            ${boxShadow};
        }
    }

    @media (max-width: 1023.89px) {
        .nomeEmail {display: none;}
    }
`;
export const WarpIcon = styled.div`
    position: relative;
    .font-ic {
        position: relative;
        z-index: 2;
    }
    &::before {
        ${transitionEaseBack};
        content: '';
        width: 3rem;
        height: 3rem;
        opacity: 0;
        transform: scale(0.5);
        background-color: var(--bg-white);
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        z-index: 1;
    }
`;
export const SetaSelect = styled(Icone)<any>`
    color: var(--bg-white);
`;
export const LogoutIcon = styled(Icone)<any>`
    color: var(--bg-white);
`;
