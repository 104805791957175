import { subDays, subMonths } from 'date-fns';
import { YupUtilsTry } from 'libs/yup/ShapeValidation';
import * as Yup from 'yup';
export const validacao = async ({ setErrors, data }) => {
    const newDate = {
        dataFim: data.dataFim,
        dataInicio: data.dataInicio,
    };

    return await YupUtilsTry(newDate, setErrors, {
        dataInicio: Yup.date()
            .min(
                subMonths(subDays(newDate.dataFim, 7), 1),
                `Intervalo não pode ser superior a 37 dias`
            )
            .max(Yup.ref('dataFim'), 'Não pode ser superior a data fim'),
        dataFim: Yup.date().max(
            new Date(),
            'Não pode ser superior a data atual'
        ),
    });
};
