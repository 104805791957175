import { useEntregadorRelatorioPgtoSintetico } from './entregador/useEntregadorRelatorioPgtoSintetico';
import { useRelatorioMestreUnidadeSin } from './mestre/useRelatorioMestreUnidadeSin';
import { FormHandles, SubmitHandler } from '@unform/core';
import { ICallbackProps } from 'components/Form/InputFile';
import { startOfWeek } from 'date-fns';
import { YupUtilsCatch } from 'libs/yup/ShapeValidation';
import { createRef, useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import { ValidationError } from 'yup';
import { prepareDados } from './../uteis/prepareDados';
import { validacao } from './../uteis/validacao';
import { useEntregadorRelatorioPgtoAnalitico } from './entregador/useEntregadorRelatorioPgtoAnalitico';
import { useRelatorioEstabelecimentoAnalitico } from './estabelecimento/useRelatorioEstabelecimentoAnalitico';
import { useRelatorioEstabelecimentoSintetico } from './estabelecimento/useRelatorioEstabelecimentoSintetico';
import { useRelatorioMestreAnalitico } from './mestre/useRelatorioMestreAnalitico';
import { useRelatorioMestreAnaliticoPeriodoSintetico } from './mestre/useRelatorioMestreAnaliticoPeriodoSintetico';
import { useEntregadorRelatorioPgtoSinteticoDia } from './entregador/useEntregadorRelatorioPgtoSinteticoDia';
import { usePlayerScaled } from './entregador/usePlayerScaled';
import { useEntregadorRelatorioPgtoSinteticoNotaFiscal } from './entregador/useEntregadorRelatorioPgtoSinteticoNotaFiscal';

interface IPropsSubmit {
    dataInicio: Date;
    dataFim: Date;
    tipoRelatorio: string;
    dataVencimentoBoleto?: Date;
    notasFiscais?: ICallbackProps[];
    boletos?: ICallbackProps[];
    periodoId?: string | number;
}
interface IError {
    message: string;
    response?: {
        data?: {
            message: string;
        };
    };
}

export const useQueryRelatorio = () => {
    const history = useHistory();
    const { unidadeId, entregadorId } = useParams<any>();
    const formRef = createRef<FormHandles>();
    const { alertConfirm, axios } = useContext(AuthContext);
    const dataStart = useMemo(() => startOfWeek(new Date()), []);
    const dataEnd = useMemo(() => new Date(), []);

    const [isUploading, setIsUploading] = useState(false);
    // Relatório entregador

    const playerPgtoAna = useEntregadorRelatorioPgtoAnalitico();
    const playerPgtoSin = useEntregadorRelatorioPgtoSintetico();
    const playerPgtoSinNotaFiscal = useEntregadorRelatorioPgtoSinteticoNotaFiscal();
    const playerPgtoSinDay = useEntregadorRelatorioPgtoSinteticoDia();
    const playerScaled = usePlayerScaled();

    // Relatório mestre
    const mestreAna = useRelatorioMestreAnalitico();
    const mestreAnaPeriodoSin = useRelatorioMestreAnaliticoPeriodoSintetico();
    const mestreUnidadeSin = useRelatorioMestreUnidadeSin();

    // Relatório estabelecimento
    const estabelecimentoAna = useRelatorioEstabelecimentoAnalitico();
    const estabelecimentoSin = useRelatorioEstabelecimentoSintetico();

    const handleSubmit: SubmitHandler<IPropsSubmit> = async (data) => {
        const { setErrors } = (formRef.current || {}) as FormHandles;

        try {
            await validacao({ data, setErrors });

            const variables = prepareDados({
                ...data,
                unidadeId,
                entregadorId,
            });
            if (data.dataVencimentoBoleto) {
                setIsUploading(true);
                axios
                    .post('/faturas', variables)
                    .then((response) => {
                        alertConfirm({
                            tipo: 'info',
                            titulo: 'Sucesso',
                            conteudo: 'Faturas geradas com sucesso',
                        });
                        history.replace('/admin/fatura');
                    })
                    .catch((error) => {
                        const erro = error as IError;
                        setIsUploading(false);
                        alertConfirm({
                            tipo: 'erro',
                            titulo: 'Erro',
                            conteudo:
                                erro?.response?.data?.message || erro.message,
                        });
                    });
            } else {
                switch (data.tipoRelatorio) {
                    case 'playerScaled':
                        return playerScaled.handleClick(variables, 'ESCALADO');
                    case 'playerInterest':
                        return playerScaled.handleClick(
                            variables,
                            'INTERESSADO'
                        );
                    case 'playerPgtoAna':
                        //finalizado
                        return playerPgtoAna.handleClick(variables);
                    case 'playerPgtoSin':
                        //finalizado
                        return playerPgtoSin.handleClick(variables);
                    case 'playerPgtoSinNotaFiscal':
                        //finalizado
                        return playerPgtoSinNotaFiscal.handleClick(variables);
                    case 'playerPgtoSinDay':
                        return playerPgtoSinDay.handleClick(variables);
                    case 'mestreAna':
                        //finalizado
                        return mestreAna.handleClick(variables);
                    case 'mestreAnaPeriodoSin':
                        return mestreAnaPeriodoSin.handleClick(variables);
                    case 'mestreUnidadeSin':
                        //finalizado
                        return mestreUnidadeSin.handleClick(variables);
                    case 'estabelecimentoAna':
                        //finalizado
                        return estabelecimentoAna.handleClick(variables);
                    case 'estabelecimentoSin':
                        //finalizado
                        return estabelecimentoSin.handleClick(variables);
                    default:
                        throw new Error(
                            'Método de relatório não foi programado'
                        );
                }
            }
        } catch (err: any) {
            console.error(err);
            if (err instanceof ValidationError) {
                YupUtilsCatch(err, setErrors);
            } else {
                alertConfirm({
                    tipo: 'erro',
                    titulo: 'Erro',
                    conteudo: err.message,
                });
            }
        }
    };

    return {
        loading:
            playerPgtoAna.loading ||
            mestreAna.loading ||
            mestreAnaPeriodoSin.loading ||
            mestreUnidadeSin.loading ||
            estabelecimentoAna.loading ||
            estabelecimentoSin.loading ||
            isUploading,
        formRef,
        dataStart,
        dataEnd,
        maxDate: dataEnd,
        handleSubmit,
    };
};
