import React, { FC } from 'react';
import CircularLoading from 'components/atom/Loading/CircularLoading';
export const Loading: FC = () => {
    return (
        <>
            <p className="mb">
                Estamos inicializando o servidor whatsapp, favor aguarde (Está
                ação pode levar até 60 segundos para estabelecer a conexão de
                forma segura).
            </p>
            <CircularLoading
                show={true}
                size={3}
                primaryColor="var(--btn-underline-cor)"
            />
        </>
    );
};
