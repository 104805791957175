import { useState, useEffect, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { AuthContext } from 'routes/auth.context';
import { upperCamelCase } from 'libs/StringUteis';

export const useSelectAwaitFetch = ({
    rootKey,
    legendAddOption = null,
    transformPostSendMutation,
    transformPostSendMutationStart,
    transformOption,
    isSelected,
    gql,
    selectFirstItem = true,
}) => {
    const [handleMutation] = useMutation(gql);

    // usado para informar erro no carregamento de dados caso necessário
    const { alertConfirm } = useContext(AuthContext);

    // Dados de callback do react select
    const [select, setSelect] = useState({ search: null, callBack: null });

    // Dados filtrados após o carregamento
    const [filterDataSelect, setFilterDataSelect] = useState([]);

    // Cache de todos os dados carregados
    const [bigDataSelect, setBigDataSelect] = useState([]);

    // Valor selecionado
    const [value, setValue] = useState(null);

    // Informa se é a primeira carga
    const [isLoadStart, setIsLoadStart] = useState(true);

    // Carrega e trata os dados ao digitar
    const loadMutation = useCallback(async () => {
        const { search } = select;
        try {
            let variables = {};
            if (isLoadStart) {
                variables = transformPostSendMutationStart();
            } else {
                variables = transformPostSendMutation(search);
            }

            const { data }: any = await handleMutation({
                variables,
            });

            // Pegando os dados de uma chave especifica do graphql
            const dados = rootKey && rootKey in data ? data[rootKey] : data;

            // Tratando dados para colocar nos options
            const options = dados.length
                ? dados
                      .map((v) => transformOption(select.search, v))
                      .filter(({ novo }) => !novo)
                : [];

            // ne necessário adicionar um novo option de "Criar novo Item"
            if (
                select.search !== null &&
                select.search.length > 3 &&
                legendAddOption
            ) {
                const hasThisOption =
                    options.filter(
                        ({ value }) =>
                            value.trim().toLowerCase() ===
                            select.search.trim().toLowerCase()
                    ).length === 0;

                if (hasThisOption) {
                    const value = upperCamelCase(select.search);
                    options.unshift({
                        novo: true,
                        value,
                        label: `${legendAddOption} "${value}"`,
                    });
                }
            }
            // Setando option selecionado em primeira carga
            if (isLoadStart && options.length === 1 && selectFirstItem) {
                setValue(options[0]);
            }

            // Adicionando dados para analise e posteriormente popular os options
            setFilterDataSelect(options);
        } catch ({ message }) {
            setFilterDataSelect([]);
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertConfirm, handleMutation, select]);

    // Verifica se deve carregar ou esperar a digitação terminar
    useEffect(() => {
        const { search, callBack } = select;
        let timer = null;
        if (isLoadStart) {
            loadMutation();
        } else if (search) {
            timer = setTimeout(loadMutation, 300);
        } else if (callBack) {
            callBack(filterDataSelect);
        }
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [filterDataSelect, isLoadStart, loadMutation, select]);

    // Populando os options
    useEffect(() => {
        if (select && select.callBack !== null) {
            select.callBack(filterDataSelect);
            setSelect({ search: null, callBack: null });
        }
        if (filterDataSelect.length > 0) {
            const newBigdata = [...bigDataSelect, ...filterDataSelect]
                .filter(
                    (e, i, arr) =>
                        arr.findIndex((e2) =>
                            Object.keys(e2).every(
                                (prop) => e2[prop] === e[prop]
                            )
                        ) === i
                )
                .filter(({ novo }) => !novo);
            setBigDataSelect(newBigdata);
        }
        if (isLoadStart) setIsLoadStart(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDataSelect]);

    // Recebe o ballback do react select para posteriormente fazer pesquisa
    const loadOptions = (search, callBack) => {
        setSelect({ search, callBack });
    };

    // Evendo de quando clica em um option
    const onChange = useCallback(
        (option) => {
            const { value, novo } = option;
            let oOptionTmp: any = {};
            if (novo) {
                oOptionTmp = option;
            } else {
                oOptionTmp = bigDataSelect.find((item) =>
                    isSelected(item, value)
                );
            }
            setValue(oOptionTmp);
        },
        [bigDataSelect, isSelected]
    );

    // Adicionando um option manualmente
    const addOption = useCallback(
        (keyName, data) => {
            const newData = transformOption(data[keyName], data);
            const newBigdata = [...bigDataSelect, newData]
                .filter(
                    (e, i, arr) =>
                        arr.findIndex((e2) =>
                            Object.keys(e2).every(
                                (prop) => e2[prop] === e[prop]
                            )
                        ) === i
                )
                .filter(({ novo }) => !novo);
            setBigDataSelect(newBigdata);
            setValue(newData);
        },
        [bigDataSelect, transformOption]
    );
    return {
        addOption,
        select: {
            cacheOptions: bigDataSelect,
            loadOptions,
            onChange,
            value,
            setValue,
        },
    };
};
