import { gql } from '@apollo/client';

export const GRAPHQL_BUSCAR_UNIDADE_FATURA: any = gql`
    query unidadeFaturaFilter($filter: UnidadeFaturaFilter) {
        unidadeFatura(filter: $filter) {
            hasNextPage
            data {
                id
                unidadeId
                periodoTurno
                razaoSocial
                nomeFantasia
                identificador
                periodoDataInicial
                periodoDataFinal
                faturaNotaFiscal
                faturaBoleto
                faturaDataPagamento
                adimplente
            }
        }
    }
`;
export const GRAPHQL_DELETE_UNIDADE_FATURA: any = gql`
    mutation deleteUnidadeFatura($faturaId: ID) {
        deleteUnidadeFatura(faturaId: $faturaId)
    }
`;
