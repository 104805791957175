import { gql } from '@apollo/client';

export const GRAPHQL_ENQUETE_PROSPECTO = gql`
    query enqueteAtivaProspecto {
        enqueteAtivaProspecto {
            id
            nome
            perguntas {
                id
                titulo
                subtitulo
                alternativas {
                    valor
                }
            }
        }
    }
`;
export const GRAPHQL_ADICIONAR_PROSPECTO = gql`
    mutation adicionarProspectoContainer($data: PropectoInput) {
        adicionarProspecto(data: $data)
    }
`;
