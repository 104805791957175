import React, { FC, memo, useContext } from 'react';
import PersonalComponent from './component';

import { SubmitHandler } from '@unform/core';
import * as yup from 'yup';
import { YupUtilsTry, YupUtilsCatch } from 'libs/yup/ShapeValidation';
import { SignupContext } from '../context';
import { useTelefone } from './hooks/useTelefone';
import { matchTelefone } from 'libs/yup/matches';
const Personal: FC = memo(() => {
    const {
        steps: {
            personal: { ref: formRef },
        },
        initialDataForm,
        switchComponent,
        submit,
    } = useContext(SignupContext);
    const mascaraTelefone = useTelefone();
    const handleSubmit: SubmitHandler<IFormLogin> = async (data, { reset }) => {
        const { setErrors } = formRef.current;
        try {
            await YupUtilsTry(data, setErrors, {
                nome: yup
                    .string()
                    .min(3, 'Nome inválido')
                    .max(60)
                    .required('Nome não informado'),
                sobrenome: yup
                    .string()
                    .min(3, 'Nome inválido')
                    .max(60)
                    .required('Sobrenome não informado'),
                email: yup
                    .string()
                    .min(3, 'Email inválido')
                    .max(60)
                    .email('Email inválido')
                    .required('Email não informado'),
                telefone: yup
                    .string()
                    .min(14, 'Telefone inválido')
                    .max(16, 'Telefone inválido')
                    .matches(matchTelefone, 'Formato de telefone inválido')
                    .required('Telefone não informado'),
            });
            submit({ atual: 'personal', proximo: 'business' });
        } catch (err) {
            YupUtilsCatch(err, setErrors);
        }
    };
    const props: any = {
        formRef,
        mascaraTelefone,
        initialData: initialDataForm[switchComponent],
        handleSubmit,
    };
    return <PersonalComponent {...props} />;
});
export default Personal;
