import MiniCardColuna from 'components/MiniCard/Coluna';
import SuperFiltro from 'components/SuperFiltro';
import { useQueryPermissions } from './hooks/useQueryPermissions';
import { useTotalEntregadorEscalado } from './../listar/hooks/useTotalEntregadorEscalado';
import React, { FC, useMemo } from 'react';
import { WarpHeaderFixo } from 'routes/admin/partial/template/styl';
import { useTransformColeta } from './hooks/useTransformColeta';
import { Container, ContainerScroll } from './styl';
import { useQuerySubscriptionFilter } from './hooks/useQuerySubscriptionFilter';

const ListarColetasMiniCard: FC<any> = () => {
    const totalEscalado = useTotalEntregadorEscalado();
    const permissoes = useQueryPermissions({
        searchResourceId: 'lista-coleta-mini-card',
        action: 'editar',
    });

    const items = [
        {
            title: 'Salvo e Pendente',
            status: [1, 8],
            cards: [],
            hasNextPage: false,
        },
        {
            title: 'Aceite',
            status: [2],
            cards: [],
            hasNextPage: false,
        },
        {
            title: 'Check(in/out) estabelecimento',
            status: [3, 4],
            cards: [],
            hasNextPage: false,
        },
        {
            title: 'Checkin cliente',
            status: [5],
            cards: [],
            hasNextPage: false,
        },
        {
            title: 'Concluído e cancelado',
            status: [6, 7],
            cards: [],
            hasNextPage: false,
        },
    ];

    items.forEach((item, index) => {
        const props = useQuerySubscriptionFilter({ status: item.status });
        item.cards = useTransformColeta({
            coletas: props.response,
            permissoes,
            totalEntregadorEscalado: totalEscalado,
            timeAdicionalCollectt: props.timeAdicionalCollectt,
            timeEsperaEstabelecimento: props.timeEsperaEstabelecimento,
        });
        item.hasNextPage = props.hasMore;
    });

    const loading = false;
    const addFilterDate = true;
    const dataSelectHeader = [];

    return (
        <>
            <WarpHeaderFixo>
                <SuperFiltro
                    loadingSearch={loading}
                    // onSearch={handleSearch}
                    addFilterDate={addFilterDate}
                    dataSelect={dataSelectHeader}
                    disableFutureDates={true}
                />
            </WarpHeaderFixo>
            <Container>
                <ContainerScroll>
                    <div className="colunas">
                        {items.map(
                            ({ title, status, cards, hasNextPage }, index) => (
                                <MiniCardColuna
                                    key={`key-${index}`}
                                    title={title}
                                    status={status}
                                    cards={cards}
                                    hasNextPage={hasNextPage}
                                />
                            ),
                        )}
                    </div>
                </ContainerScroll>
            </Container>
        </>
    );
};
export default ListarColetasMiniCard;
