import { useCallback, useEffect, useMemo, useState } from 'react';

import optionsStatusJson from '../data/options-status.json';
import trocoJson from '../data/input-troco.json';

export const useManagerInputs = (props: any) => {
    const handleChange = useCallback((data) => {
        setTroco(data.value.id === 1 ? trocoJson : []);
        setRetorno(data.value.exigeTaxaRetorno);
    }, []);
    const [troco, setTroco] = useState<any[]>([]);
    const [retorno, setRetorno] = useState(true);
    useEffect(() => {
        if (
            props &&
            props.initialData &&
            props.initialData.valorTroco !== null
        ) {
            trocoJson[0].defaultValue = props.initialData.valorTroco;
            setTroco(() => {
                return trocoJson;
            });
        }
    }, [props.initialData]);

    const [modalTransporteId, setModalTransporteId] = useState<number>(1);
    const response = useMemo(() => {
        if (props.cartoes.length === 0) {
            return [];
        }
        const optionsModal =
            props && props.fretes
                ? props.fretes
                      .map(({ id, nome, frete }) => ({
                          value: id,
                          label: `${nome} (R$ ${frete})`,
                      }))
                      .sort((a, b) => a.value - b.value)
                : null;
        return [
            {
                toolTipLegend: 'Informe a forma de pagamento para esta venda.',
                type: 'select-simple',
                label: 'Forma de pagamento',
                placeholder: 'Selecione',
                name: 'formaPagamentoId',
                options: [
                    {
                        label: "Selecione a forma de pagamento",
                        value: {
                            id: 0,
                            exigeTaxaRetorno: false,
                        }
                    },
                    ...props.cartoes
                ],
                defaultValue: props.initialData && props.initialData.formaPagamentoId ? {
                    label: props.initialData.formaPagamentoId.nome,
                    value: {
                        id: props.initialData.formaPagamentoId.id,
                        exigeTaxaRetorno: props.initialData.formaPagamentoId.exigeTaxaRetorno,
                    }
                } : {
                    label: "Selecione a forma de pagamento",
                    value: {
                        id: 0,
                        exigeTaxaRetorno: false,
                    }
                },
                onChange: handleChange,
                stylWarp: {
                    marginTop: '2rem',
                },
            },
            ...troco,
            ...(optionsModal
                ? [
                      {
                          toolTipLegend:
                              'Informe se deseja chamar um entregador imediatamente (Pendente) ou se deseja apenas salvar o pedido (Expedindo)',
                          type: 'select-simple',
                          label: 'Status do pedido',
                          placeholder: 'Selecione o status',
                          name: 'statusId',

                          options: optionsStatusJson,
                          defaultValue: optionsStatusJson[0],
                      },
                  ]
                : []),
            ...(optionsModal
                ? [
                      {
                          toolTipLegend:
                              'Informe se esta coleta precisa de um "Carro" ou uma "Moto" para ser entregue.',
                          type: 'hidden',
                          name: 'modalTransporteId',
                          value: optionsModal[0].value
                      },
                  ]
                : []),

            {
                type: 'button',
                loading: props.loading,
                value: 'Finalizar',
            },
        ];
    }, [retorno, props, troco]);

    const frete = useMemo(() => {
        const frete =
            props && props.fretes
                ? {
                      retorno,
                      ...props.fretes.find(
                          ({ id }) => id === `${modalTransporteId}`
                      ),
                  }
                : null;
        return frete;
    }, [props, retorno, modalTransporteId]);

    return { childsForm: response, frete };
};
