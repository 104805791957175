import React, { FC, memo, useContext } from 'react';
import PageSignupComponent from './component';

import { SubmitHandler } from '@unform/core';
import { SignupContext } from '../context';

const BusinessComponent: FC = memo(() => {
	const {
		steps: {
			business: { ref: formRef },
		},
		initialDataForm,
		enqueteProspecto,
		switchComponent,
		submit,
	} = useContext(SignupContext);
	const handleSubmit: SubmitHandler<IFormLogin> = async (data) => {
		const { setErrors } = formRef.current;
		const aErrors = Object.keys(data).filter((value) => data[value] === '');
		const oErros = {};
		aErrors.forEach((key) => (oErros[key] = 'Selecione uma das opções'));
		if (aErrors.length > 0) {
			setErrors(oErros);
		} else {
			submit({ atual: 'business', proximo: 'company' });
		}
	};
	const props: any = {
		formRef,
		...enqueteProspecto.enqueteAtivaProspecto,
		initialData: initialDataForm[switchComponent],
		handleSubmit,
	};
	return <PageSignupComponent {...props} />;
});
export default BusinessComponent;
